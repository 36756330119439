/* eslint-disable no-magic-numbers */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import AddSignForm, { AddSignFormHandle } from '@/features/Signs/addSign/components/AddSignForm';
import AddSignDefaultContent from '@/features/Signs/addSign/components/defaultContent/AddSignDefaultContent';
import AddSignPreview from '@/features/Signs/addSign/components/AddSignPreview';
import { ISignCreateRequest } from '@/types/sign';
import { calculateAspectRatio, formatTimeZoneToIANA } from '@/utils/helpers';
import { useCreateSign } from '@/hooks/sign';
import { useReadFiles } from '@/hooks/file';
import { IFileResponse, IFileUploadForm } from '@/types/file';
import useMyUser from '@/context/myUser/useMyUser';
import { useReadOneCompany } from '@/hooks/company';
import { VALID_FILE_EXTENSIONS_IMAGE, VALID_FILE_EXTENSIONS_VIDEO_PREVIEW } from '@/utils/constants';

const NAVIGATE_TO_REDIRECT_DELAY_IN_MILLISECONDS = 4000;

const AddSignToCompanyPage = () => {
  const routerNavigate = useNavigate();

  const {
    createSign,
    createdSign,
    createSignIsLoading,
  } = useCreateSign();

  const {
    files,
    readFiles,
    readFilesIsLoading,
  } = useReadFiles();

  const {
    myUser,
  } = useMyUser();

  const {
    company,
    readOneCompany,
  } = useReadOneCompany();

  const [searchParams] = useSearchParams();
  const companyIdQueryParam = searchParams.get('companyId');
  const redirectUrl = searchParams.get('redirectUrl');

  const [step, setStep] = useState(1);
  const [systemDefaultFiles, setSystemDefaultFiles] = useState<{static: IFileResponse, animated: IFileResponse}>();
  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const [signValues, setSignValues] = useState<ISignCreateRequest | null>(null);
  const addFormRef = useRef<AddSignFormHandle>(null);

  const previousButtonText = step === 1
    ? 'Cancel'
    : 'Previous';
  const nextButtonText = step === 3
    ? 'Submit'
    : 'Next';
  const stepTitleDictionary: Record<number, string> = {
    1: 'Step 1: Configure',
    2: 'Step 2: Choose Default Content',
    3: 'Step 3: Review',
  };
  const stepTitle = stepTitleDictionary[step];

  const onSignAdd = (values: ISignCreateRequest) => {
    setSignValues(values);
    const signAspectRatio = calculateAspectRatio(values.width, values.height);
    readFiles({
      origin: 'default',
      aspectRatio: signAspectRatio,
    });

    setStep(step + 1);
  };

  const onSelectDefaultContent = (typeOfDefault: 'custom' | 'system', defaultFile: IFileUploadForm | IFileResponse) => {
    if ('id' in defaultFile) setSelectedFile(defaultFile);
  };

  const onSignAddSubmit = () => {
    const companyId = companyIdQueryParam || myUser?.company.id;
    if (signValues && companyId) {
      createSign({
        ...signValues,
        defaultFileId: selectedFile?.id || null,
        companyId,
        timezone: formatTimeZoneToIANA(signValues.timezone),
      });
    }
  };

  const onClickPreviousButton = () => {
    switch (step) {
      case 1:
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          routerNavigate('/signs');
        }
        break;
      case 2: // fallthrough
      case 3:
        setStep(step - 1);
        break;
      default:

        // do nothing
    }
  };
  const onClickNextButton = () => {
    switch (step) {
      case 1:
        addFormRef?.current?.submit();
        break;

      case 2:
        // show toast error if file not selected
        setStep(step + 1);
        break;

      case 3:
        onSignAddSubmit();
        break;
      default:

        // do nothing
    }
  };

  const renderBody = (currentStep: number) => {
    if (currentStep === 1) {
      return (<div className="my-2">
        <AddSignForm
          signValues={signValues}
          ref={addFormRef}
          selectedFile={selectedFile}
          onFormSubmit={onSignAdd}
        />
      </div>);
    }

    if (currentStep === 2) {
      return (<>
        {systemDefaultFiles && (<div className="py-2">
          <AddSignDefaultContent
            systemFiles={systemDefaultFiles}
            onChange={onSelectDefaultContent}
            isLoading={readFilesIsLoading}
          />
        </div>)}
      </>);
    }

    if (currentStep === 3 && signValues) {
      return (<div>
        <AddSignPreview
          sign={signValues}
          selectedFile={selectedFile}
        />
      </div>);
    }

    return <></>;
  };

  useEffect(() => {
    if (companyIdQueryParam) {
      readOneCompany(companyIdQueryParam);
      return;
    }
  }, []);

  useEffect(() => {
    // close modal on successful sign creation
    if (!createdSign) return;

    if (redirectUrl) {
      toast(
        `You are being navigated to ${redirectUrl}`,
        {
          type: 'info',
        },
      );
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, NAVIGATE_TO_REDIRECT_DELAY_IN_MILLISECONDS);
      return;
    }

    routerNavigate('/signs');
  }, [createdSign]);

  useEffect(() => {
    if (!files) return;
    const staticDefaultFile = files.find((file) => VALID_FILE_EXTENSIONS_IMAGE.includes(file.fileType));
    const animatedDefaultFile = files.find((file) => VALID_FILE_EXTENSIONS_VIDEO_PREVIEW.includes(file.fileType));

    setSystemDefaultFiles({
      static: staticDefaultFile || files[0],
      animated: animatedDefaultFile || files[0],
    });

    if (!selectedFile) {
      setSelectedFile(staticDefaultFile || files[0]);
    }
  }, [files]);

  return (
    <section>
      <div className="bg-white shadow-sm p-3">
        <h1 className="h4">
          Add Sign
        </h1>
        <h2 className="h6 ">
          {stepTitle}
        </h2>
        {(company) && (
          <div>
            <FontAwesomeIcon icon={faBuilding} /> {company.name}
          </div>
        )}
      </div>

      {renderBody(step)}

      <div className="bg-white shadow-sm mt-2 p-3">
        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="light"
            onClick={onClickPreviousButton}
            disabled={createSignIsLoading}
          >
            { previousButtonText }
          </Button>

          <Button
            className="ms-2"
            type="submit"
            variant="primary"
            onClick={onClickNextButton}
            disabled={createSignIsLoading}
          >
            {(createSignIsLoading)
              ? (<FontAwesomeIcon
                icon={faCircleNotch}
                spin
              />)
              : nextButtonText }
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AddSignToCompanyPage;
