import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISignResponse } from '@/types/sign';
import { sortOption } from '@/features/Schedule/types';
import { useToggle } from '@/hooks';
import CustomIcon from '@/common/CustomIcon';

import SignSelector from './SignSelector';
import FrequencyWeightModal from './FrequencyWeightModal';
import SignPreviewModal from './SignPreviewModal';

import '@/features/Schedule/Schedule.scss';

interface ScheduleControlProps {
  selectedSign: ISignResponse | null,
  showScheduleSortOptions: () => void,
  showMultiSelectActions: () => void,
  showScheduleActionOptions: () => void,
  inMultiSelectMode: boolean,
  sortValue: sortOption,
  disableButtons?: boolean,
}

const ScheduleControls = ({
  selectedSign,
  showScheduleActionOptions,
  showMultiSelectActions,
  showScheduleSortOptions,
  inMultiSelectMode,
  sortValue,
  disableButtons = false,
}: ScheduleControlProps) => {
  const { show: showFrequencyModal, hide: hideFrequencyModal, isVisible: isFrequencyModalVisible } = useToggle();
  const { show: showSignPreviewModal, hide: hideSignPreviewModal, isVisible: isSignPreviewModalVisible } = useToggle();

  return (
    <div className="schedule bg-white px-3 py-4 shadow-sm">

      <Row>
        <Col xs={12} md={6}>
          <SignSelector
            selectedSign={selectedSign}
          />
        </Col>
      </Row>
      <div className="mt-2 d-flex justify-content-between">
        <div>
          <Button
            className="d-inline-flex align-items-center mb-2 me-2"
            variant="outline-primary"
            onClick={showSignPreviewModal}
            disabled={!selectedSign || disableButtons}
          >
            <CustomIcon
              className="me-1"
              type="eye"
            />
            Preview
          </Button>
          <Button
            className="d-inline-flex align-items-center mb-2"
            variant="outline-primary"
            onClick={showFrequencyModal}
            disabled={!selectedSign || disableButtons}
          >
            <CustomIcon
              className="me-1"
              type="bar-chart"
            />
            Frequency
          </Button>
        </div>

        <div className="d-flex justify-content-end flex-wrap">
          <Button
            className="mb-2"
            variant="outline-primary"
            onClick={showScheduleSortOptions}
            disabled={disableButtons}
          >
            <FontAwesomeIcon icon={sortValue.icon} />
          </Button>
          <div className="ms-2 d-flex">
            {(inMultiSelectMode) && (
              <Button
                className="me-2 mb-2"
                variant="outline-primary"
                onClick={showMultiSelectActions}
              >
                Actions
              </Button>
            )}
            <Button
              className="mb-2"
              variant="outline-primary"
              onClick={showScheduleActionOptions}
              disabled={disableButtons}
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Button>
          </div>
        </div>
      </div>

      {(selectedSign) && (<FrequencyWeightModal
        selectedSign={selectedSign}
        isVisible={isFrequencyModalVisible}
        onHide={hideFrequencyModal}
      />)}

      {(selectedSign) && (<SignPreviewModal
        signId={selectedSign.id}
        isVisible={isSignPreviewModalVisible}
        onHide={hideSignPreviewModal}
      />)}
    </div>
  );
};

export default ScheduleControls;
