import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IContentResponse, IContentUpdateRequest } from '@/types/content';
import {
  CONTENT_UPDATED_ERROR,
  CONTENT_UPDATED_MESSAGE,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdateOneContent = () => {
  const [updatedContent, setUpdatedContent] = useState<IContentResponse | null>(null);
  const [putContentApiConfig, setPutContentApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateContentResponse,
    loading: updateOneContentIsLoading,
  } = useCallApi(putContentApiConfig, {
    successMessage: CONTENT_UPDATED_MESSAGE,
    errorMessage: CONTENT_UPDATED_ERROR,
  });

  const updateOneContent = (contentToUpdate: IContentUpdateRequest) => {
    setPutContentApiConfig({
      url: `/contents/${contentToUpdate.id}`,
      method: 'put',
      data: contentToUpdate,
    });
  };

  useEffect(() => {
    setUpdatedContent(updateContentResponse?.data);
  }, [updateContentResponse]);

  return {
    updateOneContent,
    updatedContent,
    updateOneContentIsLoading,
  };
};

export default useUpdateOneContent;
