import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import Badge from 'react-bootstrap/esm/Badge';
import { IBumperForm } from '@/features/Schedule/types';
import { Formik, FormikProps } from 'formik';
import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import { IBumperResponse } from '@/types/sign';
import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';

import CardDetailsDuration from './CardDetailsDuration';
import CardDetailsPlaybackActivity from './CardDetailsPlaybackActivity';

interface EditBumperModalProps {
  isVisible: boolean;
  onHide: () => void;
  bumperData: IBumperResponse;
  isSubmitting: boolean;
  handleFormSubmit: (values: (IBumperForm)) => void;
}

const EditBumperModal = ({
  isVisible,
  onHide,
  bumperData,
  isSubmitting,
  handleFormSubmit,
}: EditBumperModalProps) => {
  const initialBumperFormValues: IBumperForm = {
    durationInMilliseconds: bumperData.durationInMilliseconds,
    isPaused: bumperData.isPaused,
  };

  const validate = (values: IBumperForm) => {
    const errors: {
      durationInMilliseconds?: string,
    } = {};

    if (typeof values.durationInMilliseconds === 'number' && values.durationInMilliseconds <= 0) {
      errors.durationInMilliseconds = 'Duration must be greater than 0';
    }

    return errors;
  };

  const isImageFileType = bumperData
    && bumperData.file
    && bumperData.file.fileType
    && VALID_FILE_EXTENSIONS_IMAGE.includes(bumperData.file.fileType.toLowerCase());

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      fullscreen="md-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit Bumper
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 grey-modal-body">
        <div>
          <Formik
            initialValues={initialBumperFormValues}
            onSubmit={handleFormSubmit}
            validate={validate}
          >
            {({
              values,
              errors,
              handleSubmit,
              setFieldValue,
            }: FormikProps<IBumperForm>) => {
              const durationInMilliseconds = values.durationInMilliseconds || 0;

              return (
                <Form
                  id="edit-bumper-form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  { /** Preview */}
                  <section className="bg-white shadow-sm">
                    <div className="bg-dark d-flex justify-content-center text-white">
                      <FileWithPreview
                        file={bumperData.file}
                      />
                    </div>
                    <div className="p-3">
                      <p className="mb-0">
                        {bumperData.file.width} x {bumperData.file.height}
                      </p>
                      <div className="mt-2">
                        <Badge className="bg-secondary">
                          {bumperData.file.fileType}
                        </Badge>
                      </div>
                    </div>
                  </section>
                  {/* Duration */}
                  { isImageFileType && (<div className="mt-2">
                    <CardDetailsDuration
                      durationInMilliseconds={durationInMilliseconds}
                      error={errors.durationInMilliseconds}
                      onChange={(newDurationInMilliseconds) => {
                        setFieldValue('durationInMilliseconds', newDurationInMilliseconds);
                      }}
                    />
                  </div>)}
                  {/* Paused */}
                  <div className="my-2">
                    <CardDetailsPlaybackActivity
                      item={values}
                      setFieldValue={setFieldValue}
                      description={[
                        'This setting is used to pause the bumper. An inactive bumper will ',
                        (<strong key="1">not</strong>),
                        ' play.',
                      ]}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer">
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          form="edit-bumper-form"
          disabled={isSubmitting}
        >
          {(isSubmitting)
            ? <FontAwesomeIcon icon={'circle-notch'} spin />
            : 'Submit'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBumperModal;
