export const downloadFileFromData = (data: any, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const linkEl = document.createElement('a');

  linkEl.href = url;
  linkEl.setAttribute('download', filename);

  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
};

export const getFilenameFromContentDispositionHeader = (contentDisposition: string | null, defaultFileName: string) => {
  if (typeof contentDisposition === 'string') {
    const filenameParts = contentDisposition.split('filename=');

    return typeof filenameParts[1] !== 'undefined'
      ? filenameParts[1]
      : defaultFileName;
  }

  return defaultFileName;
};
