import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// import Row from 'react-bootstrap/esm/Row';

import { useReadPlaylists } from '@/hooks/playlist';
import Loader from '@/common/Loader';
import { SortOption } from '@/features/Files/FilesTab/types';
import { SORT_OPTIONS } from '@/features/Files/FilesTab/constants';

// import PlaylistList from './components/PlaylistList';
import PlaylistListControls from './components/PlaylistListControls';
import PlaylistListView from './components/PlaylistListView';

const PlaylistTab = () => {
  const routerLocation = useLocation();
  const [searchParams] = useSearchParams();
  const searchByName = searchParams.get('name');

  const [sort, setSort] = useState<SortOption>(SORT_OPTIONS[0]);

  const {
    readPlaylists,
    playlists,
    readPlaylistsIsLoading,
  } = useReadPlaylists();

  const refresh = () => {
    const params = {
      name: routerLocation.search ? searchByName : null,
      sort: sort.field,
      direction: sort.direction,
    };
    readPlaylists(params);
  };

  useEffect(() => {
    refresh();
  }, [routerLocation, sort]);

  // const renderBody = () => {
  //   if (!readPlaylistsIsLoading && (!playlists || (playlists.length === 0))) {
  //     return (<div>No Playlists Found</div>);
  //   }

  //   return (<PlaylistList
  //     playlistList={playlists || []}
  //     refreshPlaylist={refresh}
  //   />);
  // };

  return (
    <>
      <div className="bg-white px-3 pb-3">
        <PlaylistListControls
          handleSort={setSort}
        />
      </div>
      {(readPlaylistsIsLoading)
        ? (<div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>)
        : (<>
          <div className="mt-2 px-3 py-4 bg-white shadow-sm">
            <PlaylistListView
              playlistList={playlists || []}
              refreshPlaylist={refresh}
            />
          </div>
        </>
        )}
      {/* <Row className="mt-2 g-1 align-items-end">
        {renderBody()}
      </Row> */}
    </>
  );
};

export default PlaylistTab;
