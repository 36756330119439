import React, { CSSProperties } from 'react';

import { ReactComponent as AccountIcon } from '@/assets/icons/svg/account.svg';
import { ReactComponent as BarChartIcon } from '@/assets/icons/svg/bar-chart.svg';
import { ReactComponent as ClockIcon } from '@/assets/icons/svg/clock.svg';
import { ReactComponent as EyeIcon } from '@/assets/icons/svg/eye.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/svg/file.svg';
import { ReactComponent as FolderIcon } from '@/assets/icons/svg/folder.svg';
import { ReactComponent as FolderOpenIcon } from '@/assets/icons/svg/folder_open.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/svg/home.svg';
import { ReactComponent as PlaylistIcon } from '@/assets/icons/svg/playlist.svg';
import { ReactComponent as TemplateIcon } from '@/assets/icons/svg/template.svg';
import { ReactComponent as SignIcon } from '@/assets/icons/svg/sign.svg';
import { ReactComponent as UploadIcon } from '@/assets/icons/svg/upload.svg';
import { ReactComponent as WandIcon } from '@/assets/icons/svg/wand.svg';

export type CustomIconType =
  'account'
  | 'bar-chart'
  | 'clock'
  | 'eye'
  | 'file'
  | 'folder'
  | 'folder-open'
  | 'home'
  | 'playlist'
  | 'sign'
  | 'template'
  | 'upload'
  | 'wand';

interface CustomIconProps {
  type: CustomIconType,
  className?: string,
  style?: CSSProperties,
}

const ICON_DICTIONARY: Record<CustomIconType, any> = {
  'account': AccountIcon,
  'bar-chart': BarChartIcon,
  'clock': ClockIcon,
  'eye': EyeIcon,
  'file': FileIcon,
  'folder': FolderIcon,
  'folder-open': FolderOpenIcon,
  'home': HomeIcon,
  'playlist': PlaylistIcon,
  'sign': SignIcon,
  'template': TemplateIcon,
  'upload': UploadIcon,
  'wand': WandIcon,
};

const CustomIcon = ({
  type,
  className,
  style = {},
}: CustomIconProps) => {
  const mergedStyles: CSSProperties = {
    display: 'inline-block',
    height: '1em',
    ...style,
  };

  return React.createElement(ICON_DICTIONARY[type], {
    className,
    style: mergedStyles,
  });
};

export default CustomIcon;
