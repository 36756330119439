import React from 'react';
import { Routes, Route } from 'react-router-dom';

import EditPlaylist from '@/features/Schedule/playlist/EditPlaylist';
import EditContent from '@/features/Schedule/components/EditContent';

import FileTabNavigation from './';
import FileDetails from './FilesTab/components/FileDetails';

/**
 * Builds the routes for the file feature.
 *
 * @returns {JSX.Element}
 */
export function FileRoutesSwitch(): JSX.Element {
  return (
    <div>
      <Routes>
        <Route path="/files/folders/:folderId/files/:fileId" element={<FileDetails />} />
        <Route path="/files/:fileId" element={<FileDetails />} />
        <Route path="/playlists/:playlistId" element={<EditPlaylist />} />
        <Route path="/contents/:contentId" element={<EditContent />} />
        <Route path="/*" element={<FileTabNavigation />} />
      </Routes>
    </div>
  );
}
