import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IContentResponse } from '@/types/content';
import {
  CONTENT_DUPLICATED_MESSAGE,
  CONTENT_DUPLICATED_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useDuplicateOneContent = () => {
  const [duplicatedContent, setDuplicatedContent] = useState<IContentResponse | null>(null);
  const [duplicateContentApiConfig, setDuplicateContentApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: duplicateOneContentResponse,
    loading: duplicateOneContentIsLoading,
  } = useCallApi(duplicateContentApiConfig, {
    successMessage: CONTENT_DUPLICATED_MESSAGE,
    errorMessage: CONTENT_DUPLICATED_ERROR,
  });

  const duplicateOneContent = (contentName: string, contentToDuplicate: IContentResponse) => {
    setDuplicateContentApiConfig({
      url: `/contents/${contentToDuplicate.id}/duplicate`,
      method: 'post',
      data: {
        name: contentName,
        signIds: contentToDuplicate.signs.map((s) => s.id),
        playlistIds: contentToDuplicate.playlists.map((playlist) => playlist.id),
      },
    });
  };

  useEffect(() => {
    setDuplicatedContent(duplicateOneContentResponse?.data);
  }, [duplicateOneContentResponse]);

  return {
    duplicateOneContent,
    duplicatedContent,
    duplicateOneContentIsLoading,
  };
};

export default useDuplicateOneContent;

