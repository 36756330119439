import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import HubAxiosInstance from '@/api/hubAxios';
import { IMyUserResponse } from '@/types/user';
import { useAuth0 } from '@auth0/auth0-react';

import { MyUserContext, MyUserContextData } from './myUserContext';

interface MyUserProviderProps {
  children?: React.ReactNode,
}

type Action = { type: 'LOAD_MY_USER_STARTED' }
  | { type: 'LOAD_MY_USER_COMPLETE' }
  | { type: 'MY_USER_LOADED', myUser: IMyUserResponse }

const reducer = (state: MyUserContextData, action: Action): MyUserContextData => {
  switch (action.type) {
    case 'LOAD_MY_USER_STARTED':
      return {
        ...state,
        myUserIsLoading: true,
      };
    case 'LOAD_MY_USER_COMPLETE':
      return {
        ...state,
        myUserIsLoading: false,
      };
    case 'MY_USER_LOADED':
      return {
        ...state,
        myUser: action.myUser,
      };
    default:
      return state;
  }
};

const initialState: MyUserContextData = {
  myUser: null,
  myUserIsLoading: false,
  refreshMyUser: async () => {},
};

const MyUserProvider = ({
  children,
}: MyUserProviderProps): JSX.Element => {
  const [state, setState] = useReducer(reducer, initialState);

  const { user: auth0User, getAccessTokenSilently } = useAuth0();

  const readMyUser = useCallback(async () => {
    if (!auth0User) return;

    try {
      setState({
        type: 'LOAD_MY_USER_STARTED',
      });

      const token = await getAccessTokenSilently();
      const result = await HubAxiosInstance.request<IMyUserResponse>({
        method: 'get',
        url: '/users/me',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setState({
        type: 'MY_USER_LOADED',
        myUser: result.data,
      });
    } finally {
      setState({
        type: 'LOAD_MY_USER_COMPLETE',
      });
    }
  }, [auth0User]);

  useEffect(() => {
    readMyUser();
  }, [auth0User]);

  const contextValue = useMemo(() => {
    return {
      ...state,
      refreshMyUser: readMyUser,
    };
  }, [
    state,
    readMyUser,
  ]);

  return (
    <MyUserContext.Provider value={contextValue}>
      {children}
    </MyUserContext.Provider>
  );
};

export default MyUserProvider;
