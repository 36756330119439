import React, { useCallback, useRef } from 'react';

const useLongPress = (
  onLongPress: (() => unknown),
  onClick: (() => unknown),
  defaultLongPressOptions: {shouldPreventDefault: boolean, delay: number},
) => {
  const { shouldPreventDefault, delay } = defaultLongPressOptions;
  const longPressTriggered = useRef(false);
  const timeout = useRef<number>();
  const hasScrolled = useRef(false);

  const onTouchMove = useCallback(
    () => {
      hasScrolled.current = true;
    },
    [],
  );

  const onStart = useCallback(
    (e: React.BaseSyntheticEvent) => {
      // reset state
      longPressTriggered.current = false;
      hasScrolled.current = false;

      e.target.addEventListener('touchmove', onTouchMove, {
        passive: false,
        once: true,
      });

      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => {
        if (!hasScrolled.current) {
          onLongPress();
          longPressTriggered.current = true;
        }
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  const onEnd = useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }

      // cleanup touchmove event
      if (e.target) {
        e.target.removeEventListener('touchmove', onTouchMove);
      }

      if (hasScrolled.current === true) {
        // if user has touch scrolled
        // then prevent click
        if (e.cancelable) {
          e.preventDefault();
        }
      } else if (hasScrolled.current === false && longPressTriggered.current === false) {
        // if user has not scrolled and long press has not been triggered
        // then run normal click function
        onClick();
      } else if (hasScrolled.current === false && longPressTriggered.current === true) {
        // if user has not scrolled and long press has been triggered
        // then do nothing
      }

      // call prevent default to prevent click events from firing on touchEnd
      // see: https://github.com/facebook/react/issues/9809#issuecomment-395607117
      if (e.preventDefault && e.cancelable) {
        e.preventDefault();
      }
    },
    [onClick],
  );

  return {
    onMouseDown: (e: React.BaseSyntheticEvent) => onStart(e),
    onTouchStart: (e: React.BaseSyntheticEvent) => onStart(e),
    onMouseUp: (e: React.BaseSyntheticEvent) => onEnd(e),
    onTouchEnd: (e: React.BaseSyntheticEvent) => onEnd(e),
  };
};

export default useLongPress;
