import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/esm/Row';

import { useToggle } from '@/hooks';
import { useCreateFiles, useUpdateOneFile, useMoveFiles, useDeleteFiles } from '@/hooks/file';
import { useCreateFolder, useDeleteFolders, useMoveFolders, useReadOneFolder, useUpdateOneFolder } from '@/hooks/folder';
import Loader from '@/common/Loader';
import {
  TCreateMenuActions,
  TCreateMenuActionName,
  TFolderActionName,
  TFolderActions,
  TFileActions,
  TFileActionName,
} from '@/features/Files/FilesTab/types';
import { IFileResponse, IFilesUploadForm, IFileUploadForm } from '@/types/file';
import { IFolderResponse } from '@/types/folder';
import FloatingActionButton, { FloatinActionButtonOptions } from '@/common/FloatingActionButton';

import FileListControls from './components/FileListControls';

import FolderGridView from './components/FolderGridView';
import FileGridView from './components/FileGridView';
import UploadFilesModal from './components/UploadFilesModal';
import CreateFolderModal from './components/CreateFolderModal';
import ConfirmDeleteModal from './components/FoldersFilesConfirmDeleteModal';
import FolderMoveOptions from './components/FolderMoveDestinationOptions';
import FileBreadcrumbs from './components/FileBreadcrumbs';
import FolderListView from './components/FolderListView';
import FileListView from './components/FileListView';

import './Files.scss';
import { FILE_ACTION_MENU_ITEMS } from './constants';

interface FilesTabProps {
  searchByName?: string,
}

const FilesTab = ({
  searchByName,
}: FilesTabProps) => {
  // Router Constants
  const { folderId } = useParams();
  const routerLocation = useLocation();

  // Togglable Components
  const { show: showCreateFolder, hide: hideCreateFolder, isVisible: isCreateFolderVisible } = useToggle();
  const { show: showUploadFiles, hide: hideUploadFiles, isVisible: isUploadFilesVisible } = useToggle();
  const { show: showFolderMoveOptions, hide: hideFolderMoveOptions, isVisible: isFolderMoveOptionsVisible } = useToggle();
  const { show: showConfirmDelete, hide: hideConfirmDelete, isVisible: isConfirmDeleteVisible } = useToggle();
  const { isVisible: isGridView, toggle: toggleGridView } = useToggle();

  const {
    readOneFolder,
    folders: folderFolders,
    files: folderFiles,
    breadcrumbs: folderBreadcrumbs,
    readOneFolderIsLoading,
  } = useReadOneFolder();

  const {
    createFolder,
    createdFolder,
  } = useCreateFolder();

  const {
    updateOneFolder,
    updatedFolder,
  } = useUpdateOneFolder();

  const {
    deleteFolders,
    deletedFolders,
  } = useDeleteFolders();

  const {
    moveFolders,
    movedFolders,
  } = useMoveFolders();

  const {
    createFiles,
    createdFiles,
    createFilesIsLoading,
  } = useCreateFiles();

  const {
    updateOneFile,
    updatedFile,
    updateOneFileIsLoading,
  } = useUpdateOneFile();

  const {
    moveFiles,
    movedFiles,
  } = useMoveFiles();

  const {
    deleteFiles,
    deletedFiles,
  } = useDeleteFiles();

  // API calls loading
  const isLoading = readOneFolderIsLoading || createFilesIsLoading;

  // component state
  const [sort, setSort] = useState<{field: string, direction: 'asc' | 'desc'}>({ field: 'name', direction: 'asc' });
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const [folderToDelete, setFolderToDelete] = useState<IFolderResponse | null>(null);
  const [folderToMove, setFolderToMove] = useState<IFolderResponse | null>(null);

  const [fileToMove, setFileToMove] = useState<IFileResponse | null>(null);
  const [fileToDelete, setFileToDelete] = useState<IFileResponse | null>(null);

  const [inMultiSelectMode, setInMultiSelectMode] = useState<boolean>(false);
  const [multipleFiles, setMultipleFiles] = useState<IFileResponse[]>([]);
  const [multipleFolders, setMultipleFolders] = useState<IFolderResponse[]>([]);

  const multipleFolderIds = multipleFolders.reduce((acc: number[], currFolder) => {
    if (currFolder.id) return [currFolder.id, ...acc];
    return acc;
  }, []);
  const multipleFileIds = multipleFiles.map((fileVal) => fileVal.id);

  const searchFoldersAndFiles = () => {
    readOneFolder(null, { name: searchByName, sort: sort.field, direction: sort.direction });
  };

  const loadData = () => {
    if (searchByName) {
      return searchFoldersAndFiles();
    }

    return readOneFolder(folderId || null, { sort: sort.field, direction: sort.direction });
  };

  // TODO - this useEffect runs twice on load because the api variables go from null to undefined
  useEffect(() => {
    loadData();
  }, [
    searchByName,
    routerLocation,
    sort,
    deletedFolders,
    createdFolder,
    createdFiles,
    updatedFile,
    deletedFiles,
    updatedFolder,
    movedFolders,
    movedFiles,
  ]);

  useEffect(() => {
    if (!createdFiles) return;

    hideUploadFiles();
  }, [createdFiles]);

  useEffect(() => {
    if (!deleteConfirmed) return;

    if (fileToDelete) {
      const fileIds = [fileToDelete.id];
      deleteFiles(fileIds);
    }

    if (multipleFiles.length > 0) {
      deleteFiles(multipleFileIds);
    }
    setDeleteConfirmed(false);
    hideConfirmDelete();
  }, [fileToDelete?.id, deleteConfirmed]);

  useEffect(() => {
    if (!deleteConfirmed) return;

    if (multipleFolderIds.length > 0) {
      deleteFolders(multipleFolderIds);
    }

    if (
      folderToDelete
      && folderToDelete.id
    ) {
      deleteFolders([folderToDelete.id]);
      hideConfirmDelete();
    }
    setMultipleFiles([]);
    setDeleteConfirmed(false);
  }, [folderToDelete?.id, deleteConfirmed]);

  const onDeleteFolder = (folderClicked: IFolderResponse) => {
    setFolderToDelete(folderClicked);
    setFileToDelete(null);
    showConfirmDelete();
  };

  const moveFolder = (folderClicked: IFolderResponse, toFolderId?: number) => {
    if (toFolderId) return updateOneFolder({ ...folderClicked, folderId: toFolderId });
    showFolderMoveOptions();
    return setFolderToMove(folderClicked);
  };

  const moveFile = (fileClicked: IFileResponse, toFolderId?: number) => {
    if (toFolderId || toFolderId === null) return moveFiles([fileClicked.id], toFolderId);
    showFolderMoveOptions();
    return setFileToMove(fileClicked);
  };

  const deleteFile = (fileClicked: IFileResponse) => {
    setFileToDelete(fileClicked);
    setFolderToDelete(null);
    showConfirmDelete();
  };

  const updateMultipleFiles = (action: TFileActionName) => {
    const FileActionsMap: TFileActions = {
      'move': () => {
        showFolderMoveOptions();
      },
      'delete': () => {
        showConfirmDelete();
      },
    };
    if (FileActionsMap[action as TFileActionName]) FileActionsMap[action as TFileActionName]();
  };

  const updateMultipleFolders = (action: TFolderActionName) => {
    const FolderActionsMap: TFolderActions = {
      'move': () => {
        showFolderMoveOptions();
      },
      'delete': () => {
        showConfirmDelete();
      },
    };
    if (FolderActionsMap[action as TFolderActionName]) FolderActionsMap[action as TFolderActionName]();
  };

  const renameFile = (fileClicked: IFileResponse) => {
    updateOneFile(fileClicked);
  };

  const renameFolder = (folderClicked: IFolderResponse) => {
    updateOneFolder(folderClicked);
  };

  const replaceFile = (fileClicked: IFileResponse, fileToUpload: IFileUploadForm) => {
    const formData = new FormData();
    formData.append('file', fileToUpload.file);

    // set file type
    formData.append('fileType', ((fileToUpload.file.type).replace(/(.*)\//g, '')));
    formData.append('origin', 'upload');

    updateOneFile({ ...fileClicked, file: formData });
  };

  const handleCreateMenuItemClick = (action: TCreateMenuActionName): void => {
    const CreateMenuActionMap: TCreateMenuActions = {
      'upload-file': () => {
        showUploadFiles();
      },
      'add-folder': function addFile() {
        showCreateFolder();
      },
    };
    if (CreateMenuActionMap[action as TCreateMenuActionName]) CreateMenuActionMap[action as TCreateMenuActionName]();
  };

  const createNewFolder = (folderName: string) => {
    const newFolder = {
      name: folderName,
      folderId: folderId || null,
    };

    createFolder(newFolder);
    if (newFolder) {
      hideCreateFolder();
    }
  };

  const addFolderToMultiSelect = (folderToSelect: IFolderResponse) => {
    setMultipleFolders((selectedFolders) => [...selectedFolders, folderToSelect]);
    setInMultiSelectMode(true);

    // Reset stored values for files
    setFileToDelete(null);
  };

  const removeFolderFromMultiSelect = (folderToDeselect: IFolderResponse) => {
    const multiSelectWithoutFolder = multipleFolders.filter((f) => f.id !== folderToDeselect.id);
    if (multiSelectWithoutFolder.length !== multipleFolders.length) setMultipleFolders(multiSelectWithoutFolder);
    if (multiSelectWithoutFolder.length === 0 && multipleFiles.length === 0) setInMultiSelectMode(false);
  };

  const addFileToMultiSelect = (file: IFileResponse) => {
    setMultipleFiles((selectedFiles) => [...selectedFiles, file]);
    setInMultiSelectMode(true);

    // Reset stored values for folders
    setFolderToDelete(null);
  };

  const removeFileFromMultiSelect = (file: IFileResponse) => {
    const multiSelectWithoutFile = multipleFiles.filter((f) => f.id !== file.id);
    if (multiSelectWithoutFile.length !== multipleFiles.length) setMultipleFiles(multiSelectWithoutFile);
    if (multiSelectWithoutFile.length === 0 && multipleFolders.length === 0) setInMultiSelectMode(false);
  };

  const onSelectFolderDestination = (folderDestinationId: number | null) => {
    if (fileToMove) {
      moveFiles([fileToMove.id], folderDestinationId);

      // remove file from multiselect list. Without this a bug can occur
      // where the user moves the only file in the multiselect. stranding the
      // user in multiselect mode
      removeFileFromMultiSelect(fileToMove);
    }
    if (folderToMove && folderToMove.id) {
      moveFolders([folderToMove.id], folderDestinationId);
    }
    if (multipleFiles.length > 0) {
      moveFiles(multipleFileIds, folderDestinationId);
    }
    if (multipleFolderIds.length > 0) {
      moveFolders(multipleFolderIds, folderDestinationId);
    }

    if (isFolderMoveOptionsVisible) hideFolderMoveOptions();
  };

  const uploadFiles = (filesToUpload: IFilesUploadForm) => {
    createFiles(filesToUpload, folderId);
  };

  const filesToDelete = (): IFileResponse[] | null => {
    if (multipleFiles.length > 0 && !folderToDelete) return multipleFiles;

    if (fileToDelete) return [fileToDelete];

    return null;
  };

  const foldersToDelete = (): IFolderResponse[] | null => {
    if (multipleFolders.length > 0 && !fileToDelete) return multipleFolders;

    if (folderToDelete) return [folderToDelete];

    return null;
  };

  const floatingActionOptions: FloatinActionButtonOptions = FILE_ACTION_MENU_ITEMS.map((item) => ({
    icon: item.icon,
    text: item.text,
    onClick: () => handleCreateMenuItemClick(item.action),
  }));

  return (
    <div className="has-fab" key={folderId}>
      <div className="bg-white px-3 pb-3">
        <FileListControls
          multipleFiles={multipleFiles}
          multipleFolders={multipleFolders}
          handleMultipleFileUpdates={updateMultipleFiles}
          handleMultipleFolderUpdates={updateMultipleFolders}
          handleSort={setSort}
          onSelectFolderDestination={onSelectFolderDestination}
          setMultipleFiles={setMultipleFiles}
          inMultiSelectMode={inMultiSelectMode}
          setInMultiSelectMode={setInMultiSelectMode}
          onCreateFolderSuccess={loadData}
          onUploadFilesSuccess={loadData}
          folderId={folderId}
          isGridView={isGridView}
          toggleGridView={toggleGridView}
        />
      </div>
      <div className="mt-2 px-3 py-4 bg-white shadow-sm">
        <FileBreadcrumbs
          breadcrumbList={folderBreadcrumbs}
        />
      </div>
      {(isLoading)
        ? (<div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>)
        : (<>
          {isGridView
            ? (<Row className="cursor-pointer mt-2 g-1 bg-white">
              <FolderGridView
                folderList={folderFolders}
                onMoveFolder={moveFolder}
                onDeleteFolder={onDeleteFolder}
                onRenameFolder={renameFolder}
                selectedFolders={multipleFolders}
                addFolderToMultiSelect={addFolderToMultiSelect}
                removeFolderFromMultiSelect={removeFolderFromMultiSelect}
                inMultiSelectMode={inMultiSelectMode}
              />
              <FileGridView
                fileList={folderFiles}
                onMoveFile={moveFile}
                onRenameFile={renameFile}
                onReplaceFile={replaceFile}
                onDeleteFile={deleteFile}
                onSelectFolderDestination={onSelectFolderDestination}
                selectedFiles={multipleFiles}
                addFileToMultiSelect={addFileToMultiSelect}
                removeFileFromMultiSelect={removeFileFromMultiSelect}
                inMultiSelectMode={inMultiSelectMode}
                putFileIsLoading={updateOneFileIsLoading}
              />
            </Row>)
            : (<>
              <div className="mt-2 px-3 py-4 bg-white shadow-sm">
                <FolderListView
                  folderList={folderFolders}
                  onMoveFolder={moveFolder}
                  onDeleteFolder={onDeleteFolder}
                  onRenameFolder={renameFolder}
                  selectedFolders={multipleFolders}
                  addFolderToMultiSelect={addFolderToMultiSelect}
                  removeFolderFromMultiSelect={removeFolderFromMultiSelect}
                  inMultiSelectMode={inMultiSelectMode}
                />
              </div>
              <div className="mt-2 px-3 py-4 bg-white shadow-sm">
                <FileListView
                  fileList={folderFiles}
                  onMoveFile={moveFile}
                  onRenameFile={renameFile}
                  onReplaceFile={replaceFile}
                  onDeleteFile={deleteFile}
                  onSelectFolderDestination={onSelectFolderDestination}
                  selectedFiles={multipleFiles}
                  addFileToMultiSelect={addFileToMultiSelect}
                  removeFileFromMultiSelect={removeFileFromMultiSelect}
                  inMultiSelectMode={inMultiSelectMode}
                  putFileIsLoading={updateOneFileIsLoading}
                />
              </div>
            </>)}
        </>
        )}
      <FloatingActionButton
        options={floatingActionOptions}
      />
      <CreateFolderModal
        show={isCreateFolderVisible}
        handleCloseCreateFolderModal={hideCreateFolder}
        onCreateNewFolder={createNewFolder}
      />
      <UploadFilesModal
        show={isUploadFilesVisible}
        handleCloseUploadFilesModal={hideUploadFiles}
        onUploadFiles={uploadFiles}
        postFilesIsLoading={createFilesIsLoading}
      />
      <ConfirmDeleteModal
        show={isConfirmDeleteVisible}
        onHide={hideConfirmDelete}
        foldersToDelete={foldersToDelete()}
        filesToDelete={filesToDelete()}
        onDelete={(arg) => setDeleteConfirmed(arg)}
      />
      <FolderMoveOptions
        folderList = {folderFolders}
        multipleFolders={multipleFolders}
        folderToMove = {folderToMove}
        showFolderMoveOptions = {isFolderMoveOptionsVisible}
        handleCloseFolderOptions = {hideFolderMoveOptions}
        onSelectFolderDestination = {onSelectFolderDestination}
        breadcrumbList={folderBreadcrumbs}
      />
    </div>
  );
};

export default FilesTab;
