import React from 'react';
import { DateTime } from 'luxon';
import { Field, FormikErrors } from 'formik';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Accordion from 'react-bootstrap/esm/Accordion';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { parseDatetime } from '@/utils/helpers';
import DetailedScheduleForm from '@/features/Schedule/components/DetailedScheduleForm';
import { IContentForm, IPlaylistForm } from '@/features/Schedule/types';
import { END_OF_DAY, FAR_FUTURE_DATE } from '@/features/Schedule/constants';

interface CardDetailsDateRangeProps {
  values: IContentForm | IPlaylistForm,
  errors?: FormikErrors<IContentForm | IPlaylistForm>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  onChange: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  },
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  },
}

const CardDetailsDateRange = ({
  values,
  errors,
  setFieldValue,
  onChange,
  onBlur,
}: CardDetailsDateRangeProps) => {
  return (
    <div
      className="content-schedule-date-options mb-3 justify-content-center align-items-center"
    >
      <InputGroup className="flex-nowrap mb-3">
        <Form.Group
          className="start-date"
        >
          <Form.Label
            className="mb-1 fw-bold"
            htmlFor="startDate"
          >
            Start
          </Form.Label>
          <Field
            component={Form.Control}
            type="date"
            id="startDate"
            name="startDate"
            value={values.startDate.toISODate()}
            onChange={(e: any) => {
              const startDate = DateTime.fromISO(e.target.value).isValid
                ? DateTime.fromISO(e.target.value)
                : DateTime.local();
              setFieldValue('startDate', startDate);
            }}
            onBlur={onBlur}
            isInvalid={errors && errors.startDate}
            max={values.endDate.toISODate()}
          />
          {(errors && errors.startDate) && (
            <Form.Control.Feedback type="invalid">
              {errors.startDate as string}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group
          className="end-date"
        >
          <Form.Label
            className="mb-1 fw-bold"
            htmlFor="endDate"
          >
            End
          </Form.Label>
          <Field
            component={Form.Control}
            type="date"
            id="endDate"
            name="endDate"
            value={values.endDate.toISODate()}
            onChange={(e: any) => {
              const selectedEndDate = parseDatetime(DateTime.fromISO(e.target.value));

              const endDate = selectedEndDate
                ? DateTime.local(selectedEndDate.year, selectedEndDate.month, selectedEndDate.day, END_OF_DAY.hour, END_OF_DAY.minute)
                : DateTime.local(FAR_FUTURE_DATE.year, FAR_FUTURE_DATE.month, FAR_FUTURE_DATE.day, END_OF_DAY.hour, END_OF_DAY.minute);
              setFieldValue('endDate', endDate);
            }}
            onBlur={onBlur}
            isInvalid={errors && errors.endDate}
            min={values.startDate.toISODate()}
          />
          {(errors && errors.endDate) && (
            <Form.Control.Feedback type="invalid">
              {errors.endDate as string}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </InputGroup>

      <Row className="mb-2">
        <Col
          className="d-flex align-items-center"
          xs="4"
        >
          <Form.Label
            className="mb-0 fw-bold"
            htmlFor="dailyStart"
          >
            Daily Start
          </Form.Label>
        </Col>
        <Col xs="8">
          <Field
            component={Form.Control}
            type="time"
            id="dailyStart"
            value={values.recurrence.startTime?.toLocaleString(DateTime.TIME_24_SIMPLE)}
            onChange={(e: any) => {
              const newDailyStartTime = DateTime.fromISO(e.target.value);
              setFieldValue('recurrence.startTime', newDailyStartTime);
            }}
            max={values.recurrence.endTime?.toFormat('T')}
            isInvalid={!!errors?.recurrence?.startTime}
          />
          {(errors?.recurrence?.startTime) && (
            <Form.Control.Feedback type="invalid">
              {errors.recurrence.startTime as string}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col
          className="d-flex align-items-center"
          xs="4"
        >
          <Form.Label
            className="mb-0 fw-bold"
            htmlFor="dailyEnd"
          >
            Daily End
          </Form.Label>
        </Col>
        <Col xs="8">
          <Form.Group>
            <Field
              component={Form.Control}
              type="time"
              id="dailyEnd"
              value={values.recurrence.endTime?.toLocaleString(DateTime.TIME_24_SIMPLE)}
              onChange={(e: any) => {
                const newDailyEndTime = DateTime.fromISO(e.target.value);
                setFieldValue('recurrence.endTime', newDailyEndTime);
              }}
              min={values.recurrence.startTime?.toFormat('T')}
              isInvalid={!!errors?.recurrence?.endTime}
            />
            {(errors?.recurrence?.endTime) && (
              <Form.Control.Feedback type="invalid">
                {errors.recurrence.endTime as string}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mt-2">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Advanced Schedule
            </Accordion.Header>
            <Accordion.Body>
              <DetailedScheduleForm
                item={values}
                startDate={values.startDate}
                endDate={values.endDate}
                recurrence={values.recurrence}
                onChange={onChange}
                onBlur={onBlur}
                setFieldValue={setFieldValue}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Form.Group>
    </div>
  );
};

export default CardDetailsDateRange;
