import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IContentResponse } from '@/types/content';
import {
  CONTENT_LOAD_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadOneContent = () => {
  const [content, setContent] = useState<IContentResponse | null>(null);
  const [readContentApiConfig, setReadContentApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readContentResponse,
    loading: readOneContentIsLoading,
  } = useCallApi(readContentApiConfig, { errorMessage: CONTENT_LOAD_ERROR });

  const readOneContent = (contentId: number) => {
    setReadContentApiConfig({
      url: `/contents/${contentId}`,
      method: 'get',
    });
  };

  useEffect(() => {
    setContent(readContentResponse?.data);
  }, [readContentResponse]);

  return {
    readOneContent,
    content,
    readOneContentIsLoading,
  };
};

export default useReadOneContent;

