import React from 'react';
import Alert from 'react-bootstrap/esm/Alert';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OfflingSignAlertProps {
  offlineSignCount: number,
}

const OfflineSignAlert = ({
  offlineSignCount,
}: OfflingSignAlertProps) => {
  return (
    <Alert
      className="mb-0"
      variant="danger"
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          className="me-2"
          size="2x"
          icon="exclamation-triangle"
        />
        <div>
          You have {offlineSignCount} sign{offlineSignCount === 1 ? '' : 's'} that&nbsp;
          {offlineSignCount === 1 ? 'is' : 'are'} offline. View&nbsp;
          <Link
            className="text-underline"
            to="/signs"
          >
            signs
          </Link> page for more information.
        </div>
      </div>
    </Alert>
  );
};

export default OfflineSignAlert;
