import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ISignRegistration } from '@/types/sign';
import { REGISTRATION_KEY_CREATED_MESSAGE, REGISTRATION_KEY_CREATED_ERROR } from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useCreateSignRegistrationKey = () => {
  const [createdSignRegistrationKey, setCreatedSignRegistrationKey] = useState<ISignRegistration | null>(null);
  const [postRegistrationKeyApiConfig, setPostRegistrationKeyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createSignRegistrationKeyResponse,
    loading: createSignRegistrationKeyIsLoading,
  } = useCallApi(postRegistrationKeyApiConfig, {
    successMessage: REGISTRATION_KEY_CREATED_MESSAGE,
    errorMessage: REGISTRATION_KEY_CREATED_ERROR,
  });

  const createSignRegistrationKey = (signId: number) => {
    setPostRegistrationKeyApiConfig({
      url: `/signs/${signId}/register`,
      method: 'post',
    });
  };

  useEffect(() => {
    setCreatedSignRegistrationKey(createSignRegistrationKeyResponse?.data);
  }, [createSignRegistrationKeyResponse]);

  return {
    createSignRegistrationKey,
    createdSignRegistrationKey,
    createSignRegistrationKeyIsLoading,
  };
};

export default useCreateSignRegistrationKey;
