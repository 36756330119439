import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { IUserResponse, IUserSignUpForm } from '@/types/user';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
});

interface CreateProfileModalProps {
  isVisible: boolean,
  onHide: () => void,
  initialValues: IUserSignUpForm,
  onSubmit: (data: IUserSignUpForm) => void,
  activeUser: IUserResponse | null,
}

const CreateProfileModal = ({
  isVisible,
  onHide,
  onSubmit,
  initialValues,
  activeUser,
}: CreateProfileModalProps) => {
  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Complete Your Profile</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: IUserSignUpForm) => {
          onSubmit(values);
        }}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
        }: FormikProps<IUserSignUpForm>) => {
          return (
            <Modal.Body>
              <Form
                id="setup-user-form"
                noValidate
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="firstName">
                      <FloatingLabel label="First Name">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.firstName && !errors.firstName}
                          isInvalid={!!errors.firstName && touched.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName ? errors.firstName : 'Enter first name'}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="lastName">
                      <FloatingLabel label="Last Name">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="lastName"
                          isValid={touched.lastName && !errors.lastName}
                          isInvalid={!!errors.lastName && touched.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName && touched.lastName
                            ? errors.lastName
                            : 'Enter your last name.'}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                {!activeUser &&
                    (<Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="companyName">
                          <FloatingLabel label="Company Name">
                            <Form.Control
                              type="companyName"
                              placeholder="Company Name"
                              value={values.companyName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="companyName"
                              isValid={touched.companyName && !errors.companyName}
                              isInvalid={touched.companyName && !!errors.companyName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.companyName && touched.companyName
                                ? errors.companyName
                                : 'Enter your Company Name.'}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>)
                }

              </Form>
            </Modal.Body>
          );
        }}
      </Formik>

      <Modal.Footer>
        <Button
          form="setup-user-form"
          type="submit"
          variant="primary"
        >
              Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateProfileModal;
