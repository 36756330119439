export const FILE_CREATED_MESSAGE = 'File Created';
export const FILE_UPDATED_MESSAGE = 'File Updated';
export const FILES_MOVED_MESSAGE = 'Files Moved';
export const FILE_DELETED_MESSAGE = 'File Deleted';

export const FILE_LOAD_ERROR = 'Unable to load file';
export const FILE_CREATED_ERROR = 'Unable to create file';
export const FILE_UPDATED_ERROR = 'Unable to update file';
export const FILES_MOVED_ERROR = 'Unable to move files';
export const FILE_DELETED_ERROR = 'Unable to delete file';

export const ASPECT_RATIOS = ['16:9', '4:1', '3:1', '3:2', '2:1', '2:3', '1:1', '1:2', '1:3', '1:4'];
