import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListGroup from 'react-bootstrap/esm/ListGroup';

import { IBreadcrumbResponse } from '@/features/Files/FilesTab/types';
import { IFolderResponse } from '@/types/folder';

interface FolderMoveDestinationOptionsProps {
  folderList: IFolderResponse[] | null,
  multipleFolders?: IFolderResponse[],
  folderToMove?: IFolderResponse | null,
  showFolderMoveOptions: boolean,
  handleCloseFolderOptions: () => void,
  onSelectFolderDestination: Function,
  breadcrumbList: IBreadcrumbResponse[] | null,
}

const FolderMoveDestinationOptions = ({
  folderList,
  folderToMove,
  showFolderMoveOptions,
  handleCloseFolderOptions,
  onSelectFolderDestination,
  multipleFolders,
  breadcrumbList,
}: FolderMoveDestinationOptionsProps) => {
  const [parentFolder, setParentFolder] = useState<IBreadcrumbResponse | null>(null);

  useEffect(() => {
    if (breadcrumbList && breadcrumbList.length >= 1) {
      const selectedBreadcrumb =  breadcrumbList[breadcrumbList.length - 2];
      setParentFolder(selectedBreadcrumb || null);
    }
  }, [breadcrumbList]);

  const selectedFolderIds = multipleFolders ? multipleFolders.map((f) => f.id) : [];

  const handleParentFolderClick = () => {
    if (parentFolder?.id) {
      onSelectFolderDestination(parentFolder.id);
      setParentFolder(null);
    }
  };

  return (
    <Offcanvas
      className="list-offcanvas"
      show={showFolderMoveOptions}
      onHide={handleCloseFolderOptions}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select Destination</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {(folderList || parentFolder || breadcrumbList)
          ? (<ListGroup>
            {breadcrumbList && (<ListGroup.Item action onClick={() => onSelectFolderDestination(null)}>
              <FontAwesomeIcon icon={'home'} />
              <span className="ms-2">Home</span>
            </ListGroup.Item>)}
            {parentFolder && (<ListGroup.Item action onClick={() => handleParentFolderClick()}>
              <FontAwesomeIcon icon={'arrow-up'} />
              <span className="ms-2">{parentFolder.name}</span>
            </ListGroup.Item>
            )}
            {folderList && (folderList.map((folder) => {
              return (
                !selectedFolderIds.includes(folder.id) && folder.id !== folderToMove?.id
                  ? (<ListGroup.Item key={folder.id} action onClick={() => onSelectFolderDestination(folder.id)}>
                    {folder.name}
                  </ListGroup.Item>)
                  : null
              );
            }))}
          </ListGroup>)
          : (<div className="px-3 py-4 bg-white shadow-sm">
            <span>No Available Folders</span>
          </div>)
        }
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FolderMoveDestinationOptions;
