import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import {
  FILE_DELETED_ERROR,
  FILE_DELETED_MESSAGE,
} from '@/features/Files/constants';

import useCallApi from '@/hooks/useCallApi';

const useDeleteFiles = () => {
  const [deletedFiles, setDeletedFiles] = useState<Record<string, never> | null>(null);
  const [deleteFileApiConfig, setDeleteFileApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteFilesResponse,
    loading: deleteFilesIsLoading,
  } = useCallApi(deleteFileApiConfig, {
    successMessage: FILE_DELETED_MESSAGE,
    errorMessage: FILE_DELETED_ERROR,
  });

  const deleteFiles = (fileIds: number[]) => {
    setDeleteFileApiConfig({
      url: '/files/delete',
      method: 'delete',
      data: {
        fileIds,
      },
    });
  };

  useEffect(() => {
    if (deleteFilesResponse) setDeletedFiles({});
  }, [deleteFilesResponse]);

  return {
    deleteFiles,
    deletedFiles,
    deleteFilesIsLoading,
  };
};

export default useDeleteFiles;
