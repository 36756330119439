// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
// swipeableList taken from https://malcoded.com/posts/react-swipeable-list/
import './SwipeableList.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fpsIntervalNum = 1000;
const fpsIntervalDen = 60;

interface SwipeableListItemProps {
  onSwipe: () => void;
  threshold?: number;
  children: React.ReactNode;
  background?: React.ReactNode;
}

class SwipeableListItem extends React.Component<SwipeableListItemProps> {
  // DOM Refs
  listElement;
  wrapper;
  background;

  // Drag & Drop
  dragStartX = 0;
  left = 0;
  dragged = false;

  // FPS Limit
  startTime;
  fpsInterval = fpsIntervalNum / fpsIntervalDen;

  static defaultProps = {
    threshold: 0.3,
    background: undefined,
  };

  constructor(props) {
    super(props);

    this.listElement = null;
    this.wrapper = null;
    this.background = null;

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onDragStartMouse = this.onDragStartMouse.bind(this);
    this.onDragStartTouch = this.onDragStartTouch.bind(this);
    this.onDragEndMouse = this.onDragEndMouse.bind(this);
    this.onDragEndTouch = this.onDragEndTouch.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updatePosition = this.updatePosition.bind(this);

    // this.onClicked = this.onClicked.bind(this);

    this.onSwiped = this.onSwiped.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onDragEndMouse);
    window.addEventListener('touchend', this.onDragEndTouch);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onDragEndMouse);
    window.removeEventListener('touchend', this.onDragEndTouch);
  }

  onDragStartMouse(evt) {
    this.onDragStart(evt.clientX);
    window.addEventListener('mousemove', this.onMouseMove);
  }

  onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener('touchmove', this.onTouchMove);
  }

  onDragStart(clientX) {
    this.dragged = true;
    this.dragStartX = clientX;
    this.listElement.classList.remove('bouncing');
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  onDragEndMouse() {
    window.removeEventListener('mousemove', this.onMouseMove);
    this.onDragEnd();
  }

  onDragEndTouch() {
    window.removeEventListener('touchmove', this.onTouchMove);
    this.onDragEnd();
  }

  onDragEnd() {
    if (this.dragged) {
      this.dragged = false;

      if (this.left < this.listElement.offsetWidth * this.props.threshold * -1) {
        this.left = -this.listElement.offsetWidth * 2;
        this.wrapper.style.maxHeight = 0;
        this.onSwiped();
      } else {
        this.left = 0;
      }

      this.listElement.classList.add('bouncing');
      this.listElement.style.transform = `translateX(${this.left}px)`;
      if (this.left === 0 && this.listElement.style.transform) this.listElement.style.removeProperty('transform');
    }
  }

  onMouseMove(evt) {
    const left = evt.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  }

  onTouchMove(evt) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  }

  updatePosition() {
    if (this.dragged) requestAnimationFrame(this.updatePosition);

    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval && this.listElement) {
      this.listElement.style.transform = `translateX(${this.left}px)`;

      const opacityDen = 100;

      const opacity = (Math.abs(this.left) / opacityDen).toFixed(2);
      if (opacity < 1 && opacity.toString() !== this.background.style.opacity) {
        this.background.style.opacity = opacity.toString();
      }
      if (opacity >= 1) {
        this.background.style.opacity = '1';
      }

      this.startTime = Date.now();
    }

    if (this.left === 0 && this.listElement && this.listElement.style.transform) this.listElement.style.removeProperty('transform');
  }

  // onClicked() {
  //   if (this.props.onSwipe) {
  //     this.props.onSwipe();
  //   }
  // }

  onSwiped() {
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  }

  // eslint-disable-next-line require-jsdoc
  render() {
    return (
      <>
        <div className="wrapper" ref={(div) => (this.wrapper = div)}>
          <div ref={(div) => (this.background = div)} className="background">
            {this.props.background ? (
              this.props.background
            ) : (
              <span><FontAwesomeIcon icon="trash" /></span>
            )}
          </div>
          <div
            onClick={this.onClicked}
            ref={(div) => (this.listElement = div)}
            onMouseDown={this.onDragStartMouse}
            onTouchStart={this.onDragStartTouch}
            className="list-item"
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default SwipeableListItem;
