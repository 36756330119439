import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FormikProps, Formik } from 'formik';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';

import './SignAnalyticsModal.scss';

interface SignAnalyticsModalProps {
  isVisible: boolean,
  isSubmitting?: boolean,
  onHide: () => void,
  onSubmit: (form: SignAnalytisForm) => void,
}

interface SignAnalytisForm {
  type: 'daily' | 'summary',
  startDate: DateTime,
  endDate: DateTime,
}

const SignAnalyticsModal = ({
  isVisible,
  isSubmitting = false,
  onHide,
  onSubmit,
}: SignAnalyticsModalProps) => {
  const initialFormValues: SignAnalytisForm = {
    type: 'daily',
    startDate: DateTime.now().minus({ days: 7 }).startOf('day'),
    endDate: DateTime.now().endOf('day'),
  };

  const validate = (values: SignAnalytisForm) => {
    const errors: {
      startDate?: string
      endDate?: string
    } = {};

    if (values.startDate > values.endDate) {
      errors.endDate = 'End date must be after start date';
      errors.startDate = 'Start date must be before end date';
    }

    return errors;
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Sign Analytics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validate={validate}
          disabled={isSubmitting}
        >
          {({
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            errors,
            handleSubmit,
          }: FormikProps<SignAnalytisForm>) => {
            return (
              <Form
                id="sign-analytics-form"
                className="sign-analytics-form"
                noValidate
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <Form.Label
                        className="mb-1 fw-bold"
                        htmlFor="startDate"
                      >
                        Report Type
                      </Form.Label>
                      <Form.Select
                        name="type"
                        className="mb-2"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="daily">Daily</option>
                        <option value="summary">Summary</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <InputGroup className="flex-nowrap mb-3">
                  <Form.Group className="start-date">
                    <Form.Label
                      className="mb-1 fw-bold"
                      htmlFor="startDate"
                    >
                      Start
                    </Form.Label>
                    <Field
                      component={Form.Control}
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={values.startDate.toISODate()}
                      onChange={(e: any) => {
                        const startDate = DateTime.fromISO(e.target.value).isValid
                          ? DateTime.fromISO(e.target.value)
                          : DateTime.local();
                        setFieldValue('startDate', startDate);
                      }}
                      onBlur={handleBlur}
                      isInvalid={errors && errors.startDate}
                      max={values.endDate.toISODate()}
                    />
                    {(errors && errors.startDate) && (
                      <Form.Control.Feedback type="invalid">
                        {errors.startDate as string}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="end-date">
                    <Form.Label
                      className="mb-1 fw-bold"
                      htmlFor="endDate"
                    >
                      End
                    </Form.Label>
                    <Field
                      component={Form.Control}
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={values.endDate.toISODate()}
                      onChange={(e: any) => {
                        const endDate = DateTime.fromISO(e.target.value).isValid
                          ? DateTime.fromISO(e.target.value)
                          : DateTime.local();
                        setFieldValue('endDate', endDate);
                      }}
                      onBlur={handleBlur}
                      isInvalid={errors && errors.endDate}
                      min={values.startDate.toISODate()}
                    />
                    {(errors && errors.endDate) && (
                      <Form.Control.Feedback type="invalid">
                        {errors.endDate as string}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </InputGroup>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer">
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={isSubmitting}
          form="sign-analytics-form"
        >
          {(isSubmitting)
            ? <FontAwesomeIcon icon={'circle-notch'} spin />
            : 'Submit'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SignAnalyticsModal;
