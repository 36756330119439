import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ICompanyHubResponse } from '@/types/company';
import { COMPANY_LOAD_ERROR } from '@/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadOneCompany = () => {
  const useHubApi = true;
  const [company, setCompany] = useState<ICompanyHubResponse | null>(null);
  const [getCompanyApiConfig, setGetCompanyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOneCompanyResponse,
    loading: readOneCompanyIsLoading,
  } = useCallApi(getCompanyApiConfig, {
    errorMessage: COMPANY_LOAD_ERROR,
  }, useHubApi);

  const readOneCompany = (companyId: string) => {
    setGetCompanyApiConfig({
      url: `/companies/${companyId}`,
      method: 'get',
    });
  };

  useEffect(() => {
    setCompany(readOneCompanyResponse?.data);
  }, [readOneCompanyResponse]);

  return {
    readOneCompany,
    company,
    readOneCompanyIsLoading,
  };
};

export default useReadOneCompany;

