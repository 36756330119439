
import React from 'react';

import { IFileResponse, IFileUploadForm } from '@/types/file';
import Col from 'react-bootstrap/esm/Col';

import FileGridCard from './FileGridCard';

interface FileGridViewProps {
  fileList: IFileResponse[] | null,
  onMoveFile: (fileClicked: IFileResponse) => void,
  onRenameFile: (fileClicked: IFileResponse) => void,
  onReplaceFile: (fileClicked: IFileResponse, fileToUpload: IFileUploadForm) => void,
  onDeleteFile: (fileClicked: IFileResponse) => void,
  selectedFiles: IFileResponse[],
  addFileToMultiSelect: (fileClicked: IFileResponse) => void,
  removeFileFromMultiSelect: (fileClicked: IFileResponse) => void,
  onSelectFolderDestination: Function,
  inMultiSelectMode: boolean,
  putFileIsLoading?: boolean,
}

const FileGridView = ({
  fileList,
  onMoveFile,
  onRenameFile,
  onReplaceFile,
  onDeleteFile,
  selectedFiles,
  addFileToMultiSelect,
  removeFileFromMultiSelect,
  inMultiSelectMode,
  putFileIsLoading,
}: FileGridViewProps) => {
  if (!fileList || fileList.length <= 0) {
    return (
      <></>
    );
  }

  const selectedFileIds = selectedFiles.map((f) => f.id);

  return (
    <>
      {(fileList.map((file) => {
        if (!file.id) return (<div>Not a valid file</div>);

        return (
          <Col
            key={file.id}
            xs="6"
            sm="auto"
          >
            <div className="d-flex justify-content-center">
              <FileGridCard
                file={file}
                onMoveFile={onMoveFile}
                onRenameFile={onRenameFile}
                onReplaceFile={onReplaceFile}
                onDeleteFile={onDeleteFile}
                isSelected={selectedFileIds.includes(file.id)}
                addFileToMultiSelect={addFileToMultiSelect}
                removeFileFromMultiSelect={removeFileFromMultiSelect}
                inMultiSelectMode={inMultiSelectMode}
                putFileIsLoading={putFileIsLoading}
              />
            </div>
          </Col>
        );
      }))}
    </>
  );
};

export default FileGridView;
