import { useState } from 'react';

const useToggle = () => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => {
    setIsVisible(true);
  };

  const hide = () => {
    setIsVisible(false);
  };

  const toggle = () => {
    setIsVisible(!isVisible);
  };

  return {
    show,
    hide,
    toggle,
    isVisible,
  };
};

export default useToggle;
