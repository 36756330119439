import React from 'react';
import { NavLink } from 'react-router-dom';

import { DASHBOARD_EXTERNAL_NAV_ITEMS, DASHBOARD_NAV_ITEMS } from '@/layout/constants';
import { useStore } from '@/hooks';

import './SideNav.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IfElseWrapper from '@/common/wrappers/IfElseWrapper';
import CustomIcon from '@/common/CustomIcon';

const sideNav = () => {
  const { selectedSign } = useStore();

  return (
    <div
      className="side-nav-container"
    >
      <div className="p-4 bg-white mb-2 shadow-sm">
        {DASHBOARD_EXTERNAL_NAV_ITEMS.map((item) => (
          <IfElseWrapper
            key={item.name}
            condition={!!item.isExternal}
            wrapperIf={(children) => <a href={item.url}>{children}</a>}
            wrapperElse={(children) => <NavLink to={item.url}>{children}</NavLink>}
          >
            <div className="side-nav-list-item d-flex align-items-center justify-content-between">
              <div>
                {(item.customIcon) && (
                  <CustomIcon
                    className={item.className}
                    style={{
                      height: '18px',
                    }}
                    type={item.customIcon}
                  />
                )}
                { item.name }
              </div>
              <FontAwesomeIcon
                className="side-nav-caret"
                icon="caret-right"
              />
            </div>
          </IfElseWrapper>
        ))}
      </div>
      <div className="p-4 bg-white shadow-sm flex-grow-1">
        {DASHBOARD_NAV_ITEMS.map((item) => (
          <NavLink
            key={item.name}
            to={(item.name === 'Schedule' && selectedSign) ? `/schedule/signs/${selectedSign.id}` : item.url}
          >
            {({ isActive }) => (
              <div
                className={classNames({
                  'side-nav-list-item': true,
                  'active': isActive,
                })}
              >
                {(item.customIcon) && (
                  <CustomIcon
                    className="me-3"
                    style={{
                      width: 22,
                      height: 'auto',
                    }}
                    type={item.customIcon}
                  />
                )}
                { item.name }
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default sideNav;
