import { MS_IN_S } from '@/utils/constants';
import React from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';

interface CardDetailsDurationProps {
  durationInMilliseconds: number;
  error?: string;
  onChange: {
    (newDuration: number): void;
  };
}

const CardDetailsDuration = ({
  durationInMilliseconds,
  error,
  onChange,
}: CardDetailsDurationProps) => {
  const durationInSeconds = durationInMilliseconds / MS_IN_S;

  return (
    <Card className="bg-white shadow-sm">
      <Card.Body>
        <Card.Title>
          Duration
        </Card.Title>
        <Form.Group className="text-start">
          <InputGroup>
            <Form.Control
              type="number"
              id="duration-input"
              value={durationInSeconds.toString()}
              onChange={(inputEvent: React.ChangeEvent<HTMLInputElement>) => {
                const newDurationInMilliseconds = inputEvent.target.value === ''
                  ? 0
                  : Math.round(parseFloat(inputEvent.target.value) * MS_IN_S);

                onChange(newDurationInMilliseconds);
              }}
              step="0.5"
              isInvalid={!!error}
            />
            <InputGroup.Text>seconds</InputGroup.Text>
            {error && (
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default CardDetailsDuration;
