import React from 'react';
import { pad } from '@/utils/helpers';

interface BaseTimePickerProps {
  hourIncrement?: number,
  minuteIncrement?: number,
  min?: string,
  max?: string,
  value: string,
  onChange: (input: string) => void,
}

interface TimeOption {
  value: number,
  text: string,
}

const TWELVE_HOURS = 12;

const BaseTimePicker = ({
  value,
  onChange,
  min = '00:00',
  max = '23:59',
  hourIncrement = 1,
  minuteIncrement = 1,
}: BaseTimePickerProps) => {
  const [hourValue, minuteValue] = value.split(':').map((i) => parseInt(i, 10));
  const suffixValue = hourValue >= TWELVE_HOURS ? 'pm' : 'am';
  const [minHour, minMinute] = min.split(':').map((i) => parseInt(i, 10));
  const [maxHour, maxMinute] = max.split(':').map((i) => parseInt(i, 10));

  const onTimeChange = (time: string, type: 'hour' | 'minute') => {
    let timeInput = value;
    if (type === 'hour') timeInput = `${pad(parseInt(time, 10))}:${pad(minuteValue)}`;
    if (type === 'minute') timeInput = `${pad(hourValue)}:${pad(parseInt(time, 10))}`;

    onChange(timeInput);
  };

  const onSuffixChange = (suffix: string) => {
    let timeInput = value;
    if (suffix === 'am') {
      const changedHour = hourValue >= TWELVE_HOURS ? hourValue - TWELVE_HOURS : hourValue;
      timeInput = `${pad(changedHour)}:${pad(minuteValue)}`;
    } else if (suffix === 'pm') {
      timeInput = `${pad(hourValue + TWELVE_HOURS)}:${pad(minuteValue)}`;
    }
    onChange(timeInput);
  };

  const setHourOptions = () => {
    let hours: TimeOption[] = [];
    for (let i = minHour; i <= maxHour; i += hourIncrement) {
      hours.push({
        value: i,
        text: pad(i > TWELVE_HOURS ? i - TWELVE_HOURS : i),
      });
    }
    return hours;
  };

  const setMinuteOptions = () => {
    let minutes: TimeOption[] = [];
    for (let i = minMinute; i <= maxMinute; i += minuteIncrement) {
      minutes.push({
        value: i,
        text: pad(i),
      });
    }
    return minutes;
  };

  const hourOptions = setHourOptions();
  const minuteOptions = setMinuteOptions();

  return (
    <>
      <div>
        <select
          id="hour"
          value={hourValue}
          onChange={(e) => onTimeChange(e.target.value, 'hour')}
          style={{
            borderRadius: '0.375rem 0 0 0.375rem',
          }}
        >
          {hourOptions.map((hour) => {
            return (<option key={hour.value} value={hour.value}>{hour.text}</option>);
          })}
        </select>
        <select
          className="border-start-0 border-end-0"
          id="minute"
          value={minuteValue}
          onChange={(e) => onTimeChange(e.target.value, 'minute')}
        >
          {minuteOptions.map((minute) => {
            return (<option key={minute.value} value={minute.value}>{minute.text}</option>);
          })}
        </select>
        <select
          id="ampm"
          name="AM / PM"
          value={suffixValue}
          onChange={(e) => onSuffixChange(e.target.value)}
          style={{
            borderRadius: '0 0.375rem 0.375rem 0',
          }}
        >
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </div>
    </>
  );
};

export default BaseTimePicker;

