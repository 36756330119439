import { IActionMenuItem } from '@/types/actions';

export const PLAYLIST_ACTION_EDIT = 'editPlaylist';
export const PLAYLIST_ACTION_RENAME = 'renamePlaylist';
export const PLAYLIST_ACTION_DUPLICATE = 'duplicatePlaylist';
export const PLAYLIST_ACTION_DELETE = 'deletePlaylist';
export const PLAYLIST_ACTION_OPTIONS = [
  PLAYLIST_ACTION_EDIT,
  PLAYLIST_ACTION_RENAME,
  PLAYLIST_ACTION_DELETE,
  PLAYLIST_ACTION_DUPLICATE,
] as const;

export const PLAYLIST_CARD_ACTIONS_MENU_ITEMS: IActionMenuItem[] = [
  {
    text: 'Rename',
    icon: 'i-cursor',
    action: PLAYLIST_ACTION_RENAME,
  },
  {
    text: 'Edit',
    icon: 'keyboard',
    action: PLAYLIST_ACTION_EDIT,
  },
  {
    text: 'Duplicate',
    icon: 'clone',
    action: PLAYLIST_ACTION_DUPLICATE,
  },
  {
    text: 'Delete',
    icon: 'trash',
    action: PLAYLIST_ACTION_DELETE,
    iconColor: 'danger',
  },
];

