import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IContentResponse, IContentUpdateRequest } from '@/types/content';
import {
  CONTENT_BATCH_UPDATED_MESSAGE,
  CONTENT_BATCH_UPDATED_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdateContents = () => {
  const [updatedContents, setUpdatedContents] = useState<IContentResponse[] | null>(null);
  const [putContentsApiConfig, setPutContentsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateContentsResponse,
    loading: updateContentsIsLoading,
  } = useCallApi(putContentsApiConfig, {
    successMessage: CONTENT_BATCH_UPDATED_MESSAGE,
    errorMessage: CONTENT_BATCH_UPDATED_ERROR,
  });

  const updateContents = (contentsToUpdate: IContentUpdateRequest[]) => {
    setPutContentsApiConfig({
      url: '/contents/update',
      method: 'put',
      data: { contents: contentsToUpdate },
    });
  };

  useEffect(() => {
    setUpdatedContents(updateContentsResponse?.data);
  }, [updateContentsResponse]);

  return {
    updateContents,
    updatedContents,
    updateContentsIsLoading,
  };
};

export default useUpdateContents;

