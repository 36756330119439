import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { IPlaylistResponse } from '@/types/playlist';
import {
  PLAYLIST_CONTENT_LOAD_ERROR,
  PLAYLIST_LOAD_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadOnePlaylist = () => {
/**
   * GET Playlist
   * GET Playlist Content
   *
   * These are combined so we can use the same api to refresh only the playlist content
   * ie. GET Playlist sets both playlist & playlist content data,
   * whereas GET Playlist Content sets only playlist content data.
   * The use case for this is on load you call GET Playlist to populate playlist & playlist content
   * On playlist content update you call GET Playlist Content so you only refresh the playlist content
   * while keeping the playlist data intact
   */
  const [playlist, setPlaylist] = useState<IPlaylistResponse | null>(null);
  const [getPlaylistApiConfig, setGetPlaylistApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOnePlaylistResponse,
    loading: readOnePlaylistIsLoading,
  } = useCallApi(getPlaylistApiConfig, {
    errorMessage: PLAYLIST_LOAD_ERROR,
  });

  const [playlistContents, setPlaylistContents] = useState<IPlaylistResponse['contents']>([]);
  const [getPlaylistContentsApiConfig, setGetPlaylistContentsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readPlaylistContentsResponse,
    loading: readOnePlaylistContentsIsLoading,
  } = useCallApi(getPlaylistContentsApiConfig, {
    errorMessage: PLAYLIST_CONTENT_LOAD_ERROR,
  });

  const readOnePlaylist = (playlistId: number, params: IndexApiParams = {}) => {
    setGetPlaylistApiConfig({
      url: `/playlists/${playlistId}`,
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setPlaylist(readOnePlaylistResponse?.data);
    setPlaylistContents(readOnePlaylistResponse?.data.contents);
  }, [readOnePlaylistResponse]);

  const readOnePlaylistContents = (playlistId: number, params: IndexApiParams = {}) => {
    setGetPlaylistContentsApiConfig({
      url: `/playlists/${playlistId}`,
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setPlaylistContents(readPlaylistContentsResponse?.data.contents);
  }, [readPlaylistContentsResponse]);

  return {
    readOnePlaylist,
    playlist,
    readOnePlaylistIsLoading,
    readOnePlaylistContents,
    playlistContents,
    readOnePlaylistContentsIsLoading,
  };
};

export default useReadOnePlaylist;

