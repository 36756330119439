import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/esm/Form';
import Popover from 'react-bootstrap/esm/Popover';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Button from 'react-bootstrap/esm/Button';

import { DAILY_FREQ, MONTHLY_FREQ, REPEAT_FORM_OPTIONS, WEEKLY_FREQ, YEARLY_FREQ } from '@/features/Schedule/constants';
import { Frequency } from '@/types/recurrenceRule';
import { IContentForm, IPlaylistForm } from '@/features/Schedule/types';

import WeeklyRepeatForm from './WeeklyRepeatForm';
import MonthlyRepeatForm from './MonthlyRepeatForm';
import YearlyRepeatForm from './YearlyRepeatForm';
import CustomRepeatForm from './CustomRepeatForm';

interface RepeatFormProps {
  item: IContentForm | IPlaylistForm | null,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const RepeatForm = ({
  item,
  setFieldValue,
}: RepeatFormProps) => {
  const [selectedRepeatFrequency, setSelectedRepeatFrequency] = useState<Frequency | 'CUSTOM' | 'NONE'>(item?.recurrence.freq || 'NONE');

  const onSelectRepeatFrequency = (selectedFrequency: Frequency | 'CUSTOM' | 'NONE') => {
    setSelectedRepeatFrequency(selectedFrequency);

    setFieldValue('recurrence.freq', selectedFrequency);

    if (selectedFrequency === DAILY_FREQ) {
      setFieldValue('recurrence.byDay', []);
      setFieldValue('recurrence.byWeek', null);
      setFieldValue('recurrence.byMonth', null);
      setFieldValue('recurrence.byYear', null);
    }
  };

  const toTitleCase = (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  return (
    <>
      <Form.Group>
        <h5 className="mb-3">
          Repeat {toTitleCase(selectedRepeatFrequency)}
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
              (<Popover>
                <Popover.Header as="h3">Repeat Configuration</Popover.Header>
                <Popover.Body>
                  <p>
                    This section defines how the content will repeat.
                  </p>
                  <p>
                  For example you can have your content repeat every week on Sunday and Saturday.
                  </p>
                </Popover.Body>
              </Popover>)
            }
          >
            <Button
              variant="link"
              size="sm"
            >
              <FontAwesomeIcon
                icon="info-circle"
              />
            </Button>
          </OverlayTrigger>
        </h5>
        <Form.Select
          className="mb-2"
          value={item?.recurrence.freq || 'NONE'}
          onChange={(e) => onSelectRepeatFrequency(e.target.value as Frequency | 'CUSTOM' | 'NONE')}
        >
          {REPEAT_FORM_OPTIONS.map((option) => {
            return (
              <option key={option.text} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </Form.Select>
        {
          {
            [DAILY_FREQ]: <></>,
            [WEEKLY_FREQ]: <WeeklyRepeatForm
              item={item}
              setFieldValue={setFieldValue}
            />,
            [MONTHLY_FREQ]: <MonthlyRepeatForm
              item={item}
              setFieldValue={setFieldValue}
            />,
            [YEARLY_FREQ]: <YearlyRepeatForm item={item} />,
            'CUSTOM': <CustomRepeatForm />,
            'NONE': <></>,
          }[selectedRepeatFrequency] || <></>
        }
      </Form.Group>
    </>
  );
};

export default RepeatForm;
