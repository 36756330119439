
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'react-bootstrap/esm/Button';

import { SortOption, TFileActionName, TFolderActionName } from '@/features/Files/FilesTab/types';
import { IFileResponse, IFilesUploadForm } from '@/types/file';
import { useCreateFiles } from '@/hooks/file';
import { IFolderResponse } from '@/types/folder';
import { downloadS3Url } from '@/utils/helpers';
import { ONE_SECOND_MS } from '@/utils/constants';
import { useToggle } from '@/hooks';
import { useCreateFolder } from '@/hooks/folder';
import { SORT_OPTIONS } from '@/features/Files/FilesTab/constants';
import CustomIcon from '@/common/CustomIcon';

import OffsetActionMenu from '@/common/OffcanvasActionMenu';
import { IActionMenuItem } from '@/types/actions';

import FileListSortOptions from './FileListSortOptions';
import FileListActionOptions from './FileListActionOptions';
import CreateFolderModal from './CreateFolderModal';
import UploadFilesModal from './UploadFilesModal';

interface FileListControlsProps {
  handleSort: (sortValue: { field: string, direction: 'asc' | 'desc' }) => void,
  multipleFiles: IFileResponse[],
  multipleFolders: IFolderResponse[],
  handleMultipleFileUpdates: (action: TFileActionName) => void,
  handleMultipleFolderUpdates: (action: TFolderActionName) => void,
  onSelectFolderDestination: Function,
  setMultipleFiles:React.Dispatch<React.SetStateAction<IFileResponse[]>>,
  inMultiSelectMode: boolean,
  setInMultiSelectMode:React.Dispatch<React.SetStateAction<boolean>>,
  folderId?: string
  onCreateFolderSuccess?: () => void,
  onUploadFilesSuccess?: () => void,
  isGridView: boolean,
  toggleGridView: () => void,
}

const fileListControls = ({
  multipleFiles,
  multipleFolders,
  handleMultipleFileUpdates,
  handleMultipleFolderUpdates,
  handleSort,
  setMultipleFiles,
  inMultiSelectMode,
  setInMultiSelectMode,
  folderId,
  onCreateFolderSuccess,
  onUploadFilesSuccess,
  isGridView,
  toggleGridView,
}: FileListControlsProps) => {
  const [sortValue, setSortValue] = useState(SORT_OPTIONS[1]);

  const { show: showFileListSortOptions, hide: hideFileListSortOptions, isVisible: isFileListSortOptionsVisible } = useToggle();
  const { show: showFileListActionMenu, hide: hideFileListActionMenu, isVisible: isFileListActionMenuVisible } = useToggle();
  const { show: showMultiSelectActionMenu, hide: hideMultiSelectActionMenu, isVisible: isMultiSelectActionMenuVisible } = useToggle();
  const { show: showCreateFolder, hide: hideCreateFolder, isVisible: isCreateFolderVisible } = useToggle();
  const { show: showUploadFiles, hide: hideUploadFiles, isVisible: isUploadFilesVisible } = useToggle();

  const {
    createFiles,
    createdFiles,
    createFilesIsLoading,
  } = useCreateFiles();

  const {
    createFolder,
    createdFolder,
  } = useCreateFolder();

  const sort = (option: SortOption) => {
    handleSort({ field: option.field, direction: option.direction });
    setSortValue(option);
    hideFileListSortOptions();
  };

  const downloadFiles = async () => {
    for (let i = 0; i < multipleFiles.length; i += 1) {
      if (!multipleFiles[i].downloadUrl) return;

      try {
        downloadS3Url(multipleFiles[i].downloadUrl);
        await new Promise((resolve) => setTimeout(resolve, ONE_SECOND_MS));
        setMultipleFiles([]);
      } catch (err) {
        throw Error('Error Downloading File');
      }
      await new Promise((resolve) => setTimeout(resolve, (2 * ONE_SECOND_MS)));
    }
  };

  const uploadFiles = (filesToUpload: IFilesUploadForm) => {
    createFiles(filesToUpload, folderId);
  };

  useEffect(() => {
    if (!createdFiles) return;

    hideUploadFiles();
    if (onUploadFilesSuccess) onUploadFilesSuccess();
  }, [createdFiles]);

  const createNewFolder = (folderName: string) => {
    const newFolder = {
      name: folderName,
      folderId: folderId || null,
    };

    createFolder(newFolder);
  };

  useEffect(() => {
    if (!createdFolder) return;

    hideCreateFolder();
    if (onCreateFolderSuccess) onCreateFolderSuccess();
  }, [createdFolder]);

  const onMultiSelectAction = (action: string) => {
    const ACTIONS_DICT = {
      ['moveFilesAndFolders']: () => {
        handleMultipleFileUpdates('move');
        handleMultipleFolderUpdates('move');
      },
      ['deleteFilesAndFolders']: () => {
        handleMultipleFileUpdates('delete');
        handleMultipleFolderUpdates('delete');
      },
      ['moveFiles']: () => handleMultipleFileUpdates('move'),
      ['downloadFiles']: () => downloadFiles(),
      ['deleteFiles']: () => handleMultipleFileUpdates('delete'),
      ['moveFolders']: () => handleMultipleFolderUpdates('move'),
      ['deleteFolders']: () => handleMultipleFolderUpdates('delete'),
    };

    // @ts-expect-error
    ACTIONS_DICT[action]();
    hideMultiSelectActionMenu();
  };

  const renderActions = () => {
    if (!inMultiSelectMode) return null;
    let multiSelectActionMenuItems: IActionMenuItem[] = [
      { text: 'Move Files & Folders', icon: 'arrow-right-to-bracket', action: 'moveFilesAndFolders', disabled: true, iconColor: 'primary' },
      { text: 'Delete Files & Folders', icon: 'trash', action: 'deleteFilesAndFolders', disabled: true, iconColor: 'primary' },
    ];

    if (multipleFiles.length >= 1 && multipleFolders.length === 0) {
      multiSelectActionMenuItems = [
        { text: 'Move Files', icon: 'arrow-right-to-bracket', action: 'moveFiles' },
        { text: 'Download Files', icon: 'download', action: 'downloadFiles' },
        { text: 'Delete Files', icon: 'trash', action: 'deleteFiles', iconColor: 'danger' },
      ];
    }

    if (multipleFolders.length >= 1 && multipleFiles.length === 0) {
      multiSelectActionMenuItems = [
        { text: 'Move Folders', icon: 'arrow-right-to-bracket', action: 'moveFolders' },
        { text: 'Delete Folders', icon: 'trash', action: 'deleteFolders', iconColor: 'danger' },
      ];
    }

    if (multipleFolders.length >= 1 && multipleFiles.length >= 1) {
      multiSelectActionMenuItems = [
        { text: 'Move Files & Folders', icon: 'arrow-right-to-bracket', action: 'moveFilesAndFolders' },
        { text: 'Delete Files & Folders', icon: 'trash', action: 'deleteFilesAndFolders', iconColor: 'danger' },
      ];
    }

    return (
      <>
        <Button
          className="me-2 mb-2"
          variant="outline-primary"
          onClick={showMultiSelectActionMenu}
        >
          Actions
        </Button>
        <OffsetActionMenu
          isVisible={isMultiSelectActionMenuVisible}
          onHide={hideMultiSelectActionMenu}
          actionMenuItems={multiSelectActionMenuItems}
          onActionClick={onMultiSelectAction}
        />
      </>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            className="d-inline-flex align-items-center mb-2 me-2"
            variant="outline-primary"
            onClick={showUploadFiles}
          >
            <CustomIcon
              className="me-1"
              type="upload"
            />
            Upload
          </Button>
          <Button
            className="d-inline-flex align-items-center mb-2"
            variant="outline-primary"
            onClick={showCreateFolder}
          >
            <CustomIcon
              className="me-1"
              type="folder-open"
            />
            Folder
          </Button>
        </div>
        <div className="d-flex justify-content-end flex-wrap">
          <Button
            variant="outline-primary"
            className="ms-2 mb-2"
            onClick={toggleGridView}
          >
            {
              isGridView
                ? <FontAwesomeIcon icon="table-cells-large" />
                : <FontAwesomeIcon icon="list" />
            }
          </Button>
          <Button
            className="ms-2 mb-2"
            variant="outline-primary"
            onClick={showFileListSortOptions}
          >
            <FontAwesomeIcon icon={sortValue.icon} />
          </Button>
          <div className="ms-2 d-flex">
            {renderActions()}
            <Button
              className="mb-2"
              variant="outline-primary"
              onClick={showFileListActionMenu}
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Button>
          </div>
        </div>
      </div>
      <FileListActionOptions
        isVisible={isFileListActionMenuVisible}
        onHide={hideFileListActionMenu}
        inMultiSelectMode={inMultiSelectMode}
        setInMultiSelectMode={setInMultiSelectMode}
      />
      <FileListSortOptions
        isVisible={isFileListSortOptionsVisible}
        onHide={hideFileListSortOptions}
        handleSortOptionClick={(option) => sort(option)}
        activeOption={sortValue.name}
      />
      <UploadFilesModal
        show={isUploadFilesVisible}
        handleCloseUploadFilesModal={hideUploadFiles}
        onUploadFiles={uploadFiles}
        postFilesIsLoading={createFilesIsLoading}
      />
      <CreateFolderModal
        show={isCreateFolderVisible}
        handleCloseCreateFolderModal={hideCreateFolder}
        onCreateNewFolder={createNewFolder}
      />
    </>
  );
};

export default fileListControls;

