import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';

import { SORT_OPTIONS } from '@/features/Files/FilesTab/constants';
import { SortOption } from '@/features/Files/FilesTab/types';

interface FileListSortOptionsProps {
  isVisible: boolean,
  onHide: () => void,
  handleSortOptionClick: (sortOptionValue: SortOption) => void,
  activeOption: string,
}

const FileListSortOptions = ({
  isVisible,
  onHide,
  handleSortOptionClick,
  activeOption,
}: FileListSortOptionsProps) => {
  return (
    <Offcanvas
      show={isVisible}
      onHide={onHide}
      placement="bottom"
      className="list-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Sort By</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          {SORT_OPTIONS.map((sort) => {
            return (
              <ListGroup.Item
                key={sort.name}
                action
                active={activeOption === sort.name}
                onClick={() => handleSortOptionClick(sort)}
              >
                <span>{sort.icon ? <FontAwesomeIcon icon={sort.icon} /> : null} {sort.name}</span>
              </ListGroup.Item>);
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FileListSortOptions;
