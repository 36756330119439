import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { IFileResponse, TFileOrigin } from '@/types/file';
import {
  FILE_LOAD_ERROR,
} from '@/features/Files/constants';

import useCallApi from '@/hooks/useCallApi';

interface ReadFilesApiParams extends IndexApiParams {
  origin?: TFileOrigin,
  aspectRatio?: string,
}

const useReadFiles = () => {
  const [files, setFiles] = useState<IFileResponse[]>([]);
  const [getFileListApiConfig, setGetFileListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readFilesResponse,
    loading: readFilesIsLoading,
  } = useCallApi(getFileListApiConfig, {
    errorMessage: FILE_LOAD_ERROR,
  });

  const readFiles = (params: ReadFilesApiParams = {}) => {
    setGetFileListApiConfig({
      url: '/files',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setFiles(readFilesResponse?.data);
  }, [readFilesResponse]);

  return {
    readFiles,
    files,
    readFilesIsLoading,
    setFiles,
  };
};

export default useReadFiles;

