import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Formik, FormikProps } from 'formik';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { validateFileSize, validateFileType } from '@/utils/validations';

import { IFileWithError } from '@/features/Files/FilesTab/types';
import { IFilesUploadForm } from '@/types/file';
import Loader from '@/common/Loader';
import { MAX_FILE_SIZE_IN_MB, VALID_FILE_MIME_TYPES } from '@/utils/constants';

interface UploadFilesModal {
  show: boolean,
  handleCloseUploadFilesModal: () => void,
  onUploadFiles: (files: IFilesUploadForm) => void,
  postFilesIsLoading?: boolean | undefined,
}

const uploadFilesModal = ({
  show,
  handleCloseUploadFilesModal,
  onUploadFiles,
  postFilesIsLoading,
}: UploadFilesModal) => {
  const [fileList, setFileList] = useState<IFileWithError[] | null>(null);

  const acceptedFileTypes = VALID_FILE_MIME_TYPES.join(', ');

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const clickHiddenFileInput = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (fieldName: string, value: FileList | null) => void) => {
    const { files } = (e.target as HTMLInputElement);

    if (!files) {
      return;
    }

    setFieldValue('files', files);

    const validatedFiles = Array.from(files).map((file) => {
      const errors = [];
      if (!validateFileSize(file)) errors.push(`File must be smaller than ${MAX_FILE_SIZE_IN_MB}MB`);
      if (!validateFileType(file)) errors.push(`File must be one of ${acceptedFileTypes}`);

      return {
        file,
        errors,
      };
    });

    setFileList(validatedFiles);
  };

  const isValid = (): boolean => {
    return fileList ? !fileList.some((file) => file.errors.length) : false;
  };

  const handleRemoveFile = (fileIndex: number) => {
    if (!fileList) return;

    const newFileList = [...fileList.slice(0, fileIndex), ...fileList.slice(fileIndex + 1)];
    if (newFileList) setFileList(newFileList);
  };

  const handleClose = () => {
    setFileList([]);
    handleCloseUploadFilesModal();
  };

  const handleSubmit = () => {
    if (!fileList) return;

    if (!isValid()) return;

    const files = fileList.map((item) => (item.file));
    onUploadFiles({
      files,
      origin: 'upload',
    });
    setFileList([]);
  };

  if (postFilesIsLoading) {
    return (
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title>Your files are being uploaded</Modal.Title>
        </Modal.Header>
        <div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal backdrop="static" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Files</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          files: undefined,
        }}
        onSubmit={() => {
          handleSubmit();
        }}
      >
        {({
          handleBlur,
          setFieldValue,
          isSubmitting,
        }: FormikProps<any>) => {
          return (<>
            <Modal.Body>
              <Form
                id="upload-files-form"
                noValidate
                onSubmit={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Row>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control
                        style={{ 'display': 'none' }}
                        ref={hiddenFileInput}
                        name="files"
                        value={undefined} // This is necessary to override Formik's value setting so file uploads work
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, setFieldValue)}
                        onBlur={handleBlur}
                        type="file"
                        accept={acceptedFileTypes}
                        multiple
                      />
                      <Button type="button" variant="outline-primary" onClick={clickHiddenFileInput}>Choose Files</Button>
                    </Form.Group>
                  </Col>
                </Row>
                {fileList && fileList.map((item, idx) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      key={item.file.name}
                    >
                      <div className="text-wrap">
                        {item.file.name}<br />
                        {item.errors && item.errors.map((error) => (
                          <small key={error} className="text-danger">{error}</small>
                        ))}
                      </div>
                      <Button
                        variant="link"
                        onClick={() => handleRemoveFile(idx)}
                      >
                        <FontAwesomeIcon
                          icon="x"
                        />
                      </Button>
                    </div>
                  );
                })}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="light" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                form="upload-files-form"
                type="submit"
                variant="primary"
                disabled={isSubmitting || !isValid()}
              >
                Upload
              </Button>
            </Modal.Footer>
          </>);
        }}
      </Formik>
    </Modal>
  );
};

export default uploadFilesModal;
