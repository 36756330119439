export const loadFont = async (fontFamily: string, fontDownloadUrl: string) => {
  const newStyle = document.createElement('style');
  newStyle.appendChild(document.createTextNode(`
  @font-face {
      font-family: '${fontFamily}';
      src: url(${fontDownloadUrl}) format('truetype');
  }
`));
  document.head.appendChild(newStyle);

  if (document.fonts) {
    await document.fonts.ready;
  }
};
