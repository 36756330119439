import React, { useEffect, useRef, useState } from 'react';

import { REALTIME_CURRENT_TIME_TYPE, REALTIME_WEATHER_TYPE, PrestoRealtimeFeedWithMetadata, PrestoRealtimeWithMetadata } from '@/types/file';
import { useWindowSize } from '@/hooks/useWindowSize';
import { loadFont } from '@/utils/fonts';

import './fileFeedOverlay.scss';

const MILLISECONDS_IN_SECONDS = 1000;
const MAX_FONT_SIZE = 128;

interface FileFeedOverlayProps {
  feed: PrestoRealtimeFeedWithMetadata,
  resolution: PrestoRealtimeWithMetadata['resolution'],
  videoTime: number,
  sizeToDimensions?: {
    width: number,
    height: number,
  },
}

const calculateSetup = ({
  feedSetup,
  resolution,
  containerEl,
  sizeToDimensions,
}: {
  feedSetup: PrestoRealtimeFeedWithMetadata['setup'],
  resolution: PrestoRealtimeWithMetadata['resolution'],
  containerEl: HTMLDivElement,
  sizeToDimensions?: {
    width: number,
    height: number,
  },
}) => {
  const parentElem = containerEl ? containerEl.parentElement : null;

  if (!parentElem) {
    return {
      size: {
        width: 0,
        height: 0,
      },
      position: {
        x: 0,
        y: 0,
        rotate: 0,
      },
    };
  }

  const parentElemRect = parentElem.getBoundingClientRect();
  const { size, position } = feedSetup;

  if (sizeToDimensions) {
    // convert full resolution to sizeToDimensions
    const sizeToDimensionsWidthRatio = sizeToDimensions.width / resolution.width;
    const sizeToDimensionsHeightRatio = sizeToDimensions.height / resolution.height;

    // determine new dimensions of overlay
    const newDimensions = {
      width: size.width * sizeToDimensionsWidthRatio,
      height: size.height * sizeToDimensionsHeightRatio,
    };
    const newPosition = {
      x:  position.x * sizeToDimensionsWidthRatio,
      y: position.y * sizeToDimensionsHeightRatio,
      rotate: position.rotate,
    };

    // convert sizeToDimensions ratios to parent container dimensions
    const parentContainerWidthRatio = parentElemRect.width / sizeToDimensions.width;
    const parentContainerFinalRatio = parentElemRect.height / sizeToDimensions.height;

    return {
      size: {
        width: newDimensions.width * parentContainerWidthRatio,
        height: newDimensions.height * parentContainerFinalRatio,
      },
      position: {
        x: newPosition.x * parentContainerWidthRatio,
        y: newPosition.y * parentContainerFinalRatio,
        rotate: newPosition.rotate || 0,
      },
    };
  }

  const parentContainerWidthRatio = parentElemRect.width / resolution.width;
  const parentContainerFinalRatio = parentElemRect.height / resolution.height;

  return {
    size: {
      width: size.width * parentContainerWidthRatio,
      height: size.height * parentContainerFinalRatio,
    },
    position: {
      x: position.x * parentContainerWidthRatio,
      y: position.y * parentContainerFinalRatio,
      rotate: position.rotate || 0,
    },
  };
};

const resizeText = (containerEl: HTMLDivElement, textEl: HTMLSpanElement) => {
  const containerRect = containerEl.getBoundingClientRect();

  let fontSize = 1;
  let overflowWidth = false;
  let overflowHeight = false;

  do {
    textEl.style.fontSize = `${fontSize}px`;
    const innerTextRect = textEl.getBoundingClientRect();

    overflowWidth = innerTextRect.width > containerRect.width;
    overflowHeight = innerTextRect.height > containerRect.height;

    // if text does not overflow width or height, increase font size
    if (!(overflowWidth || overflowHeight)) {
      fontSize += 1;
    }
  } while (!overflowWidth && !overflowHeight && fontSize < MAX_FONT_SIZE);

  textEl.style.fontSize = `${fontSize - 1}px`;
};

const FileFeedOverlay = ({
  feed,
  resolution,
  videoTime,
  sizeToDimensions,
}: FileFeedOverlayProps) => {
  const [feedSetup, setFeedSetup] = useState<PrestoRealtimeFeedWithMetadata['setup']>(feed.setup);
  const windowSize = useWindowSize();

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  const initializeComponent = async () => {
    if (feed.font && feed.fontDownloadUrl) {
      await loadFont(feed.font, feed.fontDownloadUrl);
    }
    if (containerRef && containerRef.current && textRef && textRef.current) {
      const adjustedFeedSetup = calculateSetup({
        feedSetup: feed.setup,
        resolution,
        containerEl: containerRef.current,
        sizeToDimensions,
      });
      setFeedSetup(adjustedFeedSetup);
      resizeText(containerRef.current, textRef.current);
    }
  };

  useEffect(() => {
    initializeComponent();
  }, []);

  useEffect(() => {
    if (containerRef && containerRef.current && textRef && textRef.current) {
      const adjustedFeedSetup = calculateSetup({
        feedSetup: feed.setup,
        resolution,
        containerEl: containerRef.current,
        sizeToDimensions,
      });
      setFeedSetup(adjustedFeedSetup);
      resizeText(containerRef.current, textRef.current);
    }
  }, [windowSize]);

  // show layer if times do not exist (for case where these values aren't defined in existing database content)
  //  or if times exists and current videoTime is within start & end times.
  const showLayer = (typeof feed.startTime !== 'number' || typeof feed.endTime !== 'number')
    || (
      typeof feed.startTime === 'number' && typeof feed.endTime === 'number'
      && videoTime * MILLISECONDS_IN_SECONDS >= feed.startTime! && videoTime * MILLISECONDS_IN_SECONDS <= feed.endTime!
    );

  const feedStyles: React.CSSProperties = {
    visibility: showLayer ? 'visible' : 'hidden',
    fontFamily: feed.font,
    color: feed.color,
    width: feedSetup.size.width,
    height: feedSetup.size.height,
    left: feedSetup.position.x,
    top: feedSetup.position.y,
    fontWeight: feed.bold ? 'bold' : 'normal',
    fontStyle: feed.italic ? 'italic' : 'normal',
    textDecoration: feed.underline ? 'underline' : 'none',
    opacity: feed.opacity ? feed.opacity : 1,
    textAlign: feed.align ? feed.align : 'left',
    lineHeight: feed.lineHeight ? feed.lineHeight : 1,
    transform: feedSetup.position.rotate ? `rotate(${feedSetup.position.rotate}deg)` : 'none',
    zIndex: 10,
  };

  const placeholderText = {
    [REALTIME_WEATHER_TYPE]: '100°',
    [REALTIME_CURRENT_TIME_TYPE]: '12:00',
  };

  return (
    <div
      className="feed-overlay"
      style={feedStyles}
      ref={containerRef}
    >
      <span
        ref={textRef}
      >
        {placeholderText[feed.realtimeDataType]}
      </span>
    </div>
  );
};

export default FileFeedOverlay;

