import React from 'react';

import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import { IFolderResponse } from '@/types/folder';

import FolderGridCard from './FolderGridCard';
import FolderListCard from './FolderListCard';

interface FolderListProps {
  isGridView: boolean,
  folders: IFolderResponse[],
  onFolderSelect: (folderId: number | null) => void,
}

const FolderList = ({
  isGridView,
  folders,
  onFolderSelect,
}: FolderListProps) => {
  if (!folders) return null;

  return (
    <>
      <Row className="cursor-pointer mt-3 g-1">
        {isGridView ? folders.map((folder) => {
          return (
            <Col
              key={folder.id}
              xs="6"
              sm="auto"
            >
              <FolderGridCard
                folder={folder}
                onFolderSelect={onFolderSelect}
              />
            </Col>
          );
        }) : folders.map((folder) => {
          return (
            <FolderListCard
              key={folder.id}
              folder={folder}
              onFolderSelect={onFolderSelect}
            />
          );
        })}
      </Row>
    </>
  );
};

export default FolderList;
