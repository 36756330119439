import { create, StateCreator } from 'zustand';
import { To } from 'react-router-dom';

import { IFileResponse } from '@/types/file';
import { IContentCreateRequest } from '@/types/content';
import { ISignResponse } from '@/types/sign';
import { IPlaylistResponse } from '@/types/playlist';
import { IUserResponse } from '@/types/user';

interface FileSlice {
  onFileClickNavigateTo: To | null,
  setOnFileClickNavigateTo: (pathname: To | null) => void,
  selectedFile: IFileResponse | null,
  setSelectedFile: (file: IFileResponse | null) => void,
  reset: () => void,
}

const createFileSlice: StateCreator<FileSlice> = (set) => ({
  onFileClickNavigateTo: null,
  setOnFileClickNavigateTo: (pathname) => {
    set((state) => ({ ...state, onFileClickNavigateTo: pathname }));
  },
  selectedFile: null,
  setSelectedFile: (file) => {
    set((state) => ({ ...state, selectedFile: file }));
  },
  reset: () => {
    set(() => ({ selectedFile: null, onFileClickNavigateTo: null }));
  },
});

interface ScheduleSlice {
  onPlaylistClickNavigateTo: To | null,
  setOnPlaylistClickNavigateTo: (pathname: To | null) => void,
  newContent: IContentCreateRequest | null,
  setNewContent: (content: IContentCreateRequest) => void,
  playlistList: IPlaylistResponse[] | null,
  setPlaylistList: (playlistList: IPlaylistResponse[] | null) => void,
  selectedPlaylist: IPlaylistResponse | null,
  setSelectedPlaylist: (playlist: IPlaylistResponse | null) => void,
  reset: () => void,
}

const createScheduleSlice: StateCreator<ScheduleSlice> = (set) => ({
  onPlaylistClickNavigateTo: null,
  setOnPlaylistClickNavigateTo: (pathname) => {
    set((state) => ({ ...state, onPlaylistClickNavigateTo: pathname }));
  },
  newContent: null,
  setNewContent: (content) => {
    set(() => ({ newContent: content }));
  },
  playlistList: null,
  setPlaylistList: (playlistList) => {
    set(() => ({ playlistList }));
  },
  selectedPlaylist: null,
  setSelectedPlaylist: (playlist) => {
    set((state) => ({ ...state, selectedPlaylist: playlist }));
  },
  reset: () => {
    set(() => ({ onPlaylistClickNavigateTo: null, newContent: null, playlistList: null, selectedPlaylist: null }));
  },
});

interface SignSlice {
  selectedSign: ISignResponse | null,
  setSelectedSign: (sign: ISignResponse | null) => void,
  reset: () => void,
}

const createSignSlice: StateCreator<SignSlice> = (set) => ({
  selectedSign: null,
  setSelectedSign: (sign) => {
    set(() => ({ selectedSign: sign }));
  },
  reset: () => {
    set(() => ({ selectedSign: null }));
  },
});

interface UserSlice {
  emulatedUser: IUserResponse | null,
  setEmulatedUser: (user: IUserResponse | null) => void,
  reset: () => void,
}

const createUserSlice: StateCreator<UserSlice> = (set) => ({
  emulatedUser: null,
  setEmulatedUser: (user) => {
    set(() => ({ emulatedUser: user }));
  },
  reset: () => {
    set(() => ({ emulatedUser: null }));
  },
});

interface StoreSlice {
  resetStore: () => void,
}

const createStoreSlice: StateCreator<StoreSlice> = (set) => ({
  resetStore: () => {
    // @ts-expect-error
    createFileSlice(set).reset();

    // @ts-expect-error
    createScheduleSlice(set).reset();

    // @ts-expect-error
    createSignSlice(set).reset();

    // @ts-expect-error
    createUserSlice(set).reset();
  },
});

const useStore = create<FileSlice & ScheduleSlice & SignSlice & UserSlice & StoreSlice>((...a) => ({
  ...createFileSlice(...a),
  ...createScheduleSlice(...a),
  ...createSignSlice(...a),
  ...createUserSlice(...a),
  ...createStoreSlice(...a),
}));

export default useStore;
