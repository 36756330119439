import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AddPlaylist from '@/features/Schedule/playlist/AddPlaylist';
import EditPlaylist from '@/features/Schedule/playlist/EditPlaylist';

import SchedulePage from './';
import EditContent from './components/EditContent';

/**
 * Builds the routes for the schedule feature.
 *
 * @returns {*}
 */
export function ScheduleRoutesSwitch() {
  return (
    <div>
      <Routes>
        <Route path="/signs/:signId/playlists/add" element={<AddPlaylist />} />
        <Route path="/signs/:signId" element={<SchedulePage />} />
        <Route path="/signs/:signId/playlists/:playlistId/contents/:contentId" element={<EditContent />} />
        <Route path="/signs/:signId/contents/:contentId" element={<EditContent />} />
        <Route path="/signs/:signId/playlists/:playlistId" element={<EditPlaylist />} />
        <Route path="/*" element={<SchedulePage />} />
      </Routes>
    </div>
  );
}
