import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import '@/styles/App.scss';
import { RoutesSwitch } from './routes';

/**
 * Base Application Page
 * @returns {function}
 */
function App() {
  return (
    <div className="App fade-in">
      <RoutesSwitch />
      <ToastContainer theme="light" autoClose={2000} hideProgressBar />
    </div>
  );
}

export default App;
