import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import { IFileResponse } from '@/types/file';
import { ISignResponse } from '@/types/sign';

import './addSignDefaultContent.scss';
import { DEFAULT_FILE_ORIGIN } from '@/features/Signs/constants';
import DefaultContentCard from '@/features/Signs/common/defaultContentCard/DefaultContentCard';
import DefaultFlagCard from '@/features/Signs/common/defaultContentCard/DefaultFlagCard';
import { VALID_FILE_EXTENSIONS_VIDEO } from '@/utils/constants';
import Loader from '@/common/Loader';

interface AddSignDefaultContentProps {
  file?: IFileResponse,
  systemFiles: ISignResponse['systemDefaultFiles'],
  onChange: (typeOfDefault: 'custom' | 'system', defaultFile: IFileResponse) => void;
  isLoading?: boolean,
}

const AddSignDefaultContent = ({
  file,
  systemFiles,
  onChange,
  isLoading,
}: AddSignDefaultContentProps) => {
  const [isCustomDefaultContent, setIsCustomDefaultContent] = useState<boolean>(false);

  useEffect(() => {
    setIsCustomDefaultContent(Boolean(file?.origin && file?.origin !== DEFAULT_FILE_ORIGIN));
  }, [file?.origin]);

  const onChangeDefaultContent = (typeOfDefault: 'custom' | 'system', defaultFile: IFileResponse) => {
    setIsCustomDefaultContent(typeOfDefault === 'custom');
    onChange(typeOfDefault, defaultFile);
  };

  const selectedFlag = () => {
    if (isCustomDefaultContent) return null;
    if (file && VALID_FILE_EXTENSIONS_VIDEO.includes(file.fileType)) return 'animated';
    return 'static';
  };

  if (isLoading) return (<Loader size="2x" />);

  return (
    <Row className="g-2">
      {/* American Flag Default Content */}
      <Col xs={12} sm={6}>
        <DefaultFlagCard
          isSelected={selectedFlag()}
          animatedFile={systemFiles.animated}
          staticFile={systemFiles.static}
          title="American Flag"
          onChange={onChangeDefaultContent}
        />
      </Col>
      {/* Custom Div with Image and Checkmark */}
      <Col xs={12} sm={6}>
        <DefaultContentCard
          isSelected={isCustomDefaultContent}
          file={file}
          title="Custom Default Content"
          onChange={onChangeDefaultContent}
        />
      </Col>
    </Row>
  );
};

export default AddSignDefaultContent;
