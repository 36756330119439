import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useLocation, NavLink } from 'react-router-dom';

import { useStore } from '@/hooks';
import { MOBILE_NAV_ITEMS } from '@/layout/constants';

import './BottomNav.scss';
import CustomIcon from '@/common/CustomIcon';

/**
 * Bottom Navigation for Mobile
 *
 * @returns {JSX.Element}
 */
export const BottomNav = (): JSX.Element => {
  const activeLocation = useLocation();

  const { selectedSign } = useStore();

  return (
    <Nav
      justify
      variant="tabs"
      defaultActiveKey={MOBILE_NAV_ITEMS[0].url}
      activeKey={activeLocation.pathname}
      className="fixed-bottom bg-dark d-lg-none bottom-nav"
    >
      {MOBILE_NAV_ITEMS.map((i) => {
        return (<Nav.Item key={i.name}>
          <NavLink
            className="nav-link rounded-0 px-0"
            to={(i.name === 'Schedule' && selectedSign) ? `/schedule/signs/${selectedSign.id}` : i.url}
          >
            <div className="nav-icon">
              {(i.customIcon) && (
                <CustomIcon
                  style={{
                    height: 18,
                    display: 'inline-block',
                  }}
                  type={i.customIcon}
                />
              )}
            </div>
            <br />
            {i.name}
          </NavLink>
        </Nav.Item>);
      })}
    </Nav>
  );
};

export default BottomNav;
