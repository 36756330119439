import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IPlaylistResponse, IPlaylistCreateRequest } from '@/types/playlist';
import {
  PLAYLIST_CREATED_MESSAGE,
  PLAYLIST_CREATED_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useCreatePlaylist = () => {
  const [createdPlaylist, setCreatedPlaylist] = useState<IPlaylistResponse | null>(null);
  const [postPlaylistApiConfig, setPostPlaylistApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createPlaylistResponse,
    loading: createPlaylistIsLoading,
  } = useCallApi(postPlaylistApiConfig, {
    successMessage: PLAYLIST_CREATED_MESSAGE,
    errorMessage: PLAYLIST_CREATED_ERROR,
  });

  const createPlaylist = (playlistToCreate: IPlaylistCreateRequest) => {
    setPostPlaylistApiConfig({
      url: '/playlists',
      method: 'post',
      data: playlistToCreate,
    });
  };

  useEffect(() => {
    setCreatedPlaylist(createPlaylistResponse?.data);
  }, [createPlaylistResponse]);

  return {
    createPlaylist,
    createdPlaylist,
    createPlaylistIsLoading,
  };
};

export default useCreatePlaylist;
