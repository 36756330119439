import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IContentResponse } from '@/types/content';
import { ISignResponse } from '@/types/sign';

import ContentListCard from './ContentListCard';

interface ReorderContentModalProps {
  show: boolean;
  onHide: () => void;
  contents: IContentResponse[];
  isSubmitting?: boolean;
  onUpdate: (contents: IContentResponse[]) => void;
  selectedSign: ISignResponse | null;
}

const ReorderContentModal = ({
  show,
  onHide,
  contents,
  isSubmitting = false,
  onUpdate,
  selectedSign,
}: ReorderContentModalProps) => {
  // create local contents state so we do not mutate prop
  const [localContents, setLocalContents] = useState<IContentResponse[]>(contents);

  useEffect(() => {
    // reset state when modal is opened
    if (show === true) {
      setLocalContents(contents);
    }
  }, [show]);

  const reorderContent = (currentIndex: number, direction: 'up' | 'down') => {
    let destinationIndex = direction === 'up'
      ? currentIndex - 1
      : currentIndex + 1;
    if (destinationIndex < 0) destinationIndex = 0;
    if (destinationIndex >= localContents.length) return;
    const reorderedContents = [...localContents];
    const tmp = reorderedContents[destinationIndex];
    reorderedContents[destinationIndex] = reorderedContents[currentIndex];
    reorderedContents[currentIndex] = tmp;

    setLocalContents(reorderedContents);
  };

  const onSubmit = () => {
    onUpdate(localContents);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      fullscreen="md-down"
      backdrop="static"
      size="lg"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Reorder Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {localContents?.map((row, index) => {
          return (
            <div
              className="d-flex align-items-center"
              // eslint-disable-next-line react/no-array-index-key
              key={`${row.id}-${index}`}
            >
              <ContentListCard
                contentItem={row}
                readonly={true}
                refreshSchedule={() => null}
                selectedSign={selectedSign}
                prefix={(
                  <ButtonGroup
                    className="me-1"
                    vertical
                  >
                    <Button
                      className="text-dark"
                      variant="link"
                      disabled={index === 0}
                      size="sm"
                      onClick={() => reorderContent(index, 'up')}
                    >
                      <FontAwesomeIcon icon="arrow-up" />
                    </Button>
                    <Button
                      className="text-dark"
                      variant="link"
                      disabled={index === localContents.length - 1}
                      size="sm"
                      onClick={() => reorderContent(index, 'down')}
                    >
                      <FontAwesomeIcon icon="arrow-down" />
                    </Button>
                  </ButtonGroup>
                )}
              />
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer justify-content-end">
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant="primary"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {(isSubmitting)
            ? (<FontAwesomeIcon
              icon="circle-notch"
              spin
            />)
            : 'Submit'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReorderContentModal;
