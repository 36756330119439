import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ISignResponse, ISignUpdateRequest } from '@/types/sign';
import {
  SIGN_UPDATED_ERROR,
  SIGN_UPDATED_MESSAGE,
} from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdateOneSign = () => {
  const [updatedSign, setUpdatedSign] = useState<ISignResponse | null>(null);
  const [putSignApiConfig, setPutSignApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateOneSignResponse,
    loading: updateOneSignIsLoading,
  } = useCallApi(putSignApiConfig, {
    successMessage: SIGN_UPDATED_MESSAGE,
    errorMessage: SIGN_UPDATED_ERROR,
  });

  const updateOneSign = (signToUpdate: Partial<ISignUpdateRequest>) => {
    setPutSignApiConfig({
      url: `/signs/${signToUpdate.id}`,
      method: 'put',
      data: signToUpdate,
    });
  };

  useEffect(() => {
    setUpdatedSign(updateOneSignResponse?.data);
  }, [updateOneSignResponse]);

  return {
    updateOneSign,
    updatedSign,
    updateOneSignIsLoading,
  };
};

export default useUpdateOneSign;
