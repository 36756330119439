import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useReadPlaylists, useUpdateOnePlaylist } from '@/hooks/playlist';
import { useDuplicateContents, useReadContents, useUpdateOneContent, useUpdateContents, useDeleteContents  } from '@/hooks/content';
import { useReadOneSign, useReadSigns } from '@/hooks/sign';

import { ERROR_TYPE } from '@/common/constants';
import { useToggle, useStore } from '@/hooks';
import { IContentResponse } from '@/types/content';
import { ISignResponse } from '@/types/sign';

import { stringToNumber } from '@/utils/helpers';

import { sortOption, TContentMultiSelectActions } from '@/features/Schedule/types';
import {
  SORT_OPTIONS,
  MULTISELECT_ACTION_MENU_ITEMS,
  MULTISELECT_ACTION_OPTIONS,
  MULTISELECT_ACTION_MOVE_TO_PLAYLIST,
  MULTISELECT_ACTION_DUPLICATE,
  MULTISELECT_ACTION_DUPLICATE_TO_SIGN,
  MULTISELECT_ACTION_DELETE,
} from '@/features/Schedule/constants';

import { IPlaylistResponse } from '@/types/playlist';
import Loader  from '@/common/Loader';
import FloatingActionButton, { FloatinActionButtonOptions } from '@/common/FloatingActionButton';
import OffcanvasActionMenu from '@/common/OffcanvasActionMenu';

import ScheduleControls from './components/ScheduleControls';
import ScheduleList from './components/ScheduleList';
import ScheduleContentSortOptions from './components/ScheduleContentListSortOptions';
import ScheduleContentOptions from './components/ScheduleContentOptions';
import AddContentToScheduleWizard from './components/AddContentWizard/AddContentToScheduleWizard';
import MoveContentToPlaylistModal from './content/MoveContentToPlaylistModal';
import SignListModal from './components/SignListModal';
import AddBumperWizard from './components/AddBumperWizard';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';

const Schedule = () => {
  const routerLocation = useLocation();
  const routerNavigate = useNavigate();
  const { signId: signIdRouteParam } = useParams();
  const signId = stringToNumber(signIdRouteParam);
  const [searchParams] = useSearchParams();
  const openPlaylist = searchParams.get('openPlaylist');

  const {
    setSelectedSign,
    setPlaylistList,
    playlistList,
    setOnPlaylistClickNavigateTo,
    selectedPlaylist,
    setSelectedPlaylist,
  } = useStore();

  // API Configs
  const {
    duplicateContents,
    duplicatedContents,
  } = useDuplicateContents();

  const {
    contents,
    readContents,
    readContentsIsLoading,
  } = useReadContents();

  const {
    updateOneContent,
  } = useUpdateOneContent();

  const {
    updateContents,
    updatedContents,
  } = useUpdateContents();

  const {
    deleteContents,
    deletedContents,
  } = useDeleteContents();

  const {
    readPlaylists,
    playlists,
    readPlaylistsIsLoading,
  } = useReadPlaylists();

  const {
    updateOnePlaylist,
    updatedPlaylist,
  } = useUpdateOnePlaylist();

  const {
    readOneSign,
    readOneSignIsLoading,
    sign,
  } = useReadOneSign();

  const {
    readSigns,
    signs,
    readSignsIsLoading,
  } = useReadSigns();

  // Togglable Components
  const { show: showScheduleSortOptions, hide: hideScheduleSortOptions, isVisible: isScheduleSortOptionsVisible } = useToggle();
  const { show: showScheduleActionOptions, hide: hideScheduleActionOptions, isVisible: isScheduleActionOptionsVisible } = useToggle();
  const { show: showScheduleAddContentWizard, hide: hideScheduleAddContentWizard, isVisible: isScheduleAddContentWizardVisible } = useToggle();
  const { show: showMultiSelectActions, hide: hideMultiSelectActions, isVisible: isshowMultiSelectActionsVisible } = useToggle();
  const { show: showMoveContentToPlaylistModal, hide: hideMoveContentToPlaylistModal, isVisible: moveContentToPlaylistModalIsVisible } = useToggle();
  const { show: showSignListModal, hide: hideSignListModal, isVisible: isSignListModalVisible } = useToggle();
  const { show: showConfirmMultiDeleteModal, hide: hideConfirmMultiDeleteModal, isVisible: isConfirmMultiDeleteModalVisible } = useToggle();
  const { show: showBumperContentModal, hide: hideBumperContentModal, isVisible: isBumperContentModalVisible } = useToggle();

  // component state
  const [sort, setSort] = useState(SORT_OPTIONS[0]);
  const [multipleContents, setMultipleContents] = useState<IContentResponse[]>([]);
  const [inMultiSelectMode, setInMultiSelectMode] = useState<boolean>(false);

  const refreshSchedule = () => {
    const params = {
      sort: sort.field,
      direction: sort.direction,
      signId,
    };
    if (signId) {
      readContents(params);
      readPlaylists(params);
    }
  };

  const refreshSign = () => {
    if (!signId) {
      toast('Error refreshing sign', {
        type: ERROR_TYPE,
      });
      return;
    }

    readOneSign(signId);
  };

  const navigateAndSetSign = (newSignId: number) => {
    routerNavigate(`/schedule/signs/${newSignId}`, { replace: true });
  };

  useEffect(() => {
    if (signId) {
      readOneSign(signId);
    }
    readSigns();
  }, []);

  useEffect(() => {
    // set first sign as default if no sign is selected
    const [firstSign] = signs;
    if (firstSign && !signId) {
      navigateAndSetSign(firstSign.id);
    }
  }, [signs]);

  useEffect(() => {
    setPlaylistList(playlists);
  }, [playlists]);

  useEffect(() => {
    if (updatedPlaylist && selectedPlaylist) setSelectedPlaylist(null);
    if (inMultiSelectMode) setInMultiSelectMode(false);
    refreshSchedule();
  }, [updatedPlaylist, duplicatedContents, updatedContents, deletedContents]);

  useEffect(() => {
    if (selectedPlaylist && sign) {
      const playlistSignIds = selectedPlaylist.signs.map((i) => i.id);
      if (!playlistSignIds.includes(sign.id)) {
        const existingPlaylistSigns = selectedPlaylist.signs.map((s) => s.id);
        updateOnePlaylist({
          id: selectedPlaylist.id,
          signIds: [...existingPlaylistSigns, sign.id],
          contentIds: selectedPlaylist.contents.map((content) => content.id),
          deletedAt: null,
        });
      }
    }
  }, [selectedPlaylist, sign]);

  // TODO - need to rework how we load data on route change. There was a bug
  // where if a playlist is open, then edited, after saving the playlist the app
  // navigates back to the schedule page. Since openPlaylist is true, the readOneSign
  // data does not refresh. Instead we had to call readOneSign from the mounted function
  useEffect(() => {
    // don't reload the data if the user is opening and closing playlists
    if (openPlaylist) return;

    if (signId) {
      readOneSign(signId);
      refreshSchedule();
    }
  }, [routerLocation, sort]);

  useEffect(() => {
    if (!sign) return;

    setSelectedSign(sign);
  }, [sign]);

  useEffect(() => {
    if (inMultiSelectMode === false) setMultipleContents([]);
  }, [inMultiSelectMode]);

  const multipleContentIds = multipleContents.map((content) => content.id);
  const multipleContentNames = multipleContents.map((content) => content.name).join(',');

  const addContentToMultiSelect = (contentClicked: IContentResponse) => {
    setMultipleContents([...multipleContents, contentClicked]);
    setInMultiSelectMode(true);
  };

  const removeContentFromMultiSelect = (contentClicked: IContentResponse) => {
    const removedContent = multipleContents.filter((content) => content.id !== contentClicked.id);
    if (removedContent.length === 0) {
      setInMultiSelectMode(false);
    }
    setMultipleContents(removedContent);
  };

  const onMoveMultipleContentsToPlaylist = (playlist: IPlaylistResponse) => {
    const movedContents = multipleContents.map((content) => {
      // Remove content from the sign directly and put it into the selected playlist.
      const playlistIds = content.playlists.map((contentPlaylist) => contentPlaylist.id).concat(playlist.id);
      const signIds = content.signs.reduce((acc: number[], currContentSign) => {
        if (currContentSign.id !== signId) return [currContentSign.id, ...acc];
        return acc;
      }, []);

      return {
        id: content.id,
        playlistIds,
        signIds,
      };
    });
    updateContents(movedContents);
    hideMoveContentToPlaylistModal();
  };

  const onDuplicateContentsToSign = (signsToDuplicateTo: ISignResponse[], isLinked: boolean) => {
    hideSignListModal();
    const signsToDuplicateToIds = signsToDuplicateTo.map((s) => s.id);

    if (isLinked) {
      const updatedLinkedContents = multipleContents.map((content) => {
        return {
          id: content.id,
          signIds: content.signs.map((s) => s.id).concat(signsToDuplicateToIds),
          playlistIds: content.playlists.map((i) => i.id),
        };
      });
      updateContents(updatedLinkedContents);
    } else {
      duplicateContents(multipleContentIds, signsToDuplicateToIds, []);
    }
  };

  const onDeleteContents = (deleteAll: boolean) => {
    hideConfirmMultiDeleteModal();
    if (deleteAll) {
      deleteContents(multipleContentIds);
    } else {
      const removedSignContents = multipleContents.map((content) => {
        const playlistIds = content.playlists.map((contentPlaylist) => contentPlaylist.id);
        const signIds = content.signs
          .filter((contentSign) => contentSign.id !== signId)
          .map((s) => s.id);

        return {
          id: content.id,
          signIds,
          playlistIds,
        };
      });
      updateContents(removedSignContents);
    }
  };

  const onMultiSelectAction = (action: string) => {
    if (!MULTISELECT_ACTION_OPTIONS.includes(action as TContentMultiSelectActions)) return;

    const ACTIONS_DICT = {
      [MULTISELECT_ACTION_MOVE_TO_PLAYLIST]: showMoveContentToPlaylistModal,
      [MULTISELECT_ACTION_DUPLICATE]: () => duplicateContents(multipleContentIds, (signId ? [signId] : []), []),
      [MULTISELECT_ACTION_DUPLICATE_TO_SIGN]: showSignListModal,
      [MULTISELECT_ACTION_DELETE]: showConfirmMultiDeleteModal,
    };

    ACTIONS_DICT[action as TContentMultiSelectActions]();
    hideMultiSelectActions();
  };

  const sortOptionClick = (sortOptionValue: sortOption) => {
    setSort(sortOptionValue);
    hideScheduleSortOptions();
  };

  const onImportPlaylist = () => {
    setOnPlaylistClickNavigateTo(`/schedule/signs/${signId}`);
    routerNavigate('/assets/playlists');
  };

  const onContentDelete = (content: IContentResponse) => {
    const playlistIds = content.playlists.map((contentPlaylist) => contentPlaylist.id);
    const signIds = content.signs
      .filter((contentSign) => contentSign.id !== signId)
      .map((s) => s.id);

    updateOneContent({
      id: content.id,
      signIds,
      playlistIds,
    });
  };

  const onContentCreateSuccess = () => {
    refreshSchedule();
  };

  const floatingActionButtonOptions: FloatinActionButtonOptions = [
    {
      icon: 'file-video',
      text: 'Content',
      onClick: showScheduleAddContentWizard,
    },
    {
      icon: 'list-ul',
      text: 'Playlist',
      onClick: () => {
        routerNavigate(`/schedule/signs/${signId}/playlists/add`);
      },
    },
  ];

  return (
    <div className="has-fab">
      {readSignsIsLoading
        ? (<Loader size="2x" />)
        : (<ScheduleControls
          selectedSign={sign}
          showScheduleSortOptions={showScheduleSortOptions}
          showMultiSelectActions={showMultiSelectActions}
          showScheduleActionOptions={showScheduleActionOptions}
          sortValue={sort}
          inMultiSelectMode={inMultiSelectMode}
          disableButtons={readOneSignIsLoading}
        />)
      }
      <div className="bg-white shadow-sm px-0 px-sm-3 py-4 mt-2">
        {(readContentsIsLoading || readPlaylistsIsLoading)
          ? (<Loader size="2x" />)
          : (<ScheduleList
            selectedSign={sign}
            userSigns={signs}
            contentList={contents}
            playlistList={playlistList}
            onContentDelete={onContentDelete}
            refreshSchedule={refreshSchedule}
            refreshSign={refreshSign}
            selectedContents={multipleContents}
            inMultiSelectMode={inMultiSelectMode}
            addContentToMultiSelect={addContentToMultiSelect}
            removeContentFromMultiSelect={removeContentFromMultiSelect}
          />)
        }
      </div>
      <FloatingActionButton
        options={floatingActionButtonOptions}
        disabled={!sign}
      />
      <ScheduleContentSortOptions
        isScheduleSortOptionsVisible={isScheduleSortOptionsVisible}
        handleCloseScheduleSortOptions={hideScheduleSortOptions}
        handleSortOptionClick={sortOptionClick}
        activeOption={sort}
      />
      <ScheduleContentOptions
        sign={sign}
        isScheduleActionOptionsVisible={isScheduleActionOptionsVisible}
        hideScheduleActionOptions={hideScheduleActionOptions}
        importPlaylist={onImportPlaylist}
        inMultiSelectMode={inMultiSelectMode}
        setInMultiSelectMode={setInMultiSelectMode}
        setBumperContent={() => {
          hideScheduleActionOptions();
          showBumperContentModal();
        }}
      />
      <AddContentToScheduleWizard
        isVisible={isScheduleAddContentWizardVisible}
        onHide={hideScheduleAddContentWizard}
        onCreateSuccess={onContentCreateSuccess}
        selectedSign={sign}
      />
      <OffcanvasActionMenu
        isVisible={isshowMultiSelectActionsVisible}
        onHide={hideMultiSelectActions}
        title="Multiselect Actions"
        actionMenuItems={MULTISELECT_ACTION_MENU_ITEMS}
        onActionClick={onMultiSelectAction}
      />
      <MoveContentToPlaylistModal
        isVisible={moveContentToPlaylistModalIsVisible}
        onHide={hideMoveContentToPlaylistModal}
        duplicateToPlaylist={false}
        contentIds={multipleContentIds}
        playlistList={playlistList || []}
        onMoveToPlaylist={onMoveMultipleContentsToPlaylist}
      />
      <SignListModal
        title="Copy to Signs"
        isVisible={isSignListModalVisible}
        onHide={hideSignListModal}
        selectedSigns={[]}
        inMultiSelectMode
        onSubmitSelectedSigns={onDuplicateContentsToSign}
      />
      <ConfirmDeleteModal
        show={isConfirmMultiDeleteModalVisible}
        onHide={hideConfirmMultiDeleteModal}
        contentName={multipleContentNames}
        onConfirmDelete={onDeleteContents}
      />
      <AddBumperWizard
        isVisible={isBumperContentModalVisible}
        onHide={hideBumperContentModal}
        selectedSign={sign}
        onCreateSuccess={refreshSign}
      />
    </div>
  );
};

export default Schedule;
