import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from 'react-bootstrap/esm/Modal';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Button from 'react-bootstrap/esm/Button';

import { IPlaylistResponse } from '@/types/playlist';
import { DUPLICATE_CONTENT_STRATEGY, MOVE_CONTENT_STRATEGY } from '@/features/Schedule/constants';

interface MoveContentToPlaylistModalProps {
  isVisible: boolean,
  onHide: () => void,
  contentIds: number[],
  playlistList: IPlaylistResponse[],
  onMoveToPlaylist: (playlist: IPlaylistResponse) => void,
  duplicateToPlaylist: boolean,
}

const MoveContentToPlaylistModal = ({
  isVisible,
  onHide,
  contentIds,
  playlistList,
  onMoveToPlaylist,
  duplicateToPlaylist,
}: MoveContentToPlaylistModalProps) => {
  const contentIdParams = contentIds.join(',');
  const routerNavigate = useNavigate();

  const moveStrategy = duplicateToPlaylist ? DUPLICATE_CONTENT_STRATEGY : MOVE_CONTENT_STRATEGY;

  return (
    <Modal show={isVisible} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{duplicateToPlaylist ? 'Duplicate to Playlist' : 'Move to Playlist'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(!playlistList) && (
          <div className="mb-2">
            No available playlists
          </div>
        )}
        <ListGroup>
          <Button
            className="w-100 mb-3"
            variant="outline-success"
            onClick={() => routerNavigate(`./playlists/add?${createSearchParams({ contentIds: contentIdParams, moveStrategy })}`)}
          >
            <FontAwesomeIcon
              className="me-2"
              icon="plus"
            />
              Create Playlist
          </Button>
          {playlistList?.map((playlist) => {
            return (
              <ListGroup.Item
                action
                key={playlist.id}
                onClick={() => onMoveToPlaylist(playlist)}
              >
                {playlist.name}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default MoveContentToPlaylistModal;
