import React from 'react';
import { ISignResponse } from '@/types/sign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'react-bootstrap/esm/Badge';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import classNames from 'classnames';

import { STATUS_OFFLINE, STATUS_ONLINE, getFormattedCheckedInAt, getSignOnlineStatus } from './utils';
import SignStatusPopover from './components/signStatusPopover/SignStatusPopover';

const SIZE_SMALL = 'small';
const SIZE_MEDIUM = 'medium';

interface SignOnlineStatusBadgeProps {
  sign: ISignResponse,
  size?: typeof SIZE_SMALL | typeof SIZE_MEDIUM,
}

const SignOnlineStatusBadge = ({
  sign,
  size = SIZE_MEDIUM,
}: SignOnlineStatusBadgeProps) => {
  const {
    onlineStatus,
    offlineReason,
  } = getSignOnlineStatus(sign);

  const checkedInAtFormatted = getFormattedCheckedInAt(sign);

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      overlay={SignStatusPopover({
        isOffline: onlineStatus === STATUS_OFFLINE,
        checkedInAtFormatted,
        offlineReason,
      })}
    >
      <Badge
        className={classNames({
          'fw-normal': true,
          'fs-6': size === SIZE_MEDIUM,
        })}
        bg={onlineStatus === STATUS_ONLINE ? 'success' : 'danger'}
      >
        <FontAwesomeIcon
          className="me-1"
          icon={onlineStatus === STATUS_ONLINE ? 'wifi' : 'triangle-exclamation'}
        />
        {onlineStatus === STATUS_ONLINE ? 'Online' : 'Offline' }
      </Badge>
    </OverlayTrigger>
  );
};

export default SignOnlineStatusBadge;
