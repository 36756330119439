import React, { useEffect } from 'react';

import { DATETIME_FORMAT } from '@/utils/constants';
import { formatDateRangesToString } from '@/utils/helpers';

import { ISignResponse } from '@/types/sign';
import { IFileResponse } from '@/types/file';
import { IContentForm } from '@/features/Schedule/types';

import { useCreateContent } from '@/hooks/content';

import AddContentWizard from './AddContentWizard';

interface AddContentToScheduleWizardProps {
  isVisible: boolean;
  onHide: () => void;
  selectedSign: ISignResponse | null;
  onCreateSuccess: () => void;
}

const AddContentToScheduleWizard = (props: AddContentToScheduleWizardProps) => {
  const { isVisible, onHide, selectedSign, onCreateSuccess } = props;

  const {
    createContent,
    createdContent,
    createContentIsLoading,
  } = useCreateContent();

  useEffect(() => {
    if (!createdContent) return;
    onHide();
    onCreateSuccess();
  }, [createdContent]);

  const onSubmitContents = (selectedFiles: IFileResponse[], contentFormValues?: IContentForm) => {
    if (!contentFormValues) return;

    const isLinked = contentFormValues.isLinked || false;
    if (!selectedFiles || !selectedFiles.length) return;

    const contentPostRequestConfig = {
      name: contentFormValues.name,
      fileId: selectedFiles[0].id,
      startDate: contentFormValues.startDate.toFormat(DATETIME_FORMAT),
      endDate: contentFormValues.endDate.toFormat(DATETIME_FORMAT),
      durationInMilliseconds: contentFormValues.durationInMilliseconds,
      frequencyWeight: contentFormValues.frequencyWeight,
      isPaused: contentFormValues.isPaused,
      playlistIds: [],
      signIds: [] as number[],
      recurrence: {
        rDate: contentFormValues.recurrence.rDate ? formatDateRangesToString(contentFormValues.recurrence.rDate) : [],
        exDate: contentFormValues.recurrence.exDate ? formatDateRangesToString(contentFormValues.recurrence.exDate) : [],
        exTime: contentFormValues.recurrence.exTime ? formatDateRangesToString(contentFormValues.recurrence.exTime) : [],
        freq: contentFormValues.recurrence.freq,
        byDay: contentFormValues.recurrence.byDay.map((day) => ({
          dayOfWeek: day.dayOfWeek,
          startTime: day.startTime.toFormat(DATETIME_FORMAT),
          endTime: day.endTime.toFormat(DATETIME_FORMAT),
        })),
        byWeek: contentFormValues.recurrence.byWeek,
        byMonth: contentFormValues.recurrence.byMonth,
        byYear: contentFormValues.recurrence.byYear,
        startTime: contentFormValues.recurrence.startTime.toFormat(DATETIME_FORMAT),
        endTime: contentFormValues.recurrence.endTime.toFormat(DATETIME_FORMAT),
      },
    };

    if (isLinked) {
      contentPostRequestConfig.signIds = contentFormValues.signs.map((s) => s.id);
      createContent(contentPostRequestConfig);
    } else {
      const unlinkedContents = contentFormValues.signs.map(({ id }) => {
        const signs = [id];
        return {
          ...contentPostRequestConfig,
          signs,
        };
      });

      unlinkedContents.forEach((content) => {
        createContent(content);
      });
    }
  };

  return (
    <>
      <AddContentWizard
        isVisible={isVisible}
        onHide={onHide}
        selectedSign={selectedSign}
        onSubmit={onSubmitContents}
        maxSelectedFilesCount={1}
        isLoading={createContentIsLoading}
      />
    </>
  );
};

export default AddContentToScheduleWizard;

