import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { SIGN_LOAD_ERROR } from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useRefreshOneSignSchedule = () => {
  const [refreshScheduleCount, setRefreshScheduleCount] = useState<number>(0);
  const [refreshSignScheduleApiConfig, setRefreshSignScheduleApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: refreshOneSignScheduleResponse,
    loading: refreshOneSignScheduleIsLoading,
  } = useCallApi(refreshSignScheduleApiConfig, {
    errorMessage: SIGN_LOAD_ERROR,
  });

  const refreshOneSignSchedule = (signId: number) => {
    setRefreshSignScheduleApiConfig({
      url: `/signs/${signId}/refresh-schedule`,
      method: 'get',
    });
  };

  useEffect(() => {
    if (refreshOneSignScheduleResponse) setRefreshScheduleCount(refreshScheduleCount + 1);
  }, [refreshOneSignScheduleResponse]);

  return {
    refreshOneSignSchedule,
    refreshScheduleCount,
    refreshOneSignScheduleIsLoading,
  };
};

export default useRefreshOneSignSchedule;

