import { AxiosResponse, AxiosRequestConfig } from 'axios';
import AppAxiosInstance from '@/api/appAxios';
import HubAxiosInstance from '@/api/hubAxios';
import { useState, useEffect, useDebugValue } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

import { SUCCESS_TYPE, ERROR_TYPE } from '@/common/constants';

interface IState {
  error: {
    status: number,
    message: string,

    // 422 error response from API
    errors: {
      rule: string,
      field: string,
      message: string,
    }[],
  } | null,
  loading: boolean | undefined,
  data: AxiosResponse | null,
}

type UseCallApi = (config: (AxiosRequestConfig | null), options?: {
  successMessage?: string
  errorMessage?: string
  ignoreError?: boolean
}, useHubApi?: boolean) => IState

/**
 * A wrapper for api calls that adds an auth token before sending
 * @param config AxiosRequestConfig
 * @param useHubApi boolean
 *
 * @returns {*}
 */
const useCallApi: UseCallApi = (config, {
  successMessage,
  errorMessage,
  ignoreError,
} = {}, useHubApi = false) => {
  const [state, setState] = useState<IState>({
    error: null,
    loading: false,
    data: null,
  });

  const { getAccessTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });

      const accessToken = await getAccessTokenSilently();

      const result = await (useHubApi ? HubAxiosInstance : AppAxiosInstance).request({
        ...config,
        params: {
          ...config?.params,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setState({
        ...state,
        data: result,
        error: null,
        loading: false,
      });

      if (successMessage) {
        toast(successMessage, { type: SUCCESS_TYPE });
      }
    } catch (err: any) {
      const error = {
        status: err?.response?.status,
        message: err.message,
        errors: err?.response?.data?.errors || [],
      };
      setState({
        ...state,

        // reset previous data so subsequent api calls do not trigger previous success message
        // for example without this if I created a user, a success message would show.
        // then if I created another user where the api call failed, an error message & success message would show
        data: null,
        error,
        loading: false,
      });
      if (errorMessage && !ignoreError) {
        toast(errorMessage, { type: ERROR_TYPE });
      }
      throw Error('Api Call Failed');
    }
  };

  useEffect(() => {
    if (config) {
      callApi();
    }

    // cleanup state to avoid memory leaks
    return () => {
      setState({
        error: null,
        loading: false,
        data: null,
      });
    };
  }, [config]);

  useDebugValue(state);

  return {
    ...state,
  };
};

export default useCallApi;
