import React from 'react';
import { useDrop } from 'react-dnd';

import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';

import { IBreadcrumbResponse } from '@/features/Files/FilesTab/types';

interface BreadcrumbItemProps {
  item: IBreadcrumbResponse,
  link: string,
  lastItem: boolean,
}

const DroppableBreadcrumbItem = ({
  item,
  link,
  lastItem,
}: BreadcrumbItemProps) => {
  const [{ dropClasses }, dropRef] = useDrop(() => ({
    accept: ['file', 'folder'],
    drop: () => ({ folder: { id: item.id } }),
    canDrop: (source: { type: string, id: number, folderId: number }) => {
      return source.type === 'folder' || source.folderId !== item.id;
    },
    collect: (monitor) => ({
      dropClasses: monitor.isOver() && monitor.canDrop() ? 'highlight-drop-area' : '',
    }),
  }));

  return (
    <Breadcrumb.Item
      ref={dropRef}
      key={item.id}
      linkAs={Link}
      linkProps={{ to: link }}
    >
      <span className={`${dropClasses} p-2 h6 ${lastItem && 'text-underline'}`}>{item.name}</span>
    </Breadcrumb.Item>
  );
};

export default DroppableBreadcrumbItem;
