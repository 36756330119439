import React from 'react';

import { ISignResponse } from '@/types/sign';
import { IFileResponse } from '@/types/file';

import AddContentWizard from './AddContentWizard';

interface AddContentToPlaylistWizardProps {
  isVisible: boolean;
  onHide: () => void;
  selectedSign: ISignResponse | null;
  onSubmit: (files: IFileResponse[]) => void;
}

const AddContentToPlaylistWizard = (props: AddContentToPlaylistWizardProps) => {
  const { isVisible, onHide, selectedSign, onSubmit } = props;

  return (
    <AddContentWizard
      isVisible={isVisible}
      onHide={onHide}
      isLoading={false}
      selectedSign={selectedSign}
      inPlaylistCreate={true}
      onSubmit={onSubmit}
    />
  );
};

export default AddContentToPlaylistWizard;

