
import React, { useRef } from 'react';
import Card from 'react-bootstrap/esm/Card';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useToggle, useLongPress } from '@/hooks';
import { TFolderCardActions } from '@/features/Files/FilesTab/types';
import { IFolderResponse } from '@/types/folder';
import { FOLDER_CARD_ACTION_MOVE, FOLDER_CARD_ACTION_RENAME, FOLDER_CARD_ACTION_DELETE } from '@/features/Files/FilesTab/constants';
import RenameModal from '@/common/RenameModal';

import FolderListCardActions from './FolderListCardActions';

import '@/features/Files/FilesTab/Files.scss';

interface FolderGridCardProps {
  folder: IFolderResponse,
  onDeleteFolder: (folder: IFolderResponse) => void,
  onMoveFolder: (folder: IFolderResponse, toFolderId?: number) => void,
  isSelected: boolean,
  addFolderToMultiSelect: (folderClicked: IFolderResponse) => void,
  removeFolderFromMultiSelect: (folderClicked: IFolderResponse) => void,
  onRenameFolder: (folderClicked: IFolderResponse) => void,
  inMultiSelectMode: boolean,
}

const FolderGridCard = ({
  folder,
  onDeleteFolder,
  onMoveFolder,
  isSelected,
  addFolderToMultiSelect,
  removeFolderFromMultiSelect,
  onRenameFolder,
  inMultiSelectMode,
}: FolderGridCardProps) => {
  const { name: folderName } = folder;
  const routerNavigate = useNavigate();

  const { show: showFolderListCardActions, hide: hideFolderListCardActions, isVisible: isFolderListCardActionsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();

  const folderCard = useRef<HTMLDivElement>(null);

  const [{ dropClasses }, dropRef] = useDrop(() => ({
    accept: ['file', 'folder'],
    drop: () => ({ folder }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canDrop: (source: { type: string, id: number, folderId: number }, monitor) => {
      const differentFolder = source.type === 'folder' ? source.id !== folder.id : false;
      return source.type === 'file' || differentFolder;
    },
    collect: (monitor) => {
      let classList = '';
      if (monitor.isOver() && monitor.canDrop()) classList += 'highlight-drop-area ';

      return {
        dropClasses: classList,
      };
    },
  }));

  const [{ dragClasses }, dragRef] = useDrag(
    () => ({
      type: 'folder',
      item: { type: 'folder', id: folder.id, folderId: folder.folderId },
      collect: (monitor) => ({
        dragClasses: monitor.isDragging() ? 'opacity-50' : '',
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ folder: IFolderResponse }>();
        if (item && dropResult && dropResult.folder.id) {
          onMoveFolder(folder, dropResult.folder.id);
        }
      },
    }),
    [],
  );

  const dragDropRef = (el: HTMLDivElement | null) => {
    dragRef(el);
    dropRef(el);
  };

  const onLongPress = () => {
    if (isSelected) {
      removeFolderFromMultiSelect(folder);
    } else {
      addFolderToMultiSelect(folder);
    }
  };

  const onClickEvent = () => {
    if (inMultiSelectMode && isSelected) {
      removeFolderFromMultiSelect(folder);
    } else if (inMultiSelectMode && !isSelected) {
      addFolderToMultiSelect(folder);
    } else {
      routerNavigate(`/assets/files/folders/${folder.id}`);
    }
  };

  const defaultLongPressOptions = {
    shouldPreventDefault: true,
    delay: 750,
  };
  const longPressEvents = useLongPress(onLongPress, onClickEvent, defaultLongPressOptions);

  const checkIcon: IconProp = isSelected
    ? ['fas', 'circle-check']
    : ['far', 'circle'];

  const onAction = (action: TFolderCardActions) => {
    const ACTIONS_DICT = {
      [FOLDER_CARD_ACTION_MOVE]: () => onMoveFolder(folder),
      [FOLDER_CARD_ACTION_RENAME]: () => showRenameModal(),
      [FOLDER_CARD_ACTION_DELETE]: () => onDeleteFolder(folder),
    };

    ACTIONS_DICT[action]();
    hideFolderListCardActions();
  };

  return (
    <div
      ref={dragDropRef}
      className={classNames('w-100', dropClasses, dragClasses)}
    >
      <Card
        className={classNames({
          'file-list-item': true,
          'bg-primary bg-opacity-25 border-primary': isSelected,
          'border-transparent': !isSelected,
        })}
        ref={folderCard}
      >
        <div {...longPressEvents}>
          <div className="position-relative">
            <div className="ratio ratio-16x9">
              <FontAwesomeIcon
                className="text-primary"
                icon="folder"
              />
            </div>
            {(inMultiSelectMode) && (
              <FontAwesomeIcon
                className={
                  classNames({
                    'bg-white rounded-circle multiselect-icon': true,
                    'text-black-50': !isSelected,
                    'text-primary': isSelected,
                  })
                }
                icon={checkIcon}
              />
            )}
          </div>
        </div>

        <Card.Footer className="app-card-footer file-list-item-footer bg-transparent">
          <OverlayTrigger
            placement="bottom"
            container={folderCard}
            overlay={
              (<Tooltip>
                {folderName}
              </Tooltip>)
            }
          >
            <span className="text-wrap">
              {folderName}
            </span>
          </OverlayTrigger>
          {!inMultiSelectMode && (<Button
            variant="link"
            onClick={showFolderListCardActions}
          >
            <FontAwesomeIcon className="text-gray" icon="ellipsis-v" />
          </Button>
          )
          }
        </Card.Footer>
        <FolderListCardActions
          isVisible={isFolderListCardActionsVisible}
          onHide={hideFolderListCardActions}
          onActionClick={(action) => onAction(action)}
        />
      </Card>

      <RenameModal
        title="Rename Folder"
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={folder.name}
        onUpdateName={(newFileName) => {
          const updatedFolder = {
            ...folder,
            name: newFileName,
          };

          onRenameFolder(updatedFolder);
        }}
      />
    </div>
  );
};

export default FolderGridCard;

