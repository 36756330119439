import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { IContentResponse } from '@/types/content';
import {
  CONTENT_LOAD_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadContents = () => {
  const [contents, setContents] = useState<IContentResponse[] | null>(null);
  const [readContentApiConfig, setReadContentApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readContentsResponse,
    loading: readContentsIsLoading,
  } = useCallApi(readContentApiConfig, { errorMessage: CONTENT_LOAD_ERROR });

  const readContents = (params: IndexApiParams = {}) => {
    setReadContentApiConfig({
      url: '/contents',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setContents(readContentsResponse?.data);
  }, [readContentsResponse]);

  return {
    readContents,
    contents,
    readContentsIsLoading,
  };
};

export default useReadContents;

