import { IANATimezone, ReadableTimezone } from '@/types/time';

export const ONE_SECOND_MS = 1000;
export const MS_IN_S = 1000;

export const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const DATETIME_WITH_MILLIS_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export const READABLE_TIMEZONES_OPTIONS = [
  'Eastern',
  'Central',
  'Mountain',
  'Pacific',
  'Alaska',
  'Hawaii',
];

export const IANA_TO_READABLE_TIMEZONES_DICT: { [key: string]: ReadableTimezone} = {
  'America/New_York': 'Eastern',
  'America/Chicago': 'Central',
  'America/Denver': 'Mountain',
  'America/Los_Angeles': 'Pacific',
  'America/Anchorage': 'Alaska',
  'Pacific/Honolulu': 'Hawaii',
};

export const READABLE_TO_IANA_TIMEZONES_DICT: { [key: string]: IANATimezone } = {
  'Eastern': 'America/New_York',
  'Central': 'America/Chicago',
  'Mountain': 'America/Denver',
  'Pacific': 'America/Los_Angeles',
  'Alaska': 'America/Anchorage',
  'Hawaii': 'Pacific/Honolulu',
};

export const MAX_FILE_SIZE_IN_MB = 160;
export const BYTES_IN_KB = 1024;
export const KB_IN_MB = 1024;

export const STATUS_CODE_401 = 401;

export const VALID_FILE_EXTENSIONS_IMAGE = ['jpg', 'jpeg', 'png'];
export const VALID_FILE_EXTENSIONS_VIDEO = ['mp4', 'wmv', 'mov', 'avi'];

// videos that can be previewed in app
export const VALID_FILE_EXTENSIONS_VIDEO_PREVIEW = ['mp4', 'wmv'];

export const VALID_FILE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/x-ms-wmv',
  'video/quicktime',
  'video/avi',
];
