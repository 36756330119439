import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useLongPress, useStore, useToggle } from '@/hooks';
import { downloadS3Url } from '@/utils/helpers';
import {
  TFileCardActions,
} from '@/features/Files/FilesTab/types';
import { IFileResponse, IFileUploadForm } from '@/types/file';
import { IFolderResponse } from '@/types/folder';
import {
  FILE_CARD_ACTION_MOVE,
  FILE_CARD_ACTION_RENAME,
  FILE_CARD_ACTION_REPLACE,
  FILE_CARD_ACTION_DOWNLOAD,
  FILE_CARD_ACTION_DELETE,
} from '@/features/Files/FilesTab/constants';
import RenameModal from '@/common/RenameModal';

import UploadFileModal from './UploadFileModal';
import FileListCardActions from './FileListCardActions';

import '@/features/Files/FilesTab/Files.scss';

interface FileGridCardProps {
  file: IFileResponse,
  onMoveFile: (fileClicked: IFileResponse, folderId?: number) => void,
  onRenameFile: (fileClicked: IFileResponse) => void,
  onReplaceFile: (fileClicked: IFileResponse, fileToUpload: IFileUploadForm) => void,
  onDeleteFile: (fileClicked: IFileResponse) => void,
  isSelected: boolean,
  addFileToMultiSelect: (fileClicked: IFileResponse) => void,
  removeFileFromMultiSelect: (fileClicked: IFileResponse) => void,
  inMultiSelectMode: boolean,
  putFileIsLoading?: boolean,
}

const FileGridCard = ({
  file,
  onMoveFile,
  onRenameFile,
  onReplaceFile,
  onDeleteFile,
  isSelected,
  addFileToMultiSelect,
  removeFileFromMultiSelect,
  inMultiSelectMode,
  putFileIsLoading,
}: FileGridCardProps) => {
  // State mgmt
  const { setSelectedFile, onFileClickNavigateTo, setOnFileClickNavigateTo } = useStore();

  const { name: fileName } = file;
  const routerNavigate = useNavigate();

  const { show: showUploadFileModal, hide: hideUploadFileModal, isVisible: isUploadFileModalVisible } = useToggle();
  const { show: showFileListCardActions, hide: hideFileListCardActions, isVisible: isFileListCardActionsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();

  const fileCard = useRef<HTMLDivElement>(null);

  const [{ dragClasses }, dragRef] = useDrag(
    () => ({
      type: 'file',
      item: { type: 'file', id: file.id, folderId: file.folderId },
      collect: (monitor) => ({
        dragClasses: monitor.isDragging() ? 'opacity-50' : '',
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ folder: IFolderResponse }>();
        if (item && dropResult && dropResult.folder.id) {
          onMoveFile(file, dropResult.folder.id);
        }
      },
    }),
    [],
  );

  useEffect(() => {
    if (putFileIsLoading === false) {
      hideUploadFileModal();
    }
  }, [putFileIsLoading]);

  const onLongPress = () => {
    if (isSelected) {
      removeFileFromMultiSelect(file);
    } else {
      addFileToMultiSelect(file);
    }
  };

  const onClickEvent: any = () => {
    if (inMultiSelectMode && isSelected) {
      removeFileFromMultiSelect(file);
    } else if (inMultiSelectMode && !isSelected) {
      addFileToMultiSelect(file);
    } else {
      setSelectedFile(file);
      if (!onFileClickNavigateTo) {
        routerNavigate(`/assets/files/${file.id}`);
        return;
      }
      routerNavigate(onFileClickNavigateTo);
      setOnFileClickNavigateTo(null);
    }
  };

  const defaultLongPressOptions = {
    shouldPreventDefault: true,
    delay: 750,
  };
  const longPressEvents = useLongPress(onLongPress, onClickEvent, defaultLongPressOptions);

  const renderFileThumbnail = () => {
    const fileUrl = file.thumbnailUrl
      ? file.thumbnailUrl
      : file.url;

    const checkIcon: IconProp = isSelected
      ? ['fas', 'circle-check']
      : ['far', 'circle'];

    return (
      <div className="position-relative">
        <div className="ratio ratio-16x9">
          <div
            className="img file-list-item-thumbnail rounded-2"
            style={{ backgroundImage: `url(${fileUrl})` }}
          />
        </div>
        {(inMultiSelectMode) && (
          <FontAwesomeIcon
            className={
              classNames({
                'bg-white rounded-circle multiselect-icon': true,
                'text-black-50': !isSelected,
                'text-primary': isSelected,
              })
            }
            icon={checkIcon}
          />
        )}
      </div>
    );
  };

  const renderTooltip = (tooltipProps: any) => (
    <Tooltip
      {...tooltipProps}
    >
      <div>{fileName}</div>
    </Tooltip>
  );

  const onAction = (action: TFileCardActions) => {
    const ACTIONS_DICT = {
      [FILE_CARD_ACTION_MOVE]: () => onMoveFile(file),
      [FILE_CARD_ACTION_RENAME]: () => showRenameModal(),
      [FILE_CARD_ACTION_REPLACE]: showUploadFileModal,
      [FILE_CARD_ACTION_DOWNLOAD]: () => downloadS3Url(file.downloadUrl),
      [FILE_CARD_ACTION_DELETE]: () => onDeleteFile(file),
    };

    ACTIONS_DICT[action]();
    hideFileListCardActions();
  };

  return (
    <div
      ref={dragRef}
      className={classNames('w-100', dragClasses)}
    >
      <Card
        className={classNames({
          'file-list-item': true,
          'bg-primary bg-opacity-25 border-primary': isSelected,
          'border-transparent': !isSelected,
        })}
        ref={fileCard}
      >
        <div {...longPressEvents}>
          {renderFileThumbnail()}
        </div>

        <Card.Footer
          className="app-card-footer"
        >
          <div>
            <OverlayTrigger
              placement="bottom"
              container={fileCard}
              overlay={renderTooltip}
            >
              <span className="text-wrap">
                {fileName}
              </span>
            </OverlayTrigger>
            {(file.width && file.height) && (<>
              <br />
              <span className="text-truncate text-muted small">
                {file.width}x{file.height}
              </span>
            </>)}
          </div>
          {!inMultiSelectMode && (<Button
            variant="link"
            onClick={showFileListCardActions}
          >
            <FontAwesomeIcon className="text-gray" icon="ellipsis-v" />
          </Button>)
          }
        </Card.Footer>
      </Card>
      <FileListCardActions
        isVisible={isFileListCardActionsVisible}
        onHide={hideFileListCardActions}
        onActionClick={onAction}
      />
      <UploadFileModal
        show={isUploadFileModalVisible}
        onUploadFile={(fileToUpload) => onReplaceFile(file, fileToUpload)}
        handleCloseUploadFileModal={hideUploadFileModal}
        loading={putFileIsLoading}
      />
      <RenameModal
        title="Rename File"
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={file.name}
        onUpdateName={(newFileName) => {
          const updatedFile = {
            ...file,
            name: newFileName,
          };

          onRenameFile(updatedFile);
        }}
      />
    </div>
  );
};

export default FileGridCard;
