import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/esm/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Button from 'react-bootstrap/esm/Button';

import { ICreateFolderForm } from '@/features/Files/FilesTab/types';

interface CreateFolderModalProps {
  show: boolean,
  handleCloseCreateFolderModal: () => void,
  onCreateNewFolder: (folderName: string) => void
}

const CreateFolderModal = ({
  show,
  handleCloseCreateFolderModal,
  onCreateNewFolder,
}: CreateFolderModalProps,
) => {
  const minStringLength = 3;
  const schema = Yup.object().shape({
    folderName: Yup.string().min(minStringLength, 'Name must be at least 3 characters').required('Please enter folder name'),
  });

  return (
    <Modal show={show} onHide={handleCloseCreateFolderModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>New Folder</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          folderName: '',
        }}
        onSubmit={(values: ICreateFolderForm) => {
          onCreateNewFolder(values.folderName);
        }}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }: FormikProps<ICreateFolderForm>) => {
          return (<>
            <Modal.Body>
              <Form
                id="create-folder-form"
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group className="mb-3" controlId="folder-name">
                  <FloatingLabel label="Folder Name">
                    <Form.Control
                      type="text"
                      placeholder="Folder Name"
                      name="folderName"
                      value={values.folderName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.folderName && !errors.folderName}
                      isInvalid={!!errors.folderName && touched.folderName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.folderName ? errors.folderName : 'Name Required'}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="light" onClick={handleCloseCreateFolderModal}>
                Cancel
              </Button>
              <Button
                form="create-folder-form"
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                Create
              </Button>
            </Modal.Footer>
          </>);
        }}
      </Formik>
    </Modal>
  );
};

export default CreateFolderModal;
