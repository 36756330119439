import React, { useState } from 'react';

import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';

import { IFileResponse } from '@/types/file';

import '@/features/Schedule/Schedule.scss';
import { DateTime } from 'luxon';

interface PendingPlaylistContentListCardProps {
  file: IFileResponse,
  startDate: DateTime,
  endDate: DateTime,
}

const PendingPlaylistContentListCard = (props: PendingPlaylistContentListCardProps) => {
  const { file, startDate, endDate } = props;

  const [validImage, setValidImage] = useState(true);

  const shortFormatStartDate = startDate.toLocaleString({ month: 'short', day: 'numeric' });
  const shortFormatEndDate = endDate.toLocaleString({ month: 'short', day: 'numeric' });

  const renderContentThumbnail = () => {
    return (
      <div className="schedule-thumbnail">
        {(file?.thumbnailUrl
          || (file && VALID_FILE_EXTENSIONS_IMAGE.includes(file.fileType))
          && validImage)
        && (
          <img
            className="align-self-center"
            src={file?.thumbnailUrl || file.url}
            style={{ width: 56, maxHeight: 56 }}
            onError={() => setValidImage(false)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative w-100 px-2 py-3 bg-light-green-1">
        <div className="d-flex flex-grow-1 text-truncate">
          {renderContentThumbnail()}

          <div className="ms-2 text-truncate">
            <strong>{file.name}</strong> <br />
            {`${shortFormatStartDate} - ${shortFormatEndDate}`}
          </div>
        </div>

        {/* {!readonly && (
          <Button
            className="text-dark"
            variant="link"
            onClick={onDelete}
          >
            <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
          </Button>
        )} */}
      </div>
    </>
  );
};

export default PendingPlaylistContentListCard;
