/* eslint-disable no-process-env */
import { NavItem } from './types';

const REACT_APP_CANVAS_URL = process.env.REACT_APP_CANVAS_URL || '';

const REACT_APP_TEMPLATES_URL = process.env.REACT_APP_TEMPLATES_URL || '';

export const HOME_NAV_ITEMS: NavItem[] = [
  {
    name: 'Templates',
    url: REACT_APP_TEMPLATES_URL,
    customIcon: 'template',
    isDisabledOnMobile: true,
    isExternal: true,
  },
  {
    name: 'Canvas',
    customIcon: 'wand',
    url: REACT_APP_CANVAS_URL,
    isDisabledOnMobile: true,
    isExternal: true,
  },
  {
    name: 'Schedule My Sign',
    url: '/schedule',
    customIcon: 'clock',
  },
  {
    name: 'My Files',
    url: '/assets/files',
    customIcon: 'file',
  },
  {
    name: 'My Signs',
    url: '/signs',
    customIcon: 'sign',
  },
];

export const MOBILE_NAV_ITEMS: NavItem[] = [
  {
    name: 'Home',
    url: '/home',
    customIcon: 'home',
  },
  {
    name: 'Schedule',
    url: '/schedule',
    customIcon: 'clock',
  },
  {
    name: 'Files',
    url: '/assets/files',
    customIcon: 'file',
  },
  {
    name: 'Signs',
    url: '/signs',
    customIcon: 'sign',
  },
];

export const DASHBOARD_NAV_ITEMS: NavItem[] = [
  {
    name: 'Home',
    url: '/home',
    customIcon: 'home',
  },
  {
    name: 'Schedule My Sign',
    url: '/schedule',
    customIcon: 'clock',
  },
  {
    name: 'Files',
    url: '/assets/files',
    customIcon: 'file',
  },
  {
    name: 'Signs',
    url: '/signs',
    customIcon: 'sign',
  },
];

export const DASHBOARD_EXTERNAL_NAV_ITEMS: NavItem[] = [
  {
    name: 'Templates',
    url: REACT_APP_TEMPLATES_URL,
    customIcon: 'template',
    isDisabledOnMobile: true,
    isExternal: true,
    className: 'me-2',
  },
  {
    name: 'Canvas',
    url: REACT_APP_CANVAS_URL,
    customIcon: 'wand',
    isDisabledOnMobile: true,
    isExternal: true,
    className: 'me-3',
  },
  {
    name: 'Scheduler',
    url: '/home',
    customIcon: 'clock',
    className: 'me-3',
  },
];
