import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { ISignResponse } from '@/types/sign';
import {
  SIGN_LOAD_ERROR,
} from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadSigns = () => {
  const [signs, setSigns] = useState<ISignResponse[]>([]);
  const [getSignListApiConfig, setGetSignListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readSignsResponse,
    loading: readSignsIsLoading,
  } = useCallApi(getSignListApiConfig, {
    errorMessage: SIGN_LOAD_ERROR,
  });

  const readSigns = (params: IndexApiParams = {}) => {
    setGetSignListApiConfig({
      url: '/signs',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setSigns(readSignsResponse?.data || []);
  }, [readSignsResponse]);

  return {
    readSigns,
    signs,
    readSignsIsLoading,
  };
};

export default useReadSigns;

