
import React from 'react';

import { IFolderResponse } from '@/types/folder';
import Col from 'react-bootstrap/esm/Col';

import FolderGridCard from './FolderGridCard';

interface FolderGridViewProps {
  folderList: IFolderResponse[] | null,
  onMoveFolder: (folderClicked: IFolderResponse) => void,
  onDeleteFolder: (folderClicked: IFolderResponse) => void,
  selectedFolders: IFolderResponse[],
  addFolderToMultiSelect: (fileClicked: IFolderResponse) => void,
  removeFolderFromMultiSelect: (fileClicked: IFolderResponse) => void,
  onRenameFolder: (folderClicked: IFolderResponse) => void,
  inMultiSelectMode: boolean,
}

const FolderGridView = ({
  folderList,
  onMoveFolder,
  onDeleteFolder,
  selectedFolders,
  addFolderToMultiSelect,
  removeFolderFromMultiSelect,
  onRenameFolder,
  inMultiSelectMode,
}: FolderGridViewProps) => {
  const selectedFolderIds = selectedFolders.map((f) => f.id);

  if (!folderList) {
    return (
      <></>
    );
  }

  return (
    <>
      {(folderList.map((folder) => (
        <Col
          key={folder.id}
          xs="6"
          sm="auto"
        >
          <div className="d-flex justify-content-center">
            <FolderGridCard
              folder={folder}
              onDeleteFolder={onDeleteFolder}
              onMoveFolder={onMoveFolder}
              isSelected={selectedFolderIds.includes(folder.id)}
              addFolderToMultiSelect={addFolderToMultiSelect}
              removeFolderFromMultiSelect={removeFolderFromMultiSelect}
              onRenameFolder={onRenameFolder}
              inMultiSelectMode={inMultiSelectMode}
            />
          </div>
        </Col>
      )))}
    </>);
};

export default FolderGridView;
