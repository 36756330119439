import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import {
  PLAYLIST_DELETED_ERROR,
  PLAYLIST_DELETED_MESSAGE,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useDeletePlaylists = () => {
  const [deletedPlaylists, setDeletedPlaylists] = useState<Record<string, never> | null>(null);
  const [deletePlaylistApiConfig, setDeletePlaylistApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deletePlaylistsResponse,
    loading: deletePlaylistsIsLoading,
  } = useCallApi(deletePlaylistApiConfig, {
    successMessage: PLAYLIST_DELETED_MESSAGE,
    errorMessage: PLAYLIST_DELETED_ERROR,
  });

  const deletePlaylists = (playlistIds: number[]) => {
    setDeletePlaylistApiConfig({
      url: '/playlists/delete',
      method: 'delete',
      data: {
        playlistIds,
      },
    });
  };

  useEffect(() => {
    if (deletePlaylistsResponse) setDeletedPlaylists({});
  }, [deletePlaylistsResponse]);

  return {
    deletePlaylists,
    deletedPlaylists,
    deletePlaylistsIsLoading,
  };
};

export default useDeletePlaylists;
