import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/esm/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Button from 'react-bootstrap/esm/Button';
import useUniqueId from '@/hooks/useUniqueId';

interface IRenameForm {
  updatedName: string
}

interface RenameModalProps {
    title?: string,
    show: boolean,
    handleCloseRenameModal: () => void,
    initialName: string,
    onUpdateName: (updatedName: string) => void,
}

const RenameModal = ({
  title = 'Rename',
  show,
  handleCloseRenameModal,
  initialName,
  onUpdateName,
}: RenameModalProps) => {
  const formId = useUniqueId('rename-form-');
  const minStringLength = 3;
  const schema = Yup.object().shape({
    updatedName: Yup.string().min(minStringLength, 'Name must be at least 3 characters').required('Please enter updated name'),
  });

  return (
    <Modal show={show} onHide={handleCloseRenameModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          updatedName: initialName,
        }}
        onSubmit={(values: IRenameForm) => {
          onUpdateName(values.updatedName);
        }}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }: FormikProps<IRenameForm>) => {
          return (<>
            <Modal.Body>
              <Form
                id={formId}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();

                  // stop propagation to prevent this form from submitting any react parent forms
                  e.stopPropagation();
                  handleSubmit();
                }}
              >
                <Form.Group className="mb-3" controlId="rename-input">
                  <FloatingLabel label="Name">
                    <Form.Control
                      type="text"
                      placeholder={initialName}
                      name="updatedName"
                      value={values.updatedName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.updatedName && !errors.updatedName}
                      isInvalid={!!errors.updatedName && touched.updatedName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.updatedName ? errors.updatedName : 'Name Required'}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="light"
                onClick={handleCloseRenameModal}
              >
                Cancel
              </Button>
              <Button
                form={formId}
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                Update
              </Button>
            </Modal.Footer>
          </>);
        }}
      </Formik>
    </Modal>
  );
};

export default RenameModal;
