import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Form from 'react-bootstrap/esm/Form';
import Badge from 'react-bootstrap/esm/Badge';
import { Formik, FormikProps } from 'formik';

import { IFileResponse } from '@/types/file';
import { DEFAULT_CONTENT_DURATION_IN_MILLISECONDS } from '@/features/Schedule/constants';
import { IBumperForm } from '@/features/Schedule/types';
import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';

import CardDetailsDuration from './CardDetailsDuration';
import CardDetailsPlaybackActivity from './CardDetailsPlaybackActivity';

interface AddBumperFormProps {
  selectedFile: IFileResponse;
  handleFormSubmit: (values: IBumperForm) => void;
}

export interface BumperAddHandle {
  submit: () => void;
}

const AddBumperForm = forwardRef<BumperAddHandle, AddBumperFormProps>((
  {
    selectedFile,
    handleFormSubmit,
  },
  ref,
) => {
  const formRef = useRef<FormikProps<IBumperForm>>(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current?.handleSubmit();
    },
  }));

  const initialBumperFormValues: IBumperForm = {
    durationInMilliseconds: selectedFile.durationInMilliseconds || DEFAULT_CONTENT_DURATION_IN_MILLISECONDS,
    isPaused: false,
  };

  const validate = (values: IBumperForm) => {
    const errors: {
      durationInMilliseconds?: string,
    } = {};

    if (typeof values.durationInMilliseconds === 'number' && values.durationInMilliseconds <= 0) {
      errors.durationInMilliseconds = 'Duration must be greater than 0';
    }

    return errors;
  };

  const isImageFileType = selectedFile
    && selectedFile.fileType
    && VALID_FILE_EXTENSIONS_IMAGE.includes(selectedFile.fileType.toLowerCase());

  return (
    <Formik
      initialValues={initialBumperFormValues}
      onSubmit={handleFormSubmit}
      validate={validate}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
      }: FormikProps<IBumperForm>) => {
        const durationInMilliseconds = values.durationInMilliseconds || 0;

        return (
          <Form noValidate onSubmit={handleSubmit}>
            { /** Preview */}
            <section className="bg-white shadow-sm">
              <div className="bg-dark d-flex justify-content-center text-white">
                <FileWithPreview
                  file={selectedFile}
                />
              </div>
              <div className="p-3">
                <p className="mb-0">
                  {selectedFile.width} x {selectedFile.height}
                </p>
                <div className="mt-2">
                  <Badge className="bg-secondary">
                    {selectedFile?.fileType}
                  </Badge>
                </div>
              </div>
            </section>
            {/* Duration */}
            { isImageFileType && (<div className="mt-2">
              <CardDetailsDuration
                durationInMilliseconds={durationInMilliseconds}
                error={errors.durationInMilliseconds}
                onChange={(newDurationInMilliseconds) => {
                  setFieldValue('durationInMilliseconds', newDurationInMilliseconds);
                }}
              />
            </div>)}
            {/* Paused */}
            <div className="my-2">
              <CardDetailsPlaybackActivity
                item={values}
                setFieldValue={setFieldValue}
                description={[
                  'This setting is used to pause the bumper. An inactive bumper will ',
                  (<strong key="1">not</strong>),
                  ' play.',
                ]}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
});

export default AddBumperForm;
