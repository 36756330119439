import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';

import { useToggle } from '@/hooks';
import OffcanvasActionMenu from '@/common/OffcanvasActionMenu';
import AddContentToScheduleWizard from '@/features/Schedule/components/AddContentWizard/AddContentToScheduleWizard';
import { IActionMenuItem } from '@/types/actions';
import { ISignResponse } from '@/types/sign';
import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';

interface DefaultContentCardProps {
  refreshSchedule: () => void,
  selectedSign: ISignResponse | null,
}

const DefaultContentCard = ({
  refreshSchedule,
  selectedSign,
}: DefaultContentCardProps) => {
  const routerNavigate = useNavigate();

  const { show: showContentListCardActions, hide: hideContentListCardActions, isVisible: isContentListCardActionsVisible } = useToggle();
  const { show: showScheduleAddContentWizard, hide: hideScheduleAddContentWizard, isVisible: isScheduleAddContentWizardVisible } = useToggle();

  const defaultContentActionItems: IActionMenuItem[] = [
    {
      text: 'Change Default Content',
      icon: 'pencil',
      action: 'navigateToSign',
    },
  ];

  const onAction = (action: string) => {
    if (action === 'navigateToSign') routerNavigate(`/signs/${selectedSign?.id}/edit`);
  };

  return (
    <>
      <div className="d-flex justify-content-between px-2 py-3 mb-2">
        <div className="d-flex flex-grow-1 text-truncate">
          <div
            className="align-self-center"
            draggable={false}
          >
            <div className="schedule-thumbnail">
              {/* TODO - add default file icon */}
              {(selectedSign
                && selectedSign.defaultFile
                && VALID_FILE_EXTENSIONS_IMAGE.includes(selectedSign.defaultFile.fileType))
                && (<img
                  src={selectedSign?.defaultFile?.thumbnailUrl || selectedSign?.defaultFile.url}
                />
                )}
            </div>
          </div>
          <div className="ms-2 text-truncate">
            <strong>Default Content</strong> <br />
            Plays when nothing else is active.
          </div>
        </div>
        <Button
          className="text-dark flex-end"
          variant="link"
          onClick={showContentListCardActions}
        >
          <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
        </Button>
      </div>

      <Button
        className="w-100 mb-3"
        variant="outline-success"
        onClick={showScheduleAddContentWizard}
      >
        <FontAwesomeIcon
          className="me-2"
          icon="plus"
        />
          Add Content
      </Button>

      <OffcanvasActionMenu
        isVisible={isContentListCardActionsVisible}
        onHide={hideContentListCardActions}
        actionMenuItems={defaultContentActionItems}
        onActionClick={onAction}
        title={'Default Content Actions'}
      />

      <AddContentToScheduleWizard
        isVisible={isScheduleAddContentWizardVisible}
        onHide={hideScheduleAddContentWizard}
        onCreateSuccess={refreshSchedule}
        selectedSign={selectedSign}
      />
    </>
  );
};

export default DefaultContentCard;
