import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useToggle } from '@/hooks';
import { useDeleteFiles, useMoveFiles } from '@/hooks/file';
import { useReadOneFolder } from '@/hooks/folder';
import { downloadS3Url } from '@/utils/helpers';
import { IFileResponse } from '@/types/file';

import ConfirmDeleteModal from './FoldersFilesConfirmDeleteModal';
import FolderMoveOptions from './FolderMoveDestinationOptions';

interface FileDetailsActionsProps {
  file: IFileResponse,
  refreshFile: () => void,
}

export const FileDetailsActions = ({
  file,
  refreshFile,
}: FileDetailsActionsProps) => {
  const routerNavigate = useNavigate();

  const { show: showConfirmDelete, hide: hideConfirmDelete, isVisible: isConfirmDeleteVisible } = useToggle();
  const {
    moveFiles,
    movedFiles,
  } = useMoveFiles();
  const { deleteFiles, deletedFiles, deleteFilesIsLoading } = useDeleteFiles();

  const {
    readOneFolder,
    folders: folderFolders,
  } = useReadOneFolder();

  const { show: showFolderMoveOptions, hide: hideFolderMoveOptions, isVisible: isFolderMoveOptionsVisible } = useToggle();

  useEffect(() => {
    refreshFile();
  }, [movedFiles]);

  useEffect(() => {
    if (!deletedFiles) return;

    hideConfirmDelete();
    routerNavigate({
      pathname: file.folderId ? `/assets/files/folders/${file.folderId}` : '/assets/files',
    });
  }, [deletedFiles]);

  const onMove = () => {
    readOneFolder(file?.folderId);
    showFolderMoveOptions();
  };

  const moveFileToDestination = (folderDestinationId: number | null) => {
    moveFiles([file.id], folderDestinationId);
    hideFolderMoveOptions();
    routerNavigate({
      pathname: folderDestinationId ? `/assets/files/folders/${folderDestinationId}/files/${file.id}` : `/assets/files/${file.id}`,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <Button
            className="me-2 mb-2"
            variant="primary"
            onClick={() => onMove()}
          >
            <FontAwesomeIcon icon={['fas', 'arrow-right-from-bracket']} />
            <span className="ms-2">
              Move
            </span>
          </Button>
          <Button
            className="me-2 mb-2"
            variant="outline-primary"
            onClick={() => downloadS3Url(file.downloadUrl)}
          >
            <FontAwesomeIcon icon={['fas', 'download']} />
            <span className="ms-2">
              Download
            </span>
          </Button>
        </div>

        <Button
          variant="danger"
          onClick={() => showConfirmDelete()}
        >
          <FontAwesomeIcon icon={['far', 'trash-can']} />
          <span className="d-none d-sm-inline-block ms-2">
            Delete
          </span>
        </Button>
      </div>

      <ConfirmDeleteModal
        show={isConfirmDeleteVisible}
        onHide={hideConfirmDelete}
        filesToDelete={[file]}
        onDelete={() => deleteFiles([file.id])}
        isDeleting={deleteFilesIsLoading}
      />

      <FolderMoveOptions
        folderList = {folderFolders}
        showFolderMoveOptions = {isFolderMoveOptionsVisible}
        handleCloseFolderOptions = {hideFolderMoveOptions}
        onSelectFolderDestination = {moveFileToDestination}
        breadcrumbList={file.breadcrumbs}
      />
    </>
  );
};
