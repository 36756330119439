import React from 'react';
import classNames from 'classnames';
import Popover from 'react-bootstrap/esm/Popover';

import './signStatusPopover.scss';

interface SignStatusPopoverProps {
  isOffline: boolean,
  checkedInAtFormatted: string,
  offlineReason: string | null,
}

const SignStatusPopover = ({
  isOffline,
  checkedInAtFormatted,
  offlineReason,
}: SignStatusPopoverProps) => (
  <Popover className="sign-online-status-popover">
    <Popover.Header
      className={classNames({
        'bg-danger': isOffline,
        'bg-success': !isOffline,
        'text-white': true,
      })}
      as="h3"
    >
      Sign Status
    </Popover.Header>
    <Popover.Body className="p-2">
      <table>
        <tbody>
          <tr>
            <td>
              Last check in
            </td>
            <td>
              {checkedInAtFormatted}
            </td>
          </tr>
          {(offlineReason) && (
            <tr>
              <td>
                Offline reason
              </td>
              <td>
                {offlineReason}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Popover.Body>
  </Popover>);

export default SignStatusPopover;
