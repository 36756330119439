/**
 * Helper Functions for use within Schedule Components
 */
import { DateTime, Interval } from 'luxon';

import { IContentResponse } from '@/types/content';
import { IPlaylistResponse } from '@/types/playlist';
import { ISignResponse } from '@/types/sign';

import { DATETIME_FORMAT } from '@/utils/constants';

import { TActivityStatus } from './types';
import { WEEKLY_FREQ, ACTIVE_STATUS, PAUSED_STATUS, FUTURE_STATUS, EXPIRED_STATUS, MONTHLY_FREQ, YEARLY_FREQ } from './constants';

const timeframeInterval = (start: string, end: string, zone: DateTime['zone'] | string) => {
  const startTime = DateTime.fromFormat(start, DATETIME_FORMAT).setZone(zone);
  const endTime = DateTime.fromFormat(end, DATETIME_FORMAT).setZone(zone);
  return Interval.fromDateTimes(startTime, endTime);
};

export const findActivityStatus = (item: IContentResponse | IPlaylistResponse, zone?: DateTime['zoneName']): TActivityStatus => {
  const { recurrence } = item;

  const now = zone ? DateTime.local({ zone }) : DateTime.local();
  const timezone = now.zoneName;
  const startDateTime = DateTime.fromFormat(item.startDatetime, DATETIME_FORMAT).setZone(zone !== null ? zone : undefined);

  if (!recurrence) return ACTIVE_STATUS;

  const isExpired = timeframeInterval(item.startDatetime, item.endDatetime, timezone).isBefore(now);
  if (isExpired) return EXPIRED_STATUS;

  const isPaused = item.isPaused;
  if (isPaused) return PAUSED_STATUS;

  const withinAdditionalDay = recurrence.rDate?.some(([start, end]) => {
    return timeframeInterval(start, end, timezone).contains(now);
  });

  const dayOfWeek = recurrence.byDay?.find((day) => day.dayOfWeek.toUpperCase() === now.weekdayShort.toUpperCase());
  if (recurrence.freq === WEEKLY_FREQ && !dayOfWeek && !withinAdditionalDay) return FUTURE_STATUS;

  const dayOfMonth = recurrence.freq === MONTHLY_FREQ;
  if (dayOfMonth && startDateTime.day !== now.day) return FUTURE_STATUS;

  const dayOfYear = recurrence.freq === YEARLY_FREQ;
  if (dayOfYear && (startDateTime.day !== now.day || startDateTime.month !== now.month)) return FUTURE_STATUS;

  const withinExcludedTimes = recurrence.exTime?.some(([start, end]) => {
    return timeframeInterval(start, end, timezone).contains(now);
  });
  if (withinExcludedTimes) return FUTURE_STATUS;

  const outsideDailyTimeframe = dayOfWeek?.dayOfWeek
    ? !timeframeInterval(dayOfWeek.startTime, dayOfWeek.endTime, timezone).contains(now)
    : !timeframeInterval(recurrence.startTime, recurrence.endTime, timezone).contains(now);
  if (outsideDailyTimeframe) {
    return FUTURE_STATUS;
  }

  const scheduledToPlayAfterNow = now < startDateTime;
  if (scheduledToPlayAfterNow) return FUTURE_STATUS;

  return ACTIVE_STATUS;
};

// determine if user has access to all passed in signs
export const userHasAccessToSigns = (
  userSigns: ISignResponse[],
  signsToValidate: ISignResponse[],
) => {
  const userSignIds = userSigns.map((sign) => sign.id);
  return signsToValidate.map((sign) => sign.id).every((signId) => userSignIds.includes(signId));
};
