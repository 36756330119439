import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useToggle } from '@/hooks';
import { useReadOneFolder } from '@/hooks/folder';
import { useReadOneSign } from '@/hooks/sign';
import { useCreateFiles, useReadFiles } from '@/hooks/file';

import Loader from '@/common/Loader';
import Search from '@/common/Search';
import CustomIcon from '@/common/CustomIcon';

import {
  IBreadcrumbResponse,
} from '@/features/Files/FilesTab/types';
import { IFileResponse, IFilesUploadForm } from '@/types/file';
import { ISignCreateRequest, ISignResponse } from '@/types/sign';

import UploadFilesModal from '@/features/Files/FilesTab/components/UploadFilesModal';
import { validatePixelMatrix } from '@/utils/validations';

import './selectFileForm.scss';
import FolderList from './components/FolderList';
import FolderFileList from './components/FolderFileList';

const HOME_BREADCRUMB_ID = 0;

interface SelectFileFormProps {
  selectedFiles: IFileResponse[] | null,
  onSelectFiles: (files: IFileResponse[]) => void,
  isSingleSelect?: boolean,
  signValues?: ISignCreateRequest | ISignResponse | null,
}

interface sortOption {
  name?: string,
  field: string,
  direction: 'asc' | 'desc',
  icon?: IconProp,
}

const sortValues: sortOption[] = [
  {
    name: 'A - Z',
    field: 'name',
    direction: 'asc',
    icon: 'arrow-up',
  },
  {
    name: 'Z - A',
    field: 'name',
    direction: 'desc',
    icon: 'arrow-down',
  },
  {
    name: 'Date Uploaded',
    field: 'createdAt',
    direction: 'desc',
  },
  {
    name: 'Last Used',
    field: 'accessed_at',
    direction: 'desc',
  },
];

const SelectFileForm = ({
  selectedFiles,
  onSelectFiles,
  signValues,
}: SelectFileFormProps) => {
  const { signId } = useParams();

  const { show: showUploadFileModal, hide: hideUploadFileModal, isVisible: isUploadFileModalVisible } = useToggle();
  const { isVisible: isGridView, toggle: toggleGridView } = useToggle();

  const [folderId, setFolderId] = useState<number | null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [sortValue, setSortValue] = useState(sortValues[0]);

  const {
    readOneFolder,
    folder,
    folders: folderFolders,
    files: folderFiles,
    breadcrumbs: folderBreadcrumbs,
    readOneFolderIsLoading,
  } = useReadOneFolder();

  const {
    readFiles,
    files,
    readFilesIsLoading,
    setFiles,
  } = useReadFiles();

  const {
    createFiles,
    createFilesIsLoading,
    createdFiles,
  } = useCreateFiles();

  const {
    readOneSign,
    sign,
  } = useReadOneSign();

  useEffect(() => {
    // use files from folder response if search is not in use
    if (searchString === '') setFiles(folderFiles);
  }, [folder]);

  useEffect(() => {
    if (folderId === null) return;

    const params = {
      sort: sortValue.field,
      direction: sortValue.direction,
    };

    const folderToReadId = folderId === HOME_BREADCRUMB_ID ? null : folderId;
    readOneFolder(folderToReadId, params);
    setSearchString('');
  }, [folderId]);

  useEffect(() => {
    if (!createdFiles || !createdFiles.length) return;
    const params = {
      sort: sortValue.field,
      direction: sortValue.direction,
    };
    const folderToReadId = folderId === HOME_BREADCRUMB_ID ? null : folderId;
    readOneFolder(folderToReadId, params);
    hideUploadFileModal();
    onSelectFiles(createdFiles);
  }, [createdFiles]);

  useEffect(() => {
    const params = {
      name: searchString !== '' ? searchString : undefined,
      sort: sortValue.field,
      direction: sortValue.direction,
    };

    // get filtered file list if search is in use
    if (searchString !== '') {
      readOneFolder(null, params);
      readFiles(params);
      setFolderId(null);
    } else {
      const folderToReadId = folderId === HOME_BREADCRUMB_ID ? null : folderId;
      readOneFolder(folderToReadId, params);
    }
  }, [searchString, sortValue]);

  useEffect(() => {
    signId && readOneSign(parseInt(signId, 10));
  }, [signId]);

  const onClickBreadcrumb = (breadcrumbId: number | null): void => {
    setFolderId(breadcrumbId);
  };

  const onFolderSelect = (selectedFolderId: number | null) => {
    setFolderId(selectedFolderId);
  };

  const onFileSelect = (selectedFile: IFileResponse) => {
    const existingSelectedFiles = selectedFiles && selectedFiles.length ? selectedFiles : [];
    const fileAlreadySelected = existingSelectedFiles.find((sf) => sf.id === selectedFile.id);
    if (fileAlreadySelected) {
      return onSelectFiles(existingSelectedFiles.filter((sf) => sf.id !== selectedFile.id));
    }
    return onSelectFiles([...existingSelectedFiles, selectedFile]);
  };

  const uploadFile = (filesToUpload: IFilesUploadForm) => {
    if (!files) return;
    const createInFolderId = folderId === HOME_BREADCRUMB_ID ? undefined : folderId?.toString();
    createFiles(filesToUpload, createInFolderId);
  };

  const renderSignValidityMessage = () => {
    if (!sign && !signValues) return null;
    const val = sign ? sign : signValues;
    if (val && files?.some((f) => !validatePixelMatrix(f, val))) {
      return (<p className="text-danger text-sm">
      Red Text indicates content will not match sign size or pixel ratio
      </p>);
    }
    return null;
  };

  // API calls loading
  const isLoading = readFilesIsLoading || readOneFolderIsLoading || createFilesIsLoading;

  const onSort = (option: sortOption) => {
    setSortValue(option);
  };

  const showAddFileButton = searchString === '';

  return (
    <div>
      <Search
        setSearchString={setSearchString}
        searchString={searchString}
        maxWidth="400px"
        searchId="search-files-file-form"
      />
      <div className="sticky-container">
        <Breadcrumb className="mt-3">
          <Breadcrumb.Item
            onClick={() => onClickBreadcrumb(HOME_BREADCRUMB_ID)}
          >
          Home
          </Breadcrumb.Item>
          {folderBreadcrumbs?.map((item: IBreadcrumbResponse) => {
            return (
              <Breadcrumb.Item
                key={item.id}
                onClick={() => onClickBreadcrumb(item.id)}
              >
                {item.name}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        {renderSignValidityMessage()}
        {/* List options */}
        <div className="d-flex justify-content-between">
          {(showAddFileButton) && (<Button
            variant="outline-primary"
            onClick={showUploadFileModal}
          >
            <CustomIcon
              className="me-1"
              type="upload"
            />
          Upload
          </Button>)}
          <div className="d-flex">
            <Button variant="outline-primary" className="me-2" onClick={toggleGridView}>
              {isGridView ? <FontAwesomeIcon icon="table-cells-large" /> : <FontAwesomeIcon icon="list" />}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
              >
                <span>{sortValue.name} {sortValue.icon ? <FontAwesomeIcon icon={sortValue.icon} /> : null}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortValues.map((option) => {
                  return (<Dropdown.Item
                    key={option.name}
                    onClick={() => onSort(option)}
                  >
                    <span>{option.name} {option.icon ? <FontAwesomeIcon icon={option.icon} /> : null}</span>
                  </Dropdown.Item>);
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {(isLoading)
        ? (<div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>)
        : (<>
          <FolderList
            isGridView={isGridView}
            folders={folderFolders}
            onFolderSelect={onFolderSelect}
          />
          <FolderFileList
            isGridView={isGridView}
            files={files}
            selectedFiles={selectedFiles}
            onFileSelect={onFileSelect}
            sign={sign || signValues}
          />
          <UploadFilesModal
            show={isUploadFileModalVisible}
            handleCloseUploadFilesModal={hideUploadFileModal}
            onUploadFiles={uploadFile}
          />
        </>
        )}
    </div>
  );
};

export default SelectFileForm;
