import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IPlaylistResponse, IPlaylistUpdateRequest } from '@/types/playlist';
import {
  PLAYLIST_BATCH_UPDATED_MESSAGE,
  PLAYLIST_BATCH_UPDATED_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdatePlaylists = () => {
  const [updatedPlaylists, setUpdatedPlaylists] = useState<IPlaylistResponse[] | null>(null);
  const [putPlaylistsApiConfig, setPutPlaylistsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updatePlaylistsResponse,
    loading: updatePlaylistsIsLoading,
  } = useCallApi(putPlaylistsApiConfig, {
    successMessage: PLAYLIST_BATCH_UPDATED_MESSAGE,
    errorMessage: PLAYLIST_BATCH_UPDATED_ERROR,
  });

  const updatePlaylists = (playlistsToUpdate: IPlaylistUpdateRequest[]) => {
    setPutPlaylistsApiConfig({
      url: '/playlists/update',
      method: 'put',
      data: { playlists: playlistsToUpdate },
    });
  };

  useEffect(() => {
    setUpdatedPlaylists(updatePlaylistsResponse?.data);
  }, [updatePlaylistsResponse]);

  return {
    updatePlaylists,
    updatedPlaylists,
    updatePlaylistsIsLoading,
  };
};

export default useUpdatePlaylists;

