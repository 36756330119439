import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { ISignResponse } from '@/types/sign';
import SignListModal from '@/features/Schedule/components/SignListModal';
import { useToggle } from '@/hooks';
import { IFileResponse } from '@/types/file';
import { validatePixelMatrix } from '@/utils/validations';

interface CardDetailsSignBadgesProps {
  selectedSigns: ISignResponse[],

  // signs user have access to
  userSigns: ISignResponse[],
  files: (IFileResponse | null)[],
  onSubmitSelectedSigns: (selectedsigns: ISignResponse[], isLinked: boolean) => void,
}

const CardDetailsSignBadges = ({
  selectedSigns,
  userSigns,
  onSubmitSelectedSigns,
  files,
}: CardDetailsSignBadgesProps) => {
  const { hide: hideRemoveButtons, toggle: toggleRemoveButtons, isVisible: areRemoveButtonsVisible } = useToggle();

  const { show: showSignListModal, hide: hideSignListModal, isVisible: isSignListModalVisible } = useToggle();

  const validSign = (sign: ISignResponse) => {
    if (!files) return true;
    return files.every((i) => i && validatePixelMatrix(i, sign));
  };

  const submitSigns = (signsToSubmit: ISignResponse[], isLinked: boolean) => {
    onSubmitSelectedSigns(signsToSubmit, isLinked);
    hideSignListModal();
  };

  const renderSignValidityMessage = () => {
    if (selectedSigns.some((sign) => (sign && files[0]) && !validatePixelMatrix(files[0], sign))) return (<p className="text-danger text-sm">
      Red signs indicates content will not match sign size or pixel ratio
    </p>);
    return null;
  };

  return (
    <div className="mb-3">
      <ButtonGroup
        className="align-items-center sign-horizontal-list mb-3"
      >
        <FontAwesomeIcon
          icon="location-dot"
          className="text-primary"
        />
        <Button
          type="button"
          className="rounded-pill"
          variant="primary"
          size="sm"
          onClick={showSignListModal}
        >
          Add +
        </Button>
        {selectedSigns.map((sign) => {
          const userHasAccessToSign = userSigns.some((item) => item.id === sign.id);

          return (<Button
            key={sign.id}
            className="rounded-pill"
            variant={validSign(sign) ? 'outline-primary' : 'outline-danger'}
            size="sm"
            onClick={toggleRemoveButtons}
            onBlur={hideRemoveButtons}
          >
            {sign.name}
            {userHasAccessToSign && areRemoveButtonsVisible
              ? (<FontAwesomeIcon
                icon="circle-xmark"
                style={{ fontSize: 14, color: '#adb5bd', marginLeft: 5 }}
                onClick={() => {
                  onSubmitSelectedSigns(selectedSigns.filter((s) => s.id !== sign.id), true);
                }}
              />)
              : null}

          </Button>);
        })}
      </ButtonGroup>
      {renderSignValidityMessage()}
      <SignListModal
        isVisible={isSignListModalVisible}
        onHide={hideSignListModal}
        title="Add Signs"
        inMultiSelectMode={true}
        selectedSigns={selectedSigns}
        onSubmitSelectedSigns={submitSigns}
        files={files}
      />
    </div>
  );
};

export default CardDetailsSignBadges;
