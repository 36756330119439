import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Signs from './signs';
import ViewSign from './viewSign';
import EditSign from './editSign';
import AddSignToCompanyPage from './components/AddSignToCompanyPage';

/**
 * Builds the routes for the signs feature.
 *
 * @returns {*}
 */
export function SignRoutesSwitch() {
  return (
    <div>
      <Routes>
        <Route path="/add" element={<AddSignToCompanyPage />} />
        <Route path="/:signId" element={<ViewSign />} />
        <Route path="/:signId/edit" element={<EditSign />} />
        <Route path="/*" element={<Signs />} />
      </Routes>
    </div>
  );
}
