import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { IPlaylistResponse } from '@/types/playlist';
import {
  PLAYLIST_LOAD_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadPlaylists = () => {
  const [playlists, setPlaylists] = useState<IPlaylistResponse[] | null>(null);

  const [getPlaylistListApiConfig, setGetPlaylistListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readPlaylistsResponse,
    loading: readPlaylistsIsLoading,
  } = useCallApi(getPlaylistListApiConfig, {
    errorMessage: PLAYLIST_LOAD_ERROR,
  });

  const readPlaylists = (params: IndexApiParams = {}) => {
    setGetPlaylistListApiConfig({
      url: '/playlists',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setPlaylists(readPlaylistsResponse?.data);
  }, [readPlaylistsResponse]);

  return {
    readPlaylists,
    playlists,
    readPlaylistsIsLoading,
  };
};

export default useReadPlaylists;

