import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import useCallApi from '@/hooks/useCallApi';

import { IFolderResponse, IFolderUpdateRequest } from '@/types/folder';

import {
  FOLDER_UPDATED_ERROR,
  FOLDER_UPDATED_MESSAGE,
} from '@/features/Files/FilesTab/constants';

const useUpdateOneFolder = () => {
  const [updatedFolder, setUpdatedFolder] = useState<IFolderResponse | null>(null);
  const [putFolderApiConfig, setPutFolderApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateOneFolderResponse,
    loading: updateOneFolderIsLoading,
  } = useCallApi(putFolderApiConfig, {
    successMessage: FOLDER_UPDATED_MESSAGE,
    errorMessage: FOLDER_UPDATED_ERROR,
  });

  const updateOneFolder = (folderToUpdate: IFolderUpdateRequest) => {
    setPutFolderApiConfig({
      url: `/folders/${folderToUpdate.id}`,
      method: 'put',
      data: folderToUpdate,
    });
  };

  useEffect(() => {
    setUpdatedFolder(updateOneFolderResponse?.data);
  }, [updateOneFolderResponse]);

  return {
    updateOneFolder,
    updatedFolder,
    updateOneFolderIsLoading,
  };
};

export default useUpdateOneFolder;
