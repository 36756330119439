import React from 'react';

import Badge from 'react-bootstrap/esm/Badge';
import Popover from 'react-bootstrap/esm/Popover';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import { IFileResponse, REALTIME_WEATHER_TYPE, REALTIME_CURRENT_TIME_TYPE } from '@/types/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FileDetailsBadgesProps {
  file: IFileResponse,
  hideFileType?: boolean,
}

const REALTIME_TYPE_DATA_MAP: Record<string, {
  label: string,
  tooltip: string,
  icon: IconProp,
}> = {
  [REALTIME_WEATHER_TYPE]: {
    label: 'Temp',
    tooltip: 'This content uses realtime weather data that will be displayed over this file on your sign.',
    icon: 'temperature-three-quarters',
  },
  [REALTIME_CURRENT_TIME_TYPE]: {
    label: 'Time',
    tooltip: 'This content uses realtime time data that will be displayed over this file on your sign.',
    icon: 'clock',
  },
};

const FileDetailsBadges = (props: FileDetailsBadgesProps) => {
  const { file, hideFileType } = props;

  return (
    <div>
      {!hideFileType && (<Badge bg="secondary">
        {file.fileType}
      </Badge>)}
      {file.realtimeTypes.map((realtimeType) => (
        <OverlayTrigger
          key={realtimeType}
          trigger={['focus', 'hover']}
          rootClose
          overlay={
            (<Popover>
              <Popover.Body>
                {REALTIME_TYPE_DATA_MAP[realtimeType].tooltip}
              </Popover.Body>
            </Popover>)
          }
        >
          <Badge
            className="ms-2"
            style={{
              backgroundColor: '#0093C3',
            }}
            bg="none"
          >
            <FontAwesomeIcon
              icon={REALTIME_TYPE_DATA_MAP[realtimeType].icon}
              className="me-2"
            />
            {REALTIME_TYPE_DATA_MAP[realtimeType].label}
          </Badge>
        </OverlayTrigger>
      ),
      )}
    </div>
  );
};

export default FileDetailsBadges;
