import { ISignResponse } from '@/types/sign';
import { DateTime } from 'luxon';

export const STATUS_OFFLINE = 'offline';
export const STATUS_ONLINE = 'online';

export const getSignOnlineStatus = (sign: ISignResponse): {
  onlineStatus: string,
  offlineReason: string | null,
} => {
  if (!sign.checkedInAt) {
    return {
      onlineStatus: STATUS_OFFLINE,
      offlineReason: 'Player has never reported in. Ensure the sign is registered with the player.',
    };
  }

  const checkedInAt = DateTime.fromISO(sign.checkedInAt, { zone: 'utc' });
  const fiveMinutesAgo = DateTime.now().minus({ minutes: 5 }).toUTC();

  if (checkedInAt < fiveMinutesAgo) {
    return {
      onlineStatus: STATUS_OFFLINE,
      offlineReason: 'Player has not reported in for at least 5 minutes.',
    };
  }

  return {
    onlineStatus: STATUS_ONLINE,
    offlineReason: null,
  };
};

export const getFormattedCheckedInAt = (sign: ISignResponse) => sign.checkedInAt
  ? DateTime.fromISO(sign.checkedInAt, { zone: 'utc' }).toLocal().toFormat('HH:mm a MMM d yyyy')
  : 'N/A';
