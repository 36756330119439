import React, { CSSProperties, useEffect, useState } from 'react';
import classNames from 'classnames';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useToggle } from '@/hooks';

import { IFileResponse, IFileUploadForm } from '@/types/file';
import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import UploadFileModal from '@/features/Files/FilesTab/components/UploadFileModal';

import './defaultContentCard.scss';
import { useCreateFiles } from '@/hooks/file';
import SelectFileForm from '@/features/Files/SelectFileForm/SelectFileForm';

interface DefaultContentCardProps {
  isSelected: boolean,
  file?: IFileResponse | null,
  title: string,
  onChange: (typeOfDefault: 'custom', defaultFile: IFileResponse) => void,
}

const DefaultContentCard = ({
  file,
  isSelected,
  title,
  onChange,
}: DefaultContentCardProps) => {
  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const { show: showUploadFileModal, hide: hideUploadFileModal, isVisible: isUploadFileModalVisible } = useToggle();
  const { show: showSelectFileModal, hide: hideSelectFileModal, isVisible: isSelectFileModalVisible } = useToggle();

  const {
    createFiles,
    createdFiles,
    createFilesIsLoading,
  } = useCreateFiles();

  const onUploadFile = (fileToUpload: IFileUploadForm) => {
    createFiles({ files: [fileToUpload.file], origin: fileToUpload.origin });

    hideUploadFileModal();
  };

  const onSelectFile = (filesToSelect: IFileResponse[]) => {
    if (!filesToSelect || !filesToSelect.length) {
      return;
    }
    const lastFileSelected = filesToSelect[filesToSelect.length - 1];
    setSelectedFile(lastFileSelected);
    hideSelectFileModal();
  };

  useEffect(() => {
    if (!createdFiles) return;
    const [createdFile] = createdFiles;
    setSelectedFile(createdFile);
  }, [createdFiles]);

  useEffect(() => {
    if (!selectedFile) return;
    onChange('custom', selectedFile);
  }, [selectedFile]);

  const checkIcon: IconProp = isSelected ? ['fas', 'circle-check'] : ['far', 'circle'];
  const imageCustomStyle: CSSProperties = {
    maxWidth: '250px',
  };

  const fileToShow = selectedFile || file || null;

  const onChangeDefaultContent = () => {
    if (fileToShow) onChange('custom', fileToShow);
  };

  return (
    <>
      <Card
        className="h-100"
        border="light"
        bg="white"
      >
        <Card.Body className="p-0">
          <Card.Title
            className="d-flex is-align-items-center"
          >
            <Button
              className="no-style-button px-3 py-2"
              onClick={onChangeDefaultContent}
            >
              <FontAwesomeIcon
                className={
                  classNames({
                    'bg-white rounded-circle mx-2': true,
                    'text-black-50': !isSelected,
                    'text-primary': isSelected,
                  })
                }
                icon={checkIcon}
              />
              {title}
            </Button>
          </Card.Title>
          {fileToShow && (<div className="d-flex justify-content-center bg-dark text-white">
            <FileWithPreview
              file={fileToShow}
              customStyle={imageCustomStyle}
              isLoading={createFilesIsLoading}
            />
          </div>)}
        </Card.Body>
        <Card.Footer
          className="border-top-0 p-3"
        >
          <div>
            <Button
              className="me-2"
              variant="outline-primary"
              onClick={showSelectFileModal}
            >
              Choose Existing
            </Button>
            <Button
              variant="outline-primary"
              onClick={showUploadFileModal}
            >
              Upload New
            </Button>
          </div>
        </Card.Footer>
      </Card>
      <UploadFileModal
        show={isUploadFileModalVisible}
        onUploadFile={onUploadFile}
        handleCloseUploadFileModal={hideUploadFileModal}
        loading={createFilesIsLoading}
      />

      <Modal show={isSelectFileModalVisible} onHide={hideSelectFileModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Choose Existing File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectFileForm
            selectedFiles={fileToShow ? [fileToShow] : []}
            onSelectFiles={onSelectFile}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DefaultContentCard;
