/* eslint-disable no-magic-numbers */
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCreateSign } from '@/hooks/sign';
import { useReadFiles } from '@/hooks/file';
import { IFileResponse, IFileUploadForm } from '@/types/file';
import { ISignCreateRequest } from '@/types/sign';
import { VALID_FILE_EXTENSIONS_IMAGE, VALID_FILE_EXTENSIONS_VIDEO } from '@/utils/constants';
import { calculateAspectRatio, formatTimeZoneToIANA } from '@/utils/helpers';

import AddSignForm, { AddSignFormHandle } from './components/AddSignForm';
import AddSignPreview from './components/AddSignPreview';
import AddSignDefaultContent from './components/defaultContent/AddSignDefaultContent';

interface AddSignWizardProps {
  isVisible: boolean;
  onHide: () => void;
  onCreateSuccess: () => void;
  companyId?: string;
}

const AddSignWizard = ({
  isVisible,
  onHide,
  onCreateSuccess,
  companyId,
}: AddSignWizardProps) => {
  const {
    createSign,
    createdSign,
    createSignIsLoading,
  } = useCreateSign();

  const {
    files,
    readFiles,
    readFilesIsLoading,
  } = useReadFiles();

  const [step, setStep] = useState(1);
  const [systemDefaultFiles, setSystemDefaultFiles] = useState<{static: IFileResponse, animated: IFileResponse}>();
  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const [signValues, setSignValues] = useState<ISignCreateRequest | null>(null);
  const addFormRef = useRef<AddSignFormHandle>(null);

  useEffect(() => {
    // reset state when modal is opened
    if (isVisible === true) {
      setSelectedFile(null);
      setStep(1);
    }
  }, [isVisible]);

  useEffect(() => {
    // close modal on successful sign creation
    if (createdSign) {
      onCreateSuccess();
      setSignValues(null);
      onHide();
    }
  }, [createdSign]);

  useEffect(() => {
    if (!files) return;
    const staticDefaultFile = files.find((file) => VALID_FILE_EXTENSIONS_IMAGE.includes(file.fileType));
    const animatedDefaultFile = files.find((file) => VALID_FILE_EXTENSIONS_VIDEO.includes(file.fileType));

    setSystemDefaultFiles({
      static: staticDefaultFile || files[0],
      animated: animatedDefaultFile || files[0],
    });

    if (!selectedFile) {
      setSelectedFile(staticDefaultFile || files[0]);
    }
  }, [files]);

  const previousButtonText = step === 1
    ? 'Cancel'
    : 'Previous';
  const nextButtonText = step === 3
    ? 'Submit'
    : 'Next';
  const stepTitleDictionary: Record<number, string> = {
    1: 'Step 1: Configure',
    2: 'Step 2: Choose Default Content',
    3: 'Step 3: Review',
  };
  const stepTitle = stepTitleDictionary[step];

  const onSignAdd = (values: ISignCreateRequest) => {
    setSignValues(values);
    const signAspectRatio = calculateAspectRatio(values.width, values.height);
    readFiles({
      origin: 'default',
      aspectRatio: signAspectRatio,
    });

    setStep(step + 1);
  };

  const onSelectDefaultContent = (typeOfDefault: 'custom' | 'system', defaultFile: IFileUploadForm | IFileResponse) => {
    if ('id' in defaultFile) setSelectedFile(defaultFile);
  };

  const onSignAddSubmit = () => {
    if (signValues) {
      createSign({
        ...signValues,
        defaultFileId: selectedFile?.id || null,
        companyId,
        timezone: formatTimeZoneToIANA(signValues.timezone),
      });
    }
  };

  const onClickPreviousButton = () => {
    switch (step) {
      case 1:
        setSignValues(null);
        onHide();
        break;
      case 2: // fallthrough
      case 3:
        setStep(step - 1);
        break;
      default:

        // do nothing
    }
  };
  const onClickNextButton = () => {
    switch (step) {
      case 1:
        addFormRef?.current?.submit();
        break;

      case 2:
        // show toast error if file not selected
        setStep(step + 1);
        break;

      case 3:
        onSignAddSubmit();
        break;
      default:

        // do nothing
    }
  };

  const renderBody = (currentStep: number) => {
    if (currentStep === 1) {
      return (<div className="my-2">
        <AddSignForm
          signValues={signValues}
          ref={addFormRef}
          selectedFile={selectedFile}
          onFormSubmit={onSignAdd}
        />
      </div>);
    }

    if (currentStep === 2) {
      return (<>
        {systemDefaultFiles && (<div className="py-2">
          <AddSignDefaultContent
            systemFiles={systemDefaultFiles}
            onChange={onSelectDefaultContent}
            isLoading={readFilesIsLoading}
          />
        </div>)}
      </>);
    }

    if (currentStep === 3 && signValues) {
      return (<div>
        <AddSignPreview
          sign={signValues}
          selectedFile={selectedFile}
        />
      </div>);
    }

    return <></>;
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      fullscreen="md-down"
      backdrop="static"
      size="lg"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <h1 className="h4 mb-0">Add Sign</h1>
          <h2 className="h6 mb-0">
            {stepTitle}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 grey-modal-body">
        {renderBody(step)}
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer justify-content-between">
        <Button
          variant="light"
          onClick={onClickPreviousButton}
          disabled={createSignIsLoading}
        >
          { previousButtonText }
        </Button>

        <Button
          type="submit"
          variant="primary"
          onClick={onClickNextButton}
          disabled={createSignIsLoading}
        >
          {(createSignIsLoading)
            ? (<FontAwesomeIcon
              icon="circle-notch"
              spin
            />)
            : nextButtonText }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSignWizard;
