import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// eslint-disable-next-line no-process-env
const AUTH0_LOGOUT_REDIRECT_URL = process.env.REACT_APP_APP_URL || '';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      type="submit"
      onClick={() => logout({
        logoutParams: {
          returnTo: AUTH0_LOGOUT_REDIRECT_URL,
        },
      })}
    >
      Log Out
    </button>
  );
};

export default LogoutButton;
