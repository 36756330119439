import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IPlaylistResponse, IPlaylistUpdateRequest } from '@/types/playlist';
import {
  PLAYLIST_UPDATED_ERROR,
  PLAYLIST_UPDATED_MESSAGE,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdateOnePlaylist = () => {
  const [updatedPlaylist, setUpdatedPlaylist] = useState<IPlaylistResponse | null>(null);
  const [putPlaylistApiConfig, setPutPlaylistApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateOnePlaylistResponse,
    loading: updateOnePlaylistIsLoading,
  } = useCallApi(putPlaylistApiConfig, {
    successMessage: PLAYLIST_UPDATED_MESSAGE,
    errorMessage: PLAYLIST_UPDATED_ERROR,
  });

  const [updatedPlaylistContents, setUpdatedPlaylistContents] = useState<IPlaylistResponse['contents'] | null>(null);
  const [putPlaylistContentApiConfig, setPutPlaylistContentApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateOnePlaylistContentsResponse,
    loading: updateOnePlaylistContentsIsLoading,
  } = useCallApi(putPlaylistContentApiConfig) || {};

  const updateOnePlaylist = (playlistToUpdate: IPlaylistUpdateRequest) => {
    setPutPlaylistApiConfig({
      url: `/playlists/${playlistToUpdate.id}`,
      method: 'put',
      data: playlistToUpdate,
    });
  };

  const updateOnePlaylistContents = (playlistToUpdate: IPlaylistUpdateRequest) => {
    setPutPlaylistContentApiConfig({
      url: `/playlists/${playlistToUpdate.id}`,
      method: 'put',
      data: playlistToUpdate,
    });
  };

  useEffect(() => {
    setUpdatedPlaylist(updateOnePlaylistResponse?.data);
  }, [updateOnePlaylistResponse]);

  useEffect(() => {
    setUpdatedPlaylistContents(updateOnePlaylistContentsResponse?.data);
  }, [updateOnePlaylistContentsResponse]);

  return {
    updateOnePlaylist,
    updatedPlaylist,
    updateOnePlaylistIsLoading,

    updateOnePlaylistContents,
    updatedPlaylistContents,
    updateOnePlaylistContentsIsLoading,
  };
};

export default useUpdateOnePlaylist;
