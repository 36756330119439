// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import './SwipeableList.scss';

class SwipeableList extends Component {
  render() {
    const { children } = this.props;

    const childrenWithProps = React.Children.map(children, (child) => {
      if (!child.props.background) {
        return React.cloneElement(child, { background: this.props.background });
      }
      return child;
    });

    return <div>{childrenWithProps}</div>;
  }
}

export default SwipeableList;
