import React from 'react';

import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import { IFileResponse } from '@/types/file';
import { ISignCreateRequest, ISignResponse } from '@/types/sign';

import FileGridCard from './FileGridCard';
import FileListCard from './FileListCard';

interface FolderFileListProps {
  isGridView: boolean,
  files: IFileResponse[],
  selectedFiles: IFileResponse[] | null,
  onFileSelect: (selectedFile: IFileResponse) => void,
  sign?: ISignResponse | ISignCreateRequest | null,
}

const FolderFileList = ({
  isGridView,
  files,
  selectedFiles,
  onFileSelect,
  sign,
}: FolderFileListProps) => {
  if (!files) return null;

  const isFileSelected = (file: IFileResponse) => {
    const selectedFileIds = selectedFiles ? selectedFiles.map((sf) => sf.id) : [];
    return selectedFileIds.includes(file.id);
  };

  return (
    <>
      <Row className="cursor-pointer mt-3 g-1">
        {isGridView ? (files.map((file) => {
          if (!file.id) return (<div>Not a valid file</div>);

          const fileIsSelected = isFileSelected(file);

          return (
            <Col
              key={file.id}
              xs="6"
              sm="auto"
            >
              <FileGridCard
                file={file}
                isSelected={fileIsSelected}
                onFileSelect={onFileSelect}
                sign={sign}
              />
            </Col>
          );
        })) : (files.map((file) => {
          if (!file.id) return (<div>Not a valid file</div>);

          const fileIsSelected = isFileSelected(file);

          return (<FileListCard
            key={file.id}
            file={file}
            isSelected={fileIsSelected}
            onFileSelect={onFileSelect}
            sign={sign}
          />);
        }))
        }
      </Row>
    </>
  );
};

export default FolderFileList;
