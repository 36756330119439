import React from 'react';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';
import ListGroup from 'react-bootstrap/esm/ListGroup';

interface FileListActionMenuProps {
  isVisible: boolean,
  onHide: () => void,
  inMultiSelectMode: boolean,
  setInMultiSelectMode: React.Dispatch<React.SetStateAction<boolean>>,
}

const FileListActionMenu = ({
  isVisible,
  onHide,
  inMultiSelectMode,
  setInMultiSelectMode,
}: FileListActionMenuProps) => {
  return (
    <Offcanvas
      show={isVisible}
      onHide={onHide}
      placement="bottom"
      className="list-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          File List Options
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          <ListGroup.Item
            action
            onClick={() => setInMultiSelectMode(!inMultiSelectMode)}
            active={inMultiSelectMode}
          >
            Select Multiple
          </ListGroup.Item>
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FileListActionMenu;
