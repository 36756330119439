import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';

import '@/features/Files/PlaylistTab/Playlists.scss';
import { IActionMenuItem } from '@/types/actions';

interface OffsetActionMenuProps {
  isVisible: boolean,
  onHide: () => void,
  actionMenuItems: IActionMenuItem[],
  onActionClick: (action: string) => void,
  title?: string,
}

const OffsetActionMenu = ({
  isVisible,
  onHide,
  actionMenuItems = [],
  onActionClick,
  title = 'Actions',
}: OffsetActionMenuProps) => {
  return (
    <Offcanvas
      show={isVisible}
      onHide={onHide}
      placement="bottom"
      className="list-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          {actionMenuItems.map((menuItem) => {
            return (
              <ListGroup.Item
                key={menuItem.text}
                onClick={() => onActionClick(menuItem.action)}
                action
                disabled={menuItem.disabled}
              >
                <FontAwesomeIcon
                  className={`text-${menuItem.iconColor ? menuItem.iconColor : 'primary'}`}
                  icon={menuItem.icon}
                />
                <span className="ms-2">{menuItem.text}</span>
              </ListGroup.Item>);
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffsetActionMenu;
