import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { useEmulateUser } from '@/hooks/user';
import useMyUser from '@/context/myUser/useMyUser';

/**
 * Emulation Notification
 *
 * @returns {JSX.Element}
 */
const EmulationNotification = (): JSX.Element => {
  const { myUser } = useMyUser();
  const { clearEmulate, clearEmulateIsLoading } = useEmulateUser();

  if (!myUser || !myUser.isEmulating) return <></>;

  const emulatedUser = myUser;

  const onCancelEmulation = () => {
    clearEmulate();
  };

  const userIdentifier = emulatedUser.fullName
    ? emulatedUser.fullName
    : emulatedUser.email;

  return (
    <Alert
      className="py-2 my-2 d-flex align-items-center justify-content-between"
      variant="primary"
    >
      <div>
        <h3 className="h5">You are emulating</h3>
        <div><strong>User:</strong> { userIdentifier }</div>
        <div><strong>Company:</strong> { emulatedUser.company.name }</div>
      </div>
      <Button
        className="ms-2"
        variant="outline-dark"
        onClick={onCancelEmulation}
        disabled={clearEmulateIsLoading}
      >
        {clearEmulateIsLoading
          ? <FontAwesomeIcon icon={faCircleNotch} spin />
          : 'Cancel'
        }
      </Button>
    </Alert>
  );
};

export default EmulationNotification;
