import React, { useState } from 'react';
import { DateTime } from 'luxon';

import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import InputGroup from 'react-bootstrap/esm/InputGroup';

import { DayOfWeek } from '@/types/recurrenceRule';
import { IContentForm, IPlaylistForm, ByDayOfWeekForm } from '@/features/Schedule/types';
import { DAYS_OF_WEEK_FORM_OPTIONS } from '@/features/Schedule/constants';
import '@/features/Schedule/Schedule.scss';

type WeeklyRepeatFormProps = {
  item: IContentForm | IPlaylistForm | null,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const WeeklyRepeatForm = ({
  item,
  setFieldValue,
}: WeeklyRepeatFormProps) => {
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<ByDayOfWeekForm[]>(item?.recurrence.byDay || []);

  const initialStartTime = item?.recurrence.startTime || DateTime.local();
  const initialEndTime = item?.recurrence.endTime || DateTime.local();
  const selectedDaysOfWeekText = selectedDaysOfWeek.map((selected) => selected.dayOfWeek);

  const onSelectDayOfWeek = (dayOfWeek: DayOfWeek, startTime?: string, endTime?: string) => {
    const dayExists = selectedDaysOfWeekText.includes(dayOfWeek);
    const shouldRemove = dayExists && (!startTime && !endTime);

    const startTimeDateTime = startTime ? DateTime.fromISO(startTime) : initialStartTime;
    const endTimeDateTime = endTime ? DateTime.fromISO(endTime) : initialEndTime;

    const filteredDaysOfWeek = selectedDaysOfWeek.filter((selectedDay) => selectedDay.dayOfWeek !== dayOfWeek);

    const updatedDaysOfWeek = shouldRemove
      ? filteredDaysOfWeek
      : [...filteredDaysOfWeek, { dayOfWeek, startTime: startTimeDateTime, endTime: endTimeDateTime }];

    setSelectedDaysOfWeek(updatedDaysOfWeek);
    setFieldValue('recurrence.byDay', updatedDaysOfWeek);
  };

  return (
    <>
      <Form.Group>
        <Form.Text className="text-muted d-block mb-2">Days of the week repeat</Form.Text>
        <ButtonGroup
          className="mb-3"
        >
          {DAYS_OF_WEEK_FORM_OPTIONS.map((d) => {
            return (<Button
              className="btn-day-of-week"
              key={d.day}
              variant={selectedDaysOfWeekText.includes(d.day) ? 'primary' : 'outline-secondary'}
              onClick={() => onSelectDayOfWeek(d.day)}
            >
              {d.abbrev}
            </Button>);
          })}
        </ButtonGroup>
        {DAYS_OF_WEEK_FORM_OPTIONS.map((d) => {
          if (!selectedDaysOfWeekText.includes(d.day)) return null;
          const selectedDay = selectedDaysOfWeek.find((selected) => selected.dayOfWeek === d.day);
          const startTime = (selectedDay?.startTime || initialStartTime).toLocaleString(DateTime.TIME_24_SIMPLE);
          const endTime = (selectedDay?.endTime || initialEndTime).toLocaleString(DateTime.TIME_24_SIMPLE);

          return (<Row
            key={d.day}
            className="mb-2"
          >
            <Col xs="3">
              <div className="mt-2">
                {d.day}
              </div>
            </Col>
            <Col xs="9">
              <InputGroup>
                <InputGroup.Text
                  className="input-group-prepend-text"
                  style={{
                    borderBottomLeftRadius: 0,
                  }}
                >
                  From
                </InputGroup.Text>
                <Form.Control
                  style={{
                    borderBottomRightRadius: 0,
                  }}
                  type="time"
                  value={startTime}
                  isInvalid={startTime > endTime}
                  onChange={(e) => onSelectDayOfWeek(d.day, e.target.value, endTime)}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text
                  className="input-group-prepend-text"
                  style={{
                    borderTopLeftRadius: 0,
                  }}
                >
                  To
                </InputGroup.Text>
                <Form.Control
                  style={{
                    borderBottomRightRadius: '0.375rem',
                  }}
                  type="time"
                  value={endTime}
                  isInvalid={startTime > endTime}
                  onChange={(e) => onSelectDayOfWeek(d.day, startTime, e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Start Time must be before end time
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>);
        })}
      </Form.Group>
    </>
  );
};

export default WeeklyRepeatForm;
