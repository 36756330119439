import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IContentResponse, IContentCreateRequest } from '@/types/content';
import {
  CONTENT_CREATED_ERROR,
  CONTENT_CREATED_MESSAGE,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useCreateContent = () => {
  const [createdContent, setCreatedContent] = useState<IContentResponse | null>(null);
  const [createContentApiConfig, setCreateContentApiConfig] = useState<AxiosRequestConfig | null>(null);

  const {
    data: createContentResponse,
    loading: createContentIsLoading,
  } = useCallApi(createContentApiConfig, {
    successMessage: CONTENT_CREATED_MESSAGE,
    errorMessage: CONTENT_CREATED_ERROR,
  });

  const createContent = (newContent: IContentCreateRequest) => {
    setCreateContentApiConfig({
      url: '/contents',
      method: 'post',
      data: newContent,
    });
  };

  useEffect(() => {
    setCreatedContent(createContentResponse?.data);
  }, [createContentResponse]);

  return {
    createContent,
    createdContent,
    createContentIsLoading,
  };
};

export default useCreateContent;
