import { AxiosRequestConfig } from 'axios';

import { IUserResponse } from './user';

export const REALTIME_WEATHER_TYPE = 'Weather';
export const REALTIME_CURRENT_TIME_TYPE = 'CurrentTime';

export type TFileOrigin = 'upload' | 'default' | 'presto'

export type RealtimeDataType = typeof REALTIME_CURRENT_TIME_TYPE | typeof REALTIME_WEATHER_TYPE

export interface PrestoRealtimeFeed {
  realtimeDataType: RealtimeDataType
  setup: {
    size: {
      width: number
      height: number
    }
    position: {
      x: number
      y: number
      rotate?: number
    }
  }
  font: string,
  color: string,
  bold?: boolean,
  italic?: boolean,
  underline?: boolean,
  lineHeight?: number,
  opacity?: number,
  align?: 'right' | 'left' | 'center',
  startTime?: number,
  endTime?: number,
}

export interface PrestoRealtime {
  resolution: {
    width: number
    height: number
  }
  aspectRatio: string
  feeds: PrestoRealtimeFeed[]
}

export interface PrestoRealtimeFeedWithMetadata extends PrestoRealtimeFeed {
  fontDownloadUrl: string | null
}

export interface PrestoRealtimeWithMetadata extends PrestoRealtime {
  feeds: PrestoRealtimeFeedWithMetadata[]
}

export interface IFileResponse {
  id: number
  companyId?: number
  height: number
  folderId: number
  name: string
  fileType: string
  location: string
  url: string
  downloadUrl: string
  thumbnailUrl: string
  prestoId: string | null
  prestoRealtime: PrestoRealtime | null
  prestoRealtimeWithMetadata: PrestoRealtimeWithMetadata | null
  realtimeTypes: RealtimeDataType[]
  origin: TFileOrigin
  breadcrumbs: {id: number, name: string}[]
  addedBy?: number
  updatedAt: string
  createdAt: string
  accessedAt?: string
  deletedAt?: string | null
  file?: FormData
  user: IUserResponse | null
  width: number
  durationInMilliseconds: number | null
  usedAsDefaultContentCount: number
}

export interface IFileCreateRequest extends AxiosRequestConfig {
  data: FormData
}

export interface IFileUploadForm {
  file: File
  origin?: TFileOrigin
}

export interface IFilesUploadForm {
  files: File[]
  origin?: TFileOrigin
}
