import React, { Fragment, useEffect, useState } from 'react';

import Form from 'react-bootstrap/esm/Form';
import Badge from 'react-bootstrap/esm/Badge';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';

import { useReadPlaylists, useUpdatePlaylists } from '@/hooks/playlist';
import { useReadContents, useUpdateContents } from '@/hooks/content';
import Loader from '@/common/Loader';

import '@/features/Schedule/Schedule.scss';
import { IContentUpdateRequest } from '@/types/content';
import { IPlaylistUpdateRequest } from '@/types/playlist';
import { ISignResponse } from '@/types/sign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FrequencyWeightModalProps {
  selectedSign: ISignResponse,
  isVisible?: boolean,
  onHide: () => void,
}

const FrequencyWeightModal = ({
  selectedSign,
  isVisible = false,
  onHide,
}: FrequencyWeightModalProps) => {
  const {
    contents,
    readContents,
    readContentsIsLoading,
  } = useReadContents();

  const {
    updateContents,
    updatedContents,
    updateContentsIsLoading,
  } = useUpdateContents();

  const {
    playlists,
    readPlaylists,
    readPlaylistsIsLoading,
  } = useReadPlaylists();

  const {
    updatePlaylists,
    updatedPlaylists,
    updatePlaylistsIsLoading,
  } = useUpdatePlaylists();

  const [updatedContent, setUpdatedContent] = useState<IContentUpdateRequest[]>([]);
  const [updatedPlaylist, setUpdatedPlaylist] = useState<IPlaylistUpdateRequest[]>([]);

  useEffect(() => {
    if (isVisible === false ) return;

    // load data when modal is opening
    const params = {
      signId: selectedSign.id,
    };
    readContents(params);
    readPlaylists(params);
  }, [isVisible]);

  useEffect(() => {
    if (updatedContents || updatedPlaylists) onHide();
  }, [updatedContents, updatedPlaylists]);

  const isLoading = readContentsIsLoading || readPlaylistsIsLoading;
  const isSubmitting = updateContentsIsLoading || updatePlaylistsIsLoading;

  const handleSubmit = () => {
    if (updatedContent.length) updateContents(updatedContent);
    if (updatedPlaylist.length) updatePlaylists(updatedPlaylist);
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      fullscreen="md-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Frequency Weight
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(isLoading)
          ? <Loader size="2x" />
          : (
            <Form
              id="frequency-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <img
                src="/frequency-slider-grayscale.jpg"
                alt="Frequency Slider Example"
                className="w-100"
              />

              {contents?.map((content) => {
                const matchingExistingUpdate = (updatedFrequency: IContentUpdateRequest) => (updatedFrequency.id === content.id);
                const newValue = updatedContent
                  .find((updatedFrequency) => (matchingExistingUpdate(updatedFrequency)));

                const updateFrequency = (frequency: number) => {
                  const updatedContentsSansSelected = updatedContent.filter((updatedVal) => (!matchingExistingUpdate(updatedVal))) || [];
                  const updatedFrequencyArray = [
                    ...updatedContentsSansSelected,
                    {
                      id: content.id,
                      playlistIds: content.playlists.map((playlist) => (playlist.id)),
                      signIds: content.signs.map((s) => s.id) || [],
                      frequencyWeight: frequency,
                    }];

                  setUpdatedContent(updatedFrequencyArray);
                };

                return (<Fragment key={content.id}>
                  <hr />
                  <div className="px-2">
                    <Form.Label>
                      <strong>{content.name}</strong>
                      <Badge
                        className="ms-2"
                        bg="primary"
                      >
                        Content
                      </Badge>
                    </Form.Label>
                    <Form.Range
                      key={content?.id}
                      id="frequencyWeight"
                      name="frequencyWeight"
                      value={newValue?.frequencyWeight || content.frequencyWeight}
                      onChange={(e: any) => updateFrequency(parseInt(e.target.value, 10))}
                      min={1}
                      max={10}
                    />
                  </div>
                </Fragment>);
              })}
              {playlists?.map((playlist) => {
                const matchingExistingUpdate = (updatedVal: IPlaylistUpdateRequest) => (updatedVal.id === playlist.id);
                const newValue = updatedPlaylist
                  .find((updatedVal) => (matchingExistingUpdate(updatedVal)));

                const updateFrequency = (frequency: number) => {
                  const updatedPlaylistsSansSelected = updatedPlaylist.filter((updatedVal) => (!matchingExistingUpdate(updatedVal))) || [];
                  const updatedFrequencyArray = [
                    ...updatedPlaylistsSansSelected,
                    {
                      id: playlist.id,
                      contentIds: playlist.contents.map((content) => (content.id)),
                      signIds: playlist.signs.map((s) => s.id),
                      frequencyWeight: frequency,
                    }];

                  setUpdatedPlaylist(updatedFrequencyArray);
                };

                return (<Fragment key={playlist.id}>
                  <hr />
                  <div
                    className="px-2"
                    key={playlist.id}
                  >
                    <Form.Label>
                      <strong>{playlist.name}</strong>
                      <Badge
                        className="ms-2"
                        bg="secondary"
                      >
                        Playlist
                      </Badge>
                    </Form.Label>
                    <Form.Range
                      key={playlist?.id}
                      id="frequencyWeight"
                      name="frequencyWeight"
                      value={newValue?.frequencyWeight || playlist.frequencyWeight}
                      onChange={(e: any) => updateFrequency(parseInt(e.target.value, 10))}
                      min={1}
                      max={10}
                      style={{ touchAction: 'none' }}
                    />
                  </div>
                </Fragment>);
              })}
            </Form>
          )}
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer">
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          form="frequency-form"
          disabled={isSubmitting || (updatedContent.length === 0 && updatedPlaylist.length === 0)}
        >
          {(isSubmitting)
            ? <FontAwesomeIcon icon={'circle-notch'} spin />
            : 'Submit'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FrequencyWeightModal;
