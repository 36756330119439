/* eslint-disable complexity */
/* eslint-disable camelcase */
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { DateTime } from 'luxon';
import { Formik, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';

import { ISignCreateRequest } from '@/types/sign';
import { IFileResponse } from '@/types/file';
import { DATETIME_FORMAT, READABLE_TIMEZONES_OPTIONS } from '@/utils/constants';

import { EMPTY_SIGN_FORM } from '@/features/Signs/constants';
import { ICreateSignForm } from '@/features/Signs/types';

interface AddSignFormProps {
  signValues: ISignCreateRequest | null;
  selectedFile: IFileResponse | null;
  onFormSubmit: (values: ISignCreateRequest) => void;
}

export interface AddSignFormHandle {
  submit: () => void;
}

const AddSignForm = forwardRef<AddSignFormHandle, AddSignFormProps>(({
  signValues,
  selectedFile,
  onFormSubmit,
}, ref) => {
  const formRef = useRef<FormikProps<ICreateSignForm>>(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current?.handleSubmit();
    },
  }));

  const initialSignValues: ICreateSignForm = signValues
    ? {
      ...signValues,
      activeStartTime: DateTime.fromFormat(signValues.activeStartTime, DATETIME_FORMAT),
      activeEndTime: DateTime.fromFormat(signValues.activeEndTime, DATETIME_FORMAT),
    }
    : {
      ...EMPTY_SIGN_FORM,
      defaultFileId: selectedFile?.id || null,
    };

  const schema = Yup.object().shape({
    address_1: Yup.string().required('Please enter sign address'),
    city: Yup.string().required('Please enter city'),
    country:  Yup.string().required('Please enter country'),
    height: Yup.number().typeError('Value must be a number').required('Please enter pixel height'),
    manufacturer: Yup.string().required('Please enter sign manufacturer'),
    name: Yup.string().required('Please enter sign name'),
    state: Yup.string().required('Please enter state'),
    timezone: Yup.string().required('Please enter timezone'),
    width: Yup.number().typeError('Value must be a number').required('Please enter pixel width'),
    zip: Yup.string().required('Please enter zip code'),
  });

  const onSubmit = (values: ICreateSignForm) => {
    const postValues: ISignCreateRequest = {
      ...values,
      height: values.height || 0,
      width: values.width || 0,
      activeStartTime: values.activeStartTime.toFormat(DATETIME_FORMAT),
      activeEndTime: values.activeEndTime.toFormat(DATETIME_FORMAT),
    };

    onFormSubmit(postValues);
  };

  return (
    <Formik
      initialValues={initialSignValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      innerRef={formRef}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }: FormikProps<ICreateSignForm>) => {
        return (<Form noValidate onSubmit={handleSubmit}>
          <div className="bg-white shadow-sm p-3">
            <Form.Group className="mb-3">
              <FloatingLabel label="Sign Name">
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
              </FloatingLabel>
              <ErrorMessage name="name">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
            </Form.Group>
            <Row>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Pixel Width">
                    <Form.Control
                      type="number"
                      name="width"
                      value={values.width || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.width && !errors.width}
                      isInvalid={!!errors.width && touched.width}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="width">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Pixel Height">
                    <Form.Control
                      type="number"
                      name="height"
                      value={values.height || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.height && !errors.height}
                      isInvalid={!!errors.height && touched.height}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="height">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Manufacturer">
                    <Form.Control
                      type="string"
                      name="manufacturer"
                      value={values.manufacturer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.manufacturer && !errors.manufacturer}
                      isInvalid={!!errors.manufacturer && touched.manufacturer}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="manufacturer">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="bg-white shadow-sm mt-2 p-3">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Active Start Time">
                    <Form.Control
                      type="time"
                      name="activeStartTime"
                      value={values.activeStartTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                      onChange={(e: any) => {
                        const dailyStartTime = DateTime.fromISO(e.target.value);
                        setFieldValue('activeStartTime', dailyStartTime);
                      }}
                      max={values.activeEndTime?.toFormat('T')}
                      onBlur={handleBlur}
                      isInvalid={values.activeStartTime.toMillis() >= values.activeEndTime.toMillis()}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Active End Time">
                    <Form.Control
                      type="time"
                      name="activeEndTime"
                      value={values.activeEndTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                      onChange={(e: any) => {
                        const dailyEndTime = DateTime.fromISO(e.target.value);
                        setFieldValue('activeEndTime', dailyEndTime);
                      }}
                      min={values.activeStartTime?.toFormat('T')}
                      isInvalid={values.activeStartTime.toMillis() >= values.activeEndTime.toMillis()}
                      onBlur={handleBlur}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    Start Time must be before end time
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <FloatingLabel label="Timezone">
                <Form.Select
                  name="timezone"
                  value={values.timezone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.timezone && !errors.timezone}
                  isInvalid={!!errors.timezone && touched.timezone}
                >
                  {READABLE_TIMEZONES_OPTIONS.map((z) => <option key={z} value={z}>{z}</option>)}
                </Form.Select>
              </FloatingLabel>
              <ErrorMessage name="timezone">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
            </Form.Group>
          </div>
          <div className="bg-white shadow-sm mt-2 p-3">
            <Row>
              <Col sm={8}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Address">
                    <Form.Control
                      type="string"
                      name="address_1"
                      value={values.address_1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.address_1 && !errors.address_1}
                      isInvalid={!!errors.address_1 && touched.address_1}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="address_1">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
              <Col >
                <Form.Group className="mb-3">
                  <FloatingLabel label="Address (Cont.)">
                    <Form.Control
                      type="string"
                      name="address_2"
                      value={values.address_2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="City">
                    <Form.Control
                      type="string"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.city && !errors.city}
                      isInvalid={!!errors.city && touched.city}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="city">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
              <Col xs={6} sm={4}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="State">
                    <Form.Control
                      type="string"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.state && !errors.state}
                      isInvalid={!!errors.state && touched.state}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="state">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
              <Col xs={6} sm={4}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Zip Code">
                    <Form.Control
                      type="string"
                      name="zip"
                      value={values.zip}
                      onChange={(e:any) => {
                        const zipToString = e.target.value.toString();
                        setFieldValue('zip', zipToString);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.zip && !errors.zip}
                      isInvalid={!!errors.zip && touched.zip}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="zip">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col >
                <Form.Group className="mb-3">
                  <FloatingLabel label="Country">
                    <Form.Control
                      type="string"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.country && !errors.country}
                      isInvalid={!!errors.country && touched.country}
                    />
                  </FloatingLabel>
                  <ErrorMessage name="country">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Form>);
      }}
    </Formik>
  );
});

export default AddSignForm;
