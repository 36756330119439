import React, { useEffect, useState } from 'react';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  NavLink,
  Routes,
  Route,
} from 'react-router-dom';

import Search from '@/common/Search';

import FilesTab from './FilesTab';
import PlaylistTab from './PlaylistTab';

import './FilesTabNavigation.scss';

const Files = () => {
  // Router Constants
  const routerNavigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchByName = searchParams.get('name') || '';

  // component state
  const [searchString, setSearchString] = useState<string>(searchByName);

  useEffect(() => {
    if (searchString) {
      const params = {
        name: searchString,
      };
      routerNavigate({
        search: createSearchParams(params).toString(),
      });
    }
  }, [searchString]);

  // this ensures searchString stays in sync with search params
  useEffect(() => {
    if (searchByName && searchByName !== searchString) setSearchString(searchByName);
  }, [searchByName]);

  return (
    <div>
      <div className="px-3 py-4 bg-white">
        <Search
          searchString={searchString}
          setSearchString={setSearchString}
          searchId="search-files"
        />
        <div className="mt-3">
          <Row>
            <Col xs={12} md={6}>
              <ButtonGroup
                className="w-100"
              >
                <NavLink
                  className="w-100 btn btn-outline-primary"
                  to={'/assets/files'}
                >
                  FILES
                </NavLink>
                <NavLink
                  className="w-100 btn btn-outline-primary"
                  to={'/assets/playlists'}
                >
                    PLAYLISTS
                </NavLink>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      </div>
      <Routes>
        <Route
          path="files"
          element={(
            <FilesTab
              searchByName={searchString}
            />
          )}
        />
        <Route
          path="files/folders/:folderId"
          element={(
            <FilesTab
              searchByName={searchString}
            />
          )}
        />
        <Route
          path="playlists"
          element={<PlaylistTab />}
        />
      </Routes>
    </div>
  );
};

export default Files;
