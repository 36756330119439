import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import {
  FOLDER_DELETED_ERROR,
  FOLDER_DELETED_MESSAGE,
} from '@/features/Files/FilesTab/constants';

import useCallApi from '@/hooks/useCallApi';

const useDeleteFolders = () => {
  const [deletedFolders, setDeletedFolders] = useState<Record<string, never> | null>(null);
  const [deleteFolderApiConfig, setDeleteFolderApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteFoldersResponse,
    loading: deleteFolderIsLoading,
  } = useCallApi(deleteFolderApiConfig, {
    successMessage: FOLDER_DELETED_MESSAGE,
    errorMessage: FOLDER_DELETED_ERROR,
  });

  const deleteFolders = (folderIds: number[]) => {
    setDeleteFolderApiConfig({
      url: '/folders/delete',
      method: 'delete',
      data: {
        folderIds,
      },
    });
  };

  useEffect(() => {
    if (deleteFoldersResponse) setDeletedFolders({});
  }, [deleteFoldersResponse]);

  return {
    deleteFolders,
    deletedFolders,
    deleteFolderIsLoading,
  };
};

export default useDeleteFolders;
