import React, { useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import Button from 'react-bootstrap/esm/Button';
import Accordion from 'react-bootstrap/esm/Accordion';

import { useDuplicatePlaylistAs, useUpdateOnePlaylist } from '@/hooks/playlist';
import { IPlaylistUpdateRequest, IPlaylistResponse } from '@/types/playlist';
import { useToggle } from '@/hooks';

import OffcanvasActionMenu from '@/common/OffcanvasActionMenu';
import RenameModal from '@/common/RenameModal';

import { findActivityStatus, userHasAccessToSigns } from '@/features/Schedule/utils';
import { TPlaylistActions } from '@/features/Schedule/types';
import {
  PLAYLIST_CARD_ACTIONS_MENU_ITEMS,
  PLAYLIST_ACTION_EDIT,
  PLAYLIST_ACTION_RENAME,
  PLAYLIST_ACTION_SAVE,
  PLAYLIST_ACTION_DUPLICATE,
  PLAYLIST_ACTION_DELETE,
  PLAYLIST_ACTION_OPTIONS,
} from '@/features/Schedule/constants';
import { ISignResponse } from '@/types/sign';
import CustomIcon from '@/common/CustomIcon';

import { IContentResponse } from '@/types/content';

import ContentListCard from './ContentListCard';
import CardStatusBadge from './CardStatusBadge';
import ReorderContentModal from './ReorderContentModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';

interface PlaylistListCardProps {
  playlistItem: IPlaylistResponse,
  onToggle: (playlistId: number) => void,
  refreshSchedule: () => void,
  selectedSign: ISignResponse | null,
  isPlaylistOpen: boolean,
  userSigns: ISignResponse[],
}

const PlaylistListCard = ({
  playlistItem,
  onToggle,
  refreshSchedule,
  selectedSign,
  isPlaylistOpen,
  userSigns = [],
}: PlaylistListCardProps) => {
  const routerNavigate = useNavigate();
  const currentSignId = selectedSign ? selectedSign.id : null;

  const {
    updateOnePlaylist,
    updatedPlaylist,
    updateOnePlaylistIsLoading,
  } = useUpdateOnePlaylist();

  const {
    duplicatePlaylistAs,
    duplicatedPlaylist,
  } = useDuplicatePlaylistAs();

  const { show: showPlaylistListCardActions, hide: hidePlaylistListCardActions, isVisible: isPlaylistListCardActionsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();
  const { show: showSavePlaylistAsModal, hide: hideSavePlaylistAsModal, isVisible: isSavePlaylistAsVisible } = useToggle();
  const { show: showDeleteConfirmationModal, hide: hideDeleteConfirmationModal, isVisible: isDeleteConfirmationModalVisible } = useToggle();
  const { show: showReorderContentModal, hide: hideReorderContentModal, isVisible: isReorderContentModalVisible } = useToggle();

  const activityStatus = findActivityStatus(playlistItem, selectedSign?.timezone);
  const isActive = activityStatus === 'active';
  const shortFormatStartDate = DateTime.fromISO(playlistItem.startDatetime).toLocaleString({ month: 'short', day: 'numeric' });
  const shortFormatEndDate = DateTime.fromISO(playlistItem.endDatetime).toLocaleString({ month: 'short', day: 'numeric' });

  useEffect(() => {
    if (updatedPlaylist || duplicatedPlaylist) refreshSchedule();
    hideReorderContentModal();
  }, [updatedPlaylist, duplicatedPlaylist]);

  const duplicatePlaylist = () => {
    if (!playlistItem) return;

    const signIds: number[] = currentSignId ? [currentSignId] : [];

    duplicatePlaylistAs(
      `${playlistItem.name} Copy`,
      playlistItem,
      signIds,
    );
  };

  const onAction = (action: string) => {
    if (!playlistItem || !PLAYLIST_ACTION_OPTIONS.includes(action as TPlaylistActions)) return;

    const ACTIONS_DICT = {
      [PLAYLIST_ACTION_EDIT]: () => routerNavigate(`./playlists/${playlistItem.id}`),
      [PLAYLIST_ACTION_RENAME]: showRenameModal,
      [PLAYLIST_ACTION_SAVE]: showSavePlaylistAsModal,
      [PLAYLIST_ACTION_DUPLICATE]: duplicatePlaylist,
      [PLAYLIST_ACTION_DELETE]: showDeleteConfirmationModal,
    };

    ACTIONS_DICT[action as TPlaylistActions]();
    hidePlaylistListCardActions();
  };

  const updatePlaylistName = (updatedName: string) => {
    if (!updatedName || !playlistItem) return;

    const playlistPutRequestData: IPlaylistUpdateRequest = {
      id: playlistItem.id,
      name: updatedName,
      contentIds: playlistItem.contents.map((content) => content.id) || [],
      signIds: playlistItem.signs.map((s) => s.id),
    };

    updateOnePlaylist(playlistPutRequestData);
    hideRenameModal();
  };

  const savePlaylistAs = (playlistName: string) => {
    if (!playlistItem) return;

    const signIds: number[] = [];

    duplicatePlaylistAs(
      playlistName,
      playlistItem,
      signIds,
    );
    hideSavePlaylistAsModal();
  };

  const deletePlaylist = (deleteAll: boolean) => {
    if (!playlistItem) return;

    if (currentSignId) {
      const activeSignIds = playlistItem.signs
        .filter((sign) => sign.id !== currentSignId)
        .map((s) => s.id);
      const playlistWithoutSignPutData = {
        id: playlistItem.id,
        contentIds: playlistItem.contents.map((content) => content.id) || [],
        signIds: deleteAll ? [] : activeSignIds,
      };
      updateOnePlaylist(playlistWithoutSignPutData);
    }
    hideDeleteConfirmationModal();
  };

  const saveReorderedContent = (contents: IContentResponse[]) => {
    const playlistPutRequestData: IPlaylistUpdateRequest = {
      id: playlistItem.id,
      contentIds: contents.map((playlistContent) => playlistContent.id),
      signIds: playlistItem.signs.map((playlistSign) => playlistSign.id),
    };

    updateOnePlaylist(playlistPutRequestData);
  };

  return (
    <>
      <Accordion.Item
        eventKey={playlistItem.id.toString()}
        key={playlistItem.id}
      >
        <Accordion.Button
          className="px-2 py-3 playlist-accordion-buton rounded-0"
          style={{
            backgroundColor: isActive ? '' : '#F2F2F2',
          }}
          onClick={() => onToggle(playlistItem.id)}
        >
          <div className="w-100 d-flex justify-content-between text-truncate">
            <Link
              className="text-truncate"
              to={`./playlists/${playlistItem.id}`}
              onClick={(clickEvent) => {
                // stop propagation because we don't want parent toggle event to be called
                if (isPlaylistOpen) {
                  clickEvent.stopPropagation();
                }
              }}
            >
              <div className="d-flex align-items-center">
                <div className="schedule-thumbnail bg-transparent">
                  <CustomIcon
                    className="text-primary"
                    style={{
                      height: 36,
                    }}
                    type="playlist"
                  />
                </div>
                <div
                  className={
                    classNames({
                      'ms-2 text-truncate': true,
                      'inactive-text': playlistItem.isPaused,
                    })
                  }
                >
                  <strong>{playlistItem.name}</strong> <br />
                  {`${shortFormatStartDate} - ${shortFormatEndDate}`}
                </div>
              </div>
            </Link>

            <CardStatusBadge
              activityStatus={activityStatus}
            />
          </div>
        </Accordion.Button>

        <Accordion.Body className="pt-0 pe-0 pb-0 ps-2">
          {/* playlist controls */}
          <div
            className="p-2 d-flex align-items-center justify-content-between"
          >
            <span>{playlistItem.contents.length} Item{playlistItem.contents.length !== 1 ? 's' : ''}</span>

            <div>
              {(playlistItem.contents.length > 1)
                ? (<>
                  <Button
                    size="sm"
                    className="me-2"
                    variant="outline-primary"
                    onClick={showReorderContentModal}
                  >
                    Reorder
                    <FontAwesomeIcon
                      className="ms-1"
                      icon="sort"
                    />
                  </Button>
                  <ReorderContentModal
                    show={isReorderContentModalVisible}
                    onHide={hideReorderContentModal}
                    onUpdate={saveReorderedContent}
                    isSubmitting={updateOnePlaylistIsLoading}
                    selectedSign={selectedSign}
                    contents={playlistItem.contents}
                  />
                </>)
                : null
              }
              <Button
                className="text-dark"
                variant="link"
                onClick={showPlaylistListCardActions}
              >
                <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
              </Button>
            </div>
          </div>
          <section>
            {playlistItem.contents.map((contentItem, idx) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${playlistItem.id}-${contentItem.id}-${idx}`}>
                  <ContentListCard
                    contentItem={contentItem}
                    refreshSchedule={refreshSchedule}
                    parentPlaylist={playlistItem}
                    selectedSign={selectedSign}
                    onEditClick={(clickedContent) => {
                      routerNavigate(`/schedule/signs/${selectedSign!.id}/playlists/${playlistItem.id}/contents/${clickedContent.id}`);
                    }}
                    userSigns={userSigns}
                  />
                  {(idx !== playlistItem.contents.length - 1) && (<hr
                    className="my-0"
                  />)}
                </Fragment>);
            })}
          </section>
        </Accordion.Body>
      </Accordion.Item>
      <OffcanvasActionMenu
        isVisible={isPlaylistListCardActionsVisible}
        onHide={hidePlaylistListCardActions}
        actionMenuItems={PLAYLIST_CARD_ACTIONS_MENU_ITEMS}
        onActionClick={onAction}
      />
      <RenameModal
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={playlistItem.name}
        onUpdateName={(updatedName) => updatePlaylistName(updatedName)}
      />
      <RenameModal
        title="Save Playlist As"
        show={isSavePlaylistAsVisible}
        handleCloseRenameModal={hideSavePlaylistAsModal}
        initialName={playlistItem.name}
        onUpdateName={(updatedName) => savePlaylistAs(updatedName)}
      />
      <ConfirmDeleteModal
        show={isDeleteConfirmationModalVisible}
        onHide={hideDeleteConfirmationModal}
        contentName={playlistItem?.name || 'Playlist'}
        onConfirmDelete={deletePlaylist}
        singleInstanceButtonText="Remove from this Sign"
        allInstanceButtonText="Remove from All Signs"
        disableAllInstanceButton={!userHasAccessToSigns(userSigns, playlistItem.signs)}
        disableAllInstanceButtonReason={!userHasAccessToSigns(userSigns, playlistItem.signs)
          ? 'Cannot remove from all signs. Playlist is attached to signs that you do not have permissions to'
          : ''
        }
      />
    </>
  );
};

export default PlaylistListCard;
