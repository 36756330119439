import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ISignResponse } from '@/types/sign';
import { SIGN_LOAD_ERROR } from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadOneSign = () => {
  const [sign, setSign] = useState<ISignResponse | null>(null);
  const [getSignApiConfig, setGetSignApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOneSignResponse,
    loading: readOneSignIsLoading,
  } = useCallApi(getSignApiConfig, {
    errorMessage: SIGN_LOAD_ERROR,
  });

  const readOneSign = (signId: number, params?: { previewStart?: string }) => {
    setGetSignApiConfig({
      url: `/signs/${signId}`,
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setSign(readOneSignResponse?.data);
  }, [readOneSignResponse]);

  return {
    readOneSign,
    sign,
    readOneSignIsLoading,
  };
};

export default useReadOneSign;

