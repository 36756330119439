import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import { IFileResponse } from '@/types/file';
import { ISignResponse } from '@/types/sign';

import './editSignDefaultContent.scss';
import { DEFAULT_FILE_ORIGIN } from '@/features/Signs/constants';
import DefaultContentCard from '@/features/Signs/common/defaultContentCard/DefaultContentCard';
import DefaultFlagCard from '@/features/Signs/common/defaultContentCard/DefaultFlagCard';
import { VALID_FILE_EXTENSIONS_VIDEO } from '@/utils/constants';

interface EditSignDefaultContentProps {
  file: IFileResponse | null,
  systemFiles: ISignResponse['systemDefaultFiles'],
  onChange: (typeOfDefault: 'custom' | 'system', defaultFile: IFileResponse) => void;
}

const EditSignDefaultContent = ({
  file,
  systemFiles,
  onChange,
}: EditSignDefaultContentProps) => {
  const [isCustomDefaultContent, setIsCustomDefaultContent] = useState<boolean>(file ? file.origin !== DEFAULT_FILE_ORIGIN : false);

  useEffect(() => {
    if (!file) return;
    setIsCustomDefaultContent(file.origin !== DEFAULT_FILE_ORIGIN);
  }, [file]);

  const onChangeDefaultContent = (typeOfDefault: 'custom' | 'system', defaultFile: IFileResponse) => {
    setIsCustomDefaultContent(typeOfDefault === 'custom');
    onChange(typeOfDefault, defaultFile);
  };

  const selectedFlag = () => {
    if (isCustomDefaultContent) return null;
    if (file && VALID_FILE_EXTENSIONS_VIDEO.includes(file.fileType)) return 'animated';
    return 'static';
  };

  return (
    <>
      <Row>
        {/* American Flag Default Content */}
        <Col className="mb-3 mb-md-0">
          <DefaultFlagCard
            isSelected={selectedFlag()}
            animatedFile={systemFiles.animated}
            staticFile={systemFiles.static}
            title="American Flag"
            onChange={onChangeDefaultContent}
          />
        </Col>
        {/* Custom Div with Image and Checkmark */}
        <Col>
          <DefaultContentCard
            isSelected={isCustomDefaultContent}
            file={file}
            title="Custom Default Content"
            onChange={onChangeDefaultContent}
          />
        </Col>
      </Row>
    </>
  );
};

export default EditSignDefaultContent;
