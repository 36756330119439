import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';

import { FOLDER_CARD_ACTIONS_MENU_ITEMS } from '@/features/Files/FilesTab/constants';

import { TFolderCardActions } from '@/features/Files/FilesTab/types';

interface IFolderListCardActionsProps {
  isVisible: boolean,
  onHide: () => void,
  onActionClick: (action: TFolderCardActions) => void,
}

const FolderListCardActions = ({
  isVisible,
  onHide,
  onActionClick,
}: IFolderListCardActionsProps) => {
  return (
    <>
      <Offcanvas
        show={isVisible}
        onHide={onHide}
        placement="bottom"
        className="list-offcanvas"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Folder Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            {FOLDER_CARD_ACTIONS_MENU_ITEMS.map((menuItem) => {
              return (
                <ListGroup.Item
                  key={menuItem.text}
                  onClick={() => onActionClick(menuItem.action)}
                  action
                >
                  <FontAwesomeIcon icon={menuItem.icon} />
                  <span className="ms-2">{menuItem.text}</span>
                </ListGroup.Item>);
            })}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FolderListCardActions;
