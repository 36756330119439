import React from 'react';

import { IPlaylistResponse } from '@/types/playlist';

import PlaylistListItem from './PlaylistListItem';

interface PlaylistListViewProps {
  playlistList: IPlaylistResponse[],
  refreshPlaylist: () => void;
}

const playlistListView = ({
  playlistList,
  refreshPlaylist,
}: PlaylistListViewProps) => {
  return (
    <section>
      <h2 className="h5 border-bottom pb-3 mb-0">
        Playlists
      </h2>
      {(!playlistList || playlistList.length === 0) && (
        <div className="px-2 py-3">
          No Playlists found
        </div>
      )}
      {playlistList.map((playlist) => (
        <PlaylistListItem
          key={playlist.id}
          playlist={playlist}
          refreshPlaylist={refreshPlaylist}
        />
      ))}
    </section>
  );
};

export default playlistListView;
