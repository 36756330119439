import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IFileResponse } from '@/types/file';
import {
  FILES_MOVED_MESSAGE,
  FILES_MOVED_ERROR,
} from '@/features/Files/constants';

import useCallApi from '@/hooks/useCallApi';

const useMoveFiles = () => {
  const [movedFiles, setMovedFiles] = useState<IFileResponse[] | null>(null);
  const [moveFilesApiConfig, setMoveFilesApiConfig] =  useState<AxiosRequestConfig | null>(null);
  const {
    data: moveFilesResponse,
    loading: moveFilesIsLoading,
  } = useCallApi(moveFilesApiConfig, {
    successMessage: FILES_MOVED_MESSAGE,
    errorMessage: FILES_MOVED_ERROR,
  }) || {};

  const moveFiles = (fileIds: number[], folderId: number | null) => {
    setMoveFilesApiConfig({
      url: '/files/move',
      method: 'put',
      data: {
        fileIds,
        folderId,
      },
    });
  };

  useEffect(() => {
    setMovedFiles(moveFilesResponse?.data);
  }, [moveFilesResponse]);

  return {
    moveFiles,
    movedFiles,
    moveFilesIsLoading,
  };
};

export default useMoveFiles;

