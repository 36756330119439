import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import Button from 'react-bootstrap/esm/Button';

import Loader  from '@/common/Loader';
import { useReadOneFile } from '@/hooks/file';

import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import FileDetailsBadges from '@/common/FileDetailsBadges';

import { FileDetailsActions } from './FileDetailsActions';
import FileBreadcrumbs, { BreadCrumbWithLink } from './FileBreadcrumbs';

const FileDetails = () => {
  // Router Constants
  const { fileId, folderId } = useParams();
  const routerNavigate = useNavigate();

  const {
    readOneFile,
    file,
    readOneFileIsLoading,
  } = useReadOneFile();

  useEffect(() => {
    readOneFile({ fileId, folderId });
  }, []);

  if (!file) {
    if (readOneFileIsLoading) {
      return (
        <div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>
      );
    }
    return (
      <>
        <section className="text-center">
          <h4>No File Info</h4>
          <Button variant="light" onClick={() => routerNavigate(-1)}>
            Go back
          </Button>
        </section>
      </>
    );
  }

  const createdAt = DateTime.fromISO(file.createdAt).toFormat('LLL dd, yyyy');

  const fullBreadcrumbs: BreadCrumbWithLink[] = [
    ...file.breadcrumbs,
    {
      id: null,
      name: file.name,
      link: `/assets/files/${fileId}`,
    },
  ];

  return (
    <div className="pb-5">
      <div className="px-3 py-4 bg-white shadow-sm">
        <FileBreadcrumbs
          breadcrumbList={fullBreadcrumbs}
        />
      </div>
      <div className="mt-2 bg-white shadow-sm">
        <div className="d-flex justify-content-center bg-dark text-white">
          <FileWithPreview
            file={file}
          />
        </div>
        <div className="p-3">
          <h1 className="h3 mb-1 text-wrap">{ file.name }</h1>
          <p>
            {file.width} x {file.height}
          </p>
          <div className="mt-2">
            <small>Uploaded {createdAt}
              {(file.user) && (<>
                by {file.user && file.user.fullName}
              </>)}
            </small> <br />
            <FileDetailsBadges file={file} />
          </div>
          <div className="mt-3">
            <FileDetailsActions
              file={file}
              refreshFile={() => readOneFile({ fileId })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDetails;
