import React, { ReactElement, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import Button from 'react-bootstrap/esm/Button';

// Hooks
import { useToggle, useStore, useLongPress } from '@/hooks';
import { useUpdateOneContent, useDuplicateOneContent, useDeleteContents } from '@/hooks/content';
import { IContentUpdateRequest, IContentResponse } from '@/types/content';
import { IPlaylistResponse } from '@/types/playlist';

import RenameModal from '@/common/RenameModal';

import {
  CONTENT_ACTION_EDIT,
  CONTENT_ACTION_RENAME,
  CONTENT_ACTION_DUPLICATE,
  CONTENT_ACTION_DUPLICATE_TO_PLAYLIST,
  CONTENT_ACTION_MOVE_TO_PLAYLIST,
  CONTENT_ACTION_MOVE_OUT_PLAYLIST,
  CONTENT_ACTION_DELETE,
  CONTENT_CARD_ACTIONS_MENU_ITEMS,
  CONTENT_ACTION_OPTIONS,
} from '@/features/Schedule/constants';
import { TContentActions } from '@/features/Schedule/types';
import { findActivityStatus, userHasAccessToSigns } from '@/features/Schedule/utils';
import MoveContentToPlaylistModal from '@/features/Schedule/content/MoveContentToPlaylistModal';

import OffcanvasActionMenu from '@/common/OffcanvasActionMenu';
import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';
import { ISignResponse } from '@/types/sign';

import CardStatusBadge from './CardStatusBadge';
import ConfirmDeleteModal from './ConfirmDeleteModal';

import '@/features/Schedule/Schedule.scss';

interface ContentListCardProps {
  contentItem: IContentResponse | null,
  refreshSchedule: () => void,
  isSelected?: boolean,
  addContentToMultiSelect?: (contentClicked: IContentResponse) => void,
  removeContentFromMultiSelect?: (contentClicked: IContentResponse) => void,
  inMultiSelectMode?: boolean,
  parentPlaylist?: IPlaylistResponse,
  readonly?: boolean,
  selectedSign: ISignResponse | null,
  prefix?: ReactElement,
  onEditClick?: (contentClicked: IContentResponse) => void,
  userSigns?: ISignResponse[],
}

const ContentListCard = ({
  contentItem,
  refreshSchedule,
  parentPlaylist,
  readonly = false,
  isSelected = false,
  addContentToMultiSelect = () => {},
  removeContentFromMultiSelect = () => {},
  inMultiSelectMode = false,
  selectedSign,
  prefix,
  onEditClick = () => {},
  userSigns = [],
}: ContentListCardProps) => {
  const { playlistId: playlistIdRouteParam, signId: signIdRouteParam } = useParams();

  const signId = signIdRouteParam ? parseInt(signIdRouteParam, 10) : null;
  const playlistId = playlistIdRouteParam ? parseInt(playlistIdRouteParam, 10) : null;

  const {
    duplicateOneContent,
    duplicatedContent,
  } = useDuplicateOneContent();

  const {
    deleteContents,
    deletedContents,
    deleteContentIsLoading,
  } = useDeleteContents();

  const {
    updatedContent,
    updateOneContent,
    updateOneContentIsLoading,
  } = useUpdateOneContent();

  const {
    playlistList,
  } = useStore();

  const { show: showContentListCardActions, hide: hideContentListCardActions, isVisible: isContentListCardActionsVisible } = useToggle();
  const { show: showMoveContentToPlaylistModal, hide: hideMoveContentToPlaylistModal, isVisible: moveContentToPlaylistModalIsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();
  const { show: showConfirmDeleteModal, hide: hideConfirmDeleteModal, isVisible: isConfirmDeleteModalVisible } = useToggle();

  const [validImage, setValidImage] = useState(true);

  const [duplicateToPlaylist, setDuplicateToPlaylist] = useState(false);

  if (!contentItem) {
    return null;
  }

  const startDate = DateTime.fromISO(contentItem.startDatetime);
  const endDate = DateTime.fromISO(contentItem.endDatetime);

  const shortFormatStartDate = startDate.toLocaleString({ month: 'short', day: 'numeric' });
  const shortFormatEndDate = endDate.toLocaleString({ month: 'short', day: 'numeric' });

  const contentPlaylistIds = contentItem.playlists.map((p) => p.id);
  const contentPlaylistsToMoveTo = (playlistList || []).filter((playlist) => !contentPlaylistIds.includes(playlist.id));

  const moveContentOut = () => {
    const contentSigns = contentItem.signs.map((s) => s.id);

    const selectedSignRequest = selectedSign ? [selectedSign.id] : [];
    const contentSignsWithCurrentSign = (selectedSign && contentSigns.find((contentSignId) => contentSignId === selectedSign.id))
      ? contentSigns
      : contentSigns.concat(selectedSignRequest);
    const contentPlaylistIdsWithoutParentPlaylist = playlistId
      ? contentPlaylistIds.filter((p) => p !== playlistId)
      : contentPlaylistIds.filter((p) => p !== parentPlaylist?.id);

    updateOneContent({
      id: contentItem.id,
      playlistIds: contentPlaylistIdsWithoutParentPlaylist,
      signIds: contentSignsWithCurrentSign,
    });
  };

  const updateContentName = (updatedName: string) => {
    if (!updatedName || !contentItem) return;

    const contentPutRequestData: IContentUpdateRequest = {
      id: contentItem.id,
      name: updatedName,
      playlistIds: contentItem.playlists.map((p) => p.id) || [],
      signIds: contentItem.signs.map((s) => s.id) || [],
    };

    updateOneContent(contentPutRequestData);
    hideRenameModal();
  };

  const onMoveToPlaylist = (playlist: IPlaylistResponse) => {
    if (duplicateToPlaylist) {
      const contentToDuplicate = {
        ...contentItem,
        playlists: [playlist],
        signs: [],
      };

      duplicateOneContent(`${contentItem.name} Copy`, contentToDuplicate);
    } else {
      const playlistIds = contentItem.playlists.filter((p) => p.id !== parentPlaylist?.id).map((i) => i.id);
      const signIds = contentItem.signs.reduce((acc: number[], currContentSign) => {
        if (currContentSign.id !== signId) return [currContentSign.id, ...acc];
        return acc;
      }, []);

      updateOneContent({
        id: contentItem.id,
        playlistIds: playlistIds.concat([playlist.id]),
        signIds,
      });
    }

    setDuplicateToPlaylist(false);
    hideMoveContentToPlaylistModal();
  };

  const onDeleteContents = (deleteAll: boolean) => {
    if (deleteAll) return deleteContents([contentItem.id]);
    const contentItemPlaylistIds = contentItem.playlists.map((contentPlaylist) => contentPlaylist.id);
    const contentItemSignIds = contentItem.signs.map((contentSign) => contentSign.id);
    let playlistIds = contentItemPlaylistIds;
    let signIds = contentItemSignIds.filter((contentSignId) => contentSignId !== signId);

    if (parentPlaylist) {
      playlistIds = contentItemPlaylistIds.filter((contentPlaylistId) => contentPlaylistId !== parentPlaylist.id);
      signIds = contentItemSignIds;
    }

    return updateOneContent({
      id: contentItem.id,
      playlistIds,
      signIds,
    });
  };

  const activityStatus = findActivityStatus(contentItem, selectedSign?.timezone);
  const isActive = activityStatus === 'active';

  useEffect(() => {
    if (deletedContents || updatedContent || duplicatedContent) refreshSchedule();
  }, [deletedContents, updatedContent, duplicatedContent]);

  const onLongPress = () => {
    if (inMultiSelectMode && isSelected) {
      removeContentFromMultiSelect(contentItem);
    } else if (!isSelected) {
      addContentToMultiSelect(contentItem);
    }
  };

  const onAction = (action: string) => {
    if (!CONTENT_ACTION_OPTIONS.includes(action as TContentActions)) return;

    const ACTIONS_DICT = {
      [CONTENT_ACTION_EDIT]: () => onEditClick(contentItem),
      [CONTENT_ACTION_RENAME]: showRenameModal,
      [CONTENT_ACTION_DUPLICATE]: () => {
        const newContentItem = {
          ...contentItem,
          signIds: signId ? contentItem.signs.filter((sign) => sign.id === signId) : [],
        };
        duplicateOneContent(`${contentItem.name} Copy`, newContentItem);
      },
      [CONTENT_ACTION_DUPLICATE_TO_PLAYLIST]: () => {
        setDuplicateToPlaylist(true);
        showMoveContentToPlaylistModal();
      },
      [CONTENT_ACTION_MOVE_TO_PLAYLIST]: () => showMoveContentToPlaylistModal(),
      [CONTENT_ACTION_MOVE_OUT_PLAYLIST]: () => moveContentOut(),
      [CONTENT_ACTION_DELETE]: showConfirmDeleteModal,
    };

    ACTIONS_DICT[action as TContentActions]();
    hideContentListCardActions();
  };

  const onClickEvent: any = () => {
    if (inMultiSelectMode && isSelected) return removeContentFromMultiSelect(contentItem);
    if (inMultiSelectMode && !isSelected) return addContentToMultiSelect(contentItem);
    return onEditClick(contentItem);
  };

  const defaultLongPressOptions = {
    shouldPreventDefault: true,
    delay: 750,
  };
  const longPressEvents: ReturnType<typeof useLongPress> | Object = readonly
    ? {}
    : useLongPress(onLongPress, onClickEvent, defaultLongPressOptions);

  const renderContentThumbnail = () => {
    return (

      // TODO - add default file icon
      <div className="schedule-thumbnail">
        {(contentItem.file?.thumbnailUrl
          || (contentItem.file && VALID_FILE_EXTENSIONS_IMAGE.includes(contentItem.file.fileType))
          && validImage)
        && (
          <img
            className="align-self-center"
            src={contentItem.file?.thumbnailUrl || contentItem.file.url}
            style={{ width: 56, maxHeight: 56 }}
            onError={() => setValidImage(false)}
          />
        )}
      </div>
    );
  };
  const checkIcon: IconProp = isSelected
    ? ['fas', 'circle-check']
    : ['far', 'circle'];

  const contentActionMenuItems = contentItem.signs.find((sign) => sign.id === signId)
    ? CONTENT_CARD_ACTIONS_MENU_ITEMS.filter((a) => a.action !== CONTENT_ACTION_MOVE_OUT_PLAYLIST)
    : CONTENT_CARD_ACTIONS_MENU_ITEMS;

  return (
    <>
      <div
        className={
          classNames({
            'd-flex justify-content-between align-items-center position-relative w-100 px-2 py-3': true,
            'bg-inactive': !isActive,
            'bg-primary bg-opacity-10': isSelected,
          })
        }
        draggable={false}
      >
        {prefix ? prefix : <></>}
        <div
          className={
            classNames({
              'd-flex flex-grow-1 text-truncate': true,
              'cursor-pointer': !readonly,
            })
          }
          draggable={false}
          {...longPressEvents}
        >
          {renderContentThumbnail()}

          {(inMultiSelectMode) && (
            <FontAwesomeIcon
              className={
                classNames({
                  'bg-white rounded-circle multiselect-icon': true,
                  'text-black-50': !isSelected,
                  'text-primary': isSelected,
                })
              }
              icon={checkIcon}
            />
          )}
          <div
            className={
              classNames({
                'ms-2 text-truncate': true,
                'inactive-text': !isActive && !isSelected,
              })
            }
          >
            <strong>{contentItem.name}</strong> <br />
            {`${shortFormatStartDate} - ${shortFormatEndDate}`}
          </div>
        </div>

        <CardStatusBadge
          activityStatus={activityStatus}
        />

        {!readonly && (
          <Button
            className="text-dark"
            variant="link"
            onClick={showContentListCardActions}
          >
            <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
          </Button>
        )}
      </div>

      <OffcanvasActionMenu
        isVisible={isContentListCardActionsVisible}
        onHide={hideContentListCardActions}
        actionMenuItems={contentActionMenuItems}
        onActionClick={onAction}
        title={`${contentItem.name} Actions`}
      />

      <MoveContentToPlaylistModal
        isVisible={moveContentToPlaylistModalIsVisible}
        onHide={hideMoveContentToPlaylistModal}
        duplicateToPlaylist={duplicateToPlaylist}
        contentIds={[contentItem.id]}
        playlistList={contentPlaylistsToMoveTo}
        onMoveToPlaylist={onMoveToPlaylist}
      />
      <RenameModal
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={contentItem.name}
        onUpdateName={(updatedName) => updateContentName(updatedName)}
      />
      <ConfirmDeleteModal
        show={isConfirmDeleteModalVisible}
        contentName={contentItem.name}
        onHide={hideConfirmDeleteModal}
        onConfirmDelete={onDeleteContents}
        isDeleting={deleteContentIsLoading || updateOneContentIsLoading}
        disableAllInstanceButton={!userHasAccessToSigns(userSigns, contentItem.signs)}
        disableAllInstanceButtonReason={!userHasAccessToSigns(userSigns, contentItem.signs)
          ? 'Cannot remove from all signs. Content is attached to signs that you do not have permissions to'
          : ''
        }
      />
    </>
  );
};

export default ContentListCard;
