import React, { useState } from 'react';

import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import ConfirmationModal from '@/common/ConfirmationModal';
import classNames from 'classnames';

interface ConfirmDeleteModalProps {
  show: boolean,
  onHide: () => void,
  contentName: string,
  onConfirmDelete: (deleteAll: boolean) => void,
  isDeleting?: boolean,
  singleInstanceButtonText?: string,
  allInstanceButtonText?: string,
  disableAllInstanceButton?: boolean,
  disableAllInstanceButtonReason?: string,
}

const ConfirmDeleteModal = ({
  show,
  onHide,
  onConfirmDelete,
  contentName,
  isDeleting = false,
  singleInstanceButtonText = 'Remove this Instance',
  allInstanceButtonText = 'Remove Everywhere',
  disableAllInstanceButton = false,
  disableAllInstanceButtonReason = '',
}: ConfirmDeleteModalProps) => {
  const [deleteAll, setDeleteAll] = useState(false);

  return (
    <ConfirmationModal
      show={show}
      onHide={onHide}
      title={`Delete ${contentName}`}
      onConfirmation={() => onConfirmDelete(deleteAll)}
      confirmButtonColor="danger"
      isSubmitting={isDeleting}
    >
      <div className="d-flex flex-column align-items-center">
        <Button
          type="button"
          onClick={() => setDeleteAll(false)}
          active={!deleteAll}
          className="mb-2 w-50"
          variant="outline-secondary"
        >
          {singleInstanceButtonText}
        </Button>

        <OverlayTrigger
          placement="top"
          overlay={(disableAllInstanceButtonReason)
            ? (
              <Tooltip>
                <div>{disableAllInstanceButtonReason}</div>
              </Tooltip>
            )
            : <></>
          }
        >
          <div
            className={classNames('w-50', {
              'cursor-not-allowed': disableAllInstanceButton,
            })}
          >
            <Button
              type="button"
              onClick={() => setDeleteAll(true)}
              active={deleteAll}
              className="w-100"
              variant="outline-secondary"
              disabled={disableAllInstanceButton}
            >
              {allInstanceButtonText}
            </Button>
          </div>
        </OverlayTrigger>
      </div>
    </ConfirmationModal>
  );
};

export default ConfirmDeleteModal;
