import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { IFileResponse } from '@/types/file';
import SelectFileForm from '@/features/Files/SelectFileForm/SelectFileForm';
import { IBumperForm } from '@/features/Schedule/types';
import { ISignResponse } from '@/types/sign';
import { ERROR_TYPE } from '@/common/constants';
import { useUpdateOneSign } from '@/hooks/sign';

import AddBumperForm, { BumperAddHandle } from './AddBumperForm';

interface AddBumperWizardProps {
  isVisible: boolean;
  onHide: () => void;
  selectedSign: ISignResponse | null;
  onCreateSuccess?: () => void;
}

const AddBumperWizard = ({
  isVisible,
  onHide,
  selectedSign,
  onCreateSuccess,
}: AddBumperWizardProps) => {
  const [step, setStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const addFormRef = useRef<BumperAddHandle>(null);

  const {
    updateOneSign,
    updatedSign,
    updateOneSignIsLoading,
  } = useUpdateOneSign();

  useEffect(() => {
    // reset state when modal is opened
    if (isVisible === true) {
      setSelectedFile(null);
      setStep(1);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!updatedSign) return;

    onCreateSuccess && onCreateSuccess();
    onHide();
  }, [updatedSign]);

  const stepTitleDictionary: Record<number, string> = {
    1: 'Step 1: Choose File',
    2: 'Step 2: Configure',
  };

  const previousButtonText = step === 1
    ? 'Cancel'
    : 'Previous';
  const nextButtonText = step === 2
    ? 'Submit'
    : 'Next';
  const stepTitle = stepTitleDictionary[step];

  const onBumperAdd = (values: IBumperForm) => {
    if (!selectedSign) {
      toast('Error adding bumper, no sign found.', {
        type: ERROR_TYPE,
      });
      return;
    }

    updateOneSign({
      id: selectedSign.id,
      bumper: {
        ...values,
        fileId: selectedFile!.id,
      },
    });
  };

  const onClickPreviousButton = () => {
    switch (step) {
      case 1:
        onHide();
        break;
      case 2:
        setStep(step - 1);
        break;
      default:

        // do nothing
    }
  };
  const onClickNextButton = () => {
    switch (step) {
      case 1:
        // show toast error if file not selected
        if (!selectedFile) {
          toast(
            'Must select file before continuing',
            {
              type: 'error',
            },
          );
          return;
        }

        setStep(step + 1);
        break;
      case 2:
        addFormRef?.current?.submit();
        break;
      default:

        // do nothing
    }
  };

  const renderBody = (currentStep: number) => {
    if (currentStep === 1) {
      return (<div className="p-3 bg-white">
        <SelectFileForm
          selectedFiles={selectedFile ? [selectedFile] : []}
          onSelectFiles={(files) => setSelectedFile(files[0])}
        />
      </div>);
    }

    if (currentStep === 2 && selectedFile) {
      return (<AddBumperForm
        ref={addFormRef}
        selectedFile={selectedFile}
        handleFormSubmit={onBumperAdd}
      />);
    }

    return <></>;
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      fullscreen="md-down"
      backdrop="static"
      size="lg"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <h1 className="h4 mb-0">Add Bumper</h1>
          <h2 className="h6 mb-0">
            {stepTitle}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 grey-modal-body">
        {renderBody(step)}
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer justify-content-between">
        <Button
          variant="light"
          onClick={onClickPreviousButton}
          disabled={updateOneSignIsLoading}
        >
          { previousButtonText }
        </Button>

        <Button
          type="submit"
          variant="primary"
          onClick={onClickNextButton}
          disabled={updateOneSignIsLoading}
        >
          {(updateOneSignIsLoading)
            ? (<FontAwesomeIcon
              icon="circle-notch"
              spin
            />)
            : nextButtonText }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBumperWizard;
