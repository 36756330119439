import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const NotFoundPage = () => {
  return (
    <section className="p-5 text-center">
      <div style={{ fontSize: 96 }}>404</div>
      <p style={{ fontSize: 24 }}>Page not found</p>
      <div>
        <Link to="/home">
          <Button variant="outline-primary">
            Homepage
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default NotFoundPage;
