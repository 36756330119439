import React, { CSSProperties, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Img from 'react-bootstrap/esm/Image';
import Modal from 'react-bootstrap/esm/Modal';

import { useToggle } from '@/hooks';
import { IFileResponse } from '@/types/file';
import { VALID_FILE_EXTENSIONS_IMAGE, VALID_FILE_EXTENSIONS_VIDEO_PREVIEW } from '@/utils/constants';

import Loader from '@/common/Loader';
import FileFeedOverlay from '@/common/FileFeedOverlay/FileFeedOverlay';

import './fileWithPreview.scss';
import useUniqueId from '@/hooks/useUniqueId';

const ONE_HUNDRED = 100;

type FileProp = IFileResponse;

interface FileWithPreviewProps {
  file: FileProp,
  customStyle?: CSSProperties,
  isLoading?: boolean,
  disablePreviewModal?: boolean,
  disableVideoControls?: boolean,
  stretchToDimensions?: {
    width: number,
    height: number,
  },
}

export interface FileWithPreviewHandle {
  videoHandle: HTMLVideoElement | null;
}

const caculatePaddingByDimensions = (dimensions?: {
  width: number,
  height: number,
}) => {
  if (!dimensions) return null;

  const {
    width,
    height,
  } = dimensions;

  if (Number.isNaN(width) || Number.isNaN(height) || Number(width) === 0) return null;

  return Math.round(Number(height) / Number(width) * ONE_HUNDRED * ONE_HUNDRED) / ONE_HUNDRED;
};

const renderFile = ({
  file,
  showRealtimeOverlay,
  customStyle,
  fromModal = false,
  videoTime,
  setVideoTime,
  previewFileId,
  setPreviewLoaded,
  videoRef,
  disableVideoControls,
  stretchToDimensions,
}: {
  file: FileProp,
  showRealtimeOverlay: boolean,
  customStyle: CSSProperties,
  fromModal?: boolean
  videoTime: number,
  setVideoTime: React.Dispatch<React.SetStateAction<number>>,
  previewFileId: string,
  setPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  videoRef: React.Ref<HTMLVideoElement>,
  disableVideoControls: boolean,
  stretchToDimensions?: {
    width: number,
    height: number,
  },
}) => {
  const isVideo = file && file.fileType ? VALID_FILE_EXTENSIONS_VIDEO_PREVIEW.includes(file.fileType) : false;
  const isImage = file && file.fileType ? VALID_FILE_EXTENSIONS_IMAGE.includes(file.fileType) : false;

  const aspectRatioPadding = caculatePaddingByDimensions(stretchToDimensions);

  const containerStyles: React.CSSProperties =  {
    maxWidth: 'none',
    margin: '0',
  };

  if (aspectRatioPadding !== null && aspectRatioPadding > ONE_HUNDRED) {
    containerStyles.maxWidth = '30%';
    containerStyles.margin = '0 auto';
  }

  const customMediaStyles: React.CSSProperties = aspectRatioPadding !== null
    ? {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'fill',
      ...customStyle,
    }
    : {
      width: '100%',
      objectFit: 'contain',
      maxHeight: '40vh',
      ...customStyle,
    };

  if (!isVideo && !isImage && aspectRatioPadding !== null) {
    return (
      <div
        className="file-container bg-black"
        style={containerStyles}
      >
        {(aspectRatioPadding !== null) && (
          <div style={{ paddingTop: `${aspectRatioPadding}%` }} />
        )}
        <div
          className="d-flex align-items-center justify-content-center position-absolute text-center p-2"
          style={{
            inset: 0,
          }}
        >
          Preview for this file type is not supported.
        </div>
      </div>
    );
  } else if (!isVideo && !isImage) {
    return (
      <div
        className="file-container bg-black"
        style={containerStyles}
      >
        <div className="ratio ratio-16x9 d-flex align-items-center justify-content-center text-center">
          Preview for this file type is not supported.
        </div>
      </div>
    );
  }

  return (
    <div
      className="file-container"
      style={containerStyles}
    >
      {(aspectRatioPadding !== null) && (
        <div style={{ paddingTop: `${aspectRatioPadding}%` }} />
      )}
      {/* realtime layer */}
      {showRealtimeOverlay && file.prestoRealtimeWithMetadata && file.prestoRealtimeWithMetadata.feeds.map((feed, idx) => {
        const uniqueKey = `${feed.realtimeDataType}-${file.id}-${idx}${fromModal ? '-modal' : ''}`;
        return ((file.prestoRealtimeWithMetadata) && (
          <FileFeedOverlay
            key={uniqueKey}
            feed={feed}
            resolution={file.prestoRealtimeWithMetadata.resolution}
            videoTime={videoTime}
            sizeToDimensions={stretchToDimensions}
          />));
      })}
      {/* media */}
      {(isVideo)
        ? (
          <video
            id={previewFileId}
            ref={videoRef}
            src={file.url}
            style={customMediaStyles}
            controls={!disableVideoControls}
            autoPlay
            muted
            playsInline
            onLoadedData={() => {
              setPreviewLoaded(true);
            }}
            onTimeUpdate={(timeUpdateEvent) => {
              const currentTime = timeUpdateEvent.target
                && (timeUpdateEvent.target as HTMLVideoElement).currentTime
                ? (timeUpdateEvent.target as HTMLVideoElement).currentTime
                : 0;

              setVideoTime(currentTime);
            }}
          >
            Your browser does not support this video
          </video>
        )
        : (
          <Img
            id={previewFileId}
            src={file.url}
            alt={`${file.name} Image`}
            style={customMediaStyles}
            fluid
            onLoad={() => {
              setPreviewLoaded(true);
            }}
          />
        )}
    </div>
  );
};

const FileWithPreview = forwardRef<FileWithPreviewHandle, FileWithPreviewProps>((
  {
    file,
    customStyle = {},
    isLoading = false,
    disablePreviewModal = false,
    disableVideoControls = false,
    stretchToDimensions = undefined,
  },
  ref,
) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useImperativeHandle(ref, () => ({
    videoHandle: videoRef.current,
  }));

  const { show: showPreviewModal, hide: hidePreviewModal, isVisible: isPreviewModalVisible } = useToggle();

  const previewFileId = useUniqueId('preview-file-');
  const [previewLoaded, setPreviewLoaded] = useState(false);

  const previewModalFileId = useUniqueId('preview-file-modal-');
  const [modalPreviewLoaded, setModalPreviewLoaded] = useState(false);

  const [videoTime, setVideoTime] = useState(0);

  if (isLoading) return <Loader size="2x" />;

  return (
    <>
      <div onClick={showPreviewModal}>
        {renderFile({
          file,
          showRealtimeOverlay: previewLoaded,
          customStyle,
          videoTime,
          setVideoTime,
          previewFileId,
          videoRef,
          setPreviewLoaded,
          disableVideoControls,
          stretchToDimensions,
        })}
      </div>
      {(isPreviewModalVisible && !disablePreviewModal) && (
        <Modal
          className="modal-media-preview"
          dialogClassName="justify-content-center"
          contentClassName="w-auto"
          show={true}
          onHide={hidePreviewModal}
          centered
        >
          {renderFile({
            file,
            showRealtimeOverlay: modalPreviewLoaded,
            customStyle,
            fromModal: true,
            videoTime,
            setVideoTime,
            previewFileId: previewModalFileId,
            videoRef,
            setPreviewLoaded: setModalPreviewLoaded,
            disableVideoControls,
            stretchToDimensions,
          })}
        </Modal>
      )}
    </>
  );
});

FileWithPreview.defaultProps = {
  customStyle: {},
  isLoading: false,
  disablePreviewModal: false,
  disableVideoControls: false,
  stretchToDimensions: undefined,
};

export default FileWithPreview;
