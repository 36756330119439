import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';

import { sortOption } from '@/features/Schedule/types';
import { SORT_OPTIONS } from '@/features/Schedule/constants';

type ScheduleContentSortOptionsProps = {
  isScheduleSortOptionsVisible: boolean,
  handleCloseScheduleSortOptions: () => void,
  handleSortOptionClick: (sortOptionValue: sortOption) => void,
  activeOption: sortOption,
};

const ScheduleContentSortOptions = ({
  isScheduleSortOptionsVisible,
  handleCloseScheduleSortOptions,
  handleSortOptionClick,
  activeOption,
}: ScheduleContentSortOptionsProps) => {
  return (
    <Offcanvas
      show={isScheduleSortOptionsVisible}
      onHide={handleCloseScheduleSortOptions}
      placement="bottom"
      className="list-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Sort By</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          {SORT_OPTIONS.map((sort) => {
            return (
              <ListGroup.Item
                key={sort.name}
                action
                active={activeOption.name === sort.name}
                onClick={() => handleSortOptionClick(sort)}
              >
                {sort.icon
                  ? <FontAwesomeIcon icon={sort.icon} />
                  : null
                }
                <span className="ms-2">{sort.name}</span>
              </ListGroup.Item>);
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ScheduleContentSortOptions;
