import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IPlaylistResponse } from '@/types/playlist';
import {
  PLAYLIST_DUPLICATED_MESSAGE,
  PLAYLIST_DUPLICATED_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useDuplicatePlaylistAs = () => {
  const [duplicatedPlaylist, setDuplicatedPlaylist] = useState<IPlaylistResponse | null>(null);
  const [duplicatePlaylistAsApiConfig, setDuplicatePlaylistAsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: duplicatePlaylistAsResponse,
    loading: duplicatePlaylistAsIsLoading,
  } = useCallApi(duplicatePlaylistAsApiConfig, {
    successMessage: PLAYLIST_DUPLICATED_MESSAGE,
    errorMessage: PLAYLIST_DUPLICATED_ERROR,
  });

  const duplicatePlaylistAs = (playlistName: string, playlistToDuplicate: IPlaylistResponse, signIds: number[]) => {
    setDuplicatePlaylistAsApiConfig({
      url: `/playlists/${playlistToDuplicate.id}/duplicate`,
      method: 'post',
      data: {
        name: playlistName,
        signIds,
      },
    });
  };

  useEffect(() => {
    setDuplicatedPlaylist(duplicatePlaylistAsResponse?.data);
  }, [duplicatePlaylistAsResponse]);

  return {
    duplicatePlaylistAs,
    duplicatedPlaylist,
    duplicatePlaylistAsIsLoading,
  };
};

export default useDuplicatePlaylistAs;
