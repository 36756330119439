import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import useCallApi from '@/hooks/useCallApi';

import { IndexApiParams } from '@/types/api';
import { IFolderResponse } from '@/types/folder';

import {
  FOLDER_LOAD_ERROR,
} from '@/features/Files/FilesTab/constants';

interface ReadFolderParams extends IndexApiParams {
  includeDefaultFolder?: boolean
}

const useReadFolder = () => {
  const [folder, setFolder] = useState<IFolderResponse>();
  const [getFolderListApiConfig, setGetFolderListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOneFolderResponse,
    loading: readOneFolderIsLoading,
  } = useCallApi(getFolderListApiConfig, {
    errorMessage: FOLDER_LOAD_ERROR,
  });

  const readOneFolder = (folderId: number | string | null, params?: ReadFolderParams) => {
    const getTopLevelFolder = folderId === null;
    setGetFolderListApiConfig({
      url: getTopLevelFolder ? '/folders' : `/folders/${folderId}`,
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setFolder(readOneFolderResponse?.data);
  }, [readOneFolderResponse]);

  const { folders = [], files = [], breadcrumbs = [] } = folder || {};

  return {
    readOneFolder,
    folder,
    folders,
    files,
    breadcrumbs,
    readOneFolderIsLoading,
  };
};

export default useReadFolder;

