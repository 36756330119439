import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { REGISTRATION_KEY_DELETED_MESSAGE, REGISTRATION_KEY_DELETED_ERROR } from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useDeleteOneSignRegistrationKey = () => {
  const [deletedSignRegistrationKey, setDeletedSignRegistrationKey] = useState<Record<string, never> | null>(null);
  const [deleteRegistrationKeyApiConfig, setDeleteRegistrationKeyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteOneSignRegistrationKeyResponse,
    loading: deleteOneSignRegistrationKeyIsLoading,
  } = useCallApi(deleteRegistrationKeyApiConfig, {
    successMessage: REGISTRATION_KEY_DELETED_MESSAGE,
    errorMessage: REGISTRATION_KEY_DELETED_ERROR,
  });

  const deleteOneSignRegistrationKey = (registrationId: number, signId: number) => {
    setDeleteRegistrationKeyApiConfig({
      url: `/signs/${signId}/registrations/${registrationId}`,
      method: 'delete',
    });
  };

  useEffect(() => {
    if (deleteOneSignRegistrationKeyResponse) setDeletedSignRegistrationKey({});
  }, [deleteOneSignRegistrationKeyResponse]);

  return {
    deleteOneSignRegistrationKey,
    deletedSignRegistrationKey,
    deleteOneSignRegistrationKeyIsLoading,
  };
};

export default useDeleteOneSignRegistrationKey;
