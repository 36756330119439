// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

type FormObserverProps<T> = {
  onChange?: (values: T) => void;
}

// FormObserver component that can be used inside a Formik component to watch for changes
// This component was made from this github issue: https://github.com/jaredpalmer/formik/issues/1633#issuecomment-640286812
// and this article: https://javascript.plainenglish.io/how-to-listen-to-formik-onchange-event-in-react-df00c4d09be
const FormObserver = <T extends unknown>({
  onChange,
}: FormObserverProps<T>) => {
  const { values, dirty, touched } = useFormikContext<T>();

  useEffect(() => {
    if (dirty && onChange) {
      onChange(values);
    }
  }, [values, dirty, touched]);
  return null;
};

export default FormObserver;
