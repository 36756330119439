import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Row from 'react-bootstrap/esm/Row';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import ToggleButton from 'react-bootstrap/esm/ToggleButton';

import { IBumperForm, IContentForm, IPlaylistForm } from '@/features/Schedule/types';
import { PLAY_ORDER_SEQUENTIAL, PLAY_ORDER_SHUFFLED, PLAY_STRATEGY_ONE, PLAY_STRATEGY_ALL } from '@/features/Schedule/constants';

interface CardDetailsPlaybackActivityProps {
  item: IPlaylistForm | IContentForm | IBumperForm,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  description?: (string | ReactElement)[],
}

const CardDetailsPlaybackActivity = ({
  item,
  setFieldValue,
  description,
}: CardDetailsPlaybackActivityProps) => {
  const { isPaused } = item;

  // @ts-expect-error
  const playOrder = item.playOrder || null;

  // @ts-expect-error
  const playStrategy = item.playStrategy || null;

  return (
    <Card className="bg-white shadow-sm">
      <Card.Body>
        <Card.Title>Playback Activity</Card.Title>
        <Form
          className="mb-2 justify-content-center"
          as={ListGroup}
          horizontal
        >
          <ButtonGroup className="text-center align-text-center align-items-center">
            <ToggleButton
              className="border-0 rounded-3"
              type="radio"
              id="playback-activity-paused"
              variant="outline-dark"
              value="true"
              checked={isPaused === true}
              onClick={() => setFieldValue('isPaused', true)}
            >
              Paused
            </ToggleButton>
            <Form.Switch checked={!isPaused} onChange={() => setFieldValue('isPaused', !isPaused)} className="mx-3" />
            <ToggleButton
              className="border-0 rounded-3"
              type="radio"
              id="playback-activity-active"
              variant="outline-primary"
              value="false"
              checked={isPaused === false}
              onClick={() => setFieldValue('isPaused', false)}
            >
              Active
            </ToggleButton>
          </ButtonGroup>
        </Form>

        <p style={{ fontSize: 12 }}>
          {(description) ||
          (<>
            This setting is used to pause content. Inactive playlists will <strong>not</strong> play.<br />
            Inactive playlists will not show in scheduling tags such as Will Play or Expired
          </>)
          }
        </p>

        {(Boolean(playOrder && playStrategy)) &&
        (<div>
          <ListGroup>
            <ListGroup.Item
              active={playOrder === PLAY_ORDER_SEQUENTIAL}
              action
              onClick={() => setFieldValue('playOrder', PLAY_ORDER_SEQUENTIAL)}
              type="button"
            >
              <Row>
                <Col xs={5} className="text-left">
                  <FontAwesomeIcon icon="list-ol" className="me-2" />Sequential
                </Col>
                <Col className="text-sm">
                Content plays in the order placed, descending.
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              active={playOrder === PLAY_ORDER_SHUFFLED}
              action
              onClick={() => setFieldValue('playOrder', PLAY_ORDER_SHUFFLED)}
              type="button"
            >
              <Row>
                <Col xs={5} className="text-left">
                  <FontAwesomeIcon icon="shuffle" className="me-2" />Shuffled
                </Col>
                <Col className="text-sm">
                Content plays randomly before looping.
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          <hr />
          <ListGroup>
            <ListGroup.Item
              active={playStrategy === PLAY_STRATEGY_ONE}
              action
              onClick={() => setFieldValue('playStrategy', PLAY_STRATEGY_ONE)}
              type="button"
            >
              <Row>
                <Col xs={5} className="text-left">
                  <FontAwesomeIcon icon="arrow-rotate-right" className="me-2" />Play one
                </Col>
                <Col className="text-sm">
                Plays one card per playthrough. <br />
                A balanced approach.
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              active={playStrategy === PLAY_STRATEGY_ALL}
              action
              onClick={() => setFieldValue('playStrategy', PLAY_STRATEGY_ALL)}
              type="button"
            >
              <Row>
                <Col xs={5} className="text-left">
                  <FontAwesomeIcon icon="arrows-rotate" className="me-2" />Play all
                </Col>
                <Col className="text-sm">
                Plays all cards per playthrough. <br />
                A strict approach.
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </div>)}
      </Card.Body>
    </Card>
  );
};

export default CardDetailsPlaybackActivity;
