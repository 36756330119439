import React, { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Form from 'react-bootstrap/esm/Form';
import Popover from 'react-bootstrap/esm/Popover';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Alert from 'react-bootstrap/esm/Alert';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISignResponse } from '@/types/sign';
import { useReadSigns } from '@/hooks/sign';
import { IFileResponse } from '@/types/file';
import '@/features/Schedule/Schedule.scss';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface SignListModalProps {
  isVisible: boolean,
  onHide: () => void,
  selectedSigns: ISignResponse[] | [],
  onSelectSign?: (sign: ISignResponse) => void,
  title?: string,
  inMultiSelectMode?: boolean,
  onSubmitSelectedSigns?: (selectedsigns: ISignResponse[], isLinked: boolean) => void,
  files?: (IFileResponse | null)[] | undefined,
}

const SignListModal = ({
  isVisible,
  onHide,
  onSelectSign,
  title = 'Select Sign',
  inMultiSelectMode = false,
  selectedSigns,
  onSubmitSelectedSigns,
  files,
}: SignListModalProps) => {
  const [isLinked, setIsLinked] = useState<boolean>(true);
  const [copyOfSelectedSigns, setCopyOfSelectedSigns] = useState(selectedSigns || []);

  useEffect(() => {
    // reset selected signs when opening modal
    if (isVisible === true) {
      setCopyOfSelectedSigns(selectedSigns);
    }
  }, [isVisible]);

  const {
    readSignsIsLoading,
    readSigns,
    signs,
  } = useReadSigns();

  useEffect(() => {
    readSigns();
  }, []);

  const validSign = (sign: ISignResponse) => {
    if (!files) return true;
    return files.every((i) => (i?.width === sign.width) && (i?.height === sign.height));
  };

  const selectSign = (sign: ISignResponse) => {
    if (onSelectSign) {
      onSelectSign(sign);
    }

    const signIsSelected = copyOfSelectedSigns.find((item) => item.id === sign.id);
    if (signIsSelected) {
      // remove sign
      setCopyOfSelectedSigns([...copyOfSelectedSigns.filter((item) => item.id !== sign.id)]);
    } else {
      // add sign
      setCopyOfSelectedSigns([...copyOfSelectedSigns, sign]);
    }
  };

  const renderSignValidityMessage = () => {
    return (<Alert variant="danger">
      Red Text indicates content will not match sign size or pixel ratio
    </Alert>);
  };

  const renderLinkedSwitch = () => {
    return (<Form.Group className="bg-white shadow-sm p-3 mb-3 d-flex align-items-center">
      <Form.Switch
        className="mb-1"
        type="switch"
        label="Linked"
        checked={isLinked}
        onChange={() => setIsLinked(!isLinked)}
      />
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={
          (<Popover>
            <Popover.Header as="h3">Linked Content</Popover.Header>
            <Popover.Body>
              <p>
                Content or Playlists that are <strong>linked</strong> share the configurations of one another.
                Changing one linked content or playlist will update them all, even on different signs.
              </p>
              <p>
                When a content or playlist is <strong>unlinked</strong> a copy of that content or playlist is made and changes made do not impact the original.
              </p>
            </Popover.Body>
          </Popover>)
        }
      >
        <Button
          variant="link"
          size="sm"
        >
          <FontAwesomeIcon
            icon="info-circle"
          />
        </Button>
      </OverlayTrigger>
    </Form.Group>);
  };

  return (
    <Modal
      contentClassName="list-modal"
      show={isVisible}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {inMultiSelectMode && renderLinkedSwitch()}
        {(files) && renderSignValidityMessage()}
        <ListGroup>
          {signs.map((sign) => {
            const isSignActive = Boolean(copyOfSelectedSigns.find((selectedSign) => selectedSign.id === sign.id));
            return (<ListGroup.Item
              key={sign.id}
              active={isSignActive}
              onClick={() => selectSign(sign)}
              action
            >
              <div>
                {sign.name} <br />
                <span
                  className={classNames({
                    'text-sm': true,
                    'text-danger bg-white px-2 py-1 rounded': !validSign(sign),
                  })}
                >
                  {sign.city}, {sign.state} | {sign.width} x {sign.height}
                </span>
              </div>
            </ListGroup.Item>
            );
          })}
        </ListGroup>
        {(readSignsIsLoading) && (
          <div className="text-center px-3 fs-5">
            <FontAwesomeIcon
              icon={faCircleNotch}
              spin
            />
          </div>
        )}
        {(!readSignsIsLoading && signs.length === 0 ) && (
          <div className="px-3">
            No Signs Found
          </div>
        )}
      </Modal.Body>
      {inMultiSelectMode && onSubmitSelectedSigns
        ? (<Modal.Footer className="sticky-modal-footer">
          <Button
            variant="light"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => onSubmitSelectedSigns(copyOfSelectedSigns, isLinked)}
          >
            Submit
          </Button>
        </Modal.Footer>)
        : null
      }
    </Modal>
  );
};

export default SignListModal;
