import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IFolderResponse, IFolderCreateRequest } from '@/types/folder';
import { FOLDER_CREATED_ERROR, FOLDER_CREATED_MESSAGE } from '@/features/Files/FilesTab/constants';

import useCallApi from '@/hooks/useCallApi';

const useCreateFolder = () => {
  const [createdFolder, setCreatedFolder] = useState<IFolderResponse | null>(null);
  const [postFolderApiConfig, setPostFolderApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createFolderResponse,
    loading: createFolderIsLoading,
  } = useCallApi(postFolderApiConfig, {
    successMessage: FOLDER_CREATED_MESSAGE,
    errorMessage: FOLDER_CREATED_ERROR,
  });

  const createFolder = (newFolder: IFolderCreateRequest) => {
    setPostFolderApiConfig({
      url: '/folders',
      method: 'post',
      data: newFolder,
    });
  };

  useEffect(() => {
    setCreatedFolder(createFolderResponse?.data);
  }, [createFolderResponse]);

  return {
    createFolder,
    createdFolder,
    createFolderIsLoading,
  };
};

export default useCreateFolder;
