/* eslint-disable no-process-env */
import axios from 'axios';

const appAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,

  // set to true to send cookies (needed for emulation)
  withCredentials: true,
});

export default appAxiosInstance;
