import React from 'react';
import Button from 'react-bootstrap/esm/Button';

import { IFolderResponse } from '@/types/folder';
import { useToggle } from '@/hooks';

import FolderListItem from './FolderListItem';

const INITIAL_FOLDER_COUNT_TO_SHOW = 5;

interface FolderListViewProps {
  folderList: IFolderResponse[],
  onMoveFolder: (folderClicked: IFolderResponse) => void,
  onDeleteFolder: (folderClicked: IFolderResponse) => void,
  selectedFolders: IFolderResponse[],
  addFolderToMultiSelect: (fileClicked: IFolderResponse) => void,
  removeFolderFromMultiSelect: (fileClicked: IFolderResponse) => void,
  onRenameFolder: (folderClicked: IFolderResponse) => void,
  inMultiSelectMode: boolean,
}

const folderListView = ({
  folderList,
  onDeleteFolder,
  selectedFolders,
  onMoveFolder,
  addFolderToMultiSelect,
  removeFolderFromMultiSelect,
  onRenameFolder,
  inMultiSelectMode,
}: FolderListViewProps) => {
  const { toggle: toggleShowMore, isVisible: showMore } = useToggle();

  const folderCount = folderList.length;

  const foldersFiltered = folderCount > INITIAL_FOLDER_COUNT_TO_SHOW && !showMore
    ? folderList.slice(0, INITIAL_FOLDER_COUNT_TO_SHOW)
    : folderList;

  return (
    <section>
      <h2 className="h5 border-bottom pb-3 mb-0">
        Folders <small>({ folderList.length })</small>
      </h2>
      {(folderList.length === 0) && (
        <div className="px-2 py-3">
          No Folders Found
        </div>
      )}
      {foldersFiltered.map((folder) => {
        const isSelected = selectedFolders.some((item) => item.id === folder.id);

        return (<FolderListItem
          key={folder.id}
          folder={folder}
          onDeleteFolder={onDeleteFolder}
          onMoveFolder={onMoveFolder}
          isSelected={isSelected}
          addFolderToMultiSelect={addFolderToMultiSelect}
          removeFolderFromMultiSelect={removeFolderFromMultiSelect}
          onRenameFolder={onRenameFolder}
          inMultiSelectMode={inMultiSelectMode}
        />);
      })}
      {(folderCount > INITIAL_FOLDER_COUNT_TO_SHOW) && (
        <Button
          className="w-100"
          variant="link"
          onClick={toggleShowMore}
        >
          Show {showMore ? 'Less' : 'More' }
        </Button>
      )}
    </section>
  );
};

export default folderListView;
