import { AxiosRequestConfig } from 'axios';

import { useState, useEffect } from 'react';

import useCallApi from '@/hooks/useCallApi';

import { buildFilesFormData } from '@/utils/helpers';
import { IFileResponse, IFilesUploadForm } from '@/types/file';

import { FILE_CREATED_ERROR, FILE_CREATED_MESSAGE } from '@/features/Files/constants';

const useCreateFiles = () => {
  const [createdFiles, setCreatedFiles] = useState<IFileResponse[] | null>(null);
  const [postFilesApiConfig, setPostFilesApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createFilesResponse,
    loading: createFilesIsLoading,
  } = useCallApi(postFilesApiConfig, {
    successMessage: FILE_CREATED_MESSAGE,
    errorMessage: FILE_CREATED_ERROR,
  });

  const createFiles = (files: IFilesUploadForm, folderId?: string) => {
    const formData = buildFilesFormData(files, folderId);

    setPostFilesApiConfig({
      url: '/files',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  };

  useEffect(() => {
    setCreatedFiles(createFilesResponse?.data);
  }, [createFilesResponse]);

  return {
    createFiles,
    createdFiles,
    createFilesIsLoading,
  };
};

export default useCreateFiles;
