import React from 'react';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';

import DroppableBreadcrumbItem from '@/common/DroppableBreadcrumbItem';
import { IBreadcrumbResponse } from '@/features/Files/FilesTab/types';

export interface BreadCrumbWithLink extends IBreadcrumbResponse {
  link?: string,
}

interface FileBreadcrumbsProps {
  breadcrumbList: BreadCrumbWithLink[] | null,
}

const fileBreadcrumbs = ({
  breadcrumbList,
}: FileBreadcrumbsProps) => {
  return (
    <Breadcrumb
      listProps={{
        style: {
          marginBottom: 0,
        },
      }}
    >
      <DroppableBreadcrumbItem
        item={{ id: null, name: 'Home' }}
        link="/assets/files"
        lastItem={false}
      />
      {breadcrumbList?.map((item, idx) => {
        return (
          <DroppableBreadcrumbItem
            key={item.id}
            item={item}
            link={item.link || `/assets/files/folders/${item.id}`}
            lastItem={idx === breadcrumbList.length - 1}
          />
        );
      })}
    </Breadcrumb>
  );
};

export default fileBreadcrumbs;
