// based on https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
// warning: per a comment in the above link, if the child is a component rendering
// the child in a wrapper -> then rendering without a wrapper will cause a mount-unmount flow

// conditional wrapper component
export default ({ condition, wrapper, children }: {
  condition: boolean,
  wrapper: (child: JSX.Element) => JSX.Element,
  children: JSX.Element
}): JSX.Element => condition ? wrapper(children) : children;
