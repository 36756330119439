import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import {
  CONTENT_DELETED_ERROR,
  CONTENT_DELETED_MESSAGE,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useDeleteContents = () => {
  const [deletedContents, setDeletedContents] = useState<Record<string, never> | null>(null);
  const [deleteContentApiConfig, setDeleteContentApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteContentsResponse,
    loading: deleteContentIsLoading,
  } = useCallApi(deleteContentApiConfig, {
    successMessage: CONTENT_DELETED_MESSAGE,
    errorMessage: CONTENT_DELETED_ERROR,
  });

  const deleteContents = (contentIds: number[]) => {
    setDeleteContentApiConfig({
      url: '/contents/delete',
      method: 'delete',
      data: {
        contentIds,
      },
    });
  };

  useEffect(() => {
    if (deleteContentsResponse) setDeletedContents({});
  }, [deleteContentsResponse]);

  return {
    deleteContents,
    deletedContents,
    deleteContentIsLoading,
  };
};

export default useDeleteContents;
