import React, { PropsWithChildren } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ConfirmationModalProps {
  show: boolean,
  title: string,
  onHide: () => void,
  onConfirmation: () => void,
  confirmButtonText?: string,
  confirmButtonColor?: string,
  isSubmitting?: boolean,
  isSubmitDisabled?: boolean,
}

const ConfirmationModal = ({
  show,
  onHide,
  title,
  onConfirmation,
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'primary',
  isSubmitting = false,
  isSubmitDisabled = false,
  children,
}: PropsWithChildren<ConfirmationModalProps>) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        if (isSubmitting) return;

        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant={confirmButtonColor}
          onClick={onConfirmation}
          disabled={isSubmitting || isSubmitDisabled}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={'circle-notch'} spin />
            : confirmButtonText
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
