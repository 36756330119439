import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggle } from '@/hooks';
import { ISignResponse } from '@/types/sign';
import { STATUS_OFFLINE, STATUS_ONLINE, getFormattedCheckedInAt, getSignOnlineStatus } from '@/features/Signs/components/signOnlineStatusBadge/utils';
import SignStatusPopover from '@/features/Signs/components/signOnlineStatusBadge/components/signStatusPopover/SignStatusPopover';

import SignListModal from './SignListModal';

interface SignSelectorProps {
  selectedSign: ISignResponse | null
}

const SignSelector = ({ selectedSign }: SignSelectorProps) => {
  const routerNavigate = useNavigate();

  const { show: showSignListModal, hide: hideSignListModal, isVisible: isSignListModalVisible } = useToggle();

  const onSelectSign = (sign: ISignResponse) => {
    routerNavigate(`/schedule/signs/${sign.id}`);
    hideSignListModal();
  };

  const signOnlineStatus = selectedSign
    ? getSignOnlineStatus(selectedSign)
    : null;
  const checkedInAtFormatted = selectedSign
    ? getFormattedCheckedInAt(selectedSign)
    : 'N/A';

  let buttonVariant = 'outline-dark';
  if (signOnlineStatus && signOnlineStatus.onlineStatus === STATUS_OFFLINE) {
    buttonVariant = 'outline-danger';
  } else if (signOnlineStatus && signOnlineStatus.onlineStatus === STATUS_ONLINE) {
    buttonVariant = 'outline-success';
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Button
          className="w-100 rounded-pill"
          variant={buttonVariant}
          onClick={showSignListModal}
        >
          <strong>{selectedSign?.name || 'Please select sign'}</strong> <br />
          {(selectedSign) && (<>
            {selectedSign.width} x {selectedSign.height}
          </>)}
        </Button>
        {(signOnlineStatus) && (<>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="auto"
            overlay={SignStatusPopover({
              isOffline: signOnlineStatus.onlineStatus === STATUS_OFFLINE,
              checkedInAtFormatted,
              offlineReason: signOnlineStatus.offlineReason,
            })}
          >
            <div>
              <FontAwesomeIcon
                className={classNames({
                  'ms-2': true,
                  'text-danger': signOnlineStatus.onlineStatus === STATUS_OFFLINE,
                  'text-success': signOnlineStatus.onlineStatus === STATUS_ONLINE,
                })}
                icon={signOnlineStatus.onlineStatus === STATUS_ONLINE ? 'wifi' : 'triangle-exclamation'}
              />
            </div>
          </OverlayTrigger>
        </>)}
      </div>
      <SignListModal
        isVisible={isSignListModalVisible}
        onHide={hideSignListModal}
        onSelectSign={onSelectSign}
        selectedSigns={selectedSign ? [selectedSign] : []}
      />
    </>
  );
};

export default SignSelector;
