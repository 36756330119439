import { IMyUserResponse } from '@/types/user';
import { createContext } from 'react';

export interface MyUserContextData {
  myUser: IMyUserResponse | null,
  myUserIsLoading: boolean,
  refreshMyUser: () => Promise<void>
}

export const MyUserContext = createContext<MyUserContextData>({
  myUser: null,
  myUserIsLoading: false,
  refreshMyUser: async () => {},
});
