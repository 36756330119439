import React, { useRef } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import { IFolderResponse } from '@/types/folder';
import CustomIcon from '@/common/CustomIcon';
import { useLongPress, useToggle } from '@/hooks';
import { TFolderCardActions } from '@/features/Files/FilesTab/types';
import { FOLDER_CARD_ACTION_MOVE, FOLDER_CARD_ACTION_RENAME, FOLDER_CARD_ACTION_DELETE } from '@/features/Files/FilesTab/constants';
import RenameModal from '@/common/RenameModal';

import FolderListCardActions from './FolderListCardActions';

interface FolderListItemProps {
  folder: IFolderResponse,
  onDeleteFolder: (folder: IFolderResponse) => void,
  onMoveFolder: (folder: IFolderResponse, toFolderId?: number) => void,
  isSelected: boolean,
  addFolderToMultiSelect: (folderClicked: IFolderResponse) => void,
  removeFolderFromMultiSelect: (folderClicked: IFolderResponse) => void,
  onRenameFolder: (folderClicked: IFolderResponse) => void,
  inMultiSelectMode: boolean,
}

const folderListItem = ({
  folder,
  onDeleteFolder,
  onMoveFolder,
  isSelected,
  addFolderToMultiSelect,
  removeFolderFromMultiSelect,
  onRenameFolder,
  inMultiSelectMode,
}: FolderListItemProps) => {
  const routerNavigate = useNavigate();

  const { show: showFolderListCardActions, hide: hideFolderListCardActions, isVisible: isFolderListCardActionsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();

  const contentRef = useRef<HTMLDivElement>(null);

  const [{ dropClasses }, dropRef] = useDrop(() => ({
    accept: ['file', 'folder'],
    drop: () => ({ folder }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canDrop: (source: { type: string, id: number, folderId: number }, monitor) => {
      const differentFolder = source.type === 'folder' ? source.id !== folder.id : false;
      return source.type === 'file' || differentFolder;
    },
    collect: (monitor) => {
      let classList = '';
      if (monitor.isOver() && monitor.canDrop()) classList += 'highlight-drop-area-no-border ';

      return {
        dropClasses: classList,
      };
    },
  }));

  const [{ dragClasses }, dragRef] = useDrag(
    () => ({
      type: 'folder',
      item: { type: 'folder', id: folder.id, folderId: folder.folderId },
      collect: (monitor) => ({
        dragClasses: monitor.isDragging() ? 'opacity-50' : '',
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ folder: IFolderResponse }>();
        if (item && dropResult && dropResult.folder.id) {
          onMoveFolder(folder, dropResult.folder.id);
        }
      },
    }),
    [],
  );

  const dragDropRef = (el: HTMLDivElement | null) => {
    dragRef(el);
    dropRef(el);
  };

  const onLongPress = () => {
    if (isSelected) {
      removeFolderFromMultiSelect(folder);
    } else {
      addFolderToMultiSelect(folder);
    }
  };

  const onClickEvent = () => {
    if (inMultiSelectMode && isSelected) {
      removeFolderFromMultiSelect(folder);
    } else if (inMultiSelectMode && !isSelected) {
      addFolderToMultiSelect(folder);
    } else {
      routerNavigate(`/assets/files/folders/${folder.id}`);
    }
  };

  const defaultLongPressOptions = {
    shouldPreventDefault: true,
    delay: 750,
  };
  const longPressEvents = useLongPress(onLongPress, onClickEvent, defaultLongPressOptions);

  const checkIcon: IconProp = isSelected
    ? ['fas', 'circle-check']
    : ['far', 'circle'];

  const onAction = (action: TFolderCardActions) => {
    const ACTIONS_DICT = {
      [FOLDER_CARD_ACTION_MOVE]: () => onMoveFolder(folder),
      [FOLDER_CARD_ACTION_RENAME]: () => showRenameModal(),
      [FOLDER_CARD_ACTION_DELETE]: () => onDeleteFolder(folder),
    };

    ACTIONS_DICT[action]();
    hideFolderListCardActions();
  };

  const renderTooltip = (tooltipProps: any) => (
    <Tooltip
      {...tooltipProps}
    >
      <div>{folder.name}</div>
    </Tooltip>
  );

  return (
    <div
      className={classNames('border-bottom d-flex', dropClasses, dragClasses)}
      style={{
        minHeight: 70,
      }}
      ref={dragDropRef}
    >
      <div
        className="py-3 px-2 flex-grow-1 cursor-pointer d-flex align-items-center text-truncate"
        {...longPressEvents}
        ref={contentRef}
      >
        {(inMultiSelectMode) && (
          <FontAwesomeIcon
            className={
              classNames({
                'bg-white rounded-circle me-2': true,
                'text-black-50': !isSelected,
                'text-primary': isSelected,
              })
            }
            icon={checkIcon}
          />
        )}
        <CustomIcon
          className="me-3 text-primary"
          style={{
            height: 24,
          }}
          type="folder"
        />
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip}
          container={contentRef}
        >
          <span className="fw-bold text-wrap">
            { folder.name }
          </span>
        </OverlayTrigger>
      </div>

      {(!inMultiSelectMode) && (
        <div className="py-3 pe-2">
          <Button
            className="text-black"
            variant="link"
            onClick={showFolderListCardActions}
          >
            <FontAwesomeIcon icon="ellipsis-v" />
          </Button>
        </div>
      )}
      <FolderListCardActions
        isVisible={isFolderListCardActionsVisible}
        onHide={hideFolderListCardActions}
        onActionClick={(action) => onAction(action)}
      />
      <RenameModal
        title="Rename Folder"
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={folder.name}
        onUpdateName={(newFileName) => {
          const updatedFolder = {
            ...folder,
            name: newFileName,
          };

          onRenameFolder(updatedFolder);
        }}
      />
    </div>
  );
};

export default folderListItem;
