import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';
import { useNavigate } from 'react-router-dom';

import { IPlaylistResponse, IPlaylistUpdateRequest } from '@/types/playlist';
import { useStore, useToggle } from '@/hooks';
import { useDeletePlaylists, useDuplicatePlaylistAs, useUpdateOnePlaylist } from '@/hooks/playlist';
import {
  PLAYLIST_CARD_ACTIONS_MENU_ITEMS,
  PLAYLIST_ACTION_EDIT,
  PLAYLIST_ACTION_RENAME,
  PLAYLIST_ACTION_DELETE,
  PLAYLIST_ACTION_OPTIONS,
  PLAYLIST_ACTION_DUPLICATE,
} from '@/features/Files/PlaylistTab/constants';
import { TPlaylistActions } from '@/features/Files/PlaylistTab/types';
import OffcanvasActionMenu from '@/common/OffcanvasActionMenu';
import ConfirmationModal from '@/common/ConfirmationModal';
import RenameModal from '@/common/RenameModal';
import { DateTime } from 'luxon';
import { DATETIME_FORMAT } from '@/utils/constants';
import CustomIcon from '@/common/CustomIcon';

interface PlaylistListItemProps {
  playlist: IPlaylistResponse,
  refreshPlaylist?: () => void,
}

const playlistListItem = ({
  playlist,
  refreshPlaylist,
}: PlaylistListItemProps) => {
  const routerNavigate = useNavigate();

  const {
    updateOnePlaylist,
    updatedPlaylist,
  } = useUpdateOnePlaylist();

  const {
    deletePlaylists,
    deletedPlaylists,
  } = useDeletePlaylists();

  const {
    duplicatePlaylistAs,
    duplicatedPlaylist,
  } = useDuplicatePlaylistAs();

  const { setSelectedPlaylist, setOnPlaylistClickNavigateTo, onPlaylistClickNavigateTo } = useStore();

  const { show: showActions, hide: hideActions, isVisible: isActionsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();
  const { show: showDeleteConfirmationModal, hide: hideDeleteConfirmationModal, isVisible: isDeleteConfirmationModalVisible } = useToggle();

  useEffect(() => {
    if ((updatedPlaylist || deletedPlaylists || duplicatedPlaylist) && refreshPlaylist) refreshPlaylist();
  }, [updatedPlaylist, deletedPlaylists, duplicatedPlaylist]);

  const duplicatePlaylist = () => {
    const signIds: number[] = [];

    duplicatePlaylistAs(
      `${playlist.name} Copy`,
      playlist,
      signIds,
    );
  };

  const onAction = (action: string) => {
    if (!playlist || !PLAYLIST_ACTION_OPTIONS.includes(action as TPlaylistActions)) return;

    const ACTIONS_DICT = {
      [PLAYLIST_ACTION_EDIT]: () => routerNavigate(`/assets/playlists/${playlist.id}`),
      [PLAYLIST_ACTION_RENAME]: showRenameModal,
      [PLAYLIST_ACTION_DUPLICATE]: duplicatePlaylist,
      [PLAYLIST_ACTION_DELETE]: showDeleteConfirmationModal,
    };

    ACTIONS_DICT[action as TPlaylistActions]();
    hideActions();
  };

  const updatePlaylistName = (updatedName: string) => {
    if (!updatedName || !playlist) return;

    const playlistPutRequestData: IPlaylistUpdateRequest = {
      id: playlist.id,
      name: updatedName,
      contentIds: playlist.contents.map((content) => content.id) || [],
      signIds: playlist.signs.map((s) => s.id) || [],
    };

    updateOnePlaylist(playlistPutRequestData);
    hideRenameModal();
  };

  const clickPlaylist = () => {
    if (!onPlaylistClickNavigateTo) {
      routerNavigate(`/assets/playlists/${playlist.id}`);
      return;
    }
    setSelectedPlaylist(playlist);
    routerNavigate(onPlaylistClickNavigateTo);
    setOnPlaylistClickNavigateTo(null);
  };

  const deletePlaylist = () => {
    deletePlaylists([playlist.id]);
    hideDeleteConfirmationModal();
  };

  const renderDeleteConfirmationBody = () => {
    const hasSigns = playlist.signs.length > 0;
    const hasMultipleSigns = playlist.signs.length > 1;
    const signNames = playlist.signs.map((i) => i.name).join(', ');

    if (!hasSigns) return `Are you sure you want to delete ${playlist.name}?`;

    return `Are you sure you want to delete ${playlist.name}, this will also delete the playlist from ${hasMultipleSigns ? 'signs' : 'the sign'}: ${signNames}?`;
  };

  const dates = () => {
    const start = DateTime.fromFormat(playlist.startDatetime, DATETIME_FORMAT).toLocaleString({ month: 'short', day: 'numeric' });
    const end = DateTime.fromFormat(playlist.endDatetime, DATETIME_FORMAT).toLocaleString({ month: 'short', day: 'numeric' });
    const endYear = DateTime.fromFormat(playlist.endDatetime, DATETIME_FORMAT).toLocaleString({ year: 'numeric' });

    return `${start} - ${end} | ${endYear}`;
  };

  return (
    <div className="border-bottom d-flex">
      <div
        className="py-3 px-2 flex-grow-1 cursor-pointer d-flex align-items-center text-truncate"
        onClick={clickPlaylist}
      >
        <CustomIcon
          className="me-3 text-primary"
          style={{
            height: 28,
          }}
          type="playlist"
        />
        <div className="text-truncate">
          <span className="fw-bold">
            { playlist.name }
          </span><br />
          <span className="text-muted small">
            { dates() }
          </span>
        </div>
      </div>
      <Button
        className="text-black"
        variant="link"
        onClick={showActions}
      >
        <FontAwesomeIcon icon="ellipsis-v" />
      </Button>
      <OffcanvasActionMenu
        isVisible={isActionsVisible}
        onHide={hideActions}
        actionMenuItems={PLAYLIST_CARD_ACTIONS_MENU_ITEMS}
        onActionClick={onAction}
      />
      <RenameModal
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={playlist.name}
        onUpdateName={(updatedName) => updatePlaylistName(updatedName)}
      />
      <ConfirmationModal
        show={isDeleteConfirmationModalVisible}
        title={`Delete ${playlist.name}?`}
        onHide={hideDeleteConfirmationModal}
        onConfirmation={deletePlaylist}
        confirmButtonText="Delete"
        confirmButtonColor="danger"
      >
        {renderDeleteConfirmationBody()}
      </ConfirmationModal>
    </div>
  );
};

export default playlistListItem;
