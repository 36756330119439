import React from 'react';

import EmulationNotification from './EmulationNotification';
import BottomNav from './BottomNav';
import TopNav from './TopNav';
import SideNav from './SideNav';

import './Layout.scss';

type LayoutProps = {
  children: JSX.Element,
}

/**
 * Application Layout
 */
class Layout extends React.Component<LayoutProps> {
  /**
   * Bottom Navigation state
   * @param {*} props
   */
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
    };
  }

  /**
   * Renders the Component
   * @returns The active route page
   */
  render() {
    const PageComponent = this.props.children;

    return (
      <div className="position-relative">
        <div
          className="content-container"
        >
          <TopNav />
          <SideNav />
          <div
            style={{
              paddingBottom: 72,
              maxWidth: 850,
              margin: 'auto',
            }}
          >
            <EmulationNotification />
            {PageComponent}
          </div>
        </div>
        <BottomNav />
      </div>
    );
  }
}

export default Layout;
