import React, { CSSProperties, useState, useEffect } from 'react';
import classNames from 'classnames';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import ToggleButton from 'react-bootstrap/esm/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/esm/ToggleButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IFileResponse } from '@/types/file';

import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';

import './defaultContentCard.scss';

interface DefaultFlagCardProps {
  isSelected: 'animated' | 'static' | null,
  animatedFile: IFileResponse,
  staticFile: IFileResponse
  title: string,
  onChange: (typeOfDefault: 'system', fileForDefault: IFileResponse) => void,
}

const DefaultFlagCard = ({
  animatedFile,
  staticFile,
  isSelected,
  title,
  onChange,
}: DefaultFlagCardProps) => {
  const [selectedFile, setSelectedFile] = useState<'animated' | 'static' | null>(isSelected);

  const fileToShow = selectedFile === 'animated' ? animatedFile : staticFile;

  useEffect(() => {
    if (fileToShow && isSelected) onChange('system', fileToShow);
  }, [selectedFile]);

  const checkIcon: IconProp = isSelected ? ['fas', 'circle-check'] : ['far', 'circle'];
  const imageCustomStyle: CSSProperties = {
    maxWidth: '250px',
  };

  return (
    <Card
      className="h-100"
      border="light"
      bg="white"
    >
      <Card.Body className="p-0">
        <Card.Title
          className="d-flex is-align-items-center"
        >
          <Button
            className="no-style-button px-3 py-2"
            onClick={() => onChange('system', fileToShow)}
          >
            <FontAwesomeIcon
              className={
                classNames({
                  'bg-white rounded-circle mx-2': true,
                  'text-black-50': !isSelected,
                  'text-primary': isSelected,
                })
              }
              icon={checkIcon}
            />
            {title}
          </Button>
        </Card.Title>
        {fileToShow && (<div className="d-flex justify-content-center bg-dark text-white">
          <FileWithPreview
            file={fileToShow}
            customStyle={imageCustomStyle}
          />
        </div>)}
      </Card.Body>
      <Card.Footer
        className="border-top-0 p-3"
      >
        <ToggleButtonGroup type="radio" name="options" value={selectedFile} onChange={setSelectedFile}>
          <ToggleButton
            id="animated-flag"
            value="animated"
            variant="outline-primary"
          >
            Animated
          </ToggleButton>
          <ToggleButton
            id="static-flag"
            value="static"
            variant="outline-primary"
          >
            Static
          </ToggleButton>
        </ToggleButtonGroup>
      </Card.Footer>
    </Card>
  );
};

export default DefaultFlagCard;
