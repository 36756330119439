import React, { useEffect, useRef } from 'react';
import { IFileResponse, IFileUploadForm } from '@/types/file';
import Button from 'react-bootstrap/esm/Button';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import CustomIcon from '@/common/CustomIcon';
import { useToggle, useStore, useLongPress } from '@/hooks';
import { IFolderResponse } from '@/types/folder';
import { TFileCardActions } from '@/features/Files/FilesTab/types';
import {
  FILE_CARD_ACTION_MOVE,
  FILE_CARD_ACTION_RENAME,
  FILE_CARD_ACTION_REPLACE,
  FILE_CARD_ACTION_DOWNLOAD,
  FILE_CARD_ACTION_DELETE,
} from '@/features/Files/FilesTab/constants';
import { downloadS3Url } from '@/utils/helpers';
import RenameModal from '@/common/RenameModal';

import FileListCardActions from './FileListCardActions';
import UploadFileModal from './UploadFileModal';

interface FileListitemProps {
  file: IFileResponse,
  onMoveFile: (fileClicked: IFileResponse, folderId?: number) => void,
  onRenameFile: (fileClicked: IFileResponse) => void,
  onReplaceFile: (fileClicked: IFileResponse, fileToUpload: IFileUploadForm) => void,
  onDeleteFile: (fileClicked: IFileResponse) => void,
  isSelected: boolean,
  addFileToMultiSelect: (fileClicked: IFileResponse) => void,
  removeFileFromMultiSelect: (fileClicked: IFileResponse) => void,
  inMultiSelectMode: boolean,
  putFileIsLoading?: boolean,
}

const fileListItem = ({
  file,
  onMoveFile,
  onRenameFile,
  onReplaceFile,
  onDeleteFile,
  isSelected,
  addFileToMultiSelect,
  removeFileFromMultiSelect,
  inMultiSelectMode,
  putFileIsLoading,
}: FileListitemProps) => {
  const { setSelectedFile, onFileClickNavigateTo, setOnFileClickNavigateTo } = useStore();
  const routerNavigate = useNavigate();

  const { show: showUploadFileModal, hide: hideUploadFileModal, isVisible: isUploadFileModalVisible } = useToggle();
  const { show: showActions, hide: hideActions, isVisible: isActionsVisible } = useToggle();
  const { show: showRenameModal, hide: hideRenameModal, isVisible: isRenameModalVisible } = useToggle();

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (putFileIsLoading === false) {
      hideUploadFileModal();
    }
  }, [putFileIsLoading]);

  const [{ dragClasses }, dragRef] = useDrag(
    () => ({
      type: 'file',
      item: { type: 'file', id: file.id, folderId: file.folderId },
      collect: (monitor) => ({
        dragClasses: monitor.isDragging() ? 'opacity-50' : '',
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ folder: IFolderResponse }>();
        if (item && dropResult && dropResult.folder.id) {
          onMoveFile(file, dropResult.folder.id);
        }
      },
    }),
    [],
  );

  const onLongPress = () => {
    if (isSelected) {
      removeFileFromMultiSelect(file);
    } else {
      addFileToMultiSelect(file);
    }
  };

  const onClickEvent: any = () => {
    if (inMultiSelectMode && isSelected) {
      removeFileFromMultiSelect(file);
    } else if (inMultiSelectMode && !isSelected) {
      addFileToMultiSelect(file);
    } else {
      setSelectedFile(file);
      if (!onFileClickNavigateTo) {
        routerNavigate(`/assets/files/${file.id}`);
        return;
      }
      routerNavigate(onFileClickNavigateTo);
      setOnFileClickNavigateTo(null);
    }
  };

  const defaultLongPressOptions = {
    shouldPreventDefault: true,
    delay: 750,
  };
  const longPressEvents = useLongPress(onLongPress, onClickEvent, defaultLongPressOptions);

  const renderFileThumbnail = () => {
    const fileUrl = file.thumbnailUrl
      ? file.thumbnailUrl
      : file.url;

    if (!fileUrl) {
      return (
        <CustomIcon
          className="me-3"
          style={{
            height: 28,
          }}
          type="file"
        />
      );
    }

    return (
      <div
        className="ratio ratio-1x1 me-3"
        style={{
          width: 36,
        }}
      >
        <div
          className="rounded-2"
          style={{
            backgroundImage: `url(${fileUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      </div>
    );
  };

  const onAction = (action: TFileCardActions) => {
    const ACTIONS_DICT = {
      [FILE_CARD_ACTION_MOVE]: () => onMoveFile(file),
      [FILE_CARD_ACTION_RENAME]: () => showRenameModal(),
      [FILE_CARD_ACTION_REPLACE]: showUploadFileModal,
      [FILE_CARD_ACTION_DOWNLOAD]: () => downloadS3Url(file.downloadUrl),
      [FILE_CARD_ACTION_DELETE]: () => onDeleteFile(file),
    };

    ACTIONS_DICT[action]();
    hideActions();
  };

  const renderTooltip = (tooltipProps: any) => (
    <Tooltip
      {...tooltipProps}
    >
      <div>{file.name}</div>
    </Tooltip>
  );

  const checkIcon: IconProp = isSelected
    ? ['fas', 'circle-check']
    : ['far', 'circle'];

  return (
    <div
      className={classNames('border-bottom d-flex', dragClasses)}
      style={{
        minHeight: 70,
      }}
      ref={dragRef}
    >
      <div
        className="py-3 px-2 flex-grow-1 cursor-pointer d-flex align-items-center text-truncate"
        {...longPressEvents}
        ref={contentRef}
      >
        {(inMultiSelectMode) && (
          <FontAwesomeIcon
            className={
              classNames({
                'bg-white rounded-circle me-2': true,
                'text-black-50': !isSelected,
                'text-primary': isSelected,
              })
            }
            icon={checkIcon}
          />
        )}
        {renderFileThumbnail()}
        <div>
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
            container={contentRef}
          >
            <span className="fw-bold text-wrap">
              { file.name }
            </span>
          </OverlayTrigger><br />
          <span className="text-muted small">
            {`${file.width} x ${file.height}`}
          </span>
        </div>
      </div>

      {(!inMultiSelectMode) && (
        <Button
          className="text-black"
          variant="link"
          onClick={showActions}
        >
          <FontAwesomeIcon icon="ellipsis-v" />
        </Button>
      )}
      <FileListCardActions
        isVisible={isActionsVisible}
        onHide={hideActions}
        onActionClick={onAction}
      />
      <UploadFileModal
        show={isUploadFileModalVisible}
        onUploadFile={(fileToUpload) => onReplaceFile(file, fileToUpload)}
        handleCloseUploadFileModal={hideUploadFileModal}
        loading={putFileIsLoading}
      />
      <RenameModal
        title="Rename File"
        show={isRenameModalVisible}
        handleCloseRenameModal={hideRenameModal}
        initialName={file.name}
        onUpdateName={(newFileName) => {
          const updatedFile = {
            ...file,
            name: newFileName,
          };

          onRenameFile(updatedFile);
        }}
      />
    </div>
  );
};

export default fileListItem;
