import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IUserResponse, IUserUpdateRequest } from '@/types/user';

import {
  USER_UPDATED_MESSAGE,
  USER_UPDATED_ERROR,
} from '@/features/Home/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdateUser = () => {
  const useHubApi = true;
  const [updatedUser, setUpdatedUser] = useState<IUserResponse[] | null>(null);
  const [putUserApiConfig, setPutUserApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateUserResponse,
    loading: updateUserIsLoading,
  } = useCallApi(putUserApiConfig, {
    successMessage: USER_UPDATED_MESSAGE,
    errorMessage: USER_UPDATED_ERROR,
  }, useHubApi);

  const updateUser = (updatedUserData: IUserUpdateRequest) => {
    setPutUserApiConfig({
      url: `/users/${updatedUserData.id}`,
      method: 'put',
      data: updatedUserData,
    });
  };

  useEffect(() => {
    setUpdatedUser(updateUserResponse?.data);
  }, [updateUserResponse]);

  return {
    updateUser,
    updatedUser,
    updateUserIsLoading,
  };
};

export default useUpdateUser;

