import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Card from 'react-bootstrap/esm/Card';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import { IFileResponse } from '@/types/file';
import { ISignCreateRequest, ISignResponse } from '@/types/sign';

import '@/features/Files/FilesTab/Files.scss';
import { validatePixelMatrix } from '@/utils/validations';
import FileDetailsBadges from '@/common/FileDetailsBadges';

interface FileGridCardProps {
  file: IFileResponse,
  onFileSelect: (file: IFileResponse) => void,
  isSelected: boolean,
  sign?: ISignResponse | ISignCreateRequest | null,
}

const FileGridCard = ({
  file,
  isSelected,
  onFileSelect,
  sign,
}: FileGridCardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const renderFileThumbnail = () => {
    const fileUrl = file.thumbnailUrl
      ? file.thumbnailUrl
      : file.url;

    return (
      <div className="position-relative">
        <div className="ratio ratio-16x9">
          <div
            className="img file-list-item-thumbnail rounded-2"
            style={{ backgroundImage: `url(${fileUrl})` }}
          />
          <FileDetailsBadges file={file} hideFileType />
        </div>
        {(isSelected) && (
          <FontAwesomeIcon
            className="bg-white rounded-circle multiselect-icon text-primary"
            icon={['fas', 'circle-check']}
          />
        )}
      </div>
    );
  };

  return (
    <div className="w-100" ref={contentRef}>
      <Card
        className={classNames({
          'file-list-item': true,
          'bg-primary bg-opacity-25 border-primary': isSelected,
          'border-transparent': !isSelected,
        })}
        onClick={() => onFileSelect(file)}
      >
        <div>
          {renderFileThumbnail()}
        </div>

        <Card.Footer
          className="app-card-footer ellipsis-container"
        >
          <div>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <div>{file.name}</div>
                </Tooltip>)}
              container={contentRef}
            >
              <span className="text-wrap">
                {file.name}
              </span>
            </OverlayTrigger>
            {(file.width && file.height) && (<>
              <br />
              <span
                className={classNames(
                  'text-truncate small',
                  sign && !validatePixelMatrix(file, sign) ? 'text-danger' : 'text-muted',
                )}
              >
                {file.width}x{file.height}
              </span>
            </>)}
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default FileGridCard;
