import React from 'react';

import { IContentForm, IPlaylistForm } from '@/features/Schedule/types';

interface YearlyRepeatFormProps {
  item: IContentForm | IPlaylistForm |null,
}

const YearlyRepeatForm = ({ item }: YearlyRepeatFormProps) => {
  return (
    <>
      Yearly on {item?.startDate.monthLong} {item?.startDate.day}
    </>
  );
};

export default YearlyRepeatForm;
