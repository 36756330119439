import React, { useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';
import classNames from 'classnames';
import { ISignResponse } from '@/types/sign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IActionMenuItem } from '@/types/actions';
import OffcanvasActionMenu from '@/common/OffcanvasActionMenu';
import { useToggle } from '@/hooks';
import { useUpdateOneSign } from '@/hooks/sign';
import ConfirmationModal from '@/common/ConfirmationModal';
import { IBumperForm } from '@/features/Schedule/types';

import EditBumperModal from './EditBumperModal';
import CardStatusBadge from './CardStatusBadge';

interface BumperCardProps {
  selectedSign: ISignResponse;
  refreshSign: () => void,
}

const ACTION_EDIT_BUMPER = 'editBumper';
const ACTION_DELETE_BUMPER = 'deleteBumper';

const BUMPER_ACTIONS: IActionMenuItem[] = [
  {
    text: 'Edit',
    icon: 'keyboard',
    action: ACTION_EDIT_BUMPER,
  },
  {
    text: 'Delete',
    icon: 'trash',
    action: ACTION_DELETE_BUMPER,
  },
];

const BumperCard = ({
  selectedSign,
  refreshSign,
}: BumperCardProps) => {
  const { bumper } = selectedSign;
  if (!bumper) return (<></>);

  const {
    updateOneSign,
    updatedSign,
    updateOneSignIsLoading,
  } = useUpdateOneSign();
  const { show: showActions, hide: hideActions, isVisible: isActionsVisible } = useToggle();
  const { show: showEditBumperModal, hide: hideEditBumperModal, isVisible: isEditBumperModalVisible } = useToggle();
  const { show: showDeleteConfirmModal, hide: hideDeleteConfirmModal, isVisible: isDeleteConfirmModalVisible } = useToggle();

  const onAction = (action: string) => {
    switch (action) {
      case ACTION_EDIT_BUMPER:showEditBumperModal();
        hideActions();
        break;
      case ACTION_DELETE_BUMPER:
        showDeleteConfirmModal();
        hideActions();
        break;
      default:

        // no default
    }
  };

  const onDeleteBumper = () => {
    updateOneSign({
      id: selectedSign.id,
      bumper: null,
    });
  };

  const onUpdateBumper = (values: IBumperForm) => {
    updateOneSign({
      id: selectedSign.id,
      bumper: {
        ...values,
        fileId: bumper.fileId,
      },
    });
  };

  useEffect(() => {
    if (!updatedSign) return;

    // hide both modals even though either modal can trigger this useEffect
    hideDeleteConfirmModal();
    hideEditBumperModal();
    refreshSign();
  }, [updatedSign]);

  return (<>
    <div
      className={
        classNames({
          'd-flex justify-content-between px-2 py-3': true,
          'bg-inactive': bumper.isPaused,
        })
      }
    >
      <div className="d-flex flex-grow-1 text-truncate">
        <div
          className="schedule-thumbnail"
          draggable={false}
        >
          {/* TODO - add default file icon */}
          {(bumper && bumper.file) && (<img
            id="bumper-thumbnail-image"
            src={bumper.file.thumbnailUrl || bumper.file.url}
          />)}
        </div>
        <div className="ms-2 text-truncate">
          <strong>Bumper Content</strong><br />
          Plays every other ad
        </div>
      </div>
      {(bumper.isPaused) && (
        <div className="d-flex">
          <CardStatusBadge
            activityStatus="paused"
          />
        </div>
      )}
      <Button
        className="text-dark flex-end"
        variant="link"
        onClick={showActions}
      >
        <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
      </Button>
    </div>

    <OffcanvasActionMenu
      isVisible={isActionsVisible}
      onHide={hideActions}
      actionMenuItems={BUMPER_ACTIONS}
      onActionClick={onAction}
      title={'Bumper Actions'}
    />

    <EditBumperModal
      isVisible={isEditBumperModalVisible}
      onHide={hideEditBumperModal}
      bumperData={bumper}
      isSubmitting={updateOneSignIsLoading || false}
      handleFormSubmit={onUpdateBumper}
    />

    <ConfirmationModal
      show={isDeleteConfirmModalVisible}
      title="Delete?"
      onHide={hideDeleteConfirmModal}
      onConfirmation={onDeleteBumper}
      confirmButtonColor="danger"
    >
      Are you sure you want to delete the bumper? This will remove the bumper from the schedule.
    </ConfirmationModal>
  </>);
};

export default BumperCard;
