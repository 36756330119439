import React from 'react';
import { Link } from 'react-router-dom';

import ListGroup from 'react-bootstrap/esm/ListGroup';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';

import { ISignResponse } from '@/types/sign';

interface ScheduleContenOptionsProps {
  sign: ISignResponse | null,
  isScheduleActionOptionsVisible: boolean,
  hideScheduleActionOptions: () => void,
  importPlaylist: () => void,
  inMultiSelectMode: boolean,
  setInMultiSelectMode: React.Dispatch<React.SetStateAction<boolean>>,
  setBumperContent: () => void,
}

const ScheduleContentOptions = ({
  sign,
  isScheduleActionOptionsVisible,
  hideScheduleActionOptions,
  importPlaylist,
  inMultiSelectMode,
  setInMultiSelectMode,
  setBumperContent,
}: ScheduleContenOptionsProps) => {
  return (
    <Offcanvas
      show={isScheduleActionOptionsVisible}
      onHide={hideScheduleActionOptions}
      placement="bottom"
      className="list-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          Schedule Options<br />
          <span className="text-sm fw-light">{sign?.name || ''}</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          <ListGroup.Item
            action
            onClick={() => setInMultiSelectMode(!inMultiSelectMode)} active={inMultiSelectMode}
          >
            Select Multiple
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={importPlaylist}
          >
            Import Playlist
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={setBumperContent}
          >
            Set Bumper Content
          </ListGroup.Item>
          {sign && (
            <ListGroup.Item
              action
              as={Link}
              to={`/signs/${sign.id}`}
            >
              View Sign Settings
            </ListGroup.Item>
          )}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ScheduleContentOptions;
