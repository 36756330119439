import React from 'react';
import Alert from 'react-bootstrap/esm/Alert';

import { IFolderResponse } from '@/types/folder';
import { IFileResponse } from '@/types/file';
import ConfirmationModal from '@/common/ConfirmationModal';

interface ConfirmDeleteModalProps {
  show: boolean,
  onHide: () => void,
  foldersToDelete?: IFolderResponse[] | null,
  filesToDelete?: IFileResponse[] | null,
  onDelete: (arg: boolean) => void,
  isDeleting?: boolean,
}

const ConfirmDeleteModal = ({
  show,
  onHide,
  foldersToDelete,
  filesToDelete,
  onDelete,
  isDeleting = false,
}: ConfirmDeleteModalProps) => {
  const showDefaultContentWarning = filesToDelete?.some((file) => file.usedAsDefaultContentCount > 0);

  return (
    <ConfirmationModal
      show={show}
      onHide={onHide}
      title="Confirm Delete"
      onConfirmation={() => onDelete(true)}
      confirmButtonColor="danger"
      isSubmitting={isDeleting}
    >
      {filesToDelete
        && (<div>
          <p>This will delete the following {filesToDelete.length > 1 ? 'files' : 'file'}.</p>
          {showDefaultContentWarning && (<Alert>A deleted file is being used as a sign&#39;s default content.
            Once deleted, the default content will be replaced with an image of the American Flag.</Alert>)}
          <ul>
            {filesToDelete.map((f) => <li key={f.id}>{f.name}</li>)}
          </ul>
        </div>)
      }
      {foldersToDelete
        && (<div>
          <p>This will delete the following {foldersToDelete.length > 1 ? 'folders' : 'folder'} and all of their content</p>
          <ul>
            {foldersToDelete?.map((f) => <li key={f.id}>{f.name}</li>)}
          </ul>
        </div>)
      }
    </ConfirmationModal>
  );
};

export default ConfirmDeleteModal;
