import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISignRegistration } from '@/types/sign';
import { useToggle } from '@/hooks';
import ConfirmationModalWithCheckbox from '@/common/ConfirmationModalWithCheckbox';

interface SignRegistrationTableProps {
  registrationKeys: ISignRegistration[],
  addRegistrationKey: () => void,
  deleteRegistrationKey: (registrationId: number) => void,
}

const SignRegistrationTable = ({
  registrationKeys,
  addRegistrationKey,
  deleteRegistrationKey,
}: SignRegistrationTableProps) => {
  const [registrationKeyToDelete, setRegistrationKeyToDelete] = useState<ISignRegistration | null>(null);
  const { show: showDeleteConfirmationModal, hide: hideDeleteConfirmationModal, isVisible: isDeleteConfirmationModalVisible } = useToggle();

  return (
    <div>
      <Table hover size="lg">
        <thead>
          <tr>
            <th>Key</th>
            <th>Created at</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {registrationKeys && registrationKeys.map((i) => {
            return (
              <tr key={i.registrationKey}>
                <td>
                  <span>{i.registrationKey}</span>
                </td>
                <td>
                  <span>{DateTime.fromISO(i.createdAt).toFormat('LLL dd, yyyy HH:mm')}</span>
                </td>
                <td className="text-right">
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setRegistrationKeyToDelete(i);
                      showDeleteConfirmationModal();
                    }}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </td>
              </tr>);
          })
          }
        </tbody>
      </Table>
      <ConfirmationModalWithCheckbox
        show={isDeleteConfirmationModalVisible}
        onHide={hideDeleteConfirmationModal}
        title="Confirm Delete"
        onConfirmation={() => {
          if (!registrationKeyToDelete) return;
          deleteRegistrationKey(registrationKeyToDelete.id);
        }}
        confirmButtonColor="danger"
      >
        <p>
          Are you sure you want to delete this registration key {(registrationKeyToDelete) && (<strong>{registrationKeyToDelete.registrationKey}</strong>)}?
        </p>
        <p>
          If a sign is
          live with this registration key, then the sign will lose connection to
          the scheduler and not be able to download schedule updates.
        </p>
      </ConfirmationModalWithCheckbox>
      <div className="d-flex justify-content-end w-100 pb-2">
        <Button
          onClick={addRegistrationKey}
        >
          <FontAwesomeIcon
            className="me-2"
            icon="plus"
          />
          Add Registration Key
        </Button>
      </div>
    </div>
  );
};

export default SignRegistrationTable;
