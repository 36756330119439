import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { SIGN_DELETED_ERROR, SIGN_DELETED_MESSAGE } from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useDeleteOneSign = () => {
  const [deletedSign, setDeletedSign] = useState<Record<string, never> | null>(null);
  const [deleteSignApiConfig, setDeleteSignApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteOneSignResponse,
    loading: deleteOneSignIsLoading,
  } = useCallApi(deleteSignApiConfig, {
    successMessage: SIGN_DELETED_MESSAGE,
    errorMessage: SIGN_DELETED_ERROR,
  });

  const deleteOneSign = (signId: number) => {
    setDeleteSignApiConfig({
      url: `/signs/${signId}`,
      method: 'delete',
    });
  };

  useEffect(() => {
    if (deleteOneSignResponse) setDeletedSign({});
  }, [deleteOneSignResponse]);

  return {
    deleteOneSign,
    deletedSign,
    deleteOneSignIsLoading,
  };
};

export default useDeleteOneSign;
