import React from 'react';
import Badge from 'react-bootstrap/esm/Badge';

import { TActivityStatus } from '@/features/Schedule/types';

interface CardStatusBadgeProps {
  activityStatus: TActivityStatus,
}

interface StatusBadge {
  text: 'Paused' | 'Expired' | 'Future',
  color: string,
}

const CardStatusBadge = ({ activityStatus }: CardStatusBadgeProps) => {
  const ACTIVITY_STATUS_BADGE_DICT = {
    'active': null,
    'expired': { text: 'Expired', color: 'danger' } as StatusBadge,
    'paused': { text: 'Paused', color: 'secondary' } as StatusBadge,
    'future': { text: 'Future', color: 'primary' } as StatusBadge,
  };

  const statusBadge = ACTIVITY_STATUS_BADGE_DICT[activityStatus];

  if (!statusBadge) return null;

  return (
    <>
      <Badge pill bg={statusBadge.color} className="align-self-center m-1">
        {statusBadge.text}
      </Badge>
    </>
  );
};

export default CardStatusBadge;
