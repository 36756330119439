import React, { PropsWithChildren, useEffect, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import useUniqueId from '@/hooks/useUniqueId';

import ConfirmationModal from './ConfirmationModal';

interface ConfirmationModalWithCheckboxProps {
  show: boolean,
  title: string,
  onHide: () => void,
  onConfirmation: () => void,
  confirmButtonText?: string,
  confirmButtonColor?: string,
  isSubmitting?: boolean,
}

const ConfirmationModalWithCheckbox = ({
  show,
  onHide,
  title,
  onConfirmation,
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'primary',
  isSubmitting = false,
  children,
}: PropsWithChildren<ConfirmationModalWithCheckboxProps>) => {
  const checkboxId = useUniqueId('confirmation-checkbox-');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (show === true) setIsChecked(false);
  }, [show]);

  return (
    <ConfirmationModal
      show={show}
      onHide={onHide}
      title={title}
      onConfirmation={onConfirmation}
      confirmButtonText={confirmButtonText}
      confirmButtonColor={confirmButtonColor}
      isSubmitting={isSubmitting}
      isSubmitDisabled={!isChecked}
    >
      {children}
      <div className="mt-2">
        <Form.Check
          id={checkboxId}
          checked={isChecked}
          label="I understand"
          onChange={(e) => setIsChecked((e.target as HTMLInputElement).checked)}
        />
      </div>
    </ConfirmationModal>
  );
};

export default ConfirmationModalWithCheckbox;
