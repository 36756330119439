import { IFileResponse } from '@/types/file';
import { ISignCreateRequest, ISignResponse } from '@/types/sign';

import { BYTES_IN_KB, KB_IN_MB, MAX_FILE_SIZE_IN_MB, VALID_FILE_MIME_TYPES } from './constants';

const FILE_SIZE_DEFAULT_IN_MB = 100;

export const validateFileSize = (file?: File | null): boolean => {
  let valid = true;
  const size = file && file.size ? file.size / BYTES_IN_KB / KB_IN_MB : FILE_SIZE_DEFAULT_IN_MB;
  if (size > MAX_FILE_SIZE_IN_MB) {
    valid = false;
  }
  return valid;
};

export const validateFileType = (file?: File | null, fileTypes: string[] | undefined = VALID_FILE_MIME_TYPES): boolean => {
  let valid = false;
  const fileType = file && file.type ? file.type : '';

  const sanitizedFileTypes = fileTypes.map((validType) => {
    // account for wildcard types like "video/*"
    const validTypeIncludesWildcard = validType.indexOf('/*') !== -1;

    return validTypeIncludesWildcard
      ? validType.split('*')[0]
      : validType;
  });

  if (sanitizedFileTypes.find((validFileType) => fileType.indexOf(validFileType) !== -1)) {
    valid = true;
  }

  return valid;
};

export const validatePixelMatrix = (file: IFileResponse, sign: ISignResponse | ISignCreateRequest) => {
  return sign.width === file.width && sign.height === file.height;
};
