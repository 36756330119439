import { IFilesUploadForm } from '@/types/file';
import { ASPECT_RATIOS } from '@/features/Files/constants';
import { DATETIME_FORMAT, IANA_TO_READABLE_TIMEZONES_DICT, READABLE_TO_IANA_TIMEZONES_DICT } from '@/utils/constants';
import { IANATimezone, ReadableTimezone } from '@/types/time';
import { DateRange, DateRangeResponse } from '@/types/recurrenceRule';
import { DateTime, DurationLike } from 'luxon';

const DEFAULT_WAIT_TIME = 1000;
const LAST_HOUR = 23;
const LAST_MIN = 59;
const ONE_HUNDRED = 100;

export const capitalizeFirstLetter = (val: string) => {
  if (typeof val !== 'string') return val;
  const lowercaseString = val.toLowerCase();
  return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
};

// eslint-disable-next-line no-unused-vars
export const _debounce = (func: (input: string) => void, wait: number = DEFAULT_WAIT_TIME) => {
  let timeout: any;

  return function executedFunction(input: string) {
    const later = () => {
      clearTimeout(timeout);
      func(input);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const downloadS3Url = (s3downloadUrl: string) => {
  if (!s3downloadUrl) {
    throw new Error('File does not exist.');
  }
  const fileName = s3downloadUrl.split('/').pop();

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = fileName || 'Project Schedule File';
  a.href = s3downloadUrl;
  a.click();
  document.body.removeChild(a);
};

export const buildFilesFormData = (files: IFilesUploadForm, folderId: string | null = null): FormData => {
  const formData = new FormData();
  files.files.forEach((file: File) => {
    formData.append('files', file);

    // set file type
    formData.append('file_type', ((file.type).replace(/(.*)\//g, '')));
  });
  formData.append('origin', 'upload');
  if (folderId) formData.append('folderId', folderId);
  return formData;
};

export const parseDatetime = (date: DateTime): { year: number, month: number, day: number, hour: number, minute: number } | null => {
  if (!date.isValid) return null;

  return {
    year: date.get('year'),
    month: date.get('month'),
    day: date.get('day'),
    hour: date.get('hour'),
    minute: date.get('minute'),
  };
};

export const formatDateRangesToString = (DateRangeArray: DateRange[]): [string, string][] => {
  return DateRangeArray.map(([startDate, endDate]) => {
    return [
      startDate.toFormat(DATETIME_FORMAT),
      endDate.toFormat(DATETIME_FORMAT),
    ];
  });
};

export const formatDateRangesToDateTime = (dateRangeArray: DateRangeResponse[]): DateRange[] => {
  return dateRangeArray.map(([startDate, endDate]) => {
    return [
      DateTime.fromFormat(startDate, DATETIME_FORMAT),
      DateTime.fromFormat(endDate, DATETIME_FORMAT),
    ];
  });
};

export const formatTimeRangesToDateTime = (timeRangeArray: DateRangeResponse[]): DateRange[] => {
  return timeRangeArray.map(([startTime, endTime]) => {
    const [startDateOnly, startTimeOnly] = startTime.split('T');
    const [endDateOnly, endTimeOnly] = endTime.split('T');
    const startDateTime = startDateOnly !== 'null' ? DateTime.fromFormat(startTime, DATETIME_FORMAT) : DateTime.fromFormat(startTimeOnly, 'HH:mm:ss');
    const endDateTime = endDateOnly !== 'null' ? DateTime.fromFormat(endTime, DATETIME_FORMAT) : DateTime.fromFormat(endTimeOnly, 'HH:mm:ss');

    return [
      startDateTime,
      endDateTime,
    ];
  });
};

export const formatTimeZoneToReadable = (timezone: string): ReadableTimezone => {
  return IANA_TO_READABLE_TIMEZONES_DICT[timezone];
};

export const formatTimeZoneToIANA = (timezone: string): IANATimezone => {
  return READABLE_TO_IANA_TIMEZONES_DICT[timezone];
};

export const today0000 = (zone?: DateTime['zoneName']) => {
  const todayDatetime = zone ? DateTime.local({ zone }) : DateTime.local();
  const today = parseDatetime(todayDatetime) || { year: 2022, month: 1, day: 1 };
  return DateTime.local(today.year, today.month, today.day, 0, 0);
};

export const today2359 = (): DateTime => {
  const todayDatetime = DateTime.local();
  const today = parseDatetime(todayDatetime) || { year: 2022, month: 1, day: 1 };
  return DateTime.local(today.year, today.month, today.day, LAST_HOUR, LAST_MIN);
};

export const durationFromNow2359 = (duration: DurationLike) => {
  const todayDatetime = today0000();
  const durationFromNowDatetime = todayDatetime.plus(duration);
  const durationFromNow = parseDatetime(durationFromNowDatetime) || { year: 2099, month: 1, day: 1 };
  const lastHour = 23;
  const lastMinute = 59;
  return DateTime.local(durationFromNow.year, durationFromNow.month, durationFromNow.day, lastHour, lastMinute);
};

export const formatDateResponseToDateTime = (dateString: string) => {
  const stringToDateTime = DateTime.fromISO(dateString);
  return stringToDateTime.isValid ? stringToDateTime : null;
};

export const pad = (d: number) => {
  const pad10 = 10;
  return (d < pad10) ? '0' + d.toString() : d.toString();
};

export const stringToNumber = (val: any) => {
  if (typeof val === 'number') return val;
  if (typeof val === 'string') return parseInt(val, 10);
  return null;
};

const calculateRatio = (width: number, height: number) => (width * ONE_HUNDRED) / (height * ONE_HUNDRED);

export const calculateAspectRatio = (width: number, height: number) => {
  if (!width || !height) return undefined;

  const ratio = calculateRatio(width, height);

  const INITIAL_RATIO_DELTA = 10;
  let closestRatio: {
    aspect: string
    calculation: number
  } = { aspect: '16:9', calculation: INITIAL_RATIO_DELTA };

  ASPECT_RATIOS.forEach((ar) => {
    const [arWidth, arHeight] = ar.split(':');

    const arRatio = calculateRatio(parseInt(arWidth, 10), parseInt(arHeight, 10));
    const ratioDelta = Math.abs(ratio - arRatio);

    if (ratioDelta < closestRatio.calculation)
      closestRatio = { aspect: ar, calculation: ratioDelta };
  });

  return closestRatio.aspect;
};

