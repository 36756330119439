import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ISignResponse, ISignCreateRequest } from '@/types/sign';
import { SIGN_CREATED_ERROR, SIGN_CREATED_MESSAGE } from '@/features/Signs/constants';

import useCallApi from '@/hooks/useCallApi';

const useCreateSign = () => {
  const [createdSign, setCreatedSign] = useState<ISignResponse | null>(null);
  const [postSignApiConfig, setPostSignApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createSignResponse,
    loading: createSignIsLoading,
  } = useCallApi(postSignApiConfig, {
    successMessage: SIGN_CREATED_MESSAGE,
    errorMessage: SIGN_CREATED_ERROR,
  });

  const createSign = (newSign: ISignCreateRequest) => {
    setPostSignApiConfig({
      url: '/signs',
      method: 'post',
      data: newSign,
    });
  };

  useEffect(() => {
    setCreatedSign(createSignResponse?.data);
  }, [createSignResponse]);

  return {
    createSign,
    createdSign,
    createSignIsLoading,
  };
};

export default useCreateSign;
