import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IContentResponse } from '@/types/content';
import {
  CONTENT_DUPLICATED_MESSAGE,
  CONTENT_DUPLICATED_ERROR,
} from '@/features/Schedule/constants';

import useCallApi from '@/hooks/useCallApi';

const useDuplicateContents = () => {
  const [duplicatedContents, setDuplicatedContents] = useState<IContentResponse[] | null>(null);
  const [duplicateContentsApiConfig, setDuplicateContentsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: duplicateContentsResponse,
    loading: duplicateContentsIsLoading,
  } = useCallApi(duplicateContentsApiConfig, {
    successMessage: CONTENT_DUPLICATED_MESSAGE,
    errorMessage: CONTENT_DUPLICATED_ERROR,
  });

  const duplicateContents = (contentIds: number[], signIds: number[], playlistIds: number[]) => {
    setDuplicateContentsApiConfig({
      url: '/contents/duplicate',
      method: 'post',
      data: {
        contentIds,
        signIds,
        playlistIds,
      },
    });
  };

  useEffect(() => {
    setDuplicatedContents(duplicateContentsResponse?.data);
  }, [duplicateContentsResponse]);

  return {
    duplicateContents,
    duplicatedContents,
    duplicateContentsIsLoading,
  };
};

export default useDuplicateContents;

