import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';

import { FILE_CARD_ACTIONS_MENU_ITEMS } from '@/features/Files/FilesTab/constants';

import { TFileCardActions } from '@/features/Files/FilesTab/types';

interface IFileListCardActionsProps {
  isVisible: boolean,
  onHide: () => void,
  onActionClick: (action: TFileCardActions) => void,
}

const FileListCardActions = ({
  isVisible,
  onHide,
  onActionClick,
}: IFileListCardActionsProps) => {
  return (
    <Offcanvas
      show={isVisible}
      onHide={onHide}
      placement="bottom"
      className="list-offcanvas"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>File Options</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          {FILE_CARD_ACTIONS_MENU_ITEMS.map((menuItem) => {
            return (
              <ListGroup.Item
                key={menuItem.text}
                onClick={() => onActionClick(menuItem.action)}
                action
              >
                <FontAwesomeIcon icon={menuItem.icon} />
                <span className="ms-2">{menuItem.text}</span>
              </ListGroup.Item>);
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FileListCardActions;
