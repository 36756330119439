import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { Link } from 'react-router-dom';

import { useToggle } from '@/hooks';
import { useSignUpUser, useUpdateUser } from '@/hooks/user';
import { ISignUpUser, IUserSignUpForm } from '@/types/user';
import Loader  from '@/common/Loader';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import pcLogo from '@/assets/logos/pc_logo_old.png';
import { HOME_NAV_ITEMS } from '@/layout/constants';
import { useWindowSize } from '@/hooks/useWindowSize';
import ConditionalWrapper from '@/common/wrappers/ConditionalWrapper';
import IfElseWrapper from '@/common/wrappers/IfElseWrapper';
import useMyUser from '@/context/myUser/useMyUser';
import CustomIcon from '@/common/CustomIcon';
import { useReadSigns } from '@/hooks/sign';
import { getSignOnlineStatus, STATUS_OFFLINE } from '@/features/Signs/components/signOnlineStatusBadge/utils';

import CreateProfileModal from './CreateProfileModal';
import OfflineSignAlert from './components/OfflineSignAlert';

const BOOTSTRAP_LG_QUERY_IN_PIXELS = 992;

const Home = () => {
  const { user: authUser } = useAuth0();
  const { myUser, myUserIsLoading, refreshMyUser } = useMyUser();

  const windowSize = useWindowSize();

  const {
    signUpUser,
    signedUpUser,
    signUpUserResponseError,
  } = useSignUpUser();

  const {
    updateUser,
    updatedUser,
  } = useUpdateUser();

  const {
    readSigns,
    signs,
  } = useReadSigns();

  const { show: showCreateProfileModal, hide: hideCreateProfileModal, isVisible: isCreateProfileModalVisible } = useToggle();

  useEffect(() => {
    if (signedUpUser || updatedUser) {
      refreshMyUser();
      hideCreateProfileModal();
    }
  }, [signedUpUser, updatedUser]);

  useEffect(() => {
    if (!myUser?.firstName || !myUser?.lastName) {
      showCreateProfileModal();
    } else {
      hideCreateProfileModal();
    }

    readSigns();
  }, [myUser]);

  const updateNewUser = (data: IUserSignUpForm) => {
    const { firstName, lastName, companyName } = data;

    // new user - already signed up by admin
    if (myUser) {
      const newUserData = {
        id: myUser.id,
        firstName,
        lastName,
      };
      updateUser(newUserData);

      // new user - not signed up
    } else if (!myUser && authUser) {
      const signUpData: ISignUpUser = {
        firstName,
        lastName,
        email: authUser?.email || '',
        companyName,
      };

      signUpUser(signUpData);
    }
  };

  if (myUserIsLoading) {
    return (<div
      className="px-3 py-4"
    >
      <Loader size="2x" />
    </div>);
  }

  if (signUpUserResponseError) {
    return <div className="px-3 py-4">Unable to create user. <br /> Please contact Project Content Support.</div>;
  }

  const windowIsLessThanLarge = windowSize.width < BOOTSTRAP_LG_QUERY_IN_PIXELS;

  const offlineSignCount = signs.filter((sign) => {
    const { onlineStatus } = getSignOnlineStatus(sign);

    return onlineStatus === STATUS_OFFLINE;
  }).length;

  return (
    <div>
      {(myUser) ? (<><div className="ratio ratio-16x9 bg-dark d-flex align-items-center justify-content-center">
        <img
          className="position-relative"
          style={{
            width: 72,
            height: 79,
          }}
          src={pcLogo}
        />
      </div><div
        className="px-3 py-4 blue-gradient text-white fw-bold text-center"
      >
          Manage your signage schedule.
      </div>
      {(offlineSignCount > 0) && (
        <OfflineSignAlert
          offlineSignCount={offlineSignCount}
        />
      )}
      <div className="bg-white fw-bold">
        {HOME_NAV_ITEMS.map((item) => (
          <ConditionalWrapper
            key={item.name}
            condition={!!item.isDisabledOnMobile && windowIsLessThanLarge}
            wrapper={(children) => (
              <OverlayTrigger
                overlay={<Tooltip>Not available on mobile</Tooltip>}
              >
                {/* this div is needed to allow hover/click to trigger the tooltip */}
                <div
                  className="bg-dark bg-opacity-25"
                >
                  {/* prevent clicks/hover on item */}
                  <div
                    style={{
                      pointerEvents: 'none',
                    }}
                  >
                    {children}
                  </div>
                </div>
              </OverlayTrigger>
            )}
          >
            <IfElseWrapper
              condition={!!item.isExternal}
              wrapperIf={(children) => <a href={item.url}>{children}</a>}
              wrapperElse={(children) => <Link to={item.url}>{children}</Link>}
            >
              <div
                className="px-3"
              >
                <div
                  className="px-2 py-4 d-flex align-items-center justify-content-between border-bottom"
                  key={item.name}
                >
                  <div>
                    {(item.customIcon) && (<CustomIcon
                      className="me-3"
                      style={{
                        width: 24,
                        height: 'auto',
                      }}
                      type={item.customIcon}
                    />)}
                    {item.name}
                  </div>
                  <Button
                    variant="link"
                  >
                    <FontAwesomeIcon
                      className="text-gray"
                      icon="chevron-right"
                    />
                  </Button>
                </div>
              </div>
            </IfElseWrapper>
          </ConditionalWrapper>
        ))}
        <a href="mailto:info@projectcontent.com">
          <div className="px-4 py-4 text-gray">
              Need Help?
          </div>
        </a>
      </div></>) : (<div className="px-3 py-4">Not Authorized</div>)}

      <CreateProfileModal
        isVisible={isCreateProfileModalVisible}
        onHide={hideCreateProfileModal}
        activeUser={myUser}
        initialValues={{
          firstName: '',
          lastName: '',
          userId: (authUser?.sub ? authUser?.sub : ''),
          companyName: '',
        }}
        onSubmit={updateNewUser}
      />
    </div>
  );
};

export default Home;
