import React from 'react';

import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import InputGroup from 'react-bootstrap/esm/InputGroup';

const CustomRepeatForm = () => {
  return (
    <>
      <Form.Group>
        <Row className="mb-2">
          <Col xs="4">
            <span>Repeat every</span>
          </Col>
          <Col xs="8">
            <InputGroup>
              <Form.Control
                type="number"
                defaultValue={1}
                style={{ width: 45, display: 'flex' }}
              />
              <Form.Select style={{ width: 120, display: 'flex' }}>
                <option value="1">Month</option>
                <option value="2">Quarter</option>
                <option value="3">Year</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 8, offset: 4 }}>
            <InputGroup className="flex-nowrap">
              <Form.Select style={{ width: 200, display: 'flex' }}>
                <option value="1">Monthly on day</option>
                <option value="2">Quarterly on day</option>
                <option value="3">Yearly on day</option>
              </Form.Select>
              <Form.Control
                type="number"
                defaultValue={15}
                style={{ width: 45, display: 'flex' }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default CustomRepeatForm;
