import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { SIGN_REPORT_ERROR } from '@/features/Signs/constants';
import useCallApi from '@/hooks/useCallApi';
import { downloadFileFromData, getFilenameFromContentDispositionHeader } from '@/utils/files';

const useReadSignImpressionsReport = () => {
  const [fileDownloaded, setFileDownloaded] = useState<Record<string, never> | null>(null);
  const [signImpressionReportApiConfig, setSignImpressionReportApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readSignImpressionReportResponse,
    loading: readSignImpressionReportIsLoading,
  } = useCallApi(signImpressionReportApiConfig, {
    errorMessage: SIGN_REPORT_ERROR,
  });

  const readSignImpressionReport = (signId: number, {
    startDate,
    endDate,
    type,
  }: {
    startDate: string,
    endDate: string,
    type: 'daily' | 'summary',
  }) => {
    const url = type === 'daily'
      ? `/signs/${signId}/impressions/daily-report`
      : `/signs/${signId}/impressions/summary-report`;

    setSignImpressionReportApiConfig({
      url,
      method: 'get',
      params: {
        startDate,
        endDate,
      },
      responseType: 'blob',
      withCredentials: true,
    });
  };

  useEffect(() => {
    if (!readSignImpressionReportResponse) return;

    const contentDisposition = (readSignImpressionReportResponse.headers['content-disposition'] as string | undefined) || null;
    const filename = getFilenameFromContentDispositionHeader(contentDisposition, 'sign_analytics_report.xlsx');

    setFileDownloaded({});

    downloadFileFromData(readSignImpressionReportResponse.data, filename);
  }, [readSignImpressionReportResponse]);

  return {
    readSignImpressionReport,
    readSignImpressionReportIsLoading,
    fileDownloaded,
  };
};

export default useReadSignImpressionsReport;

