/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Accordion from 'react-bootstrap/esm/Accordion';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Popover from 'react-bootstrap/esm/Popover';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { Field } from 'formik';

import '@/features/Schedule/Schedule.scss';

import { DateRange } from '@/types/recurrenceRule';
import { IContentForm, RecurrenceRuleForm, IPlaylistForm } from '@/features/Schedule/types';
import { END_OF_DAY, FAR_FUTURE_DATE } from '@/features/Schedule/constants';
import { parseDatetime } from '@/utils/helpers';

import RepeatForm from './RepeatForm';

interface DetailedScheduleFormProps {
  item: IContentForm | IPlaylistForm | null;
  startDate: DateTime;
  endDate: DateTime;
  recurrence: RecurrenceRuleForm;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const DetailedScheduleForm = ({
  item,
  startDate,
  endDate,
  recurrence,
  onBlur,
  setFieldValue,
}: DetailedScheduleFormProps) => {
  const [, setRDateOptions] = useState<DateRange[] | null | undefined>(recurrence.rDate);
  const [, setExDateOptions] = useState<DateRange[] | null | undefined>(recurrence.exDate);
  const [, setExTimeOptions] = useState<DateRange[] | null | undefined>(recurrence.exTime);

  useEffect(() => {
    setRDateOptions(recurrence.rDate);
  }, [recurrence.rDate]);

  const addSingleDayPlay = () => {
    recurrence.rDate = (recurrence.rDate || []).concat([[DateTime.now(), DateTime.now()]]);

    // state update necessary for UI to show created date options
    setRDateOptions(recurrence.rDate);
  };

  const removeSingleDayPlay = (index: number) => {
    const rDateShallow = recurrence.rDate ? [...recurrence.rDate] : [];
    rDateShallow?.splice(index, 1);
    recurrence.rDate = rDateShallow;
    setRDateOptions(recurrence.rDate);
  };

  const addException = () => {
    recurrence.exDate = (recurrence.exDate || []).concat([[DateTime.now().startOf('minute'), DateTime.now().plus({ weeks: 1 }).startOf('minute')]]);
    setExDateOptions(recurrence.exDate);
  };

  const removeException = (index: number) => {
    const exDateShallow = recurrence.exDate ? [...recurrence.exDate] : [];
    exDateShallow?.splice(index, 1);
    recurrence.exDate = exDateShallow;
    setExDateOptions(recurrence.exDate);
  };

  const addTimeException = () => {
    recurrence.exTime = (recurrence.exTime || []).concat([[DateTime.now().startOf('minute'), DateTime.now().startOf('minute')]]);
    setExTimeOptions(recurrence.exTime);
  };

  const removeTimeException = (index: number) => {
    const exTimeShallow = recurrence.exTime ? [...recurrence.exTime] : [];
    exTimeShallow?.splice(index, 1);
    recurrence.exTime = exTimeShallow;
    setExTimeOptions(recurrence.exTime);
  };

  const renderPopoverMessage = (title: string, messages: string[]) => (<OverlayTrigger
    trigger="click"
    placement="top"
    rootClose
    overlay={
      (<Popover>
        <Popover.Header as="h3">{title}</Popover.Header>
        <Popover.Body>
          {messages.map((row) => (
            <p key={row}>
              {row}
            </p>
          ))}
        </Popover.Body>
      </Popover>)
    }
  >
    <Button
      variant="link"
      size="sm"
    >
      <FontAwesomeIcon
        icon="info-circle"
      />
    </Button>
  </OverlayTrigger>);

  return (
    <div>
      {/* First and Last Dates */}
      <Form.Group className="mb-3 bg-white shadow-sm px-3 py-4">
        <h5>
          First and Last Dates
          {renderPopoverMessage(
            'First and Last Dates',
            [
              'This is the start and end dates that your content is allowed to play.',
              'Times are applicable only to the first and last dates selected here.',
            ],
          )}
        </h5>
        <Row className="mb-2 flex-nowrap">
          <Col
            className="d-flex align-items-center"
            xs="3"
          >
            <Form.Label
              className="mb-0"
              htmlFor="detailed-schedule-start-date"
            >
              Start
            </Form.Label>
          </Col>
          <Col xs="9">
            <Field
              component={Form.Control}
              type="datetime-local"
              id="detailed-schedule-start-date"
              value={startDate?.toFormat("yyyy-MM-dd'T'HH:mm")}
              onChange={(e: any) => setFieldValue('startDate', DateTime.fromISO(e.target.value || DateTime.now().toISO()))}
              onBlur={onBlur}
              max={endDate?.toFormat("yyyy-MM-dd'T'HH:mm")}
              validate={() => startDate > endDate}
              isInvalid={startDate > endDate}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col
            className="d-flex align-items-center"
            xs="3"
          >
            <Form.Label
              className="mb-0"
              htmlFor="detailed-schedule-end-date"
            >
              End
            </Form.Label>
          </Col>
          <Col xs="9">
            <Field
              component={Form.Control}
              type="datetime-local"
              id="detailed-schedule-end-date"
              value={endDate?.toFormat("yyyy-MM-dd'T'HH:mm")}
              onChange={(e: any) => {
                const selectedEndDate = parseDatetime(DateTime.fromISO(e.target.value));

                const endDateFieldValue = selectedEndDate
                  ? DateTime.fromISO(e.target.value)
                  : DateTime.fromObject(FAR_FUTURE_DATE);
                setFieldValue('endDate', endDateFieldValue);
              }}
              onBlur={onBlur}
              min={startDate?.toFormat("yyyy-MM-dd'T'HH:mm")}
              validate={startDate > endDate}
              isInvalid={startDate > endDate}
            />
            <Form.Control.Feedback type="invalid">
                  Start date must be before end date
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Accordion
        flush={true}
        defaultActiveKey="0"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Advanced Options</Accordion.Header>
          <Accordion.Body>
            {/* Repeat Rules */}
            <RepeatForm
              item={item}
              setFieldValue={setFieldValue}
            />
            <hr />
            {/* Do not play during these times */}
            <Form.Group className="mb-3">
              <h5 className="mb-3">
                    Do not play during these times
                {renderPopoverMessage(
                  'Do not play during these times',
                  [
                    'These are the time ranges your content is not allowed to play.',
                    'For example you may not want your content to play over lunch from 12:00 PM to 1:00 PM',
                  ],
                )}
              </h5>
              <Row>
                <Col xs={{ span: 10, offset: 2 }}>
                  {recurrence.exTime?.map((time, index) => {
                    return (<InputGroup
                      key={index}
                      className="mb-2 flex-nowrap"
                    >
                      <Button
                        variant="outline-danger"
                        onClick={() => removeTimeException(index)}
                      >
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                      <div className="flex-grow-1">
                        <InputGroup>
                          <InputGroup.Text
                            className="input-group-prepend-text"
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                            From
                          </InputGroup.Text>
                          <Field
                            style={{
                              borderBottomRightRadius: 0,
                            }}
                            component={Form.Control}
                            type="time"
                            name={`recurrence.exTime[${index}][0]`}
                            value={time[0].toFormat('T')}
                            onChange={(e: any) => {
                              setFieldValue(`recurrence.exTime[${index}][0]`, DateTime.fromISO(e.target.value));
                            }}
                            max={time[1].toFormat('T')}
                            isInvalid={time[0] > time[1]}
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputGroup.Text
                            className="input-group-prepend-text"
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                                To
                          </InputGroup.Text>
                          <Field
                            style={{
                              borderBottomRightRadius: '0.375rem',
                            }}
                            component={Form.Control}
                            type="time"
                            name={`recurrence.exTime[${index}][1]`}
                            value={time[1].toFormat('T')}
                            onChange={(e: any) => {
                              setFieldValue(`recurrence.exTime[${index}][1]`, DateTime.fromISO(e.target.value));
                            }}
                            min={time[0].toFormat('T')}
                            isInvalid={time[0] > time[1]}
                          />
                          <Form.Control.Feedback type="invalid" className="p-1">
                                Start Time must be before end time
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </InputGroup>
                    );
                  })}
                  <Button
                    className="w-100"
                    size="sm"
                    variant="outline-success"
                    onClick={addTimeException}
                  >
                        Add Times
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <hr />
            {/* Do not play during these dates */}
            <Form.Group className="mb-3">
              <h5 className="mb-3">
                    Do not play during these dates
                {renderPopoverMessage(
                  'Do not play during these dates',
                  [
                    'These are the date ranges your content is not allowed to play on.',
                    'For example you may not want your content to play over Christmas week.',
                  ],
                )}
              </h5>
              <Row>
                <Col xs={{ span: 10, offset: 2 }}>
                  {recurrence.exDate?.map((date, index) => {
                    return (<InputGroup
                      key={index}
                      className="mb-2 flex-nowrap"
                    >
                      <Button variant="outline-danger"
                        onClick={() => removeException(index)}
                      >
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                      <div className="flex-grow-1">
                        <InputGroup>
                          <InputGroup.Text
                            className="input-group-prepend-text"
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                                From
                          </InputGroup.Text>
                          <Field
                            style={{
                              borderBottomRightRadius: 0,
                            }}
                            component={Form.Control}
                            type="date"
                            name={`recurrence.exDate[${index}][0]`}
                            value={date[0].toISODate()}
                            onChange={(e: any) => {
                              const startDateFieldValue = DateTime.fromISO(e.target.value).isValid ? DateTime.fromISO(e.target.value) : DateTime.local();
                              setFieldValue(`recurrence.exDate[${index}][0]`, startDateFieldValue);
                            }}
                            max={date[1].toISODate()}
                            min={DateTime.now().toISODate()}
                            validate={date[0] > date[1]}
                            isInvalid={date[0] > date[1]}
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputGroup.Text
                            className="input-group-prepend-text"
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                                To
                          </InputGroup.Text>
                          <Field
                            style={{
                              borderTopRightRadius: 0,
                            }}
                            component={Form.Control}
                            type="date"
                            name={`recurrence.exDate[${index}][1]`}
                            value={date[1].toISODate()}
                            onChange={(e: any) => {
                              const selectedEndDate = parseDatetime(DateTime.fromISO(e.target.value));

                              const endDateFieldValue = selectedEndDate
                                ? DateTime.local(selectedEndDate.year, selectedEndDate.month, selectedEndDate.day, END_OF_DAY.hour, END_OF_DAY.minute)
                                : DateTime.local(FAR_FUTURE_DATE.year, FAR_FUTURE_DATE.month, FAR_FUTURE_DATE.day, END_OF_DAY.hour, END_OF_DAY.minute);
                              setFieldValue(`recurrence.exDate[${index}][1]`, endDateFieldValue);
                            }}
                            min={date[0].toISODate()?.toString()}
                            validate={date[0] > date[1]}
                            isInvalid={date[0] > date[1]}
                          />
                        </InputGroup>
                      </div>
                      <Form.Control.Feedback type="invalid">
                            Start date must be before end date
                      </Form.Control.Feedback>
                    </InputGroup>
                    );
                  })
                  }
                  <Button
                    className="w-100"
                    size="sm"
                    variant="outline-success"
                    onClick={addException}
                  >
                        Add Dates
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <hr />
            {/* Play on additional days */}
            <Form.Group className="mb-3">
              <h5 className="mb-3">
                    Play on additional day
                {renderPopoverMessage(
                  'Additional Days',
                  [
                    'These are additional days that the content will run. This is useful if you want to run content on days that are not included in your configuration.',
                    `For example you may have the content run on weekends, but also want it to run
                          on a Monday holiday. In this example you would add an additional day for the Monday.`,
                  ],
                )}
              </h5>
              <Row>
                <Col xs={{ span: 10, offset: 2 }}>
                  {recurrence.rDate?.map(([start], index) => {
                    return (<InputGroup
                      key={index}
                      className="mb-2 flex-nowrap"
                    >
                      <Button variant="outline-danger"
                        onClick={() => removeSingleDayPlay(index)}
                      >
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                      {(<Field
                        component={Form.Control}
                        type="date"
                        name={`recurrence.rDate.${index}`}
                        value={start.toISODate()}
                        onChange={(e: any) => {
                          if (e.target.value) {
                            const singleDate = DateTime.fromISO(e.target.value) || DateTime.now();
                            setFieldValue(`recurrence.rDate.${index}[0]`, singleDate);
                            setFieldValue(`recurrence.rDate.${index}[1]`, singleDate);
                          }
                        }}
                        min={DateTime.now().toISODate().toString()}
                      />)}
                    </InputGroup>);
                  })
                  }
                  <Button
                    className="w-100"
                    size="sm"
                    variant="outline-success"
                    onClick={() => addSingleDayPlay()}
                  >
                        Add Date
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default DetailedScheduleForm;
