import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedRoute } from '@/features/Auth/ProtectedRoute';

import Layout from '@/layout';
import HomePage from '@/features/Home';
import LoginPage from '@/features/Auth/Login';
import LogoutPage from '@/features/Auth/Logout';
import NotFoundPage from '@/common/NotFoundPage';
import { FileRoutesSwitch } from '@/features/Files/routes';
import { ScheduleRoutesSwitch } from '@/features/Schedule/routes';
import { SignRoutesSwitch } from '@/features/Signs/routes';

const protectedWithNav = (PageComponent: React.ComponentType) => {
  if (!PageComponent) throw new Error('PageComponent was not specified.');

  const layoutAndPage = () => (<Layout>
    <PageComponent />
  </Layout>);

  return (
    <ProtectedRoute component={layoutAndPage} />
  );
};

/**
 * Builds the routes for the app.
 *
 * @returns {*}
 */
export function RoutesSwitch() {
  return (
    <Routes>
      <Route path="/home" element={protectedWithNav(HomePage)} />
      <Route path="/assets/*" element={protectedWithNav(FileRoutesSwitch)} />
      <Route path="/schedule/*" element={protectedWithNav(ScheduleRoutesSwitch)} />
      <Route path="/signs/*" element={protectedWithNav(SignRoutesSwitch)} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={protectedWithNav(LogoutPage)} />
      <Route path="/" element={<Navigate to="/home" replace={true} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

