// TODO: See if this is even being used..

import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import useCallApi from '@/hooks/useCallApi';

import { IUserResponse, ISignUpUser } from '@/types/user';

import { USER_CREATED_ERROR, USER_CREATED_MESSAGE } from '@/features/Home/constants';

const useSignUpUser = () => {
  const useHubApi = true;
  const [signedUpUser, setSignedUpUser] = useState<IUserResponse | null>(null);
  const [signUpUserApiOptions, setSignUpUserApiOptions] = useState<AxiosRequestConfig | null>(null);
  const {
    data: signUpUserResponse,
    error: signUpUserResponseError,
    loading: signUpUserIsLoading,
  } = useCallApi(signUpUserApiOptions, {
    successMessage: USER_CREATED_MESSAGE,
    errorMessage: USER_CREATED_ERROR,
  }, useHubApi);

  const signUpUser = (signUpData: ISignUpUser) => {
    setSignUpUserApiOptions({
      url: '/sign-up',
      method: 'post',
      data: signUpData,
    });
  };

  useEffect(() => {
    setSignedUpUser(signUpUserResponse?.data);
  }, [signUpUserResponse]);

  return {
    signUpUser,
    signedUpUser,
    signUpUserIsLoading,
    signUpUserResponseError,
  };
};

export default useSignUpUser;
