import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IFileResponse } from '@/types/file';
import {
  FILE_UPDATED_ERROR,
  FILE_UPDATED_MESSAGE,
} from '@/features/Files/constants';

import useCallApi from '@/hooks/useCallApi';

const useUpdateOneFile = () => {
  const [updatedFile, setUpdatedFile] = useState<IFileResponse | null>(null);
  const [putFileApiConfig, setPutFileApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateOneFileResponse,
    loading: updateOneFileIsLoading,
  } = useCallApi(putFileApiConfig, {
    successMessage: FILE_UPDATED_MESSAGE,
    errorMessage: FILE_UPDATED_ERROR,
  });

  const updateOneFile = (fileToUpdate: IFileResponse) => {
    const config: {url: string, method: string, data: IFileResponse | FormData, headers?: {'Content-Type': string}} = {
      url: `/files/${fileToUpdate.id}`,
      method: 'put',
      data: fileToUpdate,
    };

    if (fileToUpdate.file) {
      config.headers = {
        'Content-Type': 'multipart/form-data',
      };
      config.data = fileToUpdate.file;
    }

    setPutFileApiConfig(config);
  };

  useEffect(() => {
    setUpdatedFile(updateOneFileResponse?.data);
  }, [updateOneFileResponse]);

  return {
    updateOneFile,
    updatedFile,
    updateOneFileIsLoading,
  };
};

export default useUpdateOneFile;
