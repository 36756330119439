export const USER_CREATED_MESSAGE = 'User Created';
export const USER_UPDATED_MESSAGE = 'User Updated';
export const USER_DELETED_MESSAGE = 'User Deleted';
export const SEND_INVITE_EMAIL_MESSAGE = 'Invite email sent successfully';

export const USER_LOAD_ERROR = 'Unable to load user';
export const USER_CREATED_ERROR = 'Unable to create user';
export const USER_UPDATED_ERROR = 'Unable to update user';
export const USER_DELETED_ERROR = 'Unable to delete user';
export const USER_PASSWORD_RESET_LINK_ERROR = 'Unable to load password reset link';
export const SEND_INVITE_EMAIL_ERROR = 'Unable to send invite email';

