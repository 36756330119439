import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';

import SelectFileForm from '@/features/Files/SelectFileForm/SelectFileForm';
import { IFileResponse } from '@/types/file';
import { IContentForm } from '@/features/Schedule/types';

interface SwapContentProps {
  isVisible: boolean;
  onHide: () => void;
  onSubmit: (files: IFileResponse[], formValues?: IContentForm) => void;
  initialSelectedFiles?: IFileResponse[],
  isLoading?: boolean;
  maxSelectedFilesCount?: number;
}

const SwapContent = ({
  isVisible,
  onHide,
  onSubmit,
  isLoading,
  initialSelectedFiles,
  maxSelectedFilesCount,
}: SwapContentProps) => {
  const [selectedFiles, setSelectedFiles] = useState<IFileResponse[] | null>(null);

  useEffect(() => {
    // reset state when modal is opened
    if (isVisible === true && initialSelectedFiles) {
      setSelectedFiles(initialSelectedFiles);
    }
  }, [isVisible]);

  const onSelectFiles = (files: IFileResponse[]) => {
    if (maxSelectedFilesCount) {
      // only select the most recent number of files
      const limitedSelectedFiles = files.slice(-maxSelectedFilesCount);
      return setSelectedFiles(limitedSelectedFiles);
    }

    return setSelectedFiles(files);
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      fullscreen="md-down"
      backdrop="static"
      size="lg"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <h1 className="h4 mb-0">Swap Content</h1>
          <h2 className="h6 mb-0">
            Choose File
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 grey-modal-body">
        <div className="p-3 bg-white">
          <SelectFileForm
            selectedFiles={selectedFiles}
            onSelectFiles={onSelectFiles}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer justify-content-between">
        <Button
          variant="light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <div>
          <Button
            type="submit"
            variant="primary"
            disabled={!selectedFiles || !selectedFiles.length}
            onClick={() => onSubmit(selectedFiles || [])}
          >
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SwapContent;
