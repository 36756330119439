import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Tooltip from 'react-bootstrap/esm/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import CustomIcon from '@/common/CustomIcon';

import { IFileResponse } from '@/types/file';
import { ISignCreateRequest, ISignResponse } from '@/types/sign';
import { validatePixelMatrix } from '@/utils/validations';

interface FileListCardProps {
  file: IFileResponse,
  isSelected: boolean,
  onFileSelect: (file: IFileResponse) => void,
  sign?: ISignResponse | ISignCreateRequest | null,
}

const FileListCard = ({
  file,
  isSelected,
  onFileSelect,
  sign,
}: FileListCardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const renderFileThumbnail = () => {
    const fileUrl = file.thumbnailUrl
      ? file.thumbnailUrl
      : file.url;

    if (!fileUrl) {
      return (
        <CustomIcon
          className="me-3"
          style={{
            height: 28,
          }}
          type="file"
        />
      );
    }

    return (
      <div
        className="ratio ratio-1x1 me-3"
        style={{
          width: 36,
        }}
      >
        <div
          className="rounded-2"
          style={{
            backgroundImage: `url(${fileUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames({ 'border-bottom d-flex m-0': true,
          'bg-primary bg-opacity-25 border-primary': isSelected })}
        style={{
          minHeight: 70,
        }}
        onClick={() => onFileSelect(file)}
      >
        <div
          className="py-3 p-2 flex-grow-1 cursor-pointer d-flex align-items-center text-truncate"
          ref={contentRef}
        >
          {isSelected && (<FontAwesomeIcon
            className="bg-white rounded-circle me-2 text-primary"
            icon="circle-check"
          />)}
          {renderFileThumbnail()}
          <div>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <div>{file.name}</div>
                </Tooltip>)}
              container={contentRef}
            >
              <span className="fw-bold text-wrap">
                { file.name }
              </span>
            </OverlayTrigger><br />
            <span
              className={classNames(
                'small',
                sign && !validatePixelMatrix(file, sign) ? 'text-danger' : 'text-muted',
              )}
            >
              {`${file.width} x ${file.height}`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileListCard;
