import React, { Fragment, useState } from 'react';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';

import Accordion from 'react-bootstrap/esm/Accordion';

import { IContentResponse } from '@/types/content';
import { IPlaylistResponse } from '@/types/playlist';
import { ISignResponse } from '@/types/sign';

import SwipeableListItem from '@/common/SwipeableList/SwipeableListItem';
import SwipeableList from '@/common/SwipeableList/SwipeableList';

import { findActivityStatus } from '@/features/Schedule/utils';

import ContentListCard from './ContentListCard';
import PlaylistListCard from './PlaylistListCard';

import '@/features/Schedule/Schedule.scss';
import DefaultContentCard from './DefaultContentCard';
import BumperCard from './BumperCard';

interface ScheduleListProps {
  selectedSign: ISignResponse | null,
  contentList: IContentResponse[] | null,
  playlistList: IPlaylistResponse[] | null,
  onContentDelete: (content: IContentResponse) => void,
  refreshSchedule: () => void,
  refreshSign: () => void,
  selectedContents: IContentResponse[],
  addContentToMultiSelect: (contentClicked: IContentResponse) => void,
  removeContentFromMultiSelect: (contentClicked: IContentResponse) => void,
  inMultiSelectMode: boolean,
  userSigns: ISignResponse[],
}

const ScheduleList = ({
  selectedSign,
  contentList,
  playlistList,
  onContentDelete,
  refreshSchedule,
  refreshSign,
  selectedContents,
  addContentToMultiSelect,
  removeContentFromMultiSelect,
  inMultiSelectMode,
  userSigns,
}: ScheduleListProps) => {
  const routerNavigate = useNavigate();
  const [searchParams] = useSearchParams();
  const openPlaylist = searchParams.get('openPlaylist');

  const [openPlaylistIds, setOpenPlaylistIds] = useState<string[]>(openPlaylist?.split(',') || []);

  const togglePlaylist = (playlistId: number) => {
    const updatedOpenPlaylistIds = openPlaylistIds.includes(playlistId.toString())
      ? openPlaylistIds.filter((id) => id !== playlistId.toString())
      : [...openPlaylistIds, playlistId.toString()];

    setOpenPlaylistIds(updatedOpenPlaylistIds);

    // Note: this check prevents the component from completely clearing out the
    // "openPlaylist" search param. This is a known bug. Without this check
    // the content list refreshes when replacing the route with an empty search string
    // this is likely caused by another component in the tree watching for route changes
    if (updatedOpenPlaylistIds.length === 0) return;

    routerNavigate({
      pathname: '.',
      search: createSearchParams({ openPlaylist: updatedOpenPlaylistIds.join(',') }).toString(),
    }, {
      replace: true,
    });
  };

  const areEntitiesPlayingNow = () => {
    if (!contentList && !playlistList) return false;
    const hasContentsPlayingNow = contentList?.some((content) => findActivityStatus(content, selectedSign?.timezone) === 'active');
    const hasPlaylistsPlayingNow = playlistList?.some((playlist) => findActivityStatus(playlist, selectedSign?.timezone) === 'active');

    return hasContentsPlayingNow || hasPlaylistsPlayingNow;
  };
  const noEntitiesPlayingNow = selectedSign && !areEntitiesPlayingNow();

  const onSwipe = (c: IContentResponse) => {
    onContentDelete(c);
  };

  const renderNoContent = () => {
    if (!selectedSign) return (<span className="p-2">No Signs Found. Go to your <u><a href="/signs" className="underline">Signs</a></u> page to add one.</span>);
    return (<div className="p-2">Nothing playing on this sign yet. Go ahead and add some content.</div>);
  };

  const renderContentList = () => {
    return (
      <section>
        {contentList?.map((content) => {
          const isSelected = !!selectedContents.find((i) => i.id === content.id);

          return (<div
            key={content.id}
          >
            <ContentListCard
              contentItem={content}
              isSelected={isSelected}
              refreshSchedule={refreshSchedule}
              inMultiSelectMode={inMultiSelectMode}
              addContentToMultiSelect={addContentToMultiSelect}
              removeContentFromMultiSelect={removeContentFromMultiSelect}
              selectedSign={selectedSign}
              onEditClick={(clickedContent) => {
                routerNavigate(`/schedule/signs/${selectedSign!.id}/contents/${clickedContent.id}`);
              }}
              userSigns={userSigns}
            />
            <hr className="my-0" />
          </div>);
        })
        }
      </section>
    );
  };

  const renderSwipeableContentList = () => {
    return (
      <SwipeableList>
        {contentList?.map((content) => {
          const isSelected = !!selectedContents.find((i) => i.id === content.id);

          return (<div key={content.id}>
            <SwipeableListItem
              onSwipe={() => onSwipe(content)}
            >
              <ContentListCard
                contentItem={content}
                isSelected={isSelected}
                refreshSchedule={refreshSchedule}
                inMultiSelectMode={inMultiSelectMode}
                addContentToMultiSelect={addContentToMultiSelect}
                removeContentFromMultiSelect={removeContentFromMultiSelect}
                selectedSign={selectedSign}
                onEditClick={(clickedContent) => {
                  routerNavigate(`/schedule/signs/${selectedSign!.id}/contents/${clickedContent.id}`);
                }}
                userSigns={userSigns}
              />
            </SwipeableListItem>
            <hr className="my-0" />
          </div>);
        })
        }
      </SwipeableList>
    );
  };

  return (
    <div className="schedule">
      {(!contentList && !playlistList) && renderNoContent()}
      {noEntitiesPlayingNow && (<>
        <DefaultContentCard
          refreshSchedule={refreshSchedule}
          selectedSign={selectedSign}
        />
        <hr className="my-0" />
      </>)}
      {selectedSign && (<>
        <BumperCard
          selectedSign={selectedSign}
          refreshSign={refreshSign}
        />
        <hr className="my-0" />
      </>)}
      {inMultiSelectMode ? renderContentList() : renderSwipeableContentList()}
      {playlistList?.map((playlist) => {
        const isPlaylistOpen = openPlaylistIds.some((playlistId) => Number(playlistId) === playlist.id);

        return (<Fragment key={playlist.id}>
          <Accordion
            defaultActiveKey={openPlaylistIds}
            alwaysOpen
            flush
          >
            <PlaylistListCard
              playlistItem={playlist}
              onToggle={togglePlaylist}
              refreshSchedule={refreshSchedule}
              selectedSign={selectedSign}
              isPlaylistOpen={isPlaylistOpen}
              userSigns={userSigns}
            />
          </Accordion>
          <hr className="my-0" />
        </Fragment>
        );
      })}
    </div>
  );
};

export default ScheduleList;
