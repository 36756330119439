import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Loader  from '@/common/Loader';

const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  // page automatically redirects to login form
  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return <Loader />;
};

export default LoginButton;

