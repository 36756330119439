import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IFileResponse } from '@/types/file';
import { FILE_LOAD_ERROR } from '@/features/Files/constants';

import useCallApi from '@/hooks/useCallApi';

const useReadOneFile = () => {
  const [file, setFile] = useState<IFileResponse | null>(null);
  const [getFileApiConfig, setGetFileApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOneFileResponse,
    loading: readOneFileIsLoading,
  } = useCallApi(getFileApiConfig, {
    errorMessage: FILE_LOAD_ERROR,
  });

  const readOneFile = (params: {folderId?: string | undefined, fileId: string | undefined}) => {
    const { folderId, fileId } = params;
    if (folderId) {
      setGetFileApiConfig({
        url: `/files/${fileId}`,
        method: 'get',
      });
    } else {
      setGetFileApiConfig({
        url: `/files/${fileId}`,
        method: 'get',
      });
    }
  };

  useEffect(() => {
    setFile(readOneFileResponse?.data);
  }, [readOneFileResponse]);

  return {
    readOneFile,
    file,
    readOneFileIsLoading,
  };
};

export default useReadOneFile;

