import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IFileResponse } from '@/types/file';
import {
  FOLDER_MOVED_MESSAGE,
  FOLDER_MOVED_ERROR,
} from '@/features/Files/FilesTab/constants';

import useCallApi from '@/hooks/useCallApi';

const useMoveFolders = () => {
  const [movedFolders, setMovedFolders] = useState<IFileResponse[] | null>(null);
  const [moveFoldersApiConfig, setMoveFoldersApiConfig] =  useState<AxiosRequestConfig | null>(null);
  const {
    data: moveFoldersResponse,
    loading: moveFoldersIsLoading,
  } = useCallApi(moveFoldersApiConfig, {
    successMessage: FOLDER_MOVED_MESSAGE,
    errorMessage: FOLDER_MOVED_ERROR,
  }) || {};

  const moveFolders = (folderIds: number[], folderId: number | null) => {
    setMoveFoldersApiConfig({
      url: '/folders/move',
      method: 'put',
      data: {
        folderIds,
        folderId,
      },
    });
  };

  useEffect(() => {
    setMovedFolders(moveFoldersResponse?.data);
  }, [moveFoldersResponse]);

  return {
    moveFolders,
    movedFolders,
    moveFoldersIsLoading,
  };
};

export default useMoveFolders;

