import React, { useRef } from 'react';

import Tooltip from 'react-bootstrap/esm/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import CustomIcon from '@/common/CustomIcon';
import { IFolderResponse } from '@/types/folder';

interface FolderListCardProps {
  folder: IFolderResponse,
  onFolderSelect: (folderId: number | null) => void,
}

const FolderListCard = ({
  folder,
  onFolderSelect,
}: FolderListCardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div
        className="border-bottom d-flex"
        style={{
          minHeight: 70,
        }}
        ref={contentRef}
        onClick={() => onFolderSelect(folder.id)}
      >
        <div className="py-3 px-2 flex-grow-1 cursor-pointer d-flex align-items-center text-truncate">
          <CustomIcon
            className="me-3 text-primary"
            style={{
              height: 24,
            }}
            type="folder"
          />
          <OverlayTrigger
            placement="top"
            overlay={(<Tooltip>
              <div>{folder.name}</div>
            </Tooltip>)}
            container={contentRef}
          >
            <span className="fw-bold text-wrap">
              { folder.name }
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default FolderListCard;
