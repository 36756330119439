
import React, { useRef } from 'react';
import Card from 'react-bootstrap/esm/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from 'react-bootstrap/esm/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import { IFolderResponse } from '@/types/folder';

import '@/features/Files/FilesTab/Files.scss';

interface FolderGridCardProps {
  folder: IFolderResponse,
  onFolderSelect: (folderId: number | null) => void,
}

const FolderGridCard = ({
  folder,
  onFolderSelect,
}: FolderGridCardProps) => {
  const { name: folderName } = folder;

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="w-100"
      ref={contentRef}
    >
      <Card
        className="file-list-item"
        onClick={() => onFolderSelect(folder.id)}
      >
        <div>
          <div className="position-relative">
            <div className="ratio ratio-16x9">
              <FontAwesomeIcon
                className="text-primary"
                icon="folder"
              />
            </div>
          </div>
        </div>

        <Card.Footer className="app-card-footer file-list-item-footer bg-transparent">

          <OverlayTrigger
            placement="top"
            overlay={(<Tooltip>
              <div>{folder.name}</div>
            </Tooltip>)}
            container={contentRef}
          >
            <span className="text-wrap">
              {folderName}
            </span>
          </OverlayTrigger>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default FolderGridCard;

