import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Formik, FormikProps } from 'formik';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';

import { IFileWithError } from '@/features/Files/FilesTab/types';
import { IFileUploadForm } from '@/types/file';
import { validateFileSize, validateFileType } from '@/utils/validations';
import Loader from '@/common/Loader';

import '@/features/Files/FilesTab/Files.scss';
import { MAX_FILE_SIZE_IN_MB, VALID_FILE_MIME_TYPES } from '@/utils/constants';

interface UploadFileModalProps {
  show: boolean,
  handleCloseUploadFileModal: () => void,
  onUploadFile: (file: IFileUploadForm) => void,
  loading?: boolean,
}

const uploadFileModal = ({
  show,
  handleCloseUploadFileModal,
  onUploadFile,
  loading,
}: UploadFileModalProps) => {
  const [file, setFile] = useState<IFileWithError | null>(null);

  const acceptedFileTypes = VALID_FILE_MIME_TYPES.join(', ');

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const clickHiddenFileInput = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = (e.target as HTMLInputElement);

    if (!files) {
      return;
    }
    const fileToUpload = files[0];

    const errors = [];
    if (!validateFileSize(fileToUpload)) errors.push(`File must be smaller than ${MAX_FILE_SIZE_IN_MB}MB`);
    if (!validateFileType(fileToUpload)) errors.push(`File must be one of ${acceptedFileTypes}`);

    setFile({
      file: fileToUpload,
      errors,
    });
  };

  const isValid = (): boolean => {
    return file ? !file?.errors.length : false;
  };

  const handleClose = () => {
    setFile(null);
    handleCloseUploadFileModal();
  };

  const handleSubmit = () => {
    if (!file) return;

    if (!isValid()) return;

    onUploadFile({
      file: file?.file,
      origin: 'upload',
    });
  };

  if (loading) {
    return (
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title>Your file is being uploaded</Modal.Title>
        </Modal.Header>
        <div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          files: undefined,
        }}
        onSubmit={() => {
          handleSubmit();
        }}
      >
        {({
          handleBlur,
          isSubmitting,
        }: FormikProps<any>) => {
          return (<>
            <Modal.Body>
              <Form
                id="upload-file-form"
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Row>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control
                        style={{ 'display': 'none' }}
                        ref={hiddenFileInput}
                        name="file"
                        value={undefined} // This is necessary to override Formik's value setting so file uploads work
                        onChange={handleFileChange}
                        onBlur={handleBlur}
                        type="file"
                        accept={acceptedFileTypes}
                      />
                      <Button type="button" variant="outline-primary" onClick={clickHiddenFileInput}>Choose File</Button>
                    </Form.Group>
                  </Col>
                </Row>
                {!!(file && file.file && file.file.name) && (
                  <Row key={file.file.name}>
                    <Col className="overflow-hidden">
                      <p>{file.file.name}<br />
                        {file.errors && file.errors.map((error: string, idx) => (
                          <small key={error} className="text-danger">{error}{idx === file.errors.length - 1 ? '' : ','} </small>
                        ))}
                      </p>
                    </Col>
                  </Row>
                )}
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button type="button" variant="light" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                form="upload-file-form"
                type="submit"
                variant="primary"
                disabled={isSubmitting || !isValid()}
              >
                Upload
              </Button>
            </Modal.Footer>
          </>);
        }}
      </Formik>
    </Modal>
  );
};

export default uploadFileModal;
