import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'react-bootstrap/esm/Button';

import { SortOption } from '@/features/Files/FilesTab/types';
import { useToggle } from '@/hooks';
import { SORT_OPTIONS } from '@/features/Files/FilesTab/constants';
import FileListSortOptions from '@/features/Files/FilesTab/components/FileListSortOptions';

interface PlaylistListControlsProps {
  handleSort: (sortValue: SortOption ) => void,
}

const PlaylistListControls = ({
  handleSort,
}: PlaylistListControlsProps) => {
  const [sortValue, setSortValue] = useState(SORT_OPTIONS[0]);

  const { show: showFileListSortOptions, hide: hideFileListSortOptions, isVisible: isFileListSortOptionsVisible } = useToggle();

  const sort = (option: SortOption) => {
    handleSort(option);
    setSortValue(option);
    hideFileListSortOptions();
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="mb-2"
          variant="outline-primary"
          onClick={showFileListSortOptions}
        >
          <FontAwesomeIcon icon={sortValue.icon} />
        </Button>
      </div>
      <FileListSortOptions
        isVisible={isFileListSortOptionsVisible}
        onHide={hideFileListSortOptions}
        handleSortOptionClick={(option) => sort(option)}
        activeOption={sortValue.name}
      />
    </>
  );
};

export default PlaylistListControls;

