import React, { useState } from 'react';

import Form from 'react-bootstrap/esm/Form';

import { ByDay } from '@/types/recurrenceRule';
import { IContentForm, IPlaylistForm } from '@/features/Schedule/types';
import { BY_WEEK_OPTIONS, BY_WEEK_TO_DAY_MAP, MONTHLY_FREQ } from '@/features/Schedule/constants';

interface MonthlyRepeatFormProps {
  item: IContentForm | IPlaylistForm | null,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const MonthlyRepeatForm = ({
  item,
  setFieldValue,
}: MonthlyRepeatFormProps) => {
  const [selectedFrequency, setSelectedFrequency] = useState<'byDate' | 'byWeek'>(item?.recurrence.byWeek ? 'byWeek' : 'byDate');

  const startDateDay: ByDay = item?.startDate.get('day') as ByDay || 1;
  const existingByDayRecurrence = item && item.recurrence.byDay[0] ? item.recurrence.byDay[0] : null;

  // @ts-expect-error
  const weekInMonth = item?.recurrence?.byWeek || BY_WEEK_OPTIONS.find((week) => (BY_WEEK_TO_DAY_MAP[week].includes(startDateDay))) || 1;
  const dayOfWeekInMonth = existingByDayRecurrence?.dayOfWeek || item?.startDate.get('weekdayShort');

  const onSelectMonthly = (freq: 'byDate' | 'byWeek') => {
    setSelectedFrequency(freq);
    if (freq === 'byWeek') {
      setFieldValue('recurrence.byWeek', weekInMonth);
      setFieldValue('recurrence.byDay', [{
        dayOfWeek: existingByDayRecurrence?.dayOfWeek || String(dayOfWeekInMonth).toUpperCase(),
        startTime: item ? item.startDate : null,
        endTime: item ? item.endDate : null,
      }]);
    } else {
      setFieldValue('recurrence.byWeek', null);
      setFieldValue('recurrence.byDay', []);
    }

    setFieldValue('recurrence.freq', MONTHLY_FREQ);
  };

  return (
    <>
      <Form.Group>
        <Form.Select value={selectedFrequency} onChange={(e) => onSelectMonthly(e.target.value as 'byDate' | 'byWeek')}>
          <option value="byDate">
            Monthly on day {startDateDay}
          </option>
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default MonthlyRepeatForm;
