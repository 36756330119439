import React, { MouseEvent as ReactMouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';
import { useNavigate } from 'react-router-dom';

import { ISignResponse } from '@/types/sign';

import SignOnlineStatusBadge from './signOnlineStatusBadge/SignOnlineStatusBadge';

interface SignCardProps {
  sign: ISignResponse | null,
}

const SignCard = ({ sign }: SignCardProps) => {
  const routerNavigate = useNavigate();

  if (!sign) {
    return null;
  }

  return (
    <div className="border-bottom d-flex align-items-center">
      <div
        className="py-3 px-2 flex-grow-1 cursor-pointer text-truncate"
        onClick={() => routerNavigate(`./${sign.id}`)}
      >
        <span className="fw-bold text-truncate">
          { sign.name }
        </span><br />
        <span className="text-muted small">
          {`${sign.width} x ${sign.height}`}
        </span>
      </div>
      <div className="d-flex align-items-center flex-nowrap">
        <span

          // stop propagation so mobile users do not navigate
          // to sign view page when trying to view the sign status
          onClick={(clickEvent: ReactMouseEvent<HTMLSpanElement>) => clickEvent.stopPropagation()}
        >
          <SignOnlineStatusBadge
            sign={sign}
            size="small"
          />
        </span>
        <Button
          variant="link"
          onClick={() => routerNavigate(`./${sign.id}`)}
        >
          <FontAwesomeIcon
            className="text-gray"
            icon="chevron-right"
          />
        </Button>
      </div>
    </div>
  );
};

export default SignCard;
