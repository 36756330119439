import { DayOfWeek, Frequency } from '@/types/recurrenceRule';

import { IActionMenuItem } from '@/types/actions';

import { IContentCardActionMenuItem, sortOption } from './types';

/* eslint-disable no-magic-numbers */

export const DAILY_FREQ = 'DAILY';
export const WEEKLY_FREQ = 'WEEKLY';
export const MONTHLY_FREQ = 'MONTHLY';
export const YEARLY_FREQ = 'YEARLY';
export const DEFAULT_FREQ = DAILY_FREQ;

export const FREQ_OPTIONS = [DAILY_FREQ, WEEKLY_FREQ, MONTHLY_FREQ, YEARLY_FREQ] as const;

export const FREQ_MAP = {
  DAILY: 'repeatDay',
  WEEKLY: 'repeatDayOfWeek',
  MONTHLY: 'repeatMonth',
  YEARLY: 'repeatYear',
};

export const REPEAT_BY_MAP = {
  repeatDayOfWeek: 'byDay',
  repeatWeek: 'byWeek',
  repeatMonth: 'byMonth',
  repeatYear: 'byYear',
};

export const BY_DAY_OF_WEEK_OPTIONS = [
  '*',
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
] as const;

export const BY_MONTH_OPTIONS = [
  '*',
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
] as const;

const WEEK_ONE_DAYS = [1, 2, 3, 4, 5, 6, 7] as const;
const WEEK_TWO_DAYS = [8, 9, 10, 11, 12, 13, 14] as const;
const WEEK_THREE_DAYS = [15, 16, 17, 18, 19, 20, 21] as const;
const WEEK_FOUR_DAYS = [22, 23, 24, 25, 26, 27, 28] as const;
const WEEK_FIVE_DAYS = [29, 30, 31] as const;

export const BY_DAY_OPTIONS = [
  ...WEEK_ONE_DAYS,
  ...WEEK_TWO_DAYS,
  ...WEEK_THREE_DAYS,
  ...WEEK_FOUR_DAYS,
  ...WEEK_FIVE_DAYS,
];

export const BY_WEEK_OPTIONS = [1, 2, 3, 4, 5] as const;

export const BY_WEEK_TO_DAY_MAP = {
  1: WEEK_ONE_DAYS,
  2: WEEK_TWO_DAYS,
  3: WEEK_THREE_DAYS,
  4: WEEK_FOUR_DAYS,
  5: WEEK_FIVE_DAYS,
};

export const EMPTY_RECURRENCE_FORM = {
  rDate: [],
  exDate: [],
  exTime: [],
  freq: DEFAULT_FREQ as Frequency,
  byDay: null,
  byWeek: null,
  byMonth: null,
  byYear: null,
  startTime: null,
  endTime: null,
};

export const REPEAT_FORM_OPTIONS: { text: string, value: Frequency | 'CUSTOM' | 'NONE' }[] = [
  {
    text: 'Does Not Repeat',
    value: DAILY_FREQ,
  },
  {
    text: 'Weekly',
    value: WEEKLY_FREQ,
  },
  {
    text: 'Monthly',
    value: MONTHLY_FREQ,
  },
  {
    text: 'Annually',
    value: YEARLY_FREQ,
  },

  // removed option for custom frequency until back end handling ready for custom frequency recurrence
  // {
  //   text: 'Custom',
  //   value: 'CUSTOM',
  // },
];

export const DAYS_OF_WEEK_FORM_OPTIONS: { day: DayOfWeek, abbrev: string }[] = [
  {
    day: 'SUN',
    abbrev: 'S',
  }, {
    day: 'MON',
    abbrev: 'M',
  }, {
    day: 'TUE',
    abbrev: 'T',
  }, {
    day: 'WED',
    abbrev: 'W',
  }, {
    day: 'THU',
    abbrev: 'Th',
  }, {
    day: 'FRI',
    abbrev: 'F',
  }, {
    day: 'SAT',
    abbrev: 'S',
  },
];

export const CONTENT_ACTION_EDIT = 'editContent';
export const CONTENT_ACTION_RENAME = 'renameContent';
export const CONTENT_ACTION_DUPLICATE = 'duplicateContent';
export const CONTENT_ACTION_DUPLICATE_TO_PLAYLIST = 'duplicateContentToPlaylist';
export const CONTENT_ACTION_MOVE_TO_PLAYLIST = 'moveContentToPlaylist';
export const CONTENT_ACTION_MOVE_OUT_PLAYLIST = 'moveContentOutPlaylist';
export const CONTENT_ACTION_DELETE = 'deleteContent';
export const CONTENT_ACTION_OPTIONS = [
  CONTENT_ACTION_EDIT,
  CONTENT_ACTION_RENAME,
  CONTENT_ACTION_DUPLICATE,
  CONTENT_ACTION_DUPLICATE_TO_PLAYLIST,
  CONTENT_ACTION_MOVE_TO_PLAYLIST,
  CONTENT_ACTION_MOVE_OUT_PLAYLIST,
  CONTENT_ACTION_DELETE,
] as const;
export const CONTENT_CARD_ACTIONS_MENU_ITEMS: IContentCardActionMenuItem[] = [
  {
    text: 'Rename',
    icon: 'i-cursor',
    action: CONTENT_ACTION_RENAME,
    color: 'primary',
  },
  {
    text: 'Edit',
    icon: 'keyboard',
    action: CONTENT_ACTION_EDIT,
    color: 'primary',
  },
  {
    text: 'Duplicate',
    icon: 'clone',
    action: CONTENT_ACTION_DUPLICATE,
    color: 'primary',
  },
  {
    text: 'Duplicate To Playlist',
    icon: 'copy',
    action: CONTENT_ACTION_DUPLICATE_TO_PLAYLIST,
    color: 'primary',
  },
  {
    text: 'Move to Playlist',
    icon: 'arrow-right-to-bracket',
    action: CONTENT_ACTION_MOVE_TO_PLAYLIST,
    color: 'primary',
  },
  {
    text: 'Move out of Playlist',
    icon: 'arrow-right-from-bracket',
    action: CONTENT_ACTION_MOVE_OUT_PLAYLIST,
    color: 'primary',
  },
  {
    text: 'Delete',
    icon: 'trash',
    action: CONTENT_ACTION_DELETE,
    color: 'danger',
  },
];

export const END_OF_DAY = {
  hour: 23,
  minute: 59,
};

export const FAR_FUTURE_DATE = {
  year: 2099,
  month: 1,
  day: 1,
};

export const DEFAULT_WEIGHT = 5;

export const SORT_OPTIONS: sortOption[] = [
  {
    name: 'A - Z',
    field: 'name',
    direction: 'asc',
    icon: 'arrow-down-a-z',
  },
  {
    name: 'Z - A',
    field: 'name',
    direction: 'desc',
    icon: 'arrow-down-z-a',
  },
  {
    name: 'Date Uploaded (Most Recent)',
    field: 'createdAt',
    direction: 'desc',
    icon: 'arrow-down-wide-short',
  },
  {
    name: 'Date Uploaded',
    field: 'createdAt',
    direction: 'asc',
    icon: 'arrow-down-short-wide',
  },
];

export const PLAYLIST = 'playlist';

export const CONTENT = 'content';

export const PLAY_ORDER_SEQUENTIAL = 'SEQUENTIAL';
export const PLAY_ORDER_SHUFFLED = 'SHUFFLED';

export const PLAY_ORDER_OPTIONS = [PLAY_ORDER_SEQUENTIAL, PLAY_ORDER_SHUFFLED] as const;

export const PLAY_STRATEGY_ONE = 'ONE';
export const PLAY_STRATEGY_ALL = 'ALL';

export const PLAY_STRATEGY_OPTIONS = [PLAY_STRATEGY_ONE, PLAY_STRATEGY_ALL] as const;

export const CONTENT_LOAD_MESSAGE = 'Contents Loaded';
export const CONTENT_CREATED_MESSAGE = 'Content Created';
export const CONTENT_UPDATED_MESSAGE = 'Content Updated';
export const CONTENT_BATCH_UPDATED_MESSAGE = 'Contents Updated';
export const CONTENT_DELETED_MESSAGE = 'Content Deleted';
export const CONTENT_DUPLICATED_MESSAGE = 'Content Duplicated';

export const CONTENT_LOAD_ERROR = 'Unable to load content';
export const CONTENT_CREATED_ERROR = 'Unable to create content';
export const CONTENT_UPDATED_ERROR = 'Unable to update content';
export const CONTENT_BATCH_UPDATED_ERROR = 'Unable to update contents';
export const CONTENT_DELETED_ERROR = 'Unable to delete content';
export const CONTENT_DUPLICATED_ERROR = 'Unable to duplicate content';

export const PLAYLIST_CREATED_MESSAGE = 'Playlist Created';
export const PLAYLIST_UPDATED_MESSAGE = 'Playlist Updated';
export const PLAYLIST_CONTENT_UPDATED_MESSAGE = 'Playlist Content Updated';
export const PLAYLIST_BATCH_UPDATED_MESSAGE = 'Playlists Updated';
export const PLAYLIST_DELETED_MESSAGE = 'Playlist Deleted';
export const PLAYLIST_DUPLICATED_MESSAGE = 'Playlist Copied';

export const PLAYLIST_LOAD_ERROR = 'Unable to load playlist';
export const PLAYLIST_CONTENT_LOAD_ERROR = 'Unable to load playlist content';
export const PLAYLIST_CREATED_ERROR = 'Unable to create playlist';
export const PLAYLIST_UPDATED_ERROR = 'Unable to update playlist';
export const PLAYLIST_CONTENT_UPDATED_ERROR = 'Unable to update playlist content';
export const PLAYLIST_BATCH_UPDATED_ERROR = 'Unable to update playlists';
export const PLAYLIST_DELETED_ERROR = 'Unable to delete playlist';
export const PLAYLIST_DUPLICATED_ERROR = 'Unable to copy playlist';

export const ENTER_KEY_CODE = 13;

export const ACTIVE_STATUS = 'active';
export const PAUSED_STATUS = 'paused';
export const EXPIRED_STATUS = 'expired';
export const FUTURE_STATUS = 'future';

/** PLAYLIST ACTION MENU */
export const PLAYLIST_ACTION_EDIT = 'editPlaylist';
export const PLAYLIST_ACTION_RENAME = 'renamePlaylist';
export const PLAYLIST_ACTION_DELETE = 'deletePlaylist';
export const PLAYLIST_ACTION_SAVE = 'savePlaylist';
export const PLAYLIST_ACTION_DUPLICATE = 'duplicatePlaylist';
export const PLAYLIST_ACTION_OPTIONS = [
  PLAYLIST_ACTION_EDIT,
  PLAYLIST_ACTION_RENAME,
  PLAYLIST_ACTION_SAVE,
  PLAYLIST_ACTION_DUPLICATE,
  PLAYLIST_ACTION_DELETE,
] as const;

export const PLAYLIST_CARD_ACTIONS_MENU_ITEMS: IActionMenuItem[] = [
  {
    text: 'Rename',
    icon: 'i-cursor',
    action: PLAYLIST_ACTION_RENAME,
  },
  {
    text: 'Edit',
    icon: 'keyboard',
    action: PLAYLIST_ACTION_EDIT,
  },
  {
    text: 'Save Copy for Later',
    icon: 'floppy-disk',
    action: PLAYLIST_ACTION_SAVE,
  },
  {
    text: 'Duplicate',
    icon: 'clone',
    action: PLAYLIST_ACTION_DUPLICATE,
  },
  {
    text: 'Delete',
    icon: 'trash',
    action: PLAYLIST_ACTION_DELETE,
    iconColor: 'danger',
  },

];

/** MULTI-SELECT ACTION MENU */
export const MULTISELECT_ACTION_MOVE_TO_PLAYLIST = 'moveToPlaylist';
export const MULTISELECT_ACTION_DUPLICATE = 'duplicateContents';
export const MULTISELECT_ACTION_DUPLICATE_TO_SIGN = 'duplicateContentsToSign';
export const MULTISELECT_ACTION_DELETE = 'deleteContents';
export const MULTISELECT_ACTION_OPTIONS = [
  MULTISELECT_ACTION_MOVE_TO_PLAYLIST,
  MULTISELECT_ACTION_DUPLICATE,
  MULTISELECT_ACTION_DUPLICATE_TO_SIGN,
  MULTISELECT_ACTION_DELETE,
] as const;

export const MULTISELECT_ACTION_MENU_ITEMS: IActionMenuItem[] = [
  {
    text: 'Move to Playlist',
    icon: 'arrow-right-to-bracket',
    action: MULTISELECT_ACTION_MOVE_TO_PLAYLIST,
  },
  {
    text: 'Duplicate',
    icon: 'clone',
    action: MULTISELECT_ACTION_DUPLICATE,
  },
  {
    text: 'Copy to Signs',
    icon: 'clone',
    action: MULTISELECT_ACTION_DUPLICATE_TO_SIGN,
  },
  {
    text: 'Delete',
    icon: 'trash',
    action: MULTISELECT_ACTION_DELETE,
    iconColor: 'danger',
  },
];

export const DUPLICATE_CONTENT_STRATEGY = 'duplicate';
export const MOVE_CONTENT_STRATEGY = 'move';

export const DEFAULT_CONTENT_DURATION_IN_MILLISECONDS = 10000;
