import { ICreateMenuItem, IFileCardActionMenuItem, SortOption, IFolderCardActionMenuItem } from '@/features/Files/FilesTab/types';

export const MAX_FILE_NAME_LENGTH = 10;

export const FOLDER_CARD_ACTION_MOVE = 'moveFolder';
export const FOLDER_CARD_ACTION_RENAME = 'renameFolder';
export const FOLDER_CARD_ACTION_DELETE = 'deleteFolder';

export const FOLDER_CARD_ACTION_OPTIONS = [
  FOLDER_CARD_ACTION_MOVE,
  FOLDER_CARD_ACTION_RENAME,
  FOLDER_CARD_ACTION_DELETE,
] as const;

export const FOLDER_CARD_ACTIONS_MENU_ITEMS: IFolderCardActionMenuItem[] = [
  {
    text: 'Move Folder',
    icon: 'arrow-right-to-bracket',
    action: FOLDER_CARD_ACTION_MOVE,
  },
  {
    text: 'Rename Folder',
    icon: 'keyboard',
    action: FOLDER_CARD_ACTION_RENAME,
  },
  {
    text: 'Delete Folder',
    icon: 'trash',
    action: FOLDER_CARD_ACTION_DELETE,
    iconColor: 'danger',
  },
];

export const FILE_CARD_ACTION_MOVE = 'moveFile';
export const FILE_CARD_ACTION_RENAME = 'renameFile';
export const FILE_CARD_ACTION_REPLACE = 'replaceFile';
export const FILE_CARD_ACTION_DOWNLOAD = 'downloadFile';
export const FILE_CARD_ACTION_DELETE = 'deleteFile';

export const FILE_CARD_ACTION_OPTIONS = [
  FILE_CARD_ACTION_MOVE,
  FILE_CARD_ACTION_RENAME,
  FILE_CARD_ACTION_REPLACE,
  FILE_CARD_ACTION_DOWNLOAD,
  FILE_CARD_ACTION_DELETE,
] as const;

export const FILE_CARD_ACTIONS_MENU_ITEMS: IFileCardActionMenuItem[] = [
  {
    text: 'Move File',
    icon: 'arrow-right-to-bracket',
    action: FILE_CARD_ACTION_MOVE,
  },
  {
    text: 'Rename File',
    icon: 'keyboard',
    action: FILE_CARD_ACTION_RENAME,
  },
  {
    text: 'Replace File',
    icon: 'arrow-right-from-bracket',
    action: FILE_CARD_ACTION_REPLACE,
  },
  {
    text: 'Download File',
    icon: 'download',
    action: FILE_CARD_ACTION_DOWNLOAD,
  },
  {
    text: 'Delete File',
    icon: 'trash',
    action: FILE_CARD_ACTION_DELETE,
  },
];

export const FILE_ACTION_MENU_ITEMS: ICreateMenuItem[] = [
  {
    text: 'Upload',
    icon: 'upload',
    action: 'upload-file',
  },
  {
    text: 'Folder',
    icon: 'folder-plus',
    action: 'add-folder',
  },

  // removed unused file action menu items until presto and custom actions are created
  // {
  //   text: 'Presto',
  //   icon: 'wand-magic-sparkles', // TODO: make this the PC logo
  //   action: 'add-presto',
  //   disabled: true,
  // },
  // {
  //   text: 'Custom',
  //   icon: 'asterisk',
  //   action: 'add-custom',
  //   disabled: true,
  // },
];

export const SORT_OPTIONS: SortOption[] = [
  {
    name: 'Date Uploaded (Most Recent)',
    field: 'createdAt',
    direction: 'desc',
    icon: 'arrow-down-wide-short',
  },
  {
    name: 'A - Z',
    field: 'name',
    direction: 'asc',
    icon: 'arrow-down-a-z',
  },
  {
    name: 'Z - A',
    field: 'name',
    direction: 'desc',
    icon: 'arrow-down-z-a',
  },
  {
    name: 'Last Used',
    field: 'accessed_at',
    direction: 'desc',
    icon: 'arrow-down-short-wide',
  },
];

export const FOLDER_CREATED_MESSAGE = 'Folder Created';
export const FOLDER_UPDATED_MESSAGE = 'Folder Updated';
export const FOLDER_MOVED_MESSAGE = 'Folder Moved';
export const FOLDER_DELETED_MESSAGE = 'Folder Deleted';

export const FOLDER_LOAD_ERROR = 'Unable to load folders';
export const FOLDER_CREATED_ERROR = 'Unable to create folder';
export const FOLDER_UPDATED_ERROR = 'Unable to update folder';
export const FOLDER_MOVED_ERROR = 'Unable to move folder';
export const FOLDER_DELETED_ERROR = 'Unable to delete folder';

export const FILE_CREATED_MESSAGE = 'File Created';
export const FILE_UPDATED_MESSAGE = 'File Updated';
export const FILE_MOVED_MESSAGE = 'File Moved';
export const FILE_DELETED_MESSAGE = 'File Deleted';

export const FILE_LOAD_ERROR = 'Unable to load files';
export const FILE_CREATED_ERROR = 'Unable to create file';
export const FILE_UPDATED_ERROR = 'Unable to update file';
export const FILE_MOVED_ERROR = 'Unable to move file';
export const FILE_DELETED_ERROR = 'Unable to delete file';
