/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useParams, useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { useToggle } from '@/hooks';
import { useCreatePlaylist, useReadOnePlaylist, useUpdateOnePlaylist } from '@/hooks/playlist';

import { useReadOneSign, useReadSigns } from '@/hooks/sign';
import Loader from '@/common/Loader';
import { EMPTY_RECURRENCE_FORM,
  DEFAULT_WEIGHT,
  PLAY_ORDER_OPTIONS,
  PLAY_STRATEGY_OPTIONS,
} from '@/features/Schedule/constants';
import { DATETIME_FORMAT } from '@/utils/constants';
import { today0000, durationFromNow2359, formatDateRangesToString, formatDateRangesToDateTime, formatTimeRangesToDateTime, stringToNumber } from '@/utils/helpers';
import { IPlaylistForm } from '@/features/Schedule/types';
import { IFileResponse } from '@/types/file';
import { IPlaylistUpdateRequest, IPlaylistCreateRequest } from '@/types/playlist';
import ConfirmDeleteModal from '@/features/Schedule/components/ConfirmDeleteModal';
import { userHasAccessToSigns } from '@/features/Schedule/utils';

import PlaylistForm from './components/PlaylistForm';
import PlaylistContentsCard from './components/PlaylistContentsCard';

import '@/features/Schedule/Schedule.scss';

const EditPlaylist = () => {
  const { signId: signIdRouteParam, playlistId: playlistIdRouteParam } = useParams();
  const routerNavigate = useNavigate();

  const signId = stringToNumber(signIdRouteParam);
  const playlistId = stringToNumber(playlistIdRouteParam);

  if (!playlistId) return (<div>No Playlist Id Given.</div>);

  const {
    readSigns,
    signs,
  } = useReadSigns();

  const {
    readOnePlaylist,
    playlist,
    readOnePlaylistIsLoading,
    readOnePlaylistContents,
    playlistContents,
  } = useReadOnePlaylist();

  const {
    createPlaylist,
  } = useCreatePlaylist();

  const {
    updateOnePlaylist,
    updateOnePlaylistIsLoading,
    updatedPlaylist,
    updatedPlaylistContents,
  } = useUpdateOnePlaylist();

  const {
    sign: selectedSign,
    readOneSign,
  } = useReadOneSign();

  const today12AM = today0000();
  const oneMonthFromNow1159PM = durationFromNow2359({ month: 1 });

  // Toggleable Views
  const { show: showDeleteConfirmationModal, hide: hideDeleteConfirmationModal, isVisible: isDeleteConfirmationModalVisible } = useToggle();

  const [currentSignId, setCurrentSignId] = useState<number | null>(signId || null);
  const [filesToAdd, setFilesToAdd] = useState<IFileResponse[]>([]);
  const [playlistDateRange, setPlaylistDateRange] = useState<{start: DateTime, end: DateTime}>({
    start: today12AM,
    end: oneMonthFromNow1159PM,
  });

  // refresh only the content list
  const refreshContentList = () => {
    if (!playlist) return;
    readOnePlaylistContents(playlist.id, { signId: currentSignId });
  };

  useEffect(() => {
    readOnePlaylist(playlistId, { signId: currentSignId });
    if (signId) readOneSign(signId);

    readSigns();
  }, []);

  useEffect(() => {
    if (!playlist) return;

    setPlaylistDateRange({
      start: playlist?.startDatetime ? DateTime.fromFormat(playlist?.startDatetime, DATETIME_FORMAT) : today12AM,
      end: playlist?.startDatetime ? DateTime.fromFormat(playlist?.endDatetime, DATETIME_FORMAT) : oneMonthFromNow1159PM,
    });
  }, [playlist]);

  useEffect(() => {
    if (!updatedPlaylist) return;

    routerNavigate(-1);
  }, [updatedPlaylist]);

  useEffect(() => {
    if (!updatedPlaylistContents) return;

    refreshContentList();
  }, [updatedPlaylistContents]);

  if (readOnePlaylistIsLoading) return (<Loader size="2x" />);

  if (!playlist) return (<div>No Playlist Found</div>);

  const recurrence = playlist?.recurrence || EMPTY_RECURRENCE_FORM;

  const playlistItemInitialFormValues: IPlaylistForm = {
    name: playlist?.name || '',
    startDate: playlist?.startDatetime ? DateTime.fromFormat(playlist?.startDatetime, DATETIME_FORMAT) : today12AM,
    endDate: playlist?.startDatetime ? DateTime.fromFormat(playlist?.endDatetime, DATETIME_FORMAT) : oneMonthFromNow1159PM,
    frequencyWeight: playlist?.frequencyWeight || playlist?.frequencyWeight === 0 ? playlist?.frequencyWeight : DEFAULT_WEIGHT,
    isPaused: playlist?.isPaused || false,
    contentIds: playlist?.contents.map((c) => c.id) || [],
    contents: playlist?.contents || [],
    signIds: playlist?.signs.map((s) => s.id) || [],
    signs: playlist?.signs || [],
    playOrder: playlist?.playOrder || PLAY_ORDER_OPTIONS[0],
    playStrategy: playlist?.playStrategy || PLAY_STRATEGY_OPTIONS[0],
    isLinked: true,
    recurrence: {
      ...recurrence,
      byDay: recurrence.byDay?.map((day) => ({
        dayOfWeek: day.dayOfWeek,
        startTime: DateTime.fromFormat(day.startTime, DATETIME_FORMAT),
        endTime: DateTime.fromFormat(day.endTime, DATETIME_FORMAT),
      })) || [],
      startTime: recurrence.startTime ? DateTime.fromFormat(recurrence.startTime, DATETIME_FORMAT) : today12AM,
      endTime: recurrence.endTime ? DateTime.fromFormat(recurrence.endTime, DATETIME_FORMAT) : oneMonthFromNow1159PM,
      rDate: recurrence.rDate ? formatDateRangesToDateTime(recurrence.rDate) : null,
      exDate: recurrence.exDate ? formatDateRangesToDateTime(recurrence.exDate) : null,
      exTime: recurrence.exTime ? formatTimeRangesToDateTime(recurrence.exTime) : null,
    },
  };

  const onNewContentFilesSelected = (files: IFileResponse[]) => {
    setFilesToAdd([...filesToAdd, ...files]);
  };

  const onPlaylistFormChange = (playlistForm: IPlaylistForm) => {
    setPlaylistDateRange({
      start: playlistForm.startDate,
      end: playlistForm.endDate,
    });
  };

  const updatePlaylistItem = (values: IPlaylistForm) => {
    if (!playlist) return;

    // setIsLinked(values.isLinked || false);
    setCurrentSignId(currentSignId || values.signs[0]?.id || null);

    const playlistPutRequestData: Required<IPlaylistUpdateRequest> = {
      id: playlist.id,
      name: values.name || playlist?.name,
      startDate: values.startDate ? values.startDate.toFormat(DATETIME_FORMAT) : playlist?.startDatetime,
      endDate: values.endDate ? values.endDate.toFormat(DATETIME_FORMAT) : playlist?.endDatetime,
      frequencyWeight: values.frequencyWeight ? values.frequencyWeight : playlist?.frequencyWeight,
      isPaused: values.isPaused,
      fileIds: filesToAdd.map((file) => file.id),

      // use playlistContents instead of values.contentIds. This prevents a user from overwriting
      // changes to the content list when they save their playlist
      contentIds: playlistContents ? playlistContents.map((c) => c.id) : [],
      signIds: values.signs.map((s) => s.id),
      playOrder: values.playOrder || playlist?.playOrder,
      playStrategy: values.playStrategy || playlist?.playStrategy,
      recurrence: {
        rDate: values.recurrence.rDate ? formatDateRangesToString(values.recurrence.rDate) : null,
        exDate: values.recurrence.exDate ? formatDateRangesToString(values.recurrence.exDate) : null,
        exTime: values.recurrence.exTime ? formatDateRangesToString(values.recurrence.exTime) : null,
        freq: values.recurrence.freq,
        byDay: values.recurrence.byDay.map((day) => ({
          dayOfWeek: day.dayOfWeek,
          startTime: day.startTime.toFormat(DATETIME_FORMAT),
          endTime: day.endTime.toFormat(DATETIME_FORMAT),
        })),
        byWeek: values.recurrence.byWeek,
        byMonth: values.recurrence.byMonth,
        byYear: values.recurrence.byYear,
        startTime: values.recurrence.startTime.toFormat(DATETIME_FORMAT),
        endTime: values.recurrence.endTime.toFormat(DATETIME_FORMAT),
      },
      deletedAt: null,
    };

    // If Playlist is Unlinked, save the current sign and create copies on each of the other signs
    if (!values.isLinked && currentSignId) {
      updateOnePlaylist({ ...playlistPutRequestData, signIds: [currentSignId] });
      const unlinkedPlaylists: IPlaylistCreateRequest[] = values.signs.filter((i) => i.id !== currentSignId).map((j) => {
        return {
          ...playlistPutRequestData,
          signIds: [j.id],
        };
      });

      unlinkedPlaylists.forEach((unlinkedPlaylist: IPlaylistCreateRequest) => {
        createPlaylist(unlinkedPlaylist);
      });
    }

    updateOnePlaylist(playlistPutRequestData);
  };

  const deletePlaylist = (deleteAll: boolean) => {
    if (currentSignId) {
      const activeSignIds = playlist.signs
        .filter((sign) => sign.id !== currentSignId)
        .map((s) => s.id);
      const playlistWithoutSignPutData = {
        id: playlist.id,
        contentIds: playlist.contents.map((content) => content.id) || [],
        signIds: deleteAll ? [] : activeSignIds,
      };
      updateOnePlaylist(playlistWithoutSignPutData);
    }
    hideDeleteConfirmationModal();
  };

  return (<>
    <PlaylistForm
      formValues={playlistItemInitialFormValues}
      playlistContents={playlistContents}
      onChange={onPlaylistFormChange}
      onSubmit={updatePlaylistItem}
      slots={{
        playlistContentsCard: (<PlaylistContentsCard
          sign={selectedSign}
          playlist={playlist}
          playlistContents={playlistContents}
          playlistDateRange={playlistDateRange}
          pendingPlaylistContents={filesToAdd}
          refreshContentList={refreshContentList}
          addNewContent={onNewContentFilesSelected}
          userSigns={signs}
        />),
        playlistDeleteButton: ((currentSignId) && (
          <section className="px-3 py-2 mt-3">
            <Button
              variant="danger"
              className="w-100"
              onClick={showDeleteConfirmationModal}
            >
              Delete Playlist
            </Button>
          </section>
        )),
      }}
      isLoading={updateOnePlaylistIsLoading || readOnePlaylistIsLoading}
      userSigns={signs}
    />

    {/* Confirm Delete Playlist */}
    <ConfirmDeleteModal
      show={isDeleteConfirmationModalVisible}
      onHide={hideDeleteConfirmationModal}
      contentName={playlist?.name || 'Playlist'}
      onConfirmDelete={deletePlaylist}
      singleInstanceButtonText="Remove from this Sign"
      allInstanceButtonText="Remove from All Signs"
      disableAllInstanceButton={!userHasAccessToSigns(signs, playlist.signs)}
      disableAllInstanceButtonReason={!userHasAccessToSigns(signs, playlist.signs)
        ? 'Cannot remove from all signs. Playlist is attached to signs that you do not have permissions to'
        : ''
      }
    />
  </>);
};

export default EditPlaylist;
