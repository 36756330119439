/* eslint-disable no-process-env */
import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';

import useMyUser from '@/context/myUser/useMyUser';

const useEmulateUser = () => {
  const useHubApi = true;

  const {
    refreshMyUser,
  } = useMyUser();

  // clear emulate
  const [clearEmulateUser, setClearEmulateUser] = useState<Record<string, never> | null>(null);
  const [clearEmulateApiConfig, setClearEmulateApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: clearEmulateResponse,
    loading: clearEmulateIsLoading,
  } = useCallApi(clearEmulateApiConfig, {
    successMessage: 'User emulation exited',
    errorMessage: 'Unable to exit user emulation',
  }, useHubApi);

  const clearEmulate = () => {
    setClearEmulateApiConfig({
      url: '/users/clear-emulate',
      method: 'post',
    });
  };
  useEffect(() => {
    if (!clearEmulateResponse) return;

    setClearEmulateUser({});
    refreshMyUser();

    window.location.href = `${process.env.REACT_APP_PROJECT_HUB_APP_URL}/admin/users`;
  }, [clearEmulateResponse]);

  return {
    clearEmulate,
    clearEmulateUser,
    clearEmulateIsLoading,
  };
};

export default useEmulateUser;
