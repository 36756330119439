import React from 'react';
import Button from 'react-bootstrap/esm/Button';

import { IFileResponse, IFileUploadForm } from '@/types/file';
import { useToggle } from '@/hooks';

import FileListItem from './FileListItem';

const INITIAL_FILE_COUNT_TO_SHOW = 5;

interface FileListViewProps {
  fileList: IFileResponse[],
  onMoveFile: (fileClicked: IFileResponse) => void,
  onRenameFile: (fileClicked: IFileResponse) => void,
  onReplaceFile: (fileClicked: IFileResponse, fileToUpload: IFileUploadForm) => void,
  onDeleteFile: (fileClicked: IFileResponse) => void,
  selectedFiles: IFileResponse[],
  addFileToMultiSelect: (fileClicked: IFileResponse) => void,
  removeFileFromMultiSelect: (fileClicked: IFileResponse) => void,
  onSelectFolderDestination: Function,
  inMultiSelectMode: boolean,
  putFileIsLoading?: boolean,
}

const fileListView = ({
  fileList,
  onMoveFile,
  onRenameFile,
  onReplaceFile,
  onDeleteFile,
  selectedFiles,
  addFileToMultiSelect,
  removeFileFromMultiSelect,
  inMultiSelectMode,
  putFileIsLoading,
}: FileListViewProps) => {
  const { toggle: toggleShowMore, isVisible: showMore } = useToggle();

  const fileCount = fileList.length;

  const filesFiltered = fileCount > INITIAL_FILE_COUNT_TO_SHOW && !showMore
    ? fileList.slice(0, INITIAL_FILE_COUNT_TO_SHOW)
    : fileList;

  return (
    <section>
      <h2 className="h5 border-bottom pb-3 mb-0">
        Files <small>({ fileList.length })</small>
      </h2>
      {(fileList.length === 0) && (
        <div className="px-2 py-3">
          No Files Found
        </div>
      )}
      {filesFiltered.map((file) => {
        const isSelected = selectedFiles.some((item) => item.id === file.id);

        return (<FileListItem
          key={file.id}
          file={file}
          onMoveFile={onMoveFile}
          onRenameFile={onRenameFile}
          onReplaceFile={onReplaceFile}
          onDeleteFile={onDeleteFile}
          isSelected={isSelected}
          addFileToMultiSelect={addFileToMultiSelect}
          removeFileFromMultiSelect={removeFileFromMultiSelect}
          inMultiSelectMode={inMultiSelectMode}
          putFileIsLoading={putFileIsLoading}
        />);
      })}
      {(fileCount > INITIAL_FILE_COUNT_TO_SHOW) && (
        <Button
          className="w-100"
          variant="link"
          onClick={toggleShowMore}
        >
          Show {showMore ? 'Less' : 'More' }
        </Button>
      )}
    </section>
  );
};

export default fileListView;
