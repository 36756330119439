import React, { useState, useEffect } from 'react';
import { createSearchParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { useToggle } from '@/hooks';
import { useReadSigns } from '@/hooks/sign';

import Loader from '@/common/Loader';
import FloatingActionButton, { FloatinActionButtonOptions } from '@/common/FloatingActionButton';
import Search from '@/common/Search';
import { USER_ROLE } from '@/constants';
import AddSignWizard from '@/features/Signs/addSign/AddSignWizard';
import useMyUser from '@/context/myUser/useMyUser';
import SignCard from '@/features/Signs/components/SignCard';

import './Signs.scss';

const Signs = () => {
  const { myUser } = useMyUser();
  const routerNavigate = useNavigate();
  const routerLocation = useLocation();
  const [searchParams] = useSearchParams();
  const searchByName = searchParams.get('name');

  const {
    readSigns,
    signs,
    readSignsIsLoading,
  } = useReadSigns();

  const [searchString, setSearchString] = useState<string>(searchByName || '');
  const { show: showAddSignWizard, hide: hideAddSignWizard, isVisible: isSignAddWizardVisible } = useToggle();

  useEffect(() => {
    const params = routerLocation.search ? { name: searchByName } : {};
    readSigns(params);
  }, [routerLocation]);

  useEffect(() => {
    if (searchString) {
      const params = {
        name: searchString,
      };
      return routerNavigate({
        pathname: '/signs',
        search: createSearchParams(params).toString(),
      });
    }

    return routerNavigate(routerLocation.pathname);
  }, [searchString]);

  const onSignCreateSuccess = () => {
    readSigns();
  };

  const renderSignList = () => {
    if (readSignsIsLoading) return <Loader size="2x" />;

    return ((signs) && (
      <section className="mt-2 px-3 py-4 bg-white shadow-sm">
        <h2 className="h5 border-bottom pb-3 mb-0">
          Signs <small>({ signs.length })</small>
        </h2>
        {(signs.length === 0) && (
          <div className="px-2 py-3">
            No Signs Found
          </div>
        )}
        {signs.map((sign) => (
          <div
            key={sign.id}
          >
            <SignCard
              sign={sign}
            />
          </div>
        ))}
      </section>
    ));
  };

  const floatingActionOptions: FloatinActionButtonOptions = [{
    icon: 'rectangle-list',
    text: 'Sign',
    onClick: showAddSignWizard,
  }];

  return (
    <div className="has-fab">
      <div className="py-4 px-3 bg-white shadow-sm">
        <Search
          setSearchString={setSearchString}
          searchString={searchString}
          placeholder="Search all Signs"
          searchId="search-signs"
        />
      </div>

      {renderSignList()}

      {(myUser && myUser.role !== USER_ROLE) && (<FloatingActionButton
        options={floatingActionOptions}
      />)}
      <AddSignWizard
        isVisible={isSignAddWizardVisible}
        onHide={hideAddSignWizard}
        onCreateSuccess={onSignCreateSuccess}
      />
    </div>
  );
};

export default Signs;
