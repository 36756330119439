import { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

// ceates a unique id that can persists through component updates
const useUniqueId = (prefix?: string) => {
  const [id] = useState(_uniqueId(prefix));

  return id;
};

export default useUniqueId;
